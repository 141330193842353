import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faMagnifyingGlass, faSpinner, faFileInvoice, faUser, faBan } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

function Earnings(props) {

    const [earnings, setEarnings] = useState(null);

    const [modalEarnings, setModalEarnings] = useState(false);

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {

    }, []);

    const modalEarningsToggle = () => setModalEarnings(!modalEarnings);
    const openModalEarnings = () => {
        getData();
    };
    const getData = async () => {
        setShowLoading(true);
        const earnResponse = await fetch('/api/account/earnings?account=' + encodeURIComponent(props.account));
        const earnData = await earnResponse.json();
        setEarnings(earnData);

        modalEarningsToggle();
        setShowLoading(false);

    }




    return (
        <>
            <button type="submit" className="btn btn-secondary btn-sm" onClick={openModalEarnings} disabled={showLoading} >
                {showLoading ?
                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                    :
                    <FontAwesomeIcon icon={faAddressCard} className="pe-2" />
                }
                Earnings
            </button>
            <Modal isOpen={modalEarnings} toggle={modalEarningsToggle} size="xxl">
                <ModalHeader toggle={modalEarningsToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faAddressCard} className="pe-2" />Earnings</h5>
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar",
                        "maxHeight": "calc(100vh - 225px)"
                    }}>

                        <table className="table table-sm table-xs table-xxs table-hover" style={{ display: "table" }}>
                            <thead>
                                <tr>
                                    <th className="table-light nowrap">EmplId</th>
                                    <th className="table-light nowrap">Position</th>
                                    <th className="table-light nowrap">Empl Rcd</th>
                                    <th className="table-light nowrap">Employee</th>
                                    <th className="table-light nowrap">Pay End Date</th>
                                    <th className="table-light nowrap">Financial Fiscal Year</th>
                                    <th className="table-light nowrap">Financial Fiscal Month</th>
                                    <th className="table-light nowrap">PeopleSoft Fiscal Month</th>
                                    <th className="table-light nowrap">Earn CD</th>
                                    <th className="table-light nowrap">Pay Group</th>
                                    <th className="table-light nowrap">Earnings</th>
                                    <th className="table-light nowrap">Source</th>
                                    <th className="table-light nowrap">Transaction #</th>
                                    <th className="table-light nowrap">System</th>
                                </tr>
                            </thead>
                            <tbody>
                                {earnings ? earnings.map((earn, i) => 
                                    <tr key={i}>
                                        <td className="nowrap">{earn.EmplId}</td>
                                        <td className="nowrap">{earn.Position}</td>
                                        <td className="nowrap text-end">{earn.EmplRcd}</td>
                                        <td className="nowrap">{earn.Name}</td>
                                        <td className="nowrap text-end">{moment(earn.PayEndDate).format('M/D/yyyy')}</td>
                                        <td className="nowrap text-end">{earn.FiscalYear}</td>
                                        <td className="nowrap text-end">{earn.FiscalMonth}</td>
                                        <td className="nowrap text-end">{earn.PSFiscalMonth}</td>
                                        <td className="nowrap">{earn.EarnCode}</td>
                                        <td className="nowrap">{earn.PayGroup}</td>
                                        <td className="nowrap text-end"><NumericFormat value={earn.Earnings} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="nowrap">{earn.Source}</td>
                                        <td className="nowrap text-end">{earn.TransactionNumber}</td>
                                        <td className="nowrap">{earn.Origin}</td>

                                        {/*<td className="text-end">*/}
                                        {/*    {*/}
                                        {/*        Number(expense.LedgerAccountId) >= 7200 ?*/}
                                        {/*            <a href="#" className="link-offset link-offset-2-hover link-underline link-underline-opacity-0 link-underline-opacity-100-hover" onClick={(e) => handleLedgerDetail(e, expense.LedgerAccountId, null, null)}><NumericFormat value={expense.Total} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></a>*/}
                                        {/*            :*/}
                                        {/*            <NumericFormat value={expense.Total} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                        {/*    }*/}
                                        {/*</td>*/}
                                        {/*<td></td>*/}
                                        {/*{fiscalPeriods ? fiscalPeriods.map(period =>*/}
                                        {/*    <td key={expense.LedgerAccountId + '-' + period.FiscalYear + '-' + period.FiscalMonth} className="text-end nowrap">*/}
                                        {/*        {*/}
                                        {/*            Number(expense.LedgerAccountId) >= 7200 ?*/}
                                        {/*                <a href="#" className="link-offset link-offset-2-hover link-underline link-underline-opacity-0 link-underline-opacity-100-hover" onClick={(e) => handleLedgerDetail(e, expense.LedgerAccountId, period.FiscalYear, period.FiscalMonth)}><NumericFormat value={getLedgerExpenseByPeriod(expense.LedgerAccountId, period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></a>*/}
                                        {/*            :*/}
                                        {/*            <NumericFormat value={getLedgerExpenseByPeriod(expense.LedgerAccountId, period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                        {/*        }*/}
                                        {/*    </td>*/}
                                        {/*) : null}*/}
                                    </tr>
                                ) : null
                               }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalEarningsToggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Earnings;