import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

function Header() {
    return (
        <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-1">
            <div className="container-xl px-2">
                <div className="page-header-content">
                    <div className="row align-items-center justify-content-between pt-2">
                        <div className="col-auto mb-3">
                            <h1 className="page-header-title">
                                <div className="page-header-icon">
                                    <FontAwesomeIcon icon={faFileInvoice} className="px-1" />
                                </div>
                                Accounts
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;