import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

function ReportSubMenu(props) {
    const { guid, version } = props;
    return (
        <div className="container-xl px-2 pb-3 mb-4">
            <nav className="nav nav-borders" >
                <NavLink to={`/reports/accountsummary/${version ? "version/" : ""}${guid}`} className="nav-link">Account Summary</NavLink>
                <NavLink to={`/reports/employees/${version ? "version/" : ""}${guid}`} className="nav-link">Employees</NavLink>
                <NavLink to={`/reports/expenses/${version ? "version/" : ""}${guid}`} className="nav-link">Expenses</NavLink>
                {/*<Link to="/accounts/find" className="nav-link">My Portfolio</Link>*/}
            </nav>
        </div>
    );
}

export default ReportSubMenu;