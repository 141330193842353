import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faMagnifyingGlass, faSpinner, faFileInvoice, faUserPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

function ExpensesByMonth(props) {

    const [rateTypes, setRateTypes] = useState(null);
    const [expensesByMonth, setExpensesByMonth] = useState([]);
    const [expensesByMonthEarnings, setExpensesByMonthEarnings] = useState([]);
    const [expensesByMonthDetail, setExpensesByMonthDetail] = useState([]);
    const [ledgerAccountTotals, setLedgerAccountTotals] = useState([]);
    const [fiscalPeriods, setFiscalPeriods] = useState([]);
    const [fiscalPeriodsEmployee, setFiscalPeriodsEmployee] = useState([]);
    const [employeeTotals, setEmployeeTotals] = useState([]);

    const [modalExpensesByMonth, setModalExpensesByMonth] = useState(false);
    const [modalExpensesByMonthDetail, setModalExpensesByMonthDetail] = useState(false);

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {

    }, []);

    const modalExpensesByMonthToggle = () => setModalExpensesByMonth(!modalExpensesByMonth);
    const modalExpensesByMonthDetailToggle = () => setModalExpensesByMonthDetail(!modalExpensesByMonthDetail);
    const openModalExpensesByMonth = () => {
        getData();
    };
    const getData = async () => {
        setShowLoading(true);
        const rtResponse = await fetch('/api/account/ratetypes?account=' + encodeURIComponent(props.account));
        const rtData = await rtResponse.json();
        setRateTypes(rtData);

        const ebmResponse = await fetch('/api/account/expensesbymonth?account=' + encodeURIComponent(props.account));
        const ebmData = await ebmResponse.json();
        setExpensesByMonth(ebmData);

        const earnResponse = await fetch('/api/account/expensesbymonthearnings?account=' + encodeURIComponent(props.account));
        const earnData = await earnResponse.json();
        setExpensesByMonthEarnings(earnData);

        let latData = [];
        let fiscalPeriods = [];
        if (ebmData && ebmData.length > 0) {
            ebmData.forEach(ebm => {
                let thisIX = latData.findIndex(lat => lat.LedgerAccount === ebm.LedgerAccount);
                let fiscalIX = fiscalPeriods.findIndex(fp => fp.FiscalMonth === ebm.FiscalMonth && fp.FiscalYear === ebm.FiscalYear);

                if (thisIX < 0) {
                    latData.push({ LedgerAccountId: ebm.LedgerAccountId, LedgerAccount: ebm.LedgerAccount, ApplyFA: ebm.ApplyFA, Total: ebm.Expenses });
                }
                else {
                    latData[thisIX].Total += ebm.Expenses;
                }
                if (fiscalIX < 0) {
                    fiscalPeriods.push({ FiscalYear: ebm.FiscalYear, FiscalMonth: ebm.FiscalMonth });
                }

            });
        }
        fiscalPeriods.sort((a, b) => {
            if (a.FiscalYear > b.FiscalYear) return 1;
            if (a.FiscalYear < b.FiscalYear) return -1;
            if (a.FiscalMonth > b.FiscalMonth) return 1;
            if (a.FiscalMonth < b.FiscalMonth) return -1;
            return 0;
        });


        //copy fiscalPeriods for employees since we have to add records without a month/year
        let fiscalPeriodsEmployee = [...fiscalPeriods];

        let empData = [];
        if (earnData && earnData.length > 0) {
            earnData.forEach(earn => {
                let thisIX = empData.findIndex(emp => emp.EmplId === earn.EmplId && emp.Position === earn.Position && emp.EmplRcd === earn.EmplRcd);
                if (thisIX < 0) {
                    empData.push({ Employee: earn.Employee, EmplId: earn.EmplId, Position: earn.Position, EmplRcd: earn.EmplRcd, Title: earn.Title, Earnings: earn.Earnings });
                }
                else {
                    empData[thisIX].Earnings += earn.Earnings;
                }
            });

            if (earnData.some(x => x.FiscalMonth === 0 || x.FiscalYear === 0)) {
                //console.log('we have some with zeros')
                fiscalPeriodsEmployee.push({ FiscalYear: 0, FiscalMonth: 0 });
            }
        }

        setEmployeeTotals(empData);
        setLedgerAccountTotals(latData);
        setFiscalPeriods(fiscalPeriods);
        setFiscalPeriodsEmployee(fiscalPeriodsEmployee);
        modalExpensesByMonthToggle();
        setShowLoading(false);

    }


    const getLedgerExpenseTotalsByPeriod = (fiscalYear, fiscalMonth) => {
        let total = 0;

        total = getLedgerDirectCostsByPeriod('No', fiscalYear, fiscalMonth) + getLedgerDirectCostsByPeriod('Yes', fiscalYear, fiscalMonth) + getFACostsTotalByPeriod(fiscalYear, fiscalMonth);

        return total;
    }
    const getLedgerExpenseTotal = () => {
        let total = 0;

        if (fiscalPeriods)
            fiscalPeriods.forEach(fp => total += getLedgerExpenseTotalsByPeriod(fp.FiscalYear, fp.FiscalMonth))

        return total;
    }
    const getFACostsTotalByPeriod = (fiscalYear, fiscalMonth) => {
        let faCosts = 0;
        if (expensesByMonth)
            expensesByMonth.filter(f => Number(f.LedgerAccountId) >= 7500 && f.FiscalYear === fiscalYear && f.FiscalMonth === fiscalMonth).forEach(ebm => faCosts += ebm.Expenses)
        return faCosts;
    }
    const getFACostsTotal = (fiscalYear, fiscalMonth) => {
        let total = 0;

        if (fiscalPeriods)
            fiscalPeriods.forEach(fp => total += getFACostsTotalByPeriod(fp.FiscalYear, fp.FiscalMonth))

        return total;
    }
    const getLedgerExpenseByPeriod = (expense, fiscalYear, fiscalMonth) => {
        let expenseValue = null;
        if (expensesByMonth && expensesByMonth.length > 0) {
            let thisExpense = expensesByMonth.find((e) => e.FiscalYear === fiscalYear && e.FiscalMonth === fiscalMonth && e.LedgerAccount === expense.LedgerAccount);
            expenseValue = thisExpense ? thisExpense.Expenses : null;
        }
        return expenseValue;
    }
    const getEarningsByPeriod = (emplId, position, emplRcd, fiscalYear, fiscalMonth) => {
        let earnings = null;
        if (expensesByMonthEarnings && expensesByMonthEarnings.length > 0) {
            let thisExpenseEarning = expensesByMonthEarnings.find((e) => e.FiscalYear === fiscalYear && e.FiscalMonth === fiscalMonth && e.EmplId === emplId && e.Position === position && e.EmplRcd === emplRcd);
            earnings = thisExpenseEarning ? thisExpenseEarning.Earnings : null;
        }
        return earnings;
    }
    const getLedgerDirectCostsByPeriod = (applyFA, fiscalYear, fiscalMonth) => {
        let returnValue = 0;
        if (expensesByMonth && expensesByMonth.filter(ebm => ebm.ApplyFA === applyFA && Number(ebm.LedgerAccountId) < 7500 && ebm.FiscalYear === fiscalYear && ebm.FiscalMonth === fiscalMonth).length > 0) {
            expensesByMonth.filter(ebm => ebm.ApplyFA === applyFA && Number(ebm.LedgerAccountId) < 7500 && ebm.FiscalYear === fiscalYear && ebm.FiscalMonth === fiscalMonth).forEach(s => { returnValue += s.Expenses; });
        }
        return returnValue;
    }
    const getLedgerDirectCosts = (applyFA) => {
        let returnValue = 0;
        if (ledgerAccountTotals && ledgerAccountTotals.filter(lat => lat.ApplyFA === applyFA && Number(lat.LedgerAccountId) < 7500).length > 0) {
            ledgerAccountTotals.filter(lat => lat.ApplyFA === applyFA && Number(lat.LedgerAccountId) < 7500).forEach(s => { returnValue += s.Total; });
        }
        return returnValue;
    }
    const handleLedgerDetail = async (e, ledgerAccountId, fiscalYear, fiscalMonth) => {
        e.preventDefault();
        setShowLoading(true);

        let query = '?account=' + encodeURIComponent(props.account);
        if (ledgerAccountId) {
            query += '&ledgerAccountId=' + encodeURIComponent(ledgerAccountId);
        }
        if (fiscalYear) {
            query += '&fiscalYear=' + encodeURIComponent(fiscalYear);
        }
        if (fiscalMonth) {
            query += '&fiscalMonth=' + encodeURIComponent(fiscalMonth);
        }

        const detailResponse = await fetch('/api/account/expensesbymonthdetail' + query);
        const detailData = await detailResponse.json();

        setExpensesByMonthDetail(detailData);

        setModalExpensesByMonthDetail(true);
        setShowLoading(false);
    }


    return (
        <>
            <button type="submit" className="btn btn-secondary btn-sm" onClick={openModalExpensesByMonth} disabled={showLoading} >
                {showLoading ?
                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                    :
                    <FontAwesomeIcon icon={faCalendarDays} className="pe-2" />
                }
                Expenses By Month
            </button>
            <Modal isOpen={modalExpensesByMonth} toggle={modalExpensesByMonthToggle} size="xxl">
                <ModalHeader toggle={modalExpensesByMonthToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faCalendarDays} className="pe-2" />Expenses By Month</h5>
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody>
                    {/*{rateTypes ? rateTypes.WorkdayRateAgreement : ''}<br />*/}
                    {/*{rateTypes ? rateTypes.Base : ''}<br />*/}
                    {/*{rateTypes ? rateTypes.AccountType : ''}<br />*/}
                    {/*{rateTypes ? rateTypes.BasisLimit : ''}<br />*/}
                    {/*{rateTypes ? rateTypes.FARateType : ''}<br />*/}
                    {/*{rateTypes ? rateTypes.WorkdayCampusType : ''}<br />*/}
                    {/*{rateTypes ? rateTypes.WorkdayRateException : ''}<br />*/}
                    {/*{rateTypes ? rateTypes.ExceptionRate : ''}<br />*/}
                    {/*{rateTypes ? rateTypes.Sponsor : ''}<br />*/}
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar",
                        "maxHeight": "calc(100vh - 225px)"
                    }}>

                        <table className="table table-sm table-xs table-xxs table-hover" style={{ display: "table" }}>
                            <thead>
                                <tr>
                                    <th colSpan="7" rowSpan="1"></th>
                                    <th className="table-light text-end nowrap">Fiscal Year</th>
                                    {fiscalPeriods ? fiscalPeriods.map(period =>
                                        <td key={period.FiscalYear + '-' + period.FiscalMonth} className="text-end nowrap">{period.FiscalYear}</td>
                                    ) : null}
                                </tr>
                                <tr>
                                    <th className="table-light nowrap" colSpan="5">Ledger Account</th>
                                    <th className="table-light nowrap" colSpan="1">Apply F&A</th>
                                    <th className="table-light text-center nowrap">All Years</th>
                                    <th className="table-light text-end nowrap">Fiscal Month</th>
                                    {fiscalPeriods ? fiscalPeriods.map(period =>
                                        <td key={period.FiscalYear + '-' + period.FiscalMonth} className="text-end nowrap">{period.FiscalMonth}</td>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {ledgerAccountTotals && ledgerAccountTotals.filter(lat => lat.LedgerAccountId < 7500).length > 0 ? ledgerAccountTotals.filter(lat => lat.LedgerAccountId < 7500).map(expense =>
                                    <tr key={Number(expense.LedgerAccountId)} >
                                        <td className="nowrap" colSpan="5">{expense.LedgerAccount}</td>
                                        <td colSpan="1">{expense.ApplyFA}</td>
                                        <td className="text-end">
                                            {
                                                Number(expense.LedgerAccountId) >= 7200 ?
                                                    <a href="#" className="link-offset link-offset-2-hover link-underline link-underline-opacity-0 link-underline-opacity-100-hover" onClick={(e) => handleLedgerDetail(e, expense.LedgerAccountId, null, null)}><NumericFormat value={expense.Total} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></a>
                                                    :
                                                    <NumericFormat value={expense.Total} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                            }
                                        </td>
                                        <td></td>
                                        {fiscalPeriods ? fiscalPeriods.map(period =>
                                            <td key={expense.LedgerAccountId + '-' + period.FiscalYear + '-' + period.FiscalMonth} className="text-end nowrap">
                                                {
                                                    Number(expense.LedgerAccountId) >= 7200 ?
                                                        <a href="#" className="link-offset link-offset-2-hover link-underline link-underline-opacity-0 link-underline-opacity-100-hover" onClick={(e) => handleLedgerDetail(e, expense.LedgerAccountId, period.FiscalYear, period.FiscalMonth)}><NumericFormat value={getLedgerExpenseByPeriod(expense, period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></a>
                                                    :
                                                    <NumericFormat value={getLedgerExpenseByPeriod(expense, period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                }
                                            </td>
                                        ) : null}
                                    </tr>
                                ) : null}
                            </tbody>
                            <tbody className="table-group-divider">
                                <tr>
                                    <td colSpan="5">Direct costs without F&A applied</td>
                                    <td className="text-end" colSpan="2">
                                        <NumericFormat value={getLedgerDirectCosts('No')} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                    </td>
                                    <td></td>
                                    {fiscalPeriods ? fiscalPeriods.map((period, i) =>
                                        <td key={i} className="text-end">
                                            <NumericFormat value={getLedgerDirectCostsByPeriod('No', period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                        </td>
                                    ) : null}
                                </tr>
                                <tr>
                                    <td colSpan="5">Direct costs with F&A applied</td>
                                    <td className="text-end" colSpan="2">
                                        <NumericFormat value={getLedgerDirectCosts('Yes')} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                    </td>
                                    <td></td>
                                    {fiscalPeriods ? fiscalPeriods.map((period, i) =>
                                        <td key={i} className="text-end">
                                            <NumericFormat value={getLedgerDirectCostsByPeriod('Yes', period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                        </td>
                                    ) : null}
                                </tr>
                            </tbody>
                            <tbody className="table-group-divider">
                                {ledgerAccountTotals && ledgerAccountTotals.filter(lat => lat.LedgerAccountId >= 7500).length > 0 ? ledgerAccountTotals.filter(lat => lat.LedgerAccountId >= 7500).map(expense =>
                                    <tr key={Number(expense.LedgerAccountId)} >
                                        <td className="nowrap" colSpan="5">{expense.LedgerAccount}</td>
                                        <td colSpan="1">{expense.ApplyFA}</td>
                                        <td className="text-end">
                                            <NumericFormat value={expense.Total} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                        </td>
                                        <td></td>
                                        {fiscalPeriods ? fiscalPeriods.map(period =>
                                            <td key={expense.LedgerAccountId + '-' + period.FiscalYear + '-' + period.FiscalMonth} className="text-end nowrap">
                                                <NumericFormat value={getLedgerExpenseByPeriod(expense, period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                            </td>
                                        ) : null}
                                    </tr>
                                ) : null}
                            </tbody>
                            <tbody className="table-group-divider">
                                <tr>
                                    <td colSpan="6" className="fw-bold">Total</td>
                                    <td className="text-end fw-bold">
                                        <a href="#" className="link-offset link-offset-2-hover link-underline link-underline-opacity-0 link-underline-opacity-100-hover" onClick={handleLedgerDetail}><NumericFormat value={getLedgerExpenseTotal()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></a>
                                    </td>
                                    <td></td>
                                    {fiscalPeriods ? fiscalPeriods.map((period, i) =>
                                        <td key={i} className="text-end fw-bold">
                                            <a href="#" className="link-offset link-offset-2-hover link-underline link-underline-opacity-0 link-underline-opacity-100-hover" onClick={(e) => handleLedgerDetail(e, null, period.FiscalYear, period.FiscalMonth)}><NumericFormat value={getLedgerExpenseTotalsByPeriod(period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></a>
                                        </td>
                                    ) : null}
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-end fw-bold">Actual F&A %</td>
                                    <td className="text-end fw-bold">
                                        <NumericFormat value={getLedgerDirectCosts('Yes') === 0 ? 0 : getFACostsTotal() / getLedgerDirectCosts('Yes') * 100} displayType="text" decimalScale={2} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                    </td>
                                    <td></td>
                                    {fiscalPeriods ? fiscalPeriods.map((period, i) =>
                                        <td key={i} className="text-end fw-bold">
                                            <NumericFormat value={getLedgerDirectCostsByPeriod('Yes', period.FiscalYear, period.FiscalMonth) === 0 ? 0 : getFACostsTotalByPeriod(period.FiscalYear, period.FiscalMonth) / getLedgerDirectCostsByPeriod('Yes', period.FiscalYear, period.FiscalMonth) * 100} displayType="text" decimalScale={2} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                        </td>
                                    ) : null}
                                </tr>
                            </tbody>
                            <tbody className="table-group-divider">
                                <tr>
                                    <td colSpan={fiscalPeriods.length + 8}>
                                        &nbsp;
                                    </td>
                                </tr>
                            </tbody>
                            <tbody className="table-group-divider">
                                <tr>
                                    <td colSpan={fiscalPeriods.length + 10}>
                                        &nbsp;
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th colSpan="7" rowSpan="1"></th>
                                    <th className="table-light text-end nowrap">Fiscal Year</th>
                                    {fiscalPeriodsEmployee ? fiscalPeriodsEmployee.map(period =>
                                        <td key={period.FiscalYear + '-' + period.FiscalMonth} className="text-end nowrap">{period.FiscalYear}</td>
                                    ) : null}
                                </tr>
                                <tr>
                                    <th className="table-light nowrap">Employee</th>
                                    <th className="table-light nowrap">EmplId</th>
                                    <th className="table-light nowrap">Position</th>
                                    <th className="table-light nowrap">Rcd</th>
                                    <th className="table-light nowrap" colSpan="2">Title</th>
                                    <th className="table-light text-center nowrap">All Years</th>
                                    <th className="table-light text-end nowrap">Fiscal Month</th>
                                    {fiscalPeriodsEmployee ? fiscalPeriodsEmployee.map(period =>
                                        <td key={period.FiscalYear + '-' + period.FiscalMonth} className="text-end nowrap">{period.FiscalMonth}</td>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {employeeTotals ? employeeTotals.map(emp =>
                                    <tr key={emp.EmplId + '-' + emp.Position + '-' + emp.EmplRcd}>
                                        <td className="nowrap">{emp.Employee}</td>
                                        <td>{emp.EmplId}</td>
                                        <td>{emp.Position}</td>
                                        <td>{emp.EmplRcd}</td>
                                        <td className="nowrap" colSpan="2">{emp.Title}</td>
                                        <td className="text-end">
                                            <NumericFormat value={emp.Earnings} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                        </td>
                                        <td></td>
                                        {fiscalPeriodsEmployee ? fiscalPeriodsEmployee.map(period =>
                                            <td key={emp.EmplId + '-' + emp.Position + '-' + emp.EmplRcd + '-' + period.FiscalYear + '-' + period.FiscalMonth} className="text-end nowrap">
                                                <NumericFormat value={getEarningsByPeriod(emp.EmplId, emp.Position, emp.EmplRcd, period.FiscalYear, period.FiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                            </td>
                                        ) : null}
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalExpensesByMonthToggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalExpensesByMonthDetail} toggle={modalExpensesByMonthDetailToggle} size="xxl">
                <ModalHeader toggle={modalExpensesByMonthDetailToggle}><FontAwesomeIcon icon={faCalendarDays} className="pe-2" />Expenses By Month - Detail</ModalHeader>
                <ModalBody>
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar", "maxHeight": "calc(100vh - 250px)"
                    }}>
                        <table className="table table-sm table-xs table-xxs table-hover" style={{ display: "table" }}>
                            <thead>
                                <tr>
                                    <th className="table-light nowrap">Ledger Account</th>
                                    <th className="table-light nowrap">Spend Category</th>
                                    <th className="table-light nowrap">Status</th>
                                    <th className="table-light nowrap">Expenses</th>
                                    <th className="table-light nowrap">Accounting Date</th>
                                    <th className="table-light nowrap">Budget Date</th>
                                    <th className="table-light nowrap">Supplier</th>
                                    <th className="table-light nowrap">Line Memo</th>
                                    <th className="table-light nowrap">Operational Transaction</th>
                                    <th className="table-light nowrap">Journal Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expensesByMonthDetail ? expensesByMonthDetail.map((detail, i) =>
                                    <tr key={i}>
                                        <td className="nowrap">{detail.LedgerAccount}</td>
                                        <td className="nowrap">{detail.SpendCategory}</td>
                                        <td className="nowrap">{detail.Status}</td>
                                        <td className="nowrap text-end"><NumericFormat value={detail.Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="nowrap text-end">{moment(detail.AccountingDate).format('yyyy-MM-DD')}</td>
                                        <td className="nowrap text-end">{moment(detail.BudgetDate).format('yyyy-MM-DD')}</td>
                                        <td className="nowrap">{detail.Supplier}</td>
                                        <td className="">{detail.LineMemo}</td>
                                        <td className="nowrap">{detail.OperationalTransaction}</td>
                                        <td className="nowrap">{detail.JournalNumber}</td>
                                    </tr>
                                ) : null }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalExpensesByMonthDetailToggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    );
}

export default ExpensesByMonth;