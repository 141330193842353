//import { Counter } from "./components/Counter";
//import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Forecasts } from "./components/forecasts/index";
import { default as Accounts } from "./components/accounts/index";
import { default as AccountsFind } from "./components/accounts/Find";
import { default as Porfolio } from "./components/accounts/Portfolio";
import { default as Account } from "./components/accounts/Account";
import { default as Debug } from "./components/testndebug/index";
import { default as Reports } from "./components/reports/index";
import { default as Report } from "./components/reports/Report";
import { default as ReportAccountSummary } from "./components/reports/AccountSummary";
import { default as Employees } from "./components/reports/Employees";
import { default as Expenses } from "./components/reports/Expenses";
//import RankItemsContainer from "./components/RankItemsContainer";
//import MovieImageArr from "./components/MovieImages.js";
//import AlbumImageArr from "./components/AlbumImages.js";

const AppRoutes = [
    {
        index: true,
        element: <Home />,
        isPublic: true
    },
    {
        path: '/forecasts',
        element: <Forecasts />,
        roles: ['RA']
    },
    {
        path: '/accounts/*',
        element: <Accounts />,
        roles: ['RA']
    },
    {
        path: '/accounts/find',
        element: <AccountsFind />,
        roles: ['RA']
    },
    {
        path: '/accounts/portfolio',
        element: <Porfolio />,
        roles: ['RA']
    },
    {
        path: '/accounts/:account/*',
        element: <Account />,
        roles: ['RA']
    },
    {
        path: '/reports/*',
        element: <Reports />
    },
    {
        path: '/reports/:guid/*',
        element: <Report />
    },
    {
        path: '/reports/version/:guid/*',
        element: <Report version />
    },
    {
        path: '/reports/accountsummary/:guid/*',
        element: <ReportAccountSummary />
    },
    {
        path: '/reports/accountsummary/version/:guid/*',
        element: <ReportAccountSummary version />
    },
    {
        path: '/reports/employees/:guid/*',
        element: <Employees />
    },
    {
        path: '/reports/employees/version/:guid/*',
        element: <Employees version />
    },
    {
        path: '/reports/expenses/:guid/*',
        element: <Expenses />
    },
    {
        path: '/reports/expenses/version/:guid/*',
        element: <Expenses version />
    },
    {
        path: '/reports/version/:guid/*',
        element: <Report version />
    },
    {
        path: '/testndebug/*',
        element: <Debug />,
        roles: ['RA']
        //},
        //{
        //    path: '/fetch-data',
        //    element: <FetchData />
        //},
        //{
        //    path: '/rank-movies',
        //    element: <RankItemsContainer dataType={1} imgArr={MovieImageArr} />
        //},
        //{
        //    path: '/rank-albums',
        //    element: <RankItemsContainer dataType={2} imgArr={AlbumImageArr} />
    }
];

export default AppRoutes;