import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faChartPie } from '@fortawesome/free-solid-svg-icons';
import useAuthRoles from "./useAuthRoles";

export default function HomeDash() {
    const { hasRole } = useAuthRoles();

    return (
        <div className="row align-items-md-stretch pt-5">
            {hasRole('RA') ?
                <div className="col-md-6">
                    <div className="h-100 p-5 bg-body-tertiary border rounded-3">
                        <h2><FontAwesomeIcon icon={faFileInvoice} className="pe-2" />Accounts</h2>
                        <p>Manage your portfolios, find accounts, edit forecast and generate reports.</p>
                        <Link to="/accounts" className="btn btn-outline-secondary me-1">Go to Accounts</Link>
                        <Link to="/accounts/portfolio" className="btn btn-outline-secondary me-1">My Portfolio</Link>
                        <Link to="/accounts/find" className="btn btn-outline-secondary me-1">Find Accounts</Link>
                    </div>
                </div>
                : null}
            <div className="col-md-6">
                <div className="h-100 p-5 bg-body-tertiary border rounded-3">
                    <h2><FontAwesomeIcon icon={faChartPie} className="pe-2" />Reports</h2>
                    <p>View you reports that have been shared with you.</p>
                    <Link to="/reports" className="btn btn-outline-secondary">View Reports</Link>
                </div>
            </div>
        </div>
    );
}
