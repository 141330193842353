import React, { useState, useEffect, useCallback } from 'react';
import { useMsal } from "@azure/msal-react";

//export function GetAuthRoles() {
//    const { instance } = useMsal();
//    const activeAccount = instance.getActiveAccount();
//    if (activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.roles && activeAccount.idTokenClaims.roles.length > 0) {
//        return activeAccount.idTokenClaims.roles;
//    }
//    else
//        return [];
//}
//export function AuthHasRole(role) {
//    const { instance } = useMsal();
//    const activeAccount = instance.getActiveAccount();
//    if (activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.roles && activeAccount.idTokenClaims.roles.length > 0) {
//        return activeAccount.idTokenClaims.roles.includes(role);
//    }
//    else
//        return false;
//}


function useAuthRoles() {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    function getRoles() {
        if (activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.roles && activeAccount.idTokenClaims.roles.length > 0) {
            return activeAccount.idTokenClaims.roles;
        }
        return [];
    }
    function hasRole(role) {
        if (activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.roles && activeAccount.idTokenClaims.roles.length > 0) {
            return activeAccount.idTokenClaims.roles.includes(role);
        }
        return false;
    }

    useEffect(() => {
    }, []);

    //const getLedgerAccountsData = useCallback(async () => {
    //    if (!ledgerAccountDirectory) {
    //        let params = sessionStorage.getItem('ledgerAccountDirectory');
    //        if (params) {
    //            return JSON.parse(params);
    //        }
    //        else {
    //            await fetch('/api/lookup/ledgeraccounts')
    //                .then(res => res.json())
    //                .then(data => {
    //                    setLedgerAccountDirectory(data);
    //                    sessionStorage.setItem('ledgerAccountDirectory', JSON.stringify(data))
    //                    return data;
    //                });
    //        }
    //    }
    //    else
    //        return ledgerAccountDirectory;
    //}, []);


    return {
        getRoles,
        hasRole
    };
}
export default useAuthRoles;