import React, { useState, useEffect, useCallback } from 'react';
import { getParameters } from './Parameters';
import { networkDays, isNumeric, isNumericDefault, isNullOrEmpty } from '../utilities';
import moment from 'moment';
import useLookup from "./shared/useLookup";

function useAccount(account, ledgerAccountDirectory, version, versionData, preLoadData) {

    const { getLedgerAccountsData: getLAData } = useLookup();
    const parameters = getParameters();

    const [isLoading, setIsLoading] = useState(false);

    const [accountDetail, setAccountDetail] = useState(null);
    const [accountBudgetExpenses, setAccountBudgetExpenses] = useState([]);
    const [accountEmployeeEarnings, setAccountEmployeeEarnings] = useState([]);
    const [accountEmployeeSemesters, setAccountEmployeeSemesters] = useState(null);
    const [accountFiscalMonths, setAccountFiscalMonths] = useState(null);
    const [accountLedgerByMonth, setAccountLedgerByMonth] = useState(null);
    const [accountEmployeeFunding, setAccountEmployeeFunding] = useState(null);
    const [accountCurrentRate, setAccountCurrentRate] = useState(null);
    const [loadComplete, setLoadComplete] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if (preLoadData) {
            loadData();
        //    if (!ledgerAccountDirectory) {
        //        getLedgerAccountsData();
        //    }
        //    getAccountData();
        }
        else
            setIsLoading(false);

    }, []);

    const getLedgerAccountsData = async () => {
        ledgerAccountDirectory = await getLAData();
        return ledgerAccountDirectory;
        //const response = await fetch('/api/lookup/ledgeraccounts');
        //const data = await response.json();
        //ledgerAccountDirectory = data;
        //setLedgerAccountDirectory(data);
    };

    const loadData = useCallback(async () => {
        setIsLoading(true);
        if (!ledgerAccountDirectory) {
            getLedgerAccountsData();
        }
        await getAccountData();
    }, [accountBudgetExpenses, accountFiscalMonths]);
    const getAccountData = async () => {
        try {
            if (!ledgerAccountDirectory) {
                ledgerAccountDirectory = await getLedgerAccountsData();
            }
            if (version && versionData) {
                setAccountDetail(versionData.AccountDetail);

                const lbmData = versionData.LedgerByMonth;
                //Now add the employee forecast totals to the last month for the right ledger accounts
                setAccountLedgerByMonth(lbmData);


                const accountPeriods = await getAccountPeriods(versionData.AccountDetail ? versionData.AccountDetail.EndDate : null);

                let currentRate = extractRate(versionData.AccountDetail, accountPeriods);
                setAccountCurrentRate(currentRate);

                //apply logic to account data
                versionData.AccountDetail = processAccountData(versionData.AccountDetail, currentRate);
                setAccountDetail(versionData.AccountDetail);



                const dataEE = versionData.EmployeeEarnings;
                setAccountEmployeeEarnings(dataEE);

                const efData = versionData.EmployeeFunding;
                setAccountEmployeeFunding(efData);

                //const responseEFPP = await fetch('/api/account/employeefundingbypayperiod?account=' + encodeURIComponent(account));
                //const dataEFPP = await responseEFPP.json();
                //setAccountEmployeeFundingByPayPeriod(dataEFPP);

                const beData = versionData.BudgetExpenses;
                //add subagreement ledger accounts
                let newBEData = addSubAgreementLedger(beData, versionData.AccountDetail);
                newBEData = addUnbudgetedRevenue(newBEData, versionData.AccountDetail);
                newBEData = addMissingFALedger(newBEData, versionData.AccountDetail);
                newBEData = processBudgetExpensesData(newBEData, versionData.AccountDetail);
                //Check to see what Ledger accounts need to be added
                setAccountBudgetExpenses(addNeededLedgerRows(versionData.AccountDetail, efData, newBEData, ledgerAccountDirectory, dataEE));

                //const fmData = await getFiscalMonths((accountDetail ? encodeURIComponent(accountDetail.EndDate) : ''));


                setAccountDetail(versionData.AccountDetail);
            }
            else {
                const responseAccount = await fetch('/api/account/detail/' +
                    (!account ? '' : encodeURIComponent(account))
                );
                let dataAccount = await responseAccount.json();

                let currentRate = extractRate(dataAccount);
                setAccountCurrentRate(currentRate);

                //apply logic to account data
                dataAccount = processAccountData(dataAccount, currentRate);
                setAccountDetail(dataAccount);

                getAccountPeriods(dataAccount ? dataAccount.EndDate : null);

                const responseEE = await fetch('/api/account/employeeearnings?account=' + encodeURIComponent(account));
                const dataEE = await responseEE.json();
                setAccountEmployeeEarnings(dataEE);

                const responseEF = await fetch('/api/account/employeefunding?account=' + encodeURIComponent(account));
                const efData = await responseEF.json();
                setAccountEmployeeFunding(efData);

                //const responseEFPP = await fetch('/api/account/employeefundingbypayperiod?account=' + encodeURIComponent(account));
                //const dataEFPP = await responseEFPP.json();
                //setAccountEmployeeFundingByPayPeriod(dataEFPP);

                const responseBE = await fetch('/api/account/budgetsexpenses?account=' + encodeURIComponent(account));
                const beData = await responseBE.json();
                //add subagreement ledger accounts
                let newBEData = addSubAgreementLedger(beData, dataAccount);
                newBEData = addUnbudgetedRevenue(newBEData, dataAccount);
                newBEData = addMissingFALedger(newBEData, dataAccount);
                newBEData = processBudgetExpensesData(newBEData, dataAccount);
                //Check to see what Ledger accounts need to be added
                setAccountBudgetExpenses(addNeededLedgerRows(dataAccount, efData, newBEData, ledgerAccountDirectory, dataEE));

                //const fmData = await getFiscalMonths((accountDetail ? encodeURIComponent(accountDetail.EndDate) : ''));


                const responseLBM = await fetch('/api/account/ledgerbymonth?account=' + encodeURIComponent(account));
                const lbmData = await responseLBM.json();
                //Now add the employee forecast totals to the last month for the right ledger accounts
                setAccountLedgerByMonth(lbmData);
            }
            setLoadComplete(true);
            setIsLoading(false)
        }
        catch (error) {
            console.log(error);
        }
    };
    const processAccountData = (data, currentRate) => {
        if (data.Sponsor === "Arizona State University Foundation (ASUF)") {
            data.ASUFRate = 0.0;
            if (currentRate > 0) {
                data.ASUFRate = 0.05;

            }
            //    if (data.CurrentERAIndirectRate > data.CurrentWorkdayIndirectRate) {
            //        data.ASUFRate = 0.05;
            //    }
        }
        return data;
    };
    const getCurrentRate = (data) => {
        //if (accountCurrentRate)
        //    return accountCurrentRate;


        //let currentRate = null;
        //if (!data)
        //    data = accountDetail;
        //if (data) {
        //    currentRate = extractRate(data);
        //    if (currentRate)
        //        setAccountCurrentRate(currentRate);
        //}
        //return currentRate;
        return accountCurrentRate;
    };
    const getAccountCurrentRate = useCallback(() => {
        return getCurrentRate();
    }, []);

    const extractRatePercent = /(\$(?=[0-9])((\d*\.\d{1,2})|\d+\.?)|((?=[0-9])(\d+\.?)|((?=([1-9]+|\d*\.0*[1-9]+))\d*\.\d{1,4}))\%)/;
    const extractRate = (data, accountPeriods) => {
        let extractedRate = null;
        if (!data)
            return null;
        if (data && data.Account.startsWith("GR")) {
            if (data && data.WorkdayRateException !== '') {
                //need to extract the rate from the exception rate
                extractedRate = data.ExceptionRate;
            }
            if (getFABase() === "TDC") {

            }
            else if (data.workdayRateAgreement.length > 30) {
                //extractRate(data);
                extractedRate = data.CurrentWorkdayIndirectRate;
                if (extractedRate !== null)
                    extractedRate = extractedRate * 100;
                //return extractedRate * 100;
            }

            if (extractedRate === null && data && data.workdayRateAgreement !== null) {
                let matches = data.workdayRateAgreement.match(extractRatePercent);
                if (matches && matches.length > 4) {
                    extractedRate = Number(matches[4]);
                }
            }
        }
        else {
            if (data.AdminServiceCharged === 0) {
                extractedRate = 0;
            }
            else {
                if (data.ASCExtraType === null) {
                    extractedRate = (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).asc * 100 : 0) + data.ASCExtra;
                }
                else {
                    if (data.ASCExtraType === '') {
                        extractedRate = (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).asc * 100 : 0);
                    }
                    else {
                        if (data.ASCExtraType === 'ABOR') {
                            extractedRate = data.ASCExtra;
                        }
                        if (["EOSS1", "EOSS3"].includes(data.ASCExtraType)) {
                            extractedRate = (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).asc * 100 : 0);
                        }

                    }
                }
            }
        }
        if (!accountPeriods) {
            accountPeriods = { FiscalMonths: accountFiscalMonths };
        }
        if (extractedRate === null && accountPeriods.FiscalMonths && accountPeriods.FiscalMonths.length > 0) {
            let indirectRate = parameters.IndirectRates.find(ir =>
                ir.rateAgreement === data.workdayRateAgreement &&
                ir.typeCampus === data.FARateType + '/' + data.WorkdayCampusType &&
                moment(accountPeriods.FiscalMonths[0].StartDate) >= moment(ir.startDate) &&
                moment(accountPeriods.FiscalMonths[0].StartDate) <= moment(ir.endDate)
            );
            if (indirectRate)
                extractedRate = indirectRate.rate * 100;
        }

        extractedRate = (data ? (data.ManualCurrentRate ? data.ManualCurrentRate * 100 : extractedRate) : 0);

        return extractedRate;
    };
    const getFABase = (dataAccount) => {
        if (!dataAccount) {
            dataAccount = accountDetail;
        }
        let returnValue = '';
        if (dataAccount) {
            if (dataAccount.workdayRateAgreement?.startsWith('TDC')) {
                returnValue = 'TDC';
            }
            if (dataAccount.workdayRateAgreement?.startsWith('MTD')) {
                returnValue = 'MTDC';
            }
            if (["ABOR", "EOSS1", "EOSS3"].includes(dataAccount.ASCExtraType)) {
                returnValue = dataAccount.ASCExtraType;
            }
        }
        return returnValue;
    };
    const getAccountFABase = useCallback(() => {
        if (accountDetail) {
            return getFABase() + (accountDetail.Account.startsWith('GR') ? ' (' + accountDetail.FARateType + '/' + accountDetail.WorkdayCampusType + ')' : '');
        }
        else
            return null;
    }, []);
    const addSubAgreementLedger = (beData, dataAccount) => {
        if (dataAccount && beData && beData.length > 0) {
            //find a 7201 item
            let subItemIX = beData.findIndex(be => Number(be.LedgerAccountId) === 7201 && (be.Expenses > dataAccount.BasisLimit || be.Budget > dataAccount.BasisLimit));
            if (subItemIX >= 0) {
                let subItem = beData.at(subItemIX);
                let thisBudget = subItem.Budget;
                let thisExpenses = subItem.Expenses;
                let thisEncumbrances = subItem.Encumbrances;
                if (thisBudget > dataAccount.BasisLimit) {
                    subItem.Budget = dataAccount.BasisLimit;
                }
                if (thisExpenses > dataAccount.BasisLimit) {
                    subItem.Expenses = dataAccount.BasisLimit;
                    subItem.Encumbrances = 0;
                }
                else {
                    if (thisEncumbrances + thisExpenses > dataAccount.BasisLimit) {
                        subItem.Encumbrances = dataAccount.BasisLimit - thisExpenses;
                        thisEncumbrances = thisEncumbrances - (dataAccount.BasisLimit - thisExpenses);
                    }
                    else {
                        thisEncumbrances = 0;
                    }
                }
                //Add the new item...
                beData.splice(subItemIX + 1, 0, {
                    LedgerAccountId: "7201.1",
                    LedgerAccount: "7201 Subagreements > " + dataAccount.BasisLimit,
                    ApplyFA: (getFABase() === 'TDC' ? "Yes" : "No"),
                    OverrideFA: null,
                    Budget: thisBudget > dataAccount.BasisLimit ? thisBudget - dataAccount.BasisLimit : 0,
                    Expenses: thisExpenses > dataAccount.BasisLimit ? thisExpenses - dataAccount.BasisLimit : 0,
                    Encumbrances: thisEncumbrances,
                    Comment: null,
                    FiscalYear: null
                })
            }
        }
        return beData;
    };
    const addUnbudgetedRevenue = (beData, dataAccount) => {
        if (!account.startsWith("GR") && dataAccount) {
            if (!beData.find(be => be.LedgerAccount === 'Unbudgeted Revenue')) {
                //Add the new item...
                beData.splice(0, 0, {
                    LedgerAccountId: "0",
                    LedgerAccount: "Unbudgeted Revenue",
                    ApplyFA: "Yes",
                    OverrideFA: null,
                    Budget: (dataAccount.RevenueBegBalance + dataAccount.Revenue) > dataAccount.Budget ? dataAccount.RevenueBegBalance + dataAccount.Revenue - dataAccount.Budget : (dataAccount.RevenueBegBalance + dataAccount.Revenue < 0) ? dataAccount.RevenueBegBalance + dataAccount.Revenue : 0,
                    Expenses: null,
                    Encumbrances: null,
                    Comment: null,
                    FiscalYear: null
                })

            }
        }
        return beData;
    };
    const addMissingFALedger = (beData, dataAccount) => {
        let items = beData ? beData.filter((row) => {
            try {
                let ledgerNumber = Number(row.LedgerAccountId);
                if (row.LedgerAccount) {
                    let id = row.LedgerAccount.substring(0, 4);
                    if (id === "7201") {
                        row.LedgerAccountId = ledgerNumber;
                    }
                    else if (isNumeric(id)) {
                        if (ledgerNumber !== Number(id)) {
                            row.LedgerAccountId = Number(id);
                        }
                    }
                }

                return Number(row.LedgerAccountId) >= 7500;
            }
            catch { }
            return false;
        }) : null;
        if (items) {
            if (items.length === 0) {
                if (account.startsWith("GR")) {
                    beData.push({
                        LedgerAccountId: "7520",
                        LedgerAccount: "7520 Facilities & Administrative Costs",
                        ApplyFA: "",
                        OverrideFA: null,
                        Budget: 0,
                        Expenses: 0,
                        Encumbrances: 0,
                        Comment: null,
                        FiscalYear: null
                    });
                }
                else {
                    beData.push({
                        LedgerAccountId: "7500",
                        LedgerAccount: "7500 Internal Allocations Expense",
                        ApplyFA: "",
                        OverrideFA: null,
                        Budget: 0,
                        Expenses: 0,
                        Encumbrances: 0,
                        Comment: null,
                        FiscalYear: null
                    });
                }
            }
        }
        return beData;
    }
    const processBudgetExpensesData = (beData, dataAccount) => {
        let items = beData;
        if (items) {
            items.forEach((abe, i) => {
                let ledgerNumber = Number(abe.LedgerAccountId);
                if (abe.LedgerAccount) {
                    let id = abe.LedgerAccount.substring(0, 4);
                    if (id === "7201") {
                        items[i].LedgerAccountId = ledgerNumber;
                    }
                    else if (isNumeric(id)) {
                        if (ledgerNumber !== Number(id)) {
                            items[i].LedgerAccountId = Number(id);
                        }
                    }
                }
            });

        }
        let thisIX = beData.findIndex(b => b.LedgerAccountId === '7500');
        if (thisIX >= 0) {
            //We have a 7500, now check to see if it has a budget
            if (beData[thisIX].Budget && beData[thisIX].Budget > 0 && getPersonalServicesBudget(beData, dataAccount) > 0) {
                //7499 Netcomm
                thisIX = beData.findIndex(b => b.LedgerAccount === "7499 Netcom");
                if (thisIX >= 0) {
                    beData[thisIX].Budget = getPersonalServicesBudget(beData, dataAccount) * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm : 0);
                }
                //7499 Risk Mgmt
                thisIX = beData.findIndex(b => b.LedgerAccount === "7499 Risk Mgmt");
                if (thisIX >= 0) {
                    beData[thisIX].Budget = getPersonalServicesBudget(beData, dataAccount) * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt : 0);
                }
            }
        }

        return beData;
    };
    const addNeededLedgerRows = (dataAccount, efData, beData, laData, dataEE) => {
        //Get the Staff Types from the employeeFunding Data
        let staffTypes = [];
        efData.forEach(ef => {
            if (staffTypes.indexOf(ef.StaffType) === -1) {
                staffTypes.push(ef.StaffType);
            }
        });
        if (dataEE && dataEE.length > 0) {
            dataEE.forEach(ee => {
                if (staffTypes.indexOf(ee.StaffType) === -1) {
                    staffTypes.push(ee.StaffType);
                }
            });
        }
        //Now get the Ledger Account Ids for these Staff Types
        if (staffTypes.length > 0 && parameters && parameters.LedgerAccountsStaffType && laData) {
            let ledgerAccountsNeeded = parameters.LedgerAccountsStaffType.filter(l => l.staffTypes.some(st => staffTypes.includes(st)));
            if (ledgerAccountsNeeded.length > 0) {
                //Now that we have the ledgerAccounts, look at the Budget Expenses Data and see which ones are missing...
                let beLedgerAccounts = beData.map(be => be.LedgerAccountId);
                let accountsToAdd = ledgerAccountsNeeded.map(x => x.ledgerAccountId).filter(n => !beLedgerAccounts.includes(n));
                accountsToAdd.forEach(a => {
                    let thisLA = laData.find(la => la.Id === a);
                    let thisLedgerAccountNeeded = ledgerAccountsNeeded.find(n => n.ledgerAccountId === a);
                    if (thisLA) {
                        beData.push({
                            "LedgerAccountId": thisLA.Id,
                            "BasisLimit": "",
                            "LedgerAccount": thisLA.Id + ' ' + thisLA.Name,
                            "ApplyFA": getFABase(dataAccount) === "MTDC" && thisLA.Id === "7111" ? "No" : "Yes",
                            "OverrideFA": null,
                            "Budget": 0,
                            "Expenses": 0,
                            "Encumbrances": "",
                            "Comment": "",
                            "FiscalYear": "",
                            "Missing": efData.some(ef => thisLedgerAccountNeeded.staffTypes.includes(ef.StaffType))
                        });
                    }
                });
            }
        }
        return beData ? beData.sort((x, y) => { return x.LedgerAccountId > y.LedgerAccountId ? 1 : x.LedgerAccountId < y.LedgerAccountId ? -1 : 0 }) : null;
    };
    const getPersonalServicesBudget = (beData) => {
        if (!beData)
            beData = accountBudgetExpenses;

        let budget = 0;
        if (beData.length > 0) {
            beData.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => budget += x.Budget);
        }
        return budget;
    };
    const getFiscalMonths = async (endDate) => {
        if (version && versionData) {
            const data = versionData.FiscalMonths;
            setAccountFiscalMonths(data);
            return data;
        }
        else {
            const response = await fetch('/api/account/fiscalmonths?account=' + encodeURIComponent(account) + '&endDate=' + (moment(endDate).isValid() ? encodeURIComponent(endDate) : ''));
            const data = await response.json();
            setAccountFiscalMonths(data);
            return data;
        }
    };
    const getEmployeeSemesters = async (endDate) => {
        if (version && versionData) {
            const data = versionData.EmployeeSemesters;
            //find the last payperiod and adjust the workdays accordingly...
            if (data && data.length > 0) {
                let payPeriod = data[data.length - 1];
                payPeriod.Workdays = networkDays(payPeriod.MinStartDate, endDate);
                data[data.length - 1] = payPeriod;
            }
            setAccountEmployeeSemesters(data);
            return data;
        }
        else {
            const response = await fetch('/api/account/employeesemesters?account=' + encodeURIComponent(account) + '&endDate=' + (moment(endDate).isValid() ? encodeURIComponent(endDate) : ''));
            const data = await response.json();
            //find the last payperiod and adjust the workdays accordingly...
            if (data && data.length > 0) {
                let payPeriod = data[data.length - 1];
                payPeriod.Workdays = networkDays(payPeriod.MinStartDate, endDate);
                data[data.length - 1] = payPeriod;
            }
            setAccountEmployeeSemesters(data);
            return data;
        }
    };
    const getAccountPeriods = async (endDate) => {
        const esData = await getEmployeeSemesters(endDate);
        const fmData = await getFiscalMonths(endDate);

        if (esData.length > 0 && fmData.length > 0) {
            let lastFM = fmData.at(-1);
            let lastES = esData.at(-1);

            while (Number(lastES.FiscalYear) * 100 + lastES.FiscalMonth > lastFM.FiscalYearNumber * 100 + lastFM.FiscalPeriodNumber) {
                fmData.push(
                    {
                        Account: account,
                        FiscalPeriodNumber: lastFM.FiscalPeriodNumber === 12 ? 1 : lastFM.FiscalPeriodNumber + 1,
                        FiscalYearNumber: lastFM.FiscalPeriodNumber === 12 ? lastFM.FiscalYearNumber + 1 : lastFM.FiscalYearNumber,
                        StartDate: moment(lastFM.StartDate).add(1, 'M').format()
                    }
                )
                lastFM = fmData.at(-1);
                setAccountFiscalMonths(fmData);
            }
        }
        return { EmployeeSemesters: esData, FiscalMonths: fmData };
    }

    const getDirectCostLedgerAccounts = () => {
        let items = accountBudgetExpenses ? accountBudgetExpenses.filter((row) => {
            try {
                let ledgerNumber = Number(row.LedgerAccountId);
                if (row.LedgerAccount) {
                    let id = row.LedgerAccount.substring(0, 4);
                    if (isNumeric(id)) {
                        if (ledgerNumber !== Number(id)) {
                            ledgerNumber = Number(id);
                        }
                    }
                }

                return ledgerNumber < 7500;
            }
            catch { }
            return false;
        }) : [];
        if (items && items.length > 0) {
            items.forEach((abe, i) => {
                if (getFABase() === 'TDC' || getFABase().startsWith('EOSS')) {
                    if (accountDetail && accountDetail.WorkdayType === 'Program' && (["7300", "7700", "7111"].includes(abe.LedgerAccountId))) {
                        items[i].ApplyFA = 'No';
                    }
                    else {
                        let ledgerNumber = Number(abe.LedgerAccountId);
                        if (abe.LedgerAccount) {
                            let id = abe.LedgerAccount.substring(0, 4);
                            if (isNumeric(id)) {
                                if (ledgerNumber !== Number(id)) {
                                    ledgerNumber = Number(id);
                                }
                            }
                        }
                        if (ledgerNumber > 0 && (ledgerNumber < 7100 || ledgerNumber >= 7500)) {
                            items[i].ApplyFA = 'No';
                        }
                        else {
                            if (!isNullOrEmpty(abe.OverrideFA)) {
                                items[i].ApplyFA = abe.OverrideFA;
                            }
                            else {
                                items[i].ApplyFA = 'Yes';
                            }
                        }
                    }
                }
            });
        }
        return items;
    }
    const getIndirectCostLedgerAccounts = () => {
        let items = accountBudgetExpenses ? accountBudgetExpenses.filter((row) => {
            try {
                let ledgerNumber = Number(row.LedgerAccountId);
                if (row.LedgerAccount) {
                    let id = row.LedgerAccount.substring(0, 4);
                    if (isNumeric(id)) {
                        if (ledgerNumber !== Number(id)) {
                            ledgerNumber = Number(id);
                        }
                    }
                }

                return ledgerNumber >= 7500;
            }
            catch { }
            return false;
        }) : null;

        if (items) {
            items.forEach((abe, i) => {
                if (accountDetail.ASUFRate !== null && accountDetail.ASUFRate !== undefined) {
                    if ((abe.LedgerAccountId === "7520" || abe.LedgerAccountId === "7521") && !abe.LedgerAccount.includes("ASUF") && accountBudgetExpenses.some(exp => exp.LedgerAccount.includes("ASUF"))) {
                        if (!abe.BudgetAdjusted) {
                            let directCostsFA = getDirectCosts('Yes');
                            let directCosts = ((directCostsFA.Budget + getDirectCosts('No').Budget) * accountDetail.ASUFRate);
                            items[i].Budget = abe.Budget - directCosts;
                            items[i].BudgetAdjusted = true;
                            items[i].Encumbrances = directCostsFA.Encumbrances * getCurrentRate() / 100;
                        }
                    }
                    else if (abe.LedgerAccount.includes("ASUF")) {
                        items[i].Budget = (getDirectCosts('Yes').Budget + getDirectCosts('No').Budget) * accountDetail.ASUFRate;
                        items[i].Encumbrances = items[i].Budget - items[i].Expenses - getAccountLedgerForecast(abe);
                        if (items[i].Encumbrances < 0)
                            items[i].Encumbrances = 0;
                    }
                }
                else {
                    if ([7500, 7520].includes(Number(items[i].LedgerAccountId))) {
                        items[i].Encumbrances = getDirectCosts('Yes').Encumbrances * getCurrentRate() / 100;
                    }
                    if (abe.LedgerAccountId === "7500" & !abe.BudgetAdjusted && abe.Budget > 0 && getPersonalServicesBudget(accountBudgetExpenses, accountDetail) > 0) {
                        if (accountBudgetExpenses && accountBudgetExpenses.find(x => x.LedgerAccount === '7499 Netcom')) {
                            abe.Budget = abe.Budget - getPersonalServicesBudget(accountBudgetExpenses, accountDetail) * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm : 0);
                        }
                        if (accountBudgetExpenses && accountBudgetExpenses.find(x => x.LedgerAccount === '7499 Risk Mgmt')) {
                            abe.Budget = abe.Budget - getPersonalServicesBudget(accountBudgetExpenses, accountDetail) * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt : 0);
                        }
                        abe.BudgetAdjusted = true;
                    }
                }
            });
        }

        return items;
    }
    const getDirectCosts = useCallback((applyFA) => {
        let budgetValue = null;
        let expensesValue = null;
        let encumbrancesValue = null;
        let forecastValue = null;
        let availableValue = null;

        let ledgerAccounts = getDirectCostLedgerAccounts()
        if (ledgerAccounts) {

            budgetValue = 0;
            expensesValue = 0;
            encumbrancesValue = 0;
            forecastValue = 0;
            availableValue = 0;

            ledgerAccounts = [...ledgerAccounts.filter(l => l.ApplyFA === applyFA && (l.LedgerAccount.startsWith("7499") || Number(l.LedgerAccountId) < 7500))];
            if (ledgerAccounts.length > 0) {
                budgetValue = ledgerAccounts.map(x => x.Budget ? x.Budget : 0).reduce((a, v) => a + v);
                expensesValue = ledgerAccounts.map(x => x.Expenses ? x.Expenses : 0).reduce((a, v) => a + v);
                encumbrancesValue = ledgerAccounts.map(x => x.Encumbrances ? x.Encumbrances : 0).reduce((a, v) => a + v);
                ledgerAccounts.forEach(la => {
                    forecastValue += getAccountLedgerForecast(la);
                });
            }
        }
        availableValue = budgetValue - (expensesValue + encumbrancesValue + forecastValue);
        return { Budget: budgetValue, Expenses: expensesValue, Encumbrances: encumbrancesValue, Forecast: forecastValue, Available: availableValue };
    }, [accountBudgetExpenses, accountEmployeeEarnings, accountLedgerByMonth]);
    const getAccountLedgerForecast = useCallback((budgetExpense) => {
        let thisForecast = 0;
        if (Number(budgetExpense.LedgerAccountId) >= 7100 && Number(budgetExpense.LedgerAccountId) <= 7105) {
            let thisLedgerAccountStaffType = parameters.LedgerAccountsStaffType.find(x => Number(x.ledgerAccountId) === Number(budgetExpense.LedgerAccountId));
            if (thisLedgerAccountStaffType) {
                let theseEarnings = accountEmployeeEarnings ? accountEmployeeEarnings.filter(aee => thisLedgerAccountStaffType.staffTypes.includes(aee.StaffType)) : null;
                if (theseEarnings) {
                    theseEarnings.forEach(e => {
                        let thisEmpForecast = getEmployeeForecasts(e);
                        if (thisEmpForecast)
                            thisForecast += thisEmpForecast.Earnings;
                    });
                }
            }
        }
        else if (Number(budgetExpense.LedgerAccountId) === 7111) {
            let thisLedgerAccountStaffType = parameters.LedgerAccountsStaffType.find(x => Number(x.ledgerAccountId) === Number(budgetExpense.LedgerAccountId));
            if (thisLedgerAccountStaffType) {
                let theseEarnings = accountEmployeeEarnings ? accountEmployeeEarnings.filter(aee => thisLedgerAccountStaffType.staffTypes.includes(aee.StaffType)) : null;
                if (theseEarnings) {
                    theseEarnings.forEach(e => {
                        let thisEmpForecast = getEmployeeForecasts(e);
                        if (thisEmpForecast)
                            thisForecast += thisEmpForecast.TuitionRemissionForecast;
                    });
                }
            }
        }
        else if (Number(budgetExpense.LedgerAccountId) >= 7112 && Number(budgetExpense.LedgerAccountId) <= 7117) {
            let thisLedgerAccountStaffType = parameters.LedgerAccountsStaffType.find(x => Number(x.ledgerAccountId) === Number(budgetExpense.LedgerAccountId));
            if (thisLedgerAccountStaffType) {
                let theseEarnings = accountEmployeeEarnings ? accountEmployeeEarnings.filter(aee => thisLedgerAccountStaffType.staffTypes.includes(aee.StaffType)) : null;
                if (theseEarnings) {
                    theseEarnings.forEach(e => {
                        let thisEmpForecast = getEmployeeForecasts(e);
                        if (thisEmpForecast)
                            thisForecast += thisEmpForecast.EREForecast;
                    });
                }
            }
        }
        else if (Number(budgetExpense.LedgerAccountId) >= 7500 && Number(budgetExpense.LedgerAccountId) <= 7521) {
            if (Number(budgetExpense.LedgerAccountId) === 7521 && budgetExpense.LedgerAccount.includes("ASUF")) {
                thisForecast = (getDirectCosts('Yes').Forecast ?? 0) * (accountDetail && accountDetail.ASUFRate ? accountDetail.ASUFRate : 0);
            }
            else {
                thisForecast = getIndirectForecastTotal();
            }
        }
        else {
            if (budgetExpense.LedgerAccount === "7499 Netcom") {
                //get this month's forecasts by month Personal Services lines times the netCom rate
                let value = 0;
                if (accountBudgetExpenses && accountFiscalMonths) {
                    accountBudgetExpenses.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => {
                        accountFiscalMonths.forEach(fm => {
                            value += getLedgerForecastByMonth(fm, x)
                        });
                    });
                }
                thisForecast += value * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm : 0);
            }
            else if (budgetExpense.LedgerAccount === "7499 Risk Mgmt") {
                //get this month's forecasts by month Personal Services lines times the riskMgmt rate
                let value = 0;
                if (accountBudgetExpenses && accountFiscalMonths) {
                    accountBudgetExpenses.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => {
                        accountFiscalMonths.forEach(fm => {
                            value += getLedgerForecastByMonth(fm, x)
                        });
                    });
                }
                thisForecast += value * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt : 0);
            }
            else {
                thisForecast = accountLedgerByMonth && accountLedgerByMonth.filter((l) => (l.LedgerAccountandDesc === budgetExpense.LedgerAccount || l.LedgerAccount === budgetExpense.LedgerAccountId)).length > 0
                    ?
                    accountLedgerByMonth.filter((l) => (l.LedgerAccountandDesc === budgetExpense.LedgerAccount || l.LedgerAccount === budgetExpense.LedgerAccountId)).map(x => x.Amount ? x.Amount : 0).reduce((a, v) => a + v)
                    :
                    0;
            }
        }
        return thisForecast;
    }, [
        parameters,
        accountDetail,
        accountBudgetExpenses,
        accountEmployeeEarnings,
        accountEmployeeSemesters,
        accountFiscalMonths,
        accountLedgerByMonth,
        accountEmployeeFunding,
        accountCurrentRate,
        loadComplete
    ]);

    const getLedgerForecastByMonth = (fiscalMonth, budgetExpense) => {
        let thisForecast = null;

        if (Number(budgetExpense.LedgerAccountId) >= 7200 && Number(budgetExpense.LedgerAccountId) < 7520) {
            let thisLedger = null;
            if (accountLedgerByMonth) {
                if (budgetExpense.LedgerAccount === "7499 Netcom") {
                    //get this month's forecasts by month Personal Services lines times the netCom rate
                    let value = 0;
                    if (accountBudgetExpenses) {
                        accountBudgetExpenses.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => value += getLedgerForecastByMonth(fiscalMonth, x));
                    }
                    thisForecast += value * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm : 0);
                }
                else if (budgetExpense.LedgerAccount === "7499 Risk Mgmt") {
                    //get this month's forecasts by month Personal Services lines times the riskMgmt rate
                    let value = 0;
                    if (accountBudgetExpenses) {
                        accountBudgetExpenses.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => value += getLedgerForecastByMonth(fiscalMonth, x));
                    }
                    thisForecast += value * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt : 0);
                }
                else {
                    thisLedger = accountLedgerByMonth.find((l) => Number(l.FiscalYear) === Number(fiscalMonth.FiscalYearNumber) && Number(l.FiscalMonth) === Number(fiscalMonth.FiscalPeriodNumber) && (l.LedgerAccountandDesc === budgetExpense.LedgerAccount || Number(l.LedgerAccount) === Number(budgetExpense.LedgerAccountId)));
                    if (thisLedger)
                        thisForecast += thisLedger.Amount ?? 0;
                }
            }
        }
        else {
            if (Number(budgetExpense.LedgerAccountId) < 7200) {
                let thisLedgerAccountStaffType = parameters.LedgerAccountsStaffType.find(x => Number(x.ledgerAccountId) === Number(budgetExpense.LedgerAccountId));
                if (thisLedgerAccountStaffType) {
                    let theseEarnings = accountEmployeeEarnings ? accountEmployeeEarnings.filter(aee => thisLedgerAccountStaffType.staffTypes.includes(aee.StaffType)) : null;
                    if (theseEarnings) {
                        theseEarnings.forEach(e => {
                            let theseSemesters = accountEmployeeSemesters ? accountEmployeeSemesters.filter(aes => Number(aes.FiscalYear) === Number(fiscalMonth.FiscalYearNumber) && Number(aes.FiscalMonth) === Number(fiscalMonth.FiscalPeriodNumber)) : null;
                            if (theseSemesters) {
                                theseSemesters.forEach(sem => {
                                    let empForecastBySem = getEmployeeForecastsBySemester(e, sem);
                                    if (Number(budgetExpense.LedgerAccountId) >= 7100 && Number(budgetExpense.LedgerAccountId) <= 7105) {
                                        thisForecast += empForecastBySem.Earnings;
                                    }
                                    else if (Number(budgetExpense.LedgerAccountId) === 7111) {
                                        thisForecast += empForecastBySem.TuitionRemissionForecast;
                                    }
                                    else if (Number(budgetExpense.LedgerAccountId) >= 7112 && Number(budgetExpense.LedgerAccountId) <= 7117) {
                                        thisForecast += empForecastBySem.EREForecast;
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }

        return thisForecast;
    }
    const getEmployeeForecasts = (employeeEarning) => {


        let employeeEarningsValue = 0;
        let employeeEREForecast = 0;
        let tuitionRemForecast = 0;
        if (accountEmployeeSemesters) {
            accountEmployeeSemesters.forEach((aes, i) => {

                let empForecast = getEmployeeForecastsBySemester(employeeEarning, aes);
                employeeEarningsValue += empForecast.Earnings;
                employeeEREForecast += empForecast.EREForecast;
                tuitionRemForecast += empForecast.TuitionRemissionForecast;
            });
        }
        return { Earnings: Math.round((employeeEarningsValue + Number.EPSILON) * 100) / 100, EREForecast: employeeEREForecast, TuitionRemissionForecast: tuitionRemForecast };
    };
    const getEmployeeForecastsBySemester = (employeeEarning, empSemester) => {
        let employeeEarningsValue = 0;
        let employeeEREForecast = 0;
        let tuitionRemForecast = 0;

        let thisPayGroup = employeeEarning.PayGroup;
        if (!thisPayGroup && employeeEarning.EmplStatusPayGroup)
            thisPayGroup = employeeEarning.EmplStatusPayGroup.split(' ')[1]

        //find the ERE Inflated Rate
        let ereRate = parameters.ERERates.find((rate) => rate.StaffType === employeeEarning.StaffType && rate.FiscalYear === Number(empSemester.FiscalYear));
        let thisERERate = 0;
        if (ereRate)
            thisERERate = ereRate.ERERate;

        let ereRateInflated = 0;
        let lastYear = Math.max(...parameters.ERERates.map(r => Number(r.FiscalYear)));
        if (Number(empSemester.FiscalYear) > lastYear) {
            ereRate = parameters.ERERates.find((rate) => rate.StaffType === employeeEarning.StaffType && rate.FiscalYear === lastYear);
            if (ereRate)
                thisERERate = ereRate.ERERate;
            ereRateInflated = thisERERate * Math.pow((1 + parameters.Inflation), (Number(empSemester.FiscalYear) - lastYear));
        }
        else {
            ereRateInflated = thisERERate;
        }

        let tuitionRemInflated = 0;
        //Find the tuition reimbursement rate
        if (employeeEarning.StaffType === 'RA/TA') {
            let thisFTE = employeeEarning.FTE != null ? employeeEarning.FTE : employeeEarning.EmplStatusPayGroup.toString().substr(6, 1);
            thisFTE = Number(thisFTE) >= .5 ? 1 : Number(thisFTE) >= .25 ? .5 : 0;
            let tuitionRemissionRate = parameters.TuitionRemissionRates.find(x => x.FiscalYear === Number(empSemester.FiscalYear));
            if (tuitionRemissionRate == null) {
                tuitionRemissionRate = parameters.TuitionRemissionRates.findLast(x => x.FiscalYear !== Number(empSemester.FiscalYear));
            }
            let tuitionRate = 0;
            if (tuitionRemissionRate) {
                tuitionRate = (empSemester.AcadPeriod.startsWith('Sum') ? tuitionRemissionRate.SummerPerWorkDay : tuitionRemissionRate.AcademicPerWorkDay) * thisFTE;
                if (Number(empSemester.FiscalYear) > tuitionRemissionRate.FiscalYear) {
                    tuitionRemInflated = tuitionRate * Math.pow(
                        (1 + parameters.Inflation),
                        (Number(empSemester.FiscalYear) - tuitionRemissionRate.FiscalYear)
                    );
                }
                else {
                    tuitionRemInflated = tuitionRate;
                }
            }
        }

        let empFundingPercent = getEmployeeFundingPercent(employeeEarning, empSemester);

        if (empFundingPercent && empFundingPercent.DisplayInput) {
            employeeEarningsValue += (empSemester.AcadPeriod.startsWith('Sum') ? employeeEarning.SummerPayRate : employeeEarning.AYPayRate) * empSemester.Workdays / 10 * (empFundingPercent.Value / 100);
            employeeEREForecast += Math.round(((empSemester.AcadPeriod.startsWith('Sum') ? employeeEarning.SummerPayRate : employeeEarning.AYPayRate) * empSemester.Workdays / 10 * (empFundingPercent.Value / 100) + Number.EPSILON) * 100) / 100 * ereRateInflated;
            tuitionRemForecast += (empSemester.Workdays * tuitionRemInflated * (empFundingPercent.Value / 100));
        }

        return { Earnings: Math.round((employeeEarningsValue + Number.EPSILON) * 100) / 100, EREForecast: employeeEREForecast, TuitionRemissionForecast: tuitionRemForecast };


    };
    const getEmployeeFundingPercent = (employeeEarning, employeeSemester) => {

        let value = null;
        let displayInput = true;
        let isUpdated = null;
        let thisEF = null;
        let thisPayGroup = employeeEarning.PayGroup;
        if (!thisPayGroup && employeeEarning.EmplStatusPayGroup)
            thisPayGroup = employeeEarning.EmplStatusPayGroup.split(' ')[1]

        if (accountEmployeeFunding) {
            thisEF = accountEmployeeFunding.find((ef) =>
                ef.StartDate === employeeSemester.MinStartDate &&
                ef.EmplId.trim() === employeeEarning.EmplId.trim() &&
                ef.Position === employeeEarning.Position &&
                ef.EmplRcd === employeeEarning.EmplRcd
            );
            if (thisPayGroup === 'FSW' && !employeeSemester.AcadPeriod.startsWith('Sum')) {
                value = null;
                displayInput = false;
            }
            else {
                if (thisPayGroup === 'ACD' && employeeSemester.AcadPeriod.startsWith('Sum')) {
                    value = null;
                    displayInput = false;
                }
                else {
                    if (thisEF) {
                        if (thisEF.EmplStatus === 'W') {
                            //if (thisPayGroup === 'FSW') {
                            //    value = Number(thisEF.FundingPercent) * 100;
                            //    displayInput = true;
                            //}
                            //if (thisPayGroup === 'GRD') {
                            //    value = Number(thisEF.FundingPercent) * 100;
                            //    displayInput = true;
                            //}
                            //    If rst!paygroup = "FSW" And Worksheets("Parameters").Cells(5, 8) = "Yes" Then
                            //        xls.Cells(irow, icol) = rst!fundingPercent
                            //    End If
                            //    If rst!paygroup = "GRD" And Worksheets("Parameters").Cells(7, 8) = "Yes" Then
                            //        xls.Cells(irow, icol) = rst!fundingPercent
                            //    End If
                            //assume that the options from the parameters (ForecastYourOptions) is the default which is 'No'
                            //value = Number(thisEF.FundingPercent) * 100;
                            displayInput = true;
                        }
                        else {
                            value = Number(thisEF.FundingPercent) * 100;
                            displayInput = true;
                        }
                    }
                }
            }
            if (thisEF && thisEF.IsForecast) {
                if (isNumeric(thisEF.FundingPercent)) {
                    value = Number(thisEF.FundingPercent) * 100;
                }
                displayInput = true;
                isUpdated = true;
            }

        }

        return { Value: value, DisplayInput: displayInput, IsUpdated: isUpdated }

    };
    const getIndirectForecastTotal = () => {
        let returnValue = 0;
        if (accountFiscalMonths) {
            accountFiscalMonths.forEach(fm => {
                returnValue += getDirectCostsByMonth(fm, 'Yes') * (getCurrentRate() / 100);
            });
        }
        return returnValue;
    };
    const getDirectCostsByMonth = (fiscalMonth, applyFA) => {
        let returnValue = 0;
        if (accountBudgetExpenses) {
            let accountLedgers = accountBudgetExpenses.filter(x => x.ApplyFA === applyFA);
            if (accountLedgers && accountLedgers.length > 0) {
                accountLedgers.forEach(al => {
                    returnValue += getLedgerForecastByMonth(fiscalMonth, al) ?? 0;
                });
            }

        }
        return returnValue;
    }
    const getIndirectCostsByMonth = (fiscalMonth) => {
        let returnValue = 0;
        if (accountBudgetExpenses) {
            let accountLedgers = getIndirectCostLedgerAccounts();
            if (accountLedgers && accountLedgers.length > 0) {
                accountLedgers.forEach(al => {
                    returnValue += getDirectCostsByMonth(fiscalMonth, 'Yes') * (getCurrentRate() / 100);
                });
            }

        }
        return returnValue;
    }
    const getForecastTotals = useCallback(() => {

        let returnBudget = 0;
        let returnExpenses = 0;
        let returnEncumbrances = 0;
        let returnForecast = 0;
        let returnAvailable = 0;


        let dirTotals = getDirectCosts('No');
        let indirTotals = getDirectCosts('Yes');

        returnBudget += dirTotals.Budget + indirTotals.Budget;
        returnExpenses += dirTotals.Expenses + indirTotals.Expenses;
        returnEncumbrances += dirTotals.Encumbrances + indirTotals.Encumbrances;
        returnForecast += dirTotals.Forecast + indirTotals.Forecast;
        returnAvailable += dirTotals.Available + indirTotals.Available;
        if (accountBudgetExpenses) {
            getIndirectCostLedgerAccounts().forEach(budgetExpense => {
                let thisForecastTotal = getAccountLedgerForecast(budgetExpense);
                returnBudget += budgetExpense.Budget;
                returnExpenses += budgetExpense.Expenses;
                returnEncumbrances += getIndirectAccountLedgerEncumbrances(budgetExpense);
                //if (budgetExpense.LedgerAccount.includes("ASUF")) {
                //    returnEncumbrances += budgetExpense.Encumbrances;
                //}
                //else {
                //    returnEncumbrances += getDirectCosts('Yes').Encumbrances * getCurrentRate() / 100;
                //}
                returnForecast += thisForecastTotal;
                returnAvailable += getIndirectAccountLedgerAvailable(budgetExpense);
            });
        }
        //console.log('getForecastTotals', { Budget: returnBudget, Expenses: returnExpenses, Encumbrances: returnEncumbrances, Forecast: returnForecast, Available: returnAvailable });
        return { Budget: returnBudget, Expenses: returnExpenses, Encumbrances: returnEncumbrances, Forecast: returnForecast, Available: returnAvailable };

    }, [
        accountDetail,
        accountBudgetExpenses,
        accountEmployeeEarnings,
        accountEmployeeSemesters,
        accountFiscalMonths,
        accountLedgerByMonth,
        accountEmployeeFunding,
        accountCurrentRate,
        loadComplete,
    ]);
    const getAccountLedgerAvailable = useCallback((budgetExpense) => {
        if (budgetExpense.Missing) {
            return null;
        }
        let thisBudget = budgetExpense.Budget ? budgetExpense.Budget : 0;
        let thisExpenses = budgetExpense.Expenses ? budgetExpense.Expenses : 0;
        let thisEncumbrances = budgetExpense.Encumbrances ? budgetExpense.Encumbrances : 0;
        let thisForecast = getAccountLedgerForecast(budgetExpense);
        if (Number(budgetExpense.LedgerAccountId) > 0) {
            return thisBudget - (thisExpenses + thisEncumbrances + thisForecast);
        }
        else {
            return null;
        }
    }, [accountEmployeeEarnings, accountEmployeeSemesters, accountEmployeeFunding, accountLedgerByMonth]);
    const getIndirectAccountLedgerEncumbrances = useCallback((budgetExpense) => {
        //console.log('getIndirectAccountLedgerEncumbrances', accountDetail, accountCurrentRate, accountBudgetExpenses, accountLedgerByMonth, accountEmployeeEarnings, accountEmployeeSemesters, accountEmployeeFunding)
        if (budgetExpense.LedgerAccount.includes("ASUF")) {
            let returnValue = 0;
            let thisBudget = budgetExpense.Budget ? budgetExpense.Budget : 0;
            let thisExpenses = budgetExpense.Expenses ? budgetExpense.Expenses : 0;
            let thisForecast = getAccountLedgerForecast(budgetExpense);
            returnValue = thisBudget - thisExpenses - thisForecast;
            if (returnValue < 0)
                returnValue = 0;
            return returnValue;
        }
        else {
            return budgetExpense.Encumbrances;
        }

    }, [accountDetail, accountCurrentRate, accountBudgetExpenses, accountLedgerByMonth, accountEmployeeEarnings, accountEmployeeSemesters, accountEmployeeFunding]);
    const getIndirectAccountLedgerAvailable = (budgetExpense) => {
        let thisBudget = budgetExpense.Budget ? budgetExpense.Budget : 0;
        let thisExpenses = budgetExpense.Expenses ? budgetExpense.Expenses : 0;
        let thisEncumbrances = getIndirectAccountLedgerEncumbrances(budgetExpense);
        //let thisForecast = getIndirectForecastTotal();
        let thisForecast = getAccountLedgerForecast(budgetExpense);
        return thisBudget - (thisExpenses + thisEncumbrances + thisForecast);
    };
    const getActualFAPercent = useCallback(() => {
        let retPerc = 0;
        let directCostsExpenses = getDirectCosts('Yes').Expenses;
        if (directCostsExpenses !== 0) {
            let la = getIndirectCostLedgerAccounts().find(l => l.LedgerAccount.startsWith("7520"))
            if (la) {
                retPerc = la.Expenses / directCostsExpenses * 100;
            }
        }
        return retPerc;
    }, []);
    const getAvailableToSpend = useCallback(() => {
        let available = 0;
        if (accountBudgetExpenses) {
            let la = getIndirectCostLedgerAccounts().find(l => l.LedgerAccount.startsWith("7521"));
            let asufAvailable = 0;
            if (la) {
                asufAvailable = getIndirectAccountLedgerAvailable(la);
            }
            available = (getCurrentRate() === 0)
                ?
                getForecastTotals().Available
                :
                (
                    getForecastTotals().Available - asufAvailable) / (1 + (getCurrentRate() / 100)
                );
        }
        return available;
    }, [accountCurrentRate, accountBudgetExpenses, accountEmployeeEarnings, accountLedgerByMonth]);
    const getForecastPayPeriods = useCallback(() => {
        let forecasts = { PI: 0, GRA: 0 };
        if (accountEmployeeFunding && accountEmployeeEarnings && accountEmployeeSemesters) {

            accountEmployeeEarnings.forEach(ee => {
                let thisFTE = ee.FTE != null ? ee.FTE : ee.EmplStatusPayGroup.toString().substr(6, 1);
                if (isNumeric(thisFTE)) {
                    thisFTE = Number(thisFTE);
                }
                else {
                    thisFTE = 1;
                }
                accountEmployeeSemesters.forEach(es => {
                    let empFundingPercent = getEmployeeFundingPercent(ee, es);
                    if (empFundingPercent && empFundingPercent.DisplayInput && empFundingPercent.Value) {
                        if (ee.StaffType === 'Faculty') {
                            forecasts.PI += empFundingPercent.Value / 100;
                        }
                        else if (ee.PayGroup === 'GRD') {
                            if (thisFTE === 1) {
                                forecasts.GRA += empFundingPercent.Value / 100;
                            }
                            else if (thisFTE >= 0.5) {
                                forecasts.GRA += empFundingPercent.Value / 100 * 0.5 * 2;
                            }
                            else if (thisFTE < 0.5) {
                                forecasts.GRA += empFundingPercent.Value / 100 * 0.25 * 2;
                            }
                        }
                    }

                });
            });

        }
        return forecasts;
    }, [accountEmployeeFunding, accountEmployeeEarnings]);

    const getGetActualTotal = useCallback(() => {
        let accountTotals = getForecastTotals();
        return (accountDetail ? accountDetail.RevenueBegBalance ?? 0 : 0) + (accountDetail ? accountDetail.Revenue ?? 0 : 0) + (accountTotals ? 0 - accountTotals.Expenses - accountTotals.Encumbrances - accountTotals.Forecast : 0);
    }, [accountDetail, accountBudgetExpenses, accountEmployeeEarnings, isLoading, accountEmployeeFunding]);
    const getAvailableDifference = useCallback(() => {
        let accountTotals = getForecastTotals();
        return (Math.round((accountTotals ? accountTotals.Available : 0) * 100) / 100) - (Math.round(getGetActualTotal() * 100) / 100);
    }, [accountDetail, accountBudgetExpenses, accountEmployeeEarnings, isLoading, accountEmployeeFunding]);
    const getAvailableToSpendDifference = useCallback(() => {
        return (Math.round(getAvailableToSpend() * 100) / 100) - (Math.round(getGetActualTotal() / (1 + (getCurrentRate() / 100)) * 100) / 100);
    }, [accountDetail, accountCurrentRate, accountLedgerByMonth , accountBudgetExpenses, accountEmployeeEarnings, isLoading, accountEmployeeFunding]);

    //const setItem = useCallback(function (i) {
    //    setValue(i);
    //}, []);

    //const writeItem = useCallback(function (i) {
    //    databaseWrite(id, i);
    //}, [id]) // this depends on id, and need to be recreated on id change

    return {
        isLoading,
        loadData,
        accountDetail,
        accountEmployeeEarnings,
        accountEmployeeSemesters,
        accountBudgetExpenses,
        accountCurrentRate,
        accountEmployeeFunding,
        getAccountCurrentRate,
        getAccountFABase,
        getCurrentRate,
        getDirectCostLedgerAccounts,
        getIndirectCostLedgerAccounts,
        getDirectCosts,
        getForecastTotals,
        getActualFAPercent,
        getAvailableToSpend,
        getLedgerForecastByMonth,
        getAccountLedgerForecast,
        getAccountLedgerAvailable,
        getIndirectAccountLedgerEncumbrances,
        getIndirectAccountLedgerAvailable,
        getEmployeeForecasts,
        getEmployeeFundingPercent,
        getForecastPayPeriods,
        loadComplete,
        getGetActualTotal,
        getAvailableDifference,
        getAvailableToSpendDifference
    };
}
export default useAccount;