import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { GetAuthUsername } from "../AuthUsername";
import Header from "./Header";
import SubMenu from "./SubMenu";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrashCan, faPenToSquare, faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { faChartPie, faFileCirclePlus, faPeopleArrows, faUserPlus, faMagnifyingGlass, faSpinner, faBriefcase, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';
import { isNullOrEmpty } from '../../utilities';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';

export default function Porfolio() {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const authUsername = GetAuthUsername();
    const authUserFullName = activeAccount ? activeAccount.name : authUsername;

    const [portfolioUsers, setPortfolioUsers] = useState([]);
    const [savedSearches, setSavedSearches] = useState([]);
    const [piPortfolio, setPIPortfolio] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [selectedUser, setSelectedUser] = useState(undefined);

    const [confirmDelSavedSearchModal, setConfirmDelSavedSearchModal] = useState(false);
    const [deletingSavedSearch, setDeletingSavedSearch] = useState(false);
    const [savedSearchToDelete, setSavedSearchToDelete] = useState(null);

    const [modalAddToPortfolio, setModalAddToPortfolio] = useState(false);
    const [searchPITerm, setSearchPITerm] = useState('');
    const [addAccount, setAddAccount] = useState('');
    const [piSearchResults, setPISearchResults] = useState([]);
    const [searchingPI, setSearchingPI] = useState(false);
    const [selectedPIs, setSelectedPIs] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [portfolioName, setPortfolioName] = useState('');
    const [runningSavedSearchId, setRunningSavedSearchId] = useState(null);

    const [modalEditToPortfolio, setModalEditToPortfolio] = useState(false);
    const [editSavedSearch, setEditSavedSearch] = useState(null);
    const [savingSavedSearch, setSavingSavedSearch] = useState(false);

    const [modalCreatePIReport, setModalCreatePIReport] = useState(false);
    const [savedSearchForReport, setSavedSearchForReport] = useState(null);
    const [newReportTitle, setNewReportTitle] = useState(null);


    const confirmDelSavedSearchModalToggle = () => setConfirmDelSavedSearchModal(!confirmDelSavedSearchModal);
    const modalAddToPortfolioToggle = () => setModalAddToPortfolio(!modalAddToPortfolio);
    const modalEditToPortfolioToggle = () => setModalEditToPortfolio(!modalEditToPortfolio);
    const modalCreatePIReportToggle = () => setModalCreatePIReport(!modalCreatePIReport);

    const piPortfolioColumns = [
        { name: 'Id', selector: row => row.id, sortable: true, omit: true },
        { name: 'Name', selector: row => row.searchName, sortable: true, id: 'Name' },
        {
            name: 'Criteria', selector: row => {
                return <>Criteria</>

            }, sortable: false, omit: false, grow: 3, cell: (row, index, column, id) => {
                let parsedCrit = JSON.parse(row.criteria);
                if (!parsedCrit.pis) {
                    //we have new criteria
                    parsedCrit = { pis: parsedCrit };
                }
                //const removeNullUndefined = obj => Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
                return <div className="w-100 pb-1">
                    {parsedCrit.pis && parsedCrit.pis.length > 0 ? parsedCrit.pis.map((crit, i) => <span className="badge text-bg-dark me-1" key={row.id + '-' + i}>{crit.FirstName} {crit.LastName} - {crit.EmplId}</span>) : null}
                    {parsedCrit.pis && parsedCrit.pis.length > 0 && parsedCrit.accounts && parsedCrit.accounts.length > 0 ? <div></div> : null}
                    {parsedCrit.accounts && parsedCrit.accounts.length > 0 ? parsedCrit.accounts.map((a, i) => <span className="badge text-bg-secondary me-1" key={row.id + '-' + i + '-' + a}>{a}</span>) : null}
                </div>
                //    let attribs = Object.entries(parsedCrit).filter(([_, v]) => !isNullOrEmpty(v));
                //    return <small>
                //        {attribs ? attribs.map((a, i) => {
                //            return <>{a[0]}: {a[1]}{attribs.length > i + 1 ? <br /> : ''} </>
                //        }) : null}
                //    </small>
            }
        },
        { name: 'Username', selector: row => row.username, sortable: true, omit: true },
        {
            name: 'Last Searched', omit: true, selector: row => row.lastSearched ? moment(row.lastSearched).format("M/D/yyyy h:mm:ss A") : null, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                const a = rowA.lastSearched ? moment(rowA.lastSearched) : 0;
                const b = rowB.lastSearched ? moment(rowB.lastSearched) : 0;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        { name: 'Results', selector: row => row.results, sortable: true, omit: true },
        {
            name: 'Date Created', selector: row => row.dateCreated ? moment(row.dateCreated).format("M/D/yyyy h:mm:ss A") : null, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                const a = rowA.dateCreated ? moment(rowA.dateCreated) : 0;
                const b = rowB.dateCreated ? moment(rowB.dateCreated) : 0;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        { name: 'Report Id', selector: row => row.reportId, sortable: true, omit: true },
        { name: 'Report Guid', selector: row => row.reportGuid, sortable: true, omit: true },
        {
            name: 'Actions', sortable: false, omit: false, cell: (row, index, column, id) => {
                return <div className="d-flex">
                    <button type="button" className="btn btn-default px-1 py-0" onClick={() => savedSearchHandler(row)} title="Run Portfolio Search">
                        <FontAwesomeIcon icon={runningSavedSearchId != null && runningSavedSearchId === row.id ? faSpinner : faMagnifyingGlass} className="" spin={runningSavedSearchId != null && runningSavedSearchId === row.id} />
                    </button>
                    <button type="button" className="btn btn-default px-1 py-0" onClick={(e) => editToPortfolio(e, row.id)} title="Edit Portfolio Search">
                        <FontAwesomeIcon icon={faPenToSquare} className="" />
                    </button>
                    {row.reportId ? null :
                        <button type="button" className="btn btn-default px-1 py-0" onClick={() => deleteSavedSearch(row.id)} title="Delete Search">
                            <FontAwesomeIcon icon={faTrashCan} className="" />
                        </button>
                    }
                    {row.reportId ?
                        <button type="button" className="btn btn-default px-1 py-0" onClick={() => viewReport(row.reportGuid)} title="View PI Report">
                            <FontAwesomeIcon icon={faFile} className="" />
                        </button>
                        :
                        <button type="button" className="btn btn-default px-1 py-0" onClick={(e) => createReport(e, row)} title="Create PI Report">
                            <FontAwesomeIcon icon={faFileCirclePlus} className="" />
                        </button>
                    }
                    {/*<button type="button" className="btn btn-default px-1 py-0" onClick={() => handleSaveEditAddEmployee(i)}>*/}
                    {/*    <FontAwesomeIcon icon={faFloppyDisk} className="" />*/}
                    {/*</button>*/}
                </div>

            }
        },
    ];
    const savedSearchColumns = [
        { name: 'Id', selector: row => row.id, sortable: true, omit: true },
        { name: 'Name', selector: row => row.searchName, sortable: true },
        {
            name: 'Criteria', selector: row => {
                if (row.criteria) {
                    let parsedCrit = JSON.parse(row.criteria);
                    return <>
                        {parsedCrit.Award ? `Award: ${parsedCrit.Award}` : null}
                        {parsedCrit.AccountStatus ? `Account Status: ${parsedCrit.AccountStatus}` : null}
                        {parsedCrit.Account ? `Account: ${parsedCrit.Account}` : null}
                        {parsedCrit.AccountType ? `Account Type: ${parsedCrit.AccountType}` : null}
                        {parsedCrit.LeadUnitFundCode ? `Lead Unit Fund Code: ${parsedCrit.LeadUnitFundCode}` : null}
                        {parsedCrit.CostCenterHierarchy ? `Cost Center Hierarchy: ${parsedCrit.CostCenterHierarchy}` : null}
                        {parsedCrit.PILastName ? `PI Last Name: ${parsedCrit.PILastName}` : null}
                    </>
                }

            }, sortable: true, omit: false, cell: (row, index, column, id) => {
                let parsedCrit = JSON.parse(row.criteria);
                //const removeNullUndefined = obj => Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
                let attribs = Object.entries(parsedCrit).filter(([_, v]) => !isNullOrEmpty(v));
                return <small>
                    {attribs ? attribs.map((a, i) => {
                        return <Fragment key={i}>{a[0]}: {a[1]}{attribs.length > i + 1 ? <br /> : ''} </Fragment>
                    }) : null}
                </small>
            }
        },
        { name: 'Username', selector: row => row.username, sortable: true, omit: true },
        {
            name: 'Last Searched', omit: true, selector: row => row.lastSearched ? moment(row.lastSearched).format("M/D/yyyy h:mm:ss A") : null, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                const a = rowA.lastSearched ? moment(rowA.lastSearched) : 0;
                const b = rowB.lastSearched ? moment(rowB.lastSearched) : 0;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        { name: 'Results', selector: row => row.results, sortable: true, omit: true },
        {
            name: 'Date Created', selector: row => row.dateCreated ? moment(row.dateCreated).format("M/D/yyyy h:mm:ss A") : null, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                const a = rowA.dateCreated ? moment(rowA.dateCreated) : 0;
                const b = rowB.dateCreated ? moment(rowB.dateCreated) : 0;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        {
            name: 'Actions', sortable: false, omit: false, cell: (row, index, column, id) => {
                return <div className="d-flex">
                    <button type="button" className="btn btn-default px-1 py-0" onClick={() => savedSearchHandler(row)}>
                        <FontAwesomeIcon icon={runningSavedSearchId != null && runningSavedSearchId === row.id ? faSpinner : faMagnifyingGlass} className="" spin={runningSavedSearchId != null && runningSavedSearchId === row.id} />
                    </button>
                    <button type="button" className="btn btn-default px-1 py-0" onClick={() => deleteSavedSearch(row.id)}>
                        <FontAwesomeIcon icon={faTrashCan} className="pe-1" />
                    </button>
                    {/*<button type="button" className="btn btn-default px-1 py-0" onClick={() => handleSaveEditAddEmployee(i)}>*/}
                    {/*    <FontAwesomeIcon icon={faFloppyDisk} className="" />*/}
                    {/*</button>*/}
                </div>

            }
        },
    ];
    useEffect(() => {
        setLoading(true);
        getPortfolioUsers();

        if (sessionStorage.getItem('PortfolioUser')) {
            const sessionPorfolioUser = sessionStorage.getItem('PortfolioUser');
            if (sessionPorfolioUser) {
                setSelectedUser(sessionPorfolioUser);
                getPIPortfolio(sessionPorfolioUser);
            }
            else {
                getPIPortfolio(selectedUser ?? authUsername);
            }
        }
        else {
            getPIPortfolio(selectedUser ?? authUsername);
        }

        getSavedSearches();

        setLoading(false);
    }, []);

    const getPortfolioUsers = async () => {
        const response = await fetch('/api/savedsearch/users?user=' + encodeURIComponent(authUsername));
        const data = await response.json();
        setPortfolioUsers(data);
    }
    const handleUserChange = (e) => {
        if (e.target.value === "") {
            setSelectedUser(undefined);
            sessionStorage.removeItem('PortfolioUser');
            getPIPortfolio(authUsername);
        }
        else {
            setSelectedUser(e.target.value);
            sessionStorage.setItem('PortfolioUser', e.target.value);
            getPIPortfolio(e.target.value);
        }
    }

    const getPIPortfolio = async (username) => {
        const response = await fetch('/api/savedsearch/piportfolio?user=' + encodeURIComponent(username ?? authUsername));
        const data = await response.json();
        setPIPortfolio(data);
    }
    const getSavedSearches = async () => {
        const response = await fetch('/api/savedsearch/?user=' + encodeURIComponent(authUsername));
        const data = await response.json();
        setSavedSearches(data);
    }
    const deleteSavedSearch = async (id) => {
        let thisObj = null;
        let clone = [...savedSearches];
        let thisItemIndex = clone.findIndex(i => i.id === id);
        if (thisItemIndex < 0) {
            clone = [...piPortfolio];
            thisItemIndex = clone.findIndex(i => i.id === id);
        }
        thisObj = clone[thisItemIndex];
        if (thisObj.isPIPortfolio) {
            let parsedCrit = JSON.parse(thisObj.criteria);
            if (!parsedCrit.pis) {
                //we have new criteria
                parsedCrit = { pis: parsedCrit };
            }
            thisObj.parsedCrit = parsedCrit;
        }
        setSavedSearchToDelete(thisObj);
        confirmDelSavedSearchModalToggle();
    }
    const confirmDeleteSavedSearch = async (id) => {
        setDeletingSavedSearch(true);
        try {
            //Delete record
            await fetch('/api/savedsearch?id=' + encodeURIComponent(id), {
                method: 'DELETE'
            }).catch(ex => {
                console.log('Caught Error')
            });

            let thisObj = null;
            let clone = [...savedSearches];
            let thisItemIndex = clone.findIndex(i => i.id === id);
            if (thisItemIndex < 0) {
                clone = [...piPortfolio];
                thisItemIndex = clone.findIndex(i => i.id === id);
            }
            thisObj = clone[thisItemIndex];


            confirmDelSavedSearchModalToggle();
            clone.splice(thisItemIndex, 1);
            if (thisObj.isPIPortfolio) {
                setPIPortfolio([...clone]);
            }
            else {
                setSavedSearches([...clone]);
            }
        }
        catch (ex) {
            console.log(ex);
            console.log('Error with Saved Search');
        }

        setDeletingSavedSearch(false);
    }

    const savedSearchHandler = async (row) => {
        //setLoading(true);
        if (row && row.criteria) {
            setRunningSavedSearchId(row.id);
            if (row.isPIPortfolio) {
                const response = await fetch('/api/savedsearch/portfoliosearch?' +
                    ('savedSearchId=' + encodeURIComponent(row.id)) + 
                    ('&accountStatus=Active')
                );
                const data = await response.json();

                try {
                    sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: true, savedSearch: row, searchCriteria: { AccountStatus: 'Active' }, results: data }, (key, value) => typeof value === 'undefined' ? null : value));
                }
                catch (ex) {
                    sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: true, savedSearch: row, searchCriteria: { AccountStatus: 'Active' }, results: [] }, (key, value) => typeof value === 'undefined' ? null : value));
                }

                navigate(`/accounts/find`);
            }
            else {
                let searchCriteria = JSON.parse(row.criteria);
                const response = await fetch('/api/account/find?' +
                    (!searchCriteria.Award ? '' : 'award=' + encodeURIComponent(searchCriteria.Award)) +
                    (!searchCriteria.AccountStatus ? '' : '&accountStatus=' + encodeURIComponent(searchCriteria.AccountStatus)) +
                    (!searchCriteria.Account ? '' : '&account=' + encodeURIComponent(searchCriteria.Account)) +
                    (!searchCriteria.AccountType ? '' : '&accountType=' + encodeURIComponent(searchCriteria.AccountType)) +
                    (!searchCriteria.LeadUnitFundCode ? '' : '&leadUnitFundCode=' + encodeURIComponent(searchCriteria.LeadUnitFundCode)) +
                    (!searchCriteria.CostCenterHierarchy ? '' : '&costCenterHierarchy=' + encodeURIComponent(searchCriteria.CostCenterHierarchy)) +
                    (!searchCriteria.PILastName ? '' : '&piLastName=' + encodeURIComponent(searchCriteria.PILastName))
                );
                const data = await response.json();

                try {
                    sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: false, searchCriteria: searchCriteria, results: data }, (key, value) => typeof value === 'undefined' ? null : value));
                }
                catch (ex) {
                    sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: false, searchCriteria: searchCriteria, results: [] }, (key, value) => typeof value === 'undefined' ? null : value));
                }

                navigate(`/accounts/find`);
            }
        }


        setRunningSavedSearchId(null);

        setLoading(false);


        //const navigate = useNavigate();
        //let navigate = useNavigate();
        //console.log(this.props);
        //console.log(this.props.history);
        //let opener = AccountOpener();
        //opener.handleClick();
        //this.props.history.push('/accounts/' + row.Account);
    };

    const searchPI = async (e) => {
        e.preventDefault();
        setSearchingPI(true);
        const response = await fetch('/api/savedsearch/searchpi?searchTerm=' + encodeURIComponent(searchPITerm));
        const data = await response.json();
        setPISearchResults(data);
        setSearchingPI(false);
    };
    const selectPI = async (row) => {
        let clone = [...selectedPIs];
        if (!clone.some(x => x.EmplId === row.EmplId)) {
            clone.push(row);
            setSelectedPIs([...clone])
        }
    }
    const removePI = async (i) => {
        let clone = [...selectedPIs];
        if (clone.length > 0) {
            clone.splice(i, 1);
            setSelectedPIs([...clone]);
        }
    }
    const addAccountToPortfolio = async (e) => {
        let clone = [...selectedAccounts];
        if (!clone.some(x => x === addAccount)) {
            clone.push(addAccount);
            setSelectedAccounts([...clone])
        }
        setAddAccount('');
    }
    const removeAccountFromPortfolio = async (i) => {
        let clone = [...selectedAccounts];
        if (clone.length > 0) {
            clone.splice(i, 1);
            setSelectedAccounts([...clone]);
        }
    }
    const addToPortfolio = async (e) => {
        e.preventDefault();
        setLoading(true);
        let saveObj = {
            SearchName: portfolioName,
            Criteria: JSON.stringify(
                {
                    pis: selectedPIs,
                    accounts: selectedAccounts,
                }
                , (key, value) => typeof value === 'undefined' ? null : value),
            Username: authUsername,
            UserFullName: authUserFullName,
            IsPIPortfolio: true,
        };

        try {
            //Post to API
            await fetch('/api/savedsearch', {
                method: 'POST',
                body: JSON.stringify(saveObj, (key, value) => typeof value === 'undefined' ? null : value),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).catch(ex => {
                console.log('Caught Error')
            });

        }
        catch (ex) {
            console.log(ex);
            console.log('Error with Saved Search');
        }
        setLoading(false);
        setPortfolioName('');
        setSelectedPIs([]);
        getPIPortfolio();
        modalAddToPortfolioToggle();
    }
    const getPISearchCriteriaPIs = (crit) => {
        let parsedCrit = JSON.parse(crit);
        if (parsedCrit && parsedCrit.pis)
            return parsedCrit.pis;

        return [];
    };
    const getPISearchCriteriaAccounts = (crit) => {
        let parsedCrit = JSON.parse(crit);
        if (parsedCrit && parsedCrit.accounts)
            return parsedCrit.accounts;

        return [];
    };
    const editToPortfolioRemovePI = (i) => {
        let pis = getPISearchCriteriaPIs(editSavedSearch.criteria);
        let accounts = getPISearchCriteriaAccounts(editSavedSearch.criteria);
        if (pis.length > 0) {
            pis.sort((a, b) => sortBy(a.FirstName, b.FirstName))
                .sort((a, b) => sortBy(a.LastName, b.LastName));
            pis.splice(i, 1);
            setEditSavedSearch({
                ...editSavedSearch, criteria: JSON.stringify(
                    {
                        pis: pis,
                        accounts: accounts,
                    }
                    , (key, value) => typeof value === 'undefined' ? null : value),
            });
        }
    };
    const editToPortfolioRemoveAccount = (i) => {
        let pis = getPISearchCriteriaPIs(editSavedSearch.criteria);
        let accounts = getPISearchCriteriaAccounts(editSavedSearch.criteria);
        if (accounts.length > 0) {
            accounts.sort((a, b) => sortBy(a, b));
            accounts.splice(i, 1);
            setEditSavedSearch({
                ...editSavedSearch, criteria: JSON.stringify(
                    {
                        pis: pis,
                        accounts: accounts,
                    }
                    , (key, value) => typeof value === 'undefined' ? null : value),
            });
        }
    };
    const addAccountEditToPortfolio = () => {
        let pis = getPISearchCriteriaPIs(editSavedSearch.criteria);
        let accounts = getPISearchCriteriaAccounts(editSavedSearch.criteria);
        if (!accounts.some(x => x === addAccount)) {
            accounts.push(addAccount);
            setEditSavedSearch({
                ...editSavedSearch, criteria: JSON.stringify(
                    {
                        pis: pis,
                        accounts: accounts,
                    }
                    , (key, value) => typeof value === 'undefined' ? null : value),
            });
        }
        setAddAccount('');
    };
    const addPIEditToPortfolio = (row) => {
        let pis = getPISearchCriteriaPIs(editSavedSearch.criteria);
        let accounts = getPISearchCriteriaAccounts(editSavedSearch.criteria);
        if (!pis.some(x => x.EmplId === row.EmplId)) {
            pis.push(row);
            setEditSavedSearch({
                ...editSavedSearch, criteria: JSON.stringify(
                    {
                        pis: pis,
                        accounts: accounts,
                    }
                    , (key, value) => typeof value === 'undefined' ? null : value),
            });
        }

    };
    const saveEditToPortfolio = async (e) => {
        e.preventDefault();
        setSavingSavedSearch(true);

        let saveObj = {
            SearchName: portfolioName,
            Criteria: JSON.stringify(
                {
                    pis: selectedPIs,
                    accounts: selectedAccounts,
                }
                , (key, value) => typeof value === 'undefined' ? null : value),
            Username: authUsername,
            UserFullName: authUserFullName,
            IsPIPortfolio: true,
        };

        try {
            //Post to API
            await fetch('/api/savedsearch', {
                method: 'POST',
                body: JSON.stringify(editSavedSearch, (key, value) => typeof value === 'undefined' ? null : value),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).catch(ex => {
                console.log('Caught Error')
            });

        }
        catch (ex) {
            console.log(ex);
            console.log('Error with Saved Search');
        }
        getPIPortfolio();
        setEditSavedSearch(null);
        setSavingSavedSearch(false);
        modalEditToPortfolioToggle();

    };
    const editToPortfolio = async (e, id) => {
        e.preventDefault();
        const response = await fetch('/api/savedsearch/' + encodeURIComponent(id));
        const data = await response.json();
        setEditSavedSearch(data);
        modalEditToPortfolioToggle();
    }
    const viewReport = async (reportGuid) => {
        //setLoading(true);
        if (reportGuid && reportGuid.length > 0) {
            navigate(`/reports/${reportGuid}`);
        }
    };
    const createReport = async (e, savedSearch) => {
        e.preventDefault();
        setSavedSearchForReport(savedSearch);
        modalCreatePIReportToggle();
    };
    const createNewReport = async (e) => {
        e.preventDefault();
        setLoading(true);
        let saveObj = {
            Title: newReportTitle,
            CreatedBy: authUsername,
            CreatedByName: authUserFullName,
            Status: 'Active',
            PortfolioSavedSearchId: savedSearchForReport.id,
        };

        try {

            //Post to API
            await fetch('/api/report', {
                method: 'POST',
                body: JSON.stringify(saveObj, (key, value) => typeof value === 'undefined' ? null : value),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => res.json()).then(data => {
                let obj = savedSearchForReport;
                obj.reportId = data.id;
                obj.reportGuid = data.guid;
                setSavedSearchForReport(obj);

                let clone = [...piPortfolio];
                let item = clone.findIndex(i => i.id === savedSearchForReport.id);
                if (item >= -1) {
                    clone[item].reportId = data.id;
                    clone[item].repotGuid = data.guid;
                }
                setPIPortfolio([...clone]);
            }).catch(ex => {
                console.log('Caught Error')
            });

        }
        catch (ex) {
            console.log(ex);
            console.log('Error with Report');
        }
        setLoading(false);

    };


    const sortBy = (a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }

    return (
        <>
            <Header />
            <SubMenu />
            <Modal isOpen={confirmDelSavedSearchModal} toggle={confirmDelSavedSearchModalToggle} size="md">
                <ModalHeader toggle={confirmDelSavedSearchModalToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faMagnifyingGlass} className="pe-2" />Delete Saved Search</h5>
                        {deletingSavedSearch ?
                            <div className="col-auto">
                                Deleting... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody style={{

                }}>
                    <div className="row pb-3">
                        <div className="col-auto">
                            Search Name: <b>{savedSearchToDelete ? savedSearchToDelete.searchName : ''}</b>
                        </div>
                    </div>
                    {savedSearchToDelete && savedSearchToDelete.isPIPortfolio
                        ?
                        <>
                            <div className="w-100 pb-1">
                                {savedSearchToDelete.parsedCrit.pis && savedSearchToDelete.parsedCrit.pis.length > 0 ? savedSearchToDelete.parsedCrit.pis.map((crit, i) => <span className="badge text-bg-dark me-1" key={savedSearchToDelete.id + '-' + i}>{crit.FirstName} {crit.LastName} - {crit.EmplId}</span>) : null}
                                {savedSearchToDelete.parsedCrit.pis && savedSearchToDelete.parsedCrit.pis.length > 0 && savedSearchToDelete.parsedCrit.accounts && savedSearchToDelete.parsedCrit.accounts.length > 0 ? <div></div> : null}
                                {savedSearchToDelete.parsedCrit.accounts && savedSearchToDelete.parsedCrit.accounts.length > 0 ? savedSearchToDelete.parsedCrit.accounts.map((a, i) => <span className="badge text-bg-secondary me-1" key={savedSearchToDelete.id + '-' + i + '-' + a}>{a}</span>) : null}
                            </div>
                        </>
                        :
                        <>
                            <div className="row">
                                <div className="col-6">
                                    Award:
                                </div>
                                <div className="col-auto fw-bold">
                                    {savedSearchToDelete ? JSON.parse(savedSearchToDelete.criteria).Award : ''}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Account Status:
                                </div>
                                <div className="col-auto fw-bold">
                                    {savedSearchToDelete ? JSON.parse(savedSearchToDelete.criteria).AccountStatus : ''}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Account:
                                </div>
                                <div className="col-auto fw-bold">
                                    {savedSearchToDelete ? JSON.parse(savedSearchToDelete.criteria).Account : ''}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Account Type:
                                </div>
                                <div className="col-auto fw-bold">
                                    {savedSearchToDelete ? JSON.parse(savedSearchToDelete.criteria).AccountType : ''}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Lead Unit/Fund Code:
                                </div>
                                <div className="col-auto fw-bold">
                                    {savedSearchToDelete ? JSON.parse(savedSearchToDelete.criteria).LeadUnitFundCode : ''}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Cost Center/Hierarchy:
                                </div>
                                <div className="col-auto fw-bold">
                                    {savedSearchToDelete ? JSON.parse(savedSearchToDelete.criteria).CostCenterHierarchy : ''}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    PI Last Name:
                                </div>
                                <div className="col-auto fw-bold">
                                    {savedSearchToDelete ? JSON.parse(savedSearchToDelete.criteria).PILastName : ''}
                                </div>
                            </div>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={confirmDelSavedSearchModalToggle}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={() => confirmDeleteSavedSearch(savedSearchToDelete ? savedSearchToDelete.id : null)}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
            <div className="container-xl px-2 pb-4">
                <div className="row g-3 d-flex pb-3">
                    <div className="col-auto align-self-center">
                        <h5 className="">
                            PI Portfolio
                        </h5>
                    </div>
                    <div className="col align-self-center">
                        <div className="row d-flex g-3 justify-content-end">
                            <div className="col-auto align-self-center">
                                <div className="input-group">
                                    <label className="input-group-text" htmlFor="userSelect"><FontAwesomeIcon icon={faPeopleArrows} /></label>
                                    <select className="form-select form-select-sm" id="userSelect" value={selectedUser} onChange={handleUserChange}>
                                        <option value="">Select From Other Portfolios...</option>
                                        {portfolioUsers && portfolioUsers.length > 0 ? portfolioUsers.map((u, i) =>
                                            <option key={i} value={u.UserName}>{u.UserFullName}</option>
                                        )
                                            :
                                            null
                                        }
                                    </select>
                                </div>
                                {/*<select className="form-select form-select-sm">*/}
                                {/*    <option value="">Select From Other Portfolios...</option>*/}
                                {/*    <option>April MacCleary</option>*/}
                                {/*    <option>David Alonzo</option>*/}
                                {/*    <option>Jayson Johnson</option>*/}
                                {/*    <option>Josh Carrasco</option>*/}
                                {/*    <option>Paul Krawczyk</option>*/}
                                {/*    <option>Reyna Gallegos</option>*/}
                                {/*</select>*/}
                            </div>
                            <div className="col-auto align-self-center">
                                <button type="submit" className="btn btn-secondary btn-sm float-end" onClick={modalAddToPortfolioToggle} disabled={false} >
                                    <FontAwesomeIcon icon={faBriefcase} className="pe-2" />
                                    Add to Porfolio
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    pagination
                    dense
                    /*pointerOnHover*/
                    striped
                    progressPending={loading}
                    columns={piPortfolioColumns}
                    data={piPortfolio}
                    paginationRowsPerPageOptions={[10, 25, 50]}
                    paginationPerPage={50}
                    rowsPerPage={50}
                    defaultSortFieldId="Name"
                    /*onRowClicked={savedSearchHandler}*/
                    customStyles="table-xs"
                        /*selectableRows*/ />
            </div>
            <div className="container-xl px-2 pb-4">
                <div className="row g-3">
                    <h5>Saved Searches</h5>
                </div>
                <DataTable
                    pagination
                    dense
                    /*pointerOnHover*/
                    striped
                    progressPending={loading}
                    columns={savedSearchColumns}
                    data={savedSearches}
                    paginationRowsPerPageOptions={[10, 25, 50]}
                    /*onRowClicked={savedSearchHandler}*/
                    customStyles="table-xs"
                        /*selectableRows*/ />
            </div>

            <Modal isOpen={modalEditToPortfolio} toggle={modalEditToPortfolioToggle} size="xl">
                <ModalHeader toggle={modalEditToPortfolioToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faBriefcase} className="pe-2" />Edit PI Portfolio</h5>
                        {deletingSavedSearch ?
                            <div className="col-auto">
                                Deleting... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody style={{

                }}>
                    {editSavedSearch ?
                        <div className="row">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="row pb-2">
                                        <label htmlFor="colFormLabelSm" className="col-auto col-form-label">Portfolio Name</label>
                                        <div className="col-auto">
                                            <input type="text" className="form-control form-control" id="colFormLabelSm" placeholder="" value={editSavedSearch.searchName} onChange={(e) => setEditSavedSearch({ ...editSavedSearch, searchName: e.target.value, })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className="col">
                                    <div className="row mb-2">
                                        <label htmlFor="colFormLabelSm" className="col-auto col-form-label text-end">Selected PIs:</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <ol className={`list-group ${editSavedSearch.criteria && getPISearchCriteriaPIs(editSavedSearch.criteria).length > 0 ? "list-group-numbered" : ""}`}>
                                                {editSavedSearch.criteria && getPISearchCriteriaPIs(editSavedSearch.criteria).length > 0 ? getPISearchCriteriaPIs(editSavedSearch.criteria)
                                                    .sort((a, b) => sortBy(a.FirstName, b.FirstName))
                                                    .sort((a, b) => sortBy(a.LastName, b.LastName))
                                                    .map((pi, i) =>
                                                        <>
                                                            <li key={i} className="list-group-item d-flex justify-content-between align-items-start" style={{ cursor: "pointer" }} onClick={() => editToPortfolioRemovePI(i)}>
                                                                <div className="ms-2 me-auto">
                                                                    <span className="fw-bold">{pi.LastName}, {pi.FirstName}</span>
                                                                    {pi.AcademicEmployeeName ? <> - ({pi.AcademicEmployeeName})</> : null}
                                                                </div>
                                                                <span className="badge bg-primary rounded-pill">{pi.EmplId}</span>
                                                            </li>
                                                        </>
                                                    )
                                                    : <li className="list-group-item text-center">
                                                        <div className="ms-2 text-center text-secondary">
                                                            Select a PI below
                                                        </div>
                                                    </li>
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row mb-2">
                                        <label htmlFor="colFormLabelSm" className="col-auto col-form-label text-end">Selected Accounts:</label>
                                        <div className="col">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Add Account..." value={addAccount} onChange={(e) => setAddAccount(e.target.value)} />
                                                <button className="btn btn-primary" type="button" disabled={!addAccount || addAccount === ''} onClick={addAccountEditToPortfolio}><FontAwesomeIcon icon={faSquarePlus} className="" /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <ol className={`list-group ${editSavedSearch.criteria && getPISearchCriteriaAccounts(editSavedSearch.criteria).length > 0 ? "list-group-numbered" : ""}`}>
                                                {editSavedSearch.criteria && getPISearchCriteriaAccounts(editSavedSearch.criteria).length > 0 ? getPISearchCriteriaAccounts(editSavedSearch.criteria)
                                                    .sort((a, b) => sortBy(a, b))
                                                    .map((a, i) =>
                                                        <>
                                                            <li key={i} className="list-group-item d-flex justify-content-between align-items-start" style={{ cursor: "pointer" }} onClick={() => editToPortfolioRemoveAccount(i)}>
                                                                <div className="ms-2 me-auto">
                                                                    <span className="fw-bold">{a}</span>
                                                                </div>
                                                            </li>
                                                        </>
                                                    )
                                                    : <li className="list-group-item text-center">
                                                        <div className="ms-2 text-center text-secondary">
                                                            Add Account above
                                                        </div>
                                                    </li>
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row justify-content-center align-items-center pb-2" onSubmit={searchPI}>
                                <div className="col-auto">
                                    <label className="my-1 me-2">Find PI:</label>
                                </div>
                                <div className="col-auto">
                                    <input type="text" className="form-control" value={searchPITerm} onChange={(e) => setSearchPITerm(e.target.value)} />
                                </div>
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-primary" disabled={!searchPITerm || searchPITerm === '' || searchingPI} >
                                        {searchingPI
                                            ?
                                            <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                                            :
                                            <FontAwesomeIcon icon={faMagnifyingGlass} className="pe-2" />
                                        }
                                        Find PI
                                    </button>
                                </div>
                            </form>
                            <DataTable
                                pagination
                                dense
                                /*pointerOnHover*/
                                striped
                                progressPending={searchingPI}
                                fixedHeader={true}
                                fixedHeaderScrollHeight="300px"
                                columns={[
                                    {
                                        name: '', width: "30px", cell: row => {
                                            return <FontAwesomeIcon icon={faUserPlus} className="" style={{ cursor: 'pointer' }} onClick={() => addPIEditToPortfolio(row)} />
                                        }
                                    },
                                    { name: 'Empl Id', selector: row => row.EmplId, sortable: true, omit: false },
                                    { name: 'First', selector: row => row.FirstName, sortable: true },
                                    { name: 'Last', selector: row => row.LastName, sortable: true },
                                    { name: 'User Id', selector: row => row.UserId, sortable: true },
                                    {
                                        name: 'Email', selector: row => row.BusinesseMail, sortable: true, wrap: false, grow: 2, cell: (row, index, column, id) => {
                                            return <div>
                                                {row.BusinesseMail}
                                            </div>
                                        }
                                    },
                                    { name: 'Full Name', selector: row => row.FullName, sortable: true, wrap: true },
                                    { name: 'Academic Employee', selector: row => row.AcademicEmployeeName, sortable: true, wrap: true },
                                    { name: 'Reference Id', selector: row => row.AcademicEmployeeReferenceId, sortable: true, wrap: true },
                                ]}
                                data={piSearchResults}
                                paginationRowsPerPageOptions={[10, 25, 50]}
                                /*onRowClicked={savedSearchHandler}*/
                                customStyles="table-xs"
                                responsive
                        /*selectableRows*/ />

                        </div>
                        : <div className="text-danger">You must select a PI Search</div>}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalEditToPortfolioToggle}>
                        Cancel
                    </Button>
                    <Button color="primary" disabled={!editSavedSearch || (editSavedSearch.searchName === '' || !(getPISearchCriteriaPIs(editSavedSearch.criteria).length !== 0 || getPISearchCriteriaAccounts(editSavedSearch.criteria).length !== 0))} onClick={saveEditToPortfolio}>
                        <FontAwesomeIcon icon={savingSavedSearch ? faSpinner : faFloppyDisk} spin={savingSavedSearch} className="me-2" />
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalAddToPortfolio} toggle={modalAddToPortfolioToggle} size="xl">
                <ModalHeader toggle={modalAddToPortfolioToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faBriefcase} className="pe-2" />Add To Portfolio</h5>
                        {deletingSavedSearch ?
                            <div className="col-auto">
                                Deleting... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody style={{

                }}>
                    <div className="row">
                        <div className="row">
                            <div className="col-auto">
                                <div className="row pb-2">
                                    <label htmlFor="colFormLabelSm" className="col-auto col-form-label">Portfolio Name</label>
                                    <div className="col-auto">
                                        <input type="text" className="form-control form-control" id="colFormLabelSm" placeholder="" value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col">
                                <div className="row mb-2">
                                    <label htmlFor="colFormLabelSm" className="col-auto col-form-label text-end">Selected PIs:</label>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <ol className={`list-group ${selectedPIs && selectedPIs.length > 0 ? "list-group-numbered" : ""}`}>
                                            {selectedPIs && selectedPIs.length > 0 ? selectedPIs
                                                .sort((a, b) => sortBy(a.FirstName, b.FirstName))
                                                .sort((a, b) => sortBy(a.LastName, b.LastName))
                                                .map((pi, i) =>
                                                    <Fragment key={i}>
                                                        <li className="list-group-item d-flex justify-content-between align-items-start" style={{ cursor: "pointer" }} onClick={() => removePI(i)}>
                                                            <div className="ms-2 me-auto">
                                                                <span className="fw-bold">{pi.LastName}, {pi.FirstName}</span>
                                                                {pi.AcademicEmployeeName ? <> - ({pi.AcademicEmployeeName})</> : null}
                                                            </div>
                                                            <span className="badge bg-primary rounded-pill">{pi.EmplId}</span>
                                                        </li>
                                                    </Fragment>
                                                )
                                                : <li className="list-group-item text-center">
                                                    <div className="ms-2 text-center text-secondary">
                                                        Select a PI below
                                                    </div>
                                                </li>
                                            }
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row mb-2">
                                    <label htmlFor="colFormLabelSm" className="col-auto col-form-label text-end">Selected Accounts:</label>
                                    <div className="col">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Add Account..." value={addAccount} onChange={(e) => setAddAccount(e.target.value)} />
                                            <button className="btn btn-primary" type="button" disabled={!addAccount || addAccount === ''} onClick={addAccountToPortfolio}><FontAwesomeIcon icon={faSquarePlus} className="" /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <ol className={`list-group ${selectedAccounts && selectedAccounts.length > 0 ? "list-group-numbered" : ""}`}>
                                            {selectedAccounts && selectedAccounts.length > 0 ? selectedAccounts
                                                .sort((a, b) => sortBy(a, b))
                                                .map((a, i) =>
                                                    <>
                                                        <li key={i} className="list-group-item d-flex justify-content-between align-items-start" style={{ cursor: "pointer" }} onClick={() => removeAccountFromPortfolio(i)}>
                                                            <div className="ms-2 me-auto">
                                                                <span className="fw-bold">{a}</span>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                                : <li className="list-group-item text-center">
                                                    <div className="ms-2 text-center text-secondary">
                                                        Add Account above
                                                    </div>
                                                </li>
                                            }
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <form className="row justify-content-center align-items-center pb-2" onSubmit={searchPI}>
                            <div className="col-auto">
                                <label className="my-1 me-2">Find PI:</label>
                            </div>
                            <div className="col-auto">
                                <input type="text" className="form-control" value={searchPITerm} onChange={(e) => setSearchPITerm(e.target.value)} />
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary" disabled={!searchPITerm || searchPITerm === '' || searchingPI} >
                                    {searchingPI
                                        ?
                                        <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                                        :
                                        <FontAwesomeIcon icon={faMagnifyingGlass} className="pe-2" />
                                    }
                                    Find PI
                                </button>
                            </div>
                        </form>
                        <DataTable
                            pagination
                            dense
                            /*pointerOnHover*/
                            striped
                            progressPending={searchingPI}
                            fixedHeader={true}
                            fixedHeaderScrollHeight="300px"
                            columns={[
                                {
                                    name: '', width: "30px", cell: row => {
                                        return <FontAwesomeIcon icon={faUserPlus} className="" style={{ cursor: 'pointer' }} onClick={() => selectPI(row)} />
                                    }
                                },
                                { name: 'Empl Id', selector: row => row.EmplId, sortable: true, omit: false },
                                { name: 'First', selector: row => row.FirstName, sortable: true },
                                { name: 'Last', selector: row => row.LastName, sortable: true },
                                { name: 'User Id', selector: row => row.UserId, sortable: true },
                                {
                                    name: 'Email', selector: row => row.BusinesseMail, sortable: true, wrap: false, grow: 2, cell: (row, index, column, id) => {
                                        return <div>
                                            {row.BusinesseMail}
                                        </div>
                                    }
                                },
                                { name: 'Full Name', selector: row => row.FullName, sortable: true, wrap: true },
                                { name: 'Academic Employee', selector: row => row.AcademicEmployeeName, sortable: true, wrap: true },
                                { name: 'Reference Id', selector: row => row.AcademicEmployeeReferenceId, sortable: true, wrap: true },
                            ]}
                            data={piSearchResults}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            /*onRowClicked={savedSearchHandler}*/
                            customStyles="table-xs"
                            responsive
                        /*selectableRows*/ />

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalAddToPortfolioToggle}>
                        Cancel
                    </Button>
                    <Button color="primary" disabled={(portfolioName === '' || !(selectedPIs.length !== 0 || selectedAccounts.length !== 0))} onClick={addToPortfolio}>
                        Add To Portfolio
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalCreatePIReport} toggle={modalCreatePIReportToggle} size="md">
                <ModalHeader toggle={modalCreatePIReportToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faChartPie} className="pe-2" />Create PI Report</h5>
                        {deletingSavedSearch ?
                            <div className="col-auto">
                                Deleting... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody style={{

                }}>
                    <div className="row">
                        <div className="row pb-3">
                            <div className="col-12">
                                <div className="row pb-2">
                                    <label htmlFor="colFormLabelSm" className="col-4 col-form-label">PI Portfolio Name:</label>
                                    <label htmlFor="colFormLabelSm" className="col-8 col-form-label fw-bold">{savedSearchForReport ? savedSearchForReport.searchName : null}</label>
                                </div>
                                <div className="row pb-2">
                                    <label htmlFor="colFormLabelSm" className="col-4 col-form-label">Report Title:</label>
                                    <div className="col-8">
                                        <input type="text" className="form-control form-control" id="colFormLabelSm" placeholder={savedSearchForReport ? savedSearchForReport.searchName + " Report" : null} value={newReportTitle} onChange={(e) => setNewReportTitle(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {savedSearchForReport && savedSearchForReport.reportId ?
                            <>
                                <hr />
                                <div className="col-12 text-center">
                                    <h5>The report has been created.</h5>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => { setSavedSearchForReport(null); setNewReportTitle(null); modalCreatePIReportToggle(); }}>
                        Cancel
                    </Button>
                    {savedSearchForReport && savedSearchForReport.reportId ?
                        <Button color="secondary" onClick={() => viewReport(savedSearchForReport.reportGuid)}>
                            <FontAwesomeIcon icon={faFile} className="" /> View Report
                        </Button>
                        :
                        <Button color="primary" disabled={!(newReportTitle && newReportTitle !== '')} onClick={createNewReport}>
                            Create PI Report
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        </>
    );

}
