import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import ReactDOM from 'react-dom';
import { Link, Route, Routes } from 'react-router-dom';
import { GetAuthUsername } from "../AuthUsername";

import Header from "./Header";
import SubMenu from "./SubMenu";
//import Find from "./Find";

import { NumericFormat } from 'react-number-format';

import { Alert, Button } from 'reactstrap';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faLink, faMagnifyingGlass, faSpinner, faFileInvoice, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import acquireTokenRequest from '../../MsalHelper.ts';

export default function AccountSummary() {
    const { instance, accounts } = useMsal();
    const [reports, setReports] = useState([]);
    const [accountMRUs, setAccountMRUs] = useState([]);
    const [loading, setLoading] = useState(true);
    const authUsername = GetAuthUsername();


    const navigate = useNavigate();

    const dateNow = moment();

    const columns = [
        { name: 'Title', selector: row => row.Title, sortable: true },
        { name: 'Created By', selector: row => row.CreatedByName, sortable: true },
        {
            name: 'Date Created', selector: row => row.DateCreated ? moment(row.DateCreated).format("M/D/yyyy h:mm:ss A") : null, sortable: true, sortFunction: (rowA, rowB) => {
                const a = rowA.DateCreated ? moment(rowA.DateCreated) : 0;
                const b = rowB.DateCreated ? moment(rowB.DateCreated) : 0;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        {
            name: 'Actions', sortable: false, omit: false, cell: (row, index, column, id) => {
                return <div className="d-flex">
                    <button type="button" className="btn btn-default px-1 py-0" title={`Copy Link:\n${window.location.origin + '/reports/' + row.Guid}`} onClick={(e) => copyLink(e, row)}>
                        <FontAwesomeIcon icon={faLink} className="" />
                    </button>
                </div>

            }
        },
    ];

    useEffect(() => {
        getReports();

    }, []);

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const copyLink = (e, row) => {
        copyTextToClipboard(window.location.origin + '/reports/' + row.Guid)
            .then(() => {
                e.target.Title = 'Copied'
            })
            .catch((err) => {
                console.log(err)
            });
    }
    const getReports = async () => {

        setLoading(true);

        const response = await fetch('/api/report?user=' + encodeURIComponent(authUsername));
        const data = await response.json();
        setReports(data);
        setLoading(false);

    }
    const openReportHandler = row => {
        navigate(`/reports/${row.Guid}`);
    };

    return (
        <>
            <Header />
            <SubMenu />
            <div className="container">
                <div className="row g-3 mb-4 mx-2">
                    <h5>My Reports</h5>
                    <p className="text-secondary">Select the report to review from the list below.</p>
                    <DataTable
                        pagination
                        dense
                        pointerOnHover
                        striped
                        progressPending={loading}
                        columns={columns}
                        data={reports}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        onRowClicked={openReportHandler}
                        /*selectableRows*/ />
                </div>
            </div>
        </>
    );

}
