import React, { Component } from 'react';
import { Container, Badge } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { GetAuthUsername } from "./AuthUsername";
import moment from 'moment';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = {
            environment: null,
        };

    }

    componentDidMount() {
        this.getEnvData();
    }

    render() {
        return (
            <div className="content-wrap">
                <NavMenu />
                <Container fluid>
                    {this.props.children}
                </Container>
                <footer className="border-top text-muted mt-auto py-1">
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <div>
                                &copy; {moment().year()} - Forecasting Tool
                            </div>
                            <div>
                                <small>
                                    <Badge color={this.state.environment && this.state.environment.badgeBG ? this.state.environment.badgeBG : 'secondary'}>
                                        {this.state.environment && this.state.environment.environment ? this.state.environment.environment : ''} {this.state.environment && this.state.environment.version ? this.state.environment.version : process.env.REACT_APP_VERSION}
                                    </Badge>
                                </small>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
    async getEnvData() {
        const response = await fetch('/api/lookup/environment');
        const data = await response.json();
        this.setState({ environment: data });
    }

}