import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import '@fortawesome/fontawesome-free/css/all.min.css';
export default function HomeLoadTimeWarning() {

    const [date, setDate] = useState(new Date());
    const [databaseLoad, setDatabaseLoad] = useState(null);
    const [toastIcon, setToastIcon] = useState(<FontAwesomeIcon icon={faBarsProgress} className="pe-2" />);
    const [toastOpen, setToastOpen] = useState(false);
    const [configData, setConfigData] = useState(null);
    const [dismissTime, setDismissTime] = useState(null);

    const toggleToast = () => {
        setToastOpen(!toastOpen);
        setDismissTime(moment());
    };

    

    useEffect(() => {


        var timer = setInterval(() => {
            //setDate(new Date());
            //setToastOpen(showMessage());
            getDBLoadInfo();
        }, 300000);

        if (!databaseLoad) {
            getDBLoadInfo();
        }
        if (!configData) {
            getConfigData();
        }
        return function cleanup() {
            clearInterval(timer)
        }

    });

    const getDBLoadInfo = async () => {
        const response = await fetch('/api/lookup/databaseload');
        const data = await response.json();
        showToast(data);
        setDatabaseLoad(data);

    };
    const showToast = async (data) => {
        /*
        if there is a record, take a look at the start/end date
        If there is an end date populated, the latest load has completed
            Should we look at the end time to see if it has been more than 24 hours since the last load completed?
        If there is no end date, the load is in progress and the toast should be shown

        */
        let show = false;
        if (data) {
            //There is a record, let's take a look at the dates
            if (data.LoadStart) {
                //The load has started, now see if the load has finished
                if (data.LoadEnd) {
                    //The load has finished
                }
                else {
                    //The load has not finished, show it
                    show = true;
                }
            }
        }
        else {
            //There is no record so assume there is no load yet
        }
        setToastOpen(show);
    };


    const getConfigData = async () => {
        const response = await fetch('/api/lookup/databaseloadtime');
        const data = await response.json();
        setConfigData(data);
    };
    const getMessage = () => {
        if (configData) {
            let message = configData.message;
            message = message.replace(/\${time}/, configData && configData.availableStartTimeUTC ? moment.utc(configData.availableStartTimeUTC, 'h:mm A').local().format('h:mm A') : '');
            return message;
        }
        return ''
    };
    const showMessage = () => {
        if (configData) {
            if (dismissTime) {
                if (!isBefore()) {
                    setDismissTime(null);
                    return false;
                }
                if (moment(dismissTime).add(configData.snoozeMinutes, 'minutes').isBefore(moment())) {
                    setDismissTime(null);
                    return true;
                }
                else {
                    return false;
                }
            } 
            else {
                return isBefore();
            }
        }
        return false;
    };
    const isBefore = () => {
        if (configData) {
            let now = moment();
            let startTime = configData.availableStartTimeUTC ? moment.utc(configData.availableStartTimeUTC, 'h:mm A').local() : moment();

            return now.date() === startTime.date() && now.isBefore(startTime);
        }
        return false;
    }

    return (
        <>
            <div className="p-3 toast-container position-fixed top-0 end-0 p-3">
                <Toast fade={true} isOpen={toastOpen} className="mt-3">
                    <ToastHeader toggle={toggleToast} icon={toastIcon} fade="true" aria-live="assertive" aria-atomic="true" className="text-bg-warning">
                        Forecasting Tool Database
                    </ToastHeader>
                    <ToastBody className="bg-warning-subtle">
                        <div dangerouslySetInnerHTML={{ __html: getMessage() }} />
                        {databaseLoad && databaseLoad.StatusMessage ?
                            <>
                                <br />
                                <div dangerouslySetInnerHTML={{ __html: databaseLoad.StatusMessage }} />
                            </>
                        : null}

                    </ToastBody>
                </Toast>
                {/*<Toast fade={true} isOpen={toastOpen} className="mt-3">*/}
                {/*    <ToastHeader toggle={null} icon={<i className="fas fa-triangle-exclamation pe-2"></i>} fade="true" aria-live="assertive" aria-atomic="true" className="">*/}
                {/*        Forecasting Tool*/}
                {/*    </ToastHeader>*/}
                {/*    <ToastBody className="">*/}
                {/*        <div dangerouslySetInnerHTML={{ __html: getMessage() }} />*/}
                {/*    </ToastBody>*/}
                {/*</Toast>*/}
                {/*<Toast fade={true} isOpen={toastOpen} className="text-bg-info">*/}
                {/*    <ToastBody className="">*/}
                {/*        <div dangerouslySetInnerHTML={{ __html: getMessage() }} />*/}
                {/*    </ToastBody>*/}
                {/*</Toast>*/}
{/*                <i className="fas fa-check"></i>*/}
            </div>
            {/*<div className="row align-items-md-stretch pt-5">*/}
            {/*    <div className="col-md-12">*/}
            {/*        <div className="card bg-warning-subtle mb-3">*/}
            {/*            <div className="card-header bg-warning"><FontAwesomeIcon icon={faBarsProgress} className="pe-2" />Note</div>*/}
            {/*            <div className="card-body text-warning-emphasis">*/}
            {/*                <h5 className="card-title">Forecasting Tool Database</h5>*/}
            {/*                {date.toUTCString()}<br />*/}
            {/*                Parsed UTC to Local: {moment.utc('4:00 pm', 'h:mm a').local().format()}<br />*/}
            {/*                Parsed UTC: {moment.utc('4:00 pm', 'h:mm a').format()}<br />*/}
            {/*                Prased UTC From Config: {configData && configData.availableStartTimeUTC ? moment.utc(configData.availableStartTimeUTC, 'h:mm A').format() : <i>Nothing</i>}<br />*/}
            {/*                Parsed From Config to Local: {configData && configData.availableStartTimeUTC ? moment.utc(configData.availableStartTimeUTC, 'h:mm A').local().format('h:mm A') : <i>Nothing</i>}<br />*/}
            {/*                Time Now: {moment().format()}<br />*/}
            {/*                moment(): {moment().format()}<br />*/}
            {/*                start Time: {configData && configData.availableStartTimeUTC ? moment.utc(configData.availableStartTimeUTC, 'h:mm A').local().format() : <>None</>}<br />*/}
            {/*                show: {JSON.stringify(showMessage()) }<br />*/}
            {/*                {moment().isBefore(configData && configData.availableStartTimeUTC ? moment.utc(configData.availableStartTimeUTC, 'h:mm A') : moment.utc()) ? 'Show It' : 'Hide It'}<br />*/}
            {/*                {moment(date).utc().format('LTS')}<br />*/}
            {/*                {moment(date).utc().format('h:mm A')}*/}
            {/*                <p className="card-text">The Forecasting Tool database is refreshed during business off hours.  Accessing the tool before 9AM may cause intermittent delays and inconsistent results.</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
}
