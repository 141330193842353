import React, { useState, useEffect } from 'react';

export const useFetch = (url) => {
	let apiBaseUrl = process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API_URL : '';
	console.log('useFecth', process.env.NODE_ENV, apiBaseUrl);


	const [state, setState] = useState();

	useEffect(() => {
		const dataFetch = async () => {
			const data = await (await fetch(url)).json();

			setState(data);
		};

		dataFetch();
	}, [url]);

	return { data: state };
}

export const isNumeric = (n) => {
	return !isNaN(parseFloat(n)) && isFinite(n);
};
export const isNumericDefault = (n, defaultValue) => {
	return isNumeric(n) ? n : defaultValue;
};
export const isNullOrEmpty = (val) => {
	return (!val || val === undefined || val === "" || val.length === 0);
};
export const isNullOrEmptyDefault = (val, defaultValue) => {
	return isNullOrEmpty(val) ? val : defaultValue;
};
export const networkDays = (startDate, endDate) => {
	startDate = typeof startDate == 'object' ? startDate : new Date(startDate);
	endDate = typeof endDate == 'object' ? endDate : new Date(endDate);
	//if (endDate > startDate) {
		let days = Math.ceil((endDate.setHours(23, 59, 59, 999) - startDate.setHours(0, 0, 0, 1)) / (86400 * 1000));
		let weeks = Math.floor(Math.ceil((endDate.setHours(23, 59, 59, 999) - startDate.setHours(0, 0, 0, 1)) / (86400 * 1000)) / 7);

		days = days - (weeks * 2);
		days = startDate.getDay() - endDate.getDay() > 1 ? days - 2 : days;
		days = startDate.getDay() === 0 && endDate.getDay() !== 6 ? days - 1 : days;
		days = endDate.getDay() === 6 && startDate.getDay() !== 0 ? days - 1 : days;

		return days;
//	}
//	return null;
};
