import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { GetAuthUsername } from "../AuthUsername";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'bootstrap';
import TimeFromDate from '../shared/TimeFromDate';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function AccountNotes(props) {

    const { instance } = useMsal();

    const [accountNotes, setAccountNotes] = useState([]);
    const [newNote, setNewNote] = useState('');
    const [noteType, setNoteType] = useState('PI');

    const [noteToDelete, setNoteToDelete] = useState(null);
    const [noteToEdit, setNoteToEdit] = useState(null);

    const activeAccount = instance.getActiveAccount();
    const authUsername = GetAuthUsername();

    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);

    const modalToggle = () => setModal(!modal);
    const modalEditToggle = () => setModalEdit(!modalEdit);

    useEffect(() => {
        async function fetchData() {
            const responseNote = await fetch('/api/account/note?account=' + encodeURIComponent(props.account));
            const dataNote = await responseNote.json();
            setAccountNotes(dataNote);
        }
        fetchData();

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };


    }, []);


    const addNewNote = async (e) => {
        e.preventDefault();
        let createNote = { id: null, account: props.account, note: newNote, noteBy: activeAccount ? activeAccount.name : authUsername, noteType: noteType, dateCreated: moment().valueOf() };

        let response = fetch('/api/account/note', {
            method: 'POST',
            body: JSON.stringify({ account: props.account, note: newNote, noteBy: activeAccount ? activeAccount.name : authUsername, noteType: noteType }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        //let data = await response.json();
        //console.log(data);

        setNewNote('');
        setAccountNotes([createNote, ...accountNotes]);
    }

    const handleNewNote = event => {
        setNewNote(event.target.value);
    };

    const noteTabSelect = (value) => {
        setNoteType(value);
    };

    const deleteNote = (note) => {
        setNoteToDelete(note);
        modalToggle();
    };
    const confirmDeleteNote = async () => {
        try {
            //Delete record
            await fetch('/api/account/note?id=' + encodeURIComponent(noteToDelete.id), {
                method: 'DELETE'
            }).catch(ex => {
                console.log('Caught Error')
            });

            let clone = [...accountNotes];
            let thisItemIndex = clone.findIndex(i => i.id === noteToDelete.id);
            if (thisItemIndex >= 0) {
                clone.splice(thisItemIndex, 1);
                setAccountNotes([...clone]);
            }
            setNoteToDelete(null);
            modalToggle();
        }
        catch (ex) {
            console.log(ex);
            console.log('Error with Delete Note');
        }
    };
    const editNote = (note) => {
        setNoteToEdit(note);
        modalEditToggle();
    };
    const handleEditNote = event => {
        setNoteToEdit({ ...noteToEdit, note: event.target.value, updatedBy: activeAccount ? activeAccount.name : authUsername, dateUpdated: moment().local().toDate().toJSON() });
    };
    const saveEditNote = async (e) => {
        e.preventDefault();

        await fetch('/api/account/note', {
            method: 'POST',
            body: JSON.stringify(noteToEdit, (key, value) => typeof value === 'undefined' ? null : value),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        let clone = accountNotes;
        let thisNoteIX = clone.findIndex(x => x.id === noteToEdit.id);
        if (thisNoteIX >= 0) {
            clone[thisNoteIX] = noteToEdit;
        }
        setAccountNotes([...clone]);

        setNoteToEdit(null);
        modalEditToggle();
    }



    return (
        <>
            <div className="card mb-4 h-100">
                <div className="card-header">
                    <FontAwesomeIcon icon={faClipboard} className="pe-1" />Notes
                    <ul className="nav nav-underline float-end p-0" id="myTab" role="tablist">
                        <li className="nav-item p-0" role="presentation">
                            <button className={`nav-link p-0 lh-1 ${noteType === 'PI' ? "active" : ""}`} id="piTab" onClick={() => noteTabSelect('PI')} type="button">PI</button>
                        </li>
                        <li className="nav-item p-0" role="presentation">
                            <button className={`nav-link p-0 lh-1 ${noteType === 'RA' ? "active" : ""}`} id="raTab" onClick={() => noteTabSelect('RA')} type="button">RA</button>
                        </li>
                    </ul>
                </div>
                <div className="card-body p-1 pb-0">
                    <div className="notes" style={{ 'overflowY': 'auto', 'maxHeight': '335px' }}>
                        {accountNotes.filter(row => row.noteType === noteType).map((note, index) =>
                            <div className="card mb-1" key={index}>
                                <div className="card-header px-1 py-1 fw-bold">
                                    {note.updatedBy ?? note.noteBy}
                                    <small className="text-danger ps-1 text-end float-end" >
                                        <FontAwesomeIcon title="Delete Note" icon={faTrashCan} style={{ "cursor": "pointer" }} onClick={() => deleteNote(note)} />
                                    </small>
                                    <small className="text-primary ps-1 text-end float-end" >
                                        <FontAwesomeIcon title="Edit Note" icon={faPenToSquare} style={{ "cursor": "pointer" }} onClick={() => editNote(note)} />
                                    </small>
                                    <small data-bs-toggle="tooltip" data-bs-title={moment(note.dateUpdated ?? note.dateCreated).format("M/D/yyyy h:mm:ss A")} className="text-end float-end"><TimeFromDate date={note.dateUpdated ?? note.dateCreated} /></small>
                                </div>
                                {/*<span className="position-absolute translate-middle badge text-primary" style={{ "top": "0px", "right": "-0px", "cursor": "pointer" }} data-bs-toggle="dropdown" aria-expanded="false">*/}
                                {/*    <FontAwesomeIcon icon={faClipboard} />*/}
                                {/*    */}{/*<span class="visually-hidden">unread messages</span>*/}
                                {/*</span>*/}
                                <div className="card-body px-2 py-1">
                                    <p className="card-text">{note.note}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-footer p-1">
                    <form onSubmit={addNewNote}>
                        <div className="input-group">
                            <textarea className="form-control form-control-sm " rows="3" style={{ resize: 'none' }} value={newNote} onChange={handleNewNote} >{newNote}</textarea>
                            <button className="btn btn-sm btn-primary" type="submit">Add Note</button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal isOpen={modal} toggle={modalToggle} size="md">
                <ModalHeader toggle={modalToggle}><FontAwesomeIcon icon={faClipboard} className="pe-2 text-danger" />Delete Note</ModalHeader>
                <ModalBody>
                    <h5 className="pb-3">Confirm to delete the note below:</h5>
                    {noteToDelete ?
                        <div className="notes card mb-1">
                            <div className="card-header px-2 py-1 fw-bold">
                                {noteToDelete.udpatedBy ?? noteToDelete.noteBy}
                                <small data-bs-toggle="tooltip" data-bs-title={moment(noteToDelete.dateUpdated ?? noteToDelete.dateCreated).format("M/D/yyyy h:mm:ss A")} className="text-end float-end"><TimeFromDate date={noteToDelete.dateUpdated ?? noteToDelete.dateCreated} /></small>
                            </div>
                            <div className="card-body px-2 py-1">
                                <p className="card-text">{noteToDelete.note}</p>
                            </div>
                        </div>
                        :
                        null}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDeleteNote}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalEdit} toggle={modalEditToggle} size="md">
                <ModalHeader toggle={modalEditToggle}><FontAwesomeIcon icon={faClipboard} className="pe-2" />Edit Note</ModalHeader>
                <ModalBody>
                    {noteToEdit ?
                        <div className="card mb-1">
                            <div className="card-header px-2 py-1 fw-bold">
                                {noteToEdit.updatedBy ?? noteToEdit.noteBy}
                                <small data-bs-toggle="tooltip" data-bs-title={moment(noteToEdit.dateUpdated ?? noteToEdit.dateCreated).format("M/D/yyyy h:mm:ss A")} className="text-end float-end"><TimeFromDate date={noteToEdit.dateUpdated ?? noteToEdit.dateCreated} /></small>
                            </div>
                            <div className="card-body px-2 py-1">
                                <textarea className="form-control form-control-sm " rows="10" style={{ resize: 'none' }} value={noteToEdit.note} onChange={handleEditNote} >{noteToEdit.note}</textarea>
                            </div>
                        </div>
                        :
                        null}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalEditToggle}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={saveEditNote}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default AccountNotes;