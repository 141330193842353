import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, Route, Routes } from 'react-router-dom';
import { GetAuthUsername } from "../AuthUsername";

import Header from "./Header";
import SubMenu from "./SubMenu";
//import Find from "./Find";

import { Alert, Button } from 'reactstrap';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faMagnifyingGlass, faSpinner, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import TimeFromDate from '../shared/TimeFromDate';


function Accounts() {
    const [accountMRUs, setAccountMRUs] = useState([]);
    const [loading, setLoading] = useState(true);
    const authUsername = GetAuthUsername();

    const navigate = useNavigate();

    const columns = [
        { name: 'Account', selector: row => row.account, sortable: true },
        { name: 'Accessed Date', selector: row => row.accessedDate, cell: row => <span data-bs-toggle="tooltip" data-bs-title={moment(row.accessedDate).format("M/D/yyyy h:mm:ss A")}><TimeFromDate date={row.accessedDate} /></span>, sortable: true },
    ];

    useEffect(() => {
        // Update the document title using the browser API
        populateMRUs();

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    }, []);

    const populateMRUs = async () => {

        setLoading(true);

        const response = await fetch('/api/account/mru?user=' + encodeURIComponent(authUsername));
        const data = await response.json();
        setAccountMRUs(data);
        setLoading(false);

    }

    const accountHandler = row => {
        navigate(`/accounts/${row.account}`);
    };

    return (
        <>
            <Header />
            <SubMenu />
            <div className="container-xl px-2 pb-4">
                <div className="row g-3">
                    <h5>Recently Accessed Accounts</h5>
                    <DataTable
                        pagination
                        dense
                        pointerOnHover
                        striped
                        progressPending={loading}
                        columns={columns}
                        data={accountMRUs}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        onRowClicked={accountHandler}
                        /*selectableRows*/ />
                </div>

            </div>

        </>
    );

}
export default Accounts;
//export class Accounts extends Component {
//    static displayName = Accounts.name;

//    constructor(props) {
//        super(props);
//        this.state = {
//            accountMRUs: [],
//            searchBy: '',
//            searchAward: '',
//            searchAccountStatus: '',
//            searchAccount: '',
//            searchAccountType: '',
//            searchLeadUnitFundCode: '',
//            searchCostCenterHierarchy: '',
//            searchPILastName: '',
//            optionsAccountStatus: ['Active', 'Active and Expired', 'All', 'Activation in Progress'],
//            optionsAccountType: ['All', 'C/S and P/I', 'Not C/S and P/I', 'Just Workday', 'Workday Programs', 'Workday Gifts', 'Workday Projects'],
//            optionsLeadUnitFundCode: [],
//            optionsCostCenterHierarchy: [],
//            accounts: [],
//            loading: false,
//            searching: false,
//            showAlert: false,
//            alertContent: '',
//        };

//    }

//    componentDidMount() {
//        this.populateMRUs();
//    }

//    render() {

//        //const dataSet = [
//        //    ["Tiger Nixon", "System Architect", "Edinburgh", "5421", "2011/04/25", "$320,800"],
//        //    ["Garrett Winters", "Accountant", "Tokyo", "8422", "2011/07/25", "$170,750"],
//        //    ["Ashton Cox", "Junior Technical Author", "San Francisco", "1562", "2009/01/12", "$86,000"]
//        //];
//        const navigate = useNavigate();

//        const handleChange = event => {
//            const searchTerm = event.target.value;
//            // Clear any existing timeout
//            clearTimeout(this.searchTimeout);

//            // Set a new timeout to execute the search after 500 milliseconds of inactivity
//            this.searchTimeout = setTimeout(() => {
//                this.setState({ searchTerm: searchTerm, loading: true }, () => {
//                    this.findAccounts();
//                });
//            }, 500);

//        };
//        const handleChangeAward = event => {
//            this.setState({ searchAward: event.target.value });
//        };
//        const handleChangeAccountStatus = event => {
//            this.setState({ searchAccountStatus: event.target.value });
//        };
//        const handleChangeAccount = event => {
//            this.setState({ searchAccount: event.target.value });
//        };
//        const handleChangeAccountType = event => {
//            this.setState({ searchAccountType: event.target.value });
//        };
//        const handleChangeLeadUnitFundCode = event => {
//            this.setState({ searchLeadUnitFundCode: event.target.value });
//        };
//        const handleChangeCostCenterHierarchy = event => {
//            this.setState({ searchCostCenterHierarchy: event.target.value });
//        };
//        const handleChangePILastName= event => {
//            this.setState({ searchPILastName: event.target.value });
//        };
//        const handleFindAccounts = event => {
//            this.setState({ searching: true });
//            this.findAccounts();
//        };
//        const toggleAlert = event => {
//            this.setState({ showAlert: !this.state.showAlert })
//        };
//        const accountHandler = row => {
//            navigate(`/accounts/${row.Account}`);
//        };

//        //const dataSet = this.state.accounts;
//        const columns = [
//            { name: 'Account', selector: row => row.account, sortable: true },
//            { name: 'Accessed Date', selector: row => row.accessedDate ? moment(row.accessedDate).fromNow() : null, sortable: true },
//        ];



//        return (
//            <>
//                <Header />
//                <SubMenu />
//                <div className="container-xl px-2 pb-4">

//                <div className="row g-3">
//                    <h5>Recently Accessed Accounts</h5>
//                        <DataTable pagination dense pointerOnHover striped progressPending={this.state.loading} columns={columns} data={this.state.accountMRUs} paginationRowsPerPageOptions={[10, 25, 50]}
//                            onRowClicked={accountHandler} /*selectableRows*/ />
//                    </div>

//                    <div className="col-auto">
//                        <label htmlFor="txtSearchAward" className="col-form-label col-form-label-sm">Award</label>
//                        <input type="text" className="form-control form-control-sm" id="txtSearchAward" value={this.state.searchAward} onChange={handleChangeAward} />
//                    </div>
//                    <div className="col-auto">
//                        <label htmlFor="ddlAccountStatus" className="col-form-label col-form-label-sm">Account Status</label>
//                        <select id="ddlAccountStatus" className="form-select form-select-sm" onChange={handleChangeAccountStatus}>
//                            {this.state.optionsAccountStatus.map(element => 
//                                element === this.state.searchAccountStatus ? (
//                                    <option key={element} value={element} selected>
//                                        {element}
//                                    </option>
//                                ) : (<option key={element} value={element}>{element}</option>))
//                            }
//                        </select>
//                    </div>
//                    <div className="col-auto">
//                        <label htmlFor="txtSearchAccount" className="col-form-label col-form-label-sm">Account</label>
//                        <input type="text" className="form-control form-control-sm" id="txtSearchAccount" value={this.state.searchAccount} onChange={handleChangeAccount} />
//                    </div>
//                    <div className="col-auto">
//                        <label htmlFor="ddlAccountType" className="col-form-label col-form-label-sm">Account Type</label>
//                        <select id="ddlAccountType" className="form-select form-select-sm" onChange={handleChangeAccountType}>
//                            {this.state.optionsAccountType.map(element =>
//                                element === this.state.searchAccountType ? (
//                                    <option key={element} value={element} selected>
//                                        {element}
//                                    </option>
//                                ) : (<option key={element} value={element}>{element}</option>))
//                            }
//                        </select>
//                    </div>
//                    <div className="col-auto">
//                        <label htmlFor="ddlLeadUnitFundCode" className="col-form-label col-form-label-sm">Lead Unit/Fund Code</label>
//                        <select id="ddlLeadUnitFundCode" className="form-select form-select-sm" onChange={handleChangeLeadUnitFundCode}>
//                            {this.state.optionsLeadUnitFundCode.map(element =>
//                                element === this.state.searchLeadUnitFundCode ? (
//                                    <option key={element} value={element} selected>
//                                        {element}
//                                    </option>
//                                ) : (<option key={element} value={element}>{element}</option>))
//                            }
//                        </select>
//                    </div>
//                    <div className="col-auto">
//                        <label htmlFor="ddlCostCenterHierarchy" className="col-form-label col-form-label-sm">Cost Center/Hierarchy</label>
//                        <select id="ddlCostCenterHierarchy" className="form-select form-select-sm" onChange={handleChangeCostCenterHierarchy}>
//                            <option key="" value=""></option>
//                            {this.state.optionsCostCenterHierarchy.map(element =>
//                                element === this.state.searchCostCenterHierarchy ? (
//                                    <option key={element} value={element} selected>
//                                        {element}
//                                    </option>
//                                ) : (<option key={element} value={element}>{element}</option>))
//                            }
//                        </select>
//                    </div>
//                    <div className="col-auto">
//                        <label htmlFor="txtPILastName" className="col-form-label col-form-label-sm">PI Last Name</label>
//                        <input type="text" className="form-control form-control-sm" id="txtPILastName" value={this.state.searchPILastName} onChange={handleChangePILastName} />
//                    </div>
//                    <div className="col-auto">
//                        <button type="submit" className="btn btn-primary btn-sm" onClick={handleFindAccounts} disabled={this.state.searching} >
//                            {this.state.searching ?
//                                <FontAwesomeIcon icon={faSpinner} spin className="px-2" />
//                                 :
//                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-2" />
//                            }
//                            Find Accounts
//                        </button>
//                        {/*<button class="btn btn-primary btn-sm" type="button">*/}
//                        {/*    <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>*/}
//                        {/*    Find Accounts*/}
//                        {/*</button>*/}
//                        {/*<div class="spinner-border text-light" role="status">*/}
//                        {/*    <span class="sr-only">Loading...</span>*/}
//                        {/*</div>*/}
//                    </div>
//                </div>
//                {/*<Alert color="danger" className="position-absolute top-0 end-0 me-5 mt-5" isOpen={this.state.showAlert} toggle={toggleAlert} >Data is saved sucessfully</Alert>  */}
//                {/*<Button color="secondary" onClick={toggleAlert} >Show Alert</Button>*/}
//                {/*<p className="fs-6">*/}
//                {/*    Award: {this.state.searchAward}&nbsp;*/}
//                {/*    Account Status: {this.state.searchAccountStatus} &nbsp;*/}
//                {/*    Account: {this.state.searchAccount} &nbsp;*/}
//                {/*    Account Type: {this.state.searchAccountType} &nbsp;*/}
//                {/*    Lead Unit/FundCode: {this.state.searchLeadUnitFundCode} &nbsp;*/}
//                {/*    Cost Center/Hierarchy: {this.state.searchCostCenterHierarchy} &nbsp;*/}
//                {/*    PI Last Name: {this.state.searchPILastName} &nbsp;*/}
//                {/*</p>*/}



//                <DataTable pagination dense pointerOnHover striped progressPending={this.state.loading && this.state.searching} columns={columns} data={this.state.accounts} paginationRowsPerPageOptions={[10, 25, 50]}/*selectableRows*/ />

//                {/*{this.state.loading ? (*/}
//                {/*    <h4 className="text-warning">Loading data...</h4>*/}
//                {/*) : (*/}
//                {/*    //<DataTableFunc columns={[*/}
//                {/*    //                            { title: "date" },*/}
//                {/*    //    { title: "temperatureC" },*/}
//                {/*    //    { title: "temperatureF" },*/}
//                {/*    //    { title: "summary" }*/}
//                {/*    //                        ] } data={this.state.forecasts} />*/}
//                {/*        <>*/}
//                {/*    <table className="table table-hover">*/}
//                {/*        <thead className="table-light">*/}
//                {/*            <tr>*/}
//                {/*                <th>Date</th>*/}
//                {/*                <th>C</th>*/}
//                {/*                <th>F</th>*/}
//                {/*                <th>Summary</th>*/}
//                {/*            </tr>*/}
//                {/*        </thead>*/}
//                {/*        <tbody class="table-group-divider">*/}
//                {/*            {this.state.forecasts.map(forecast => (*/}
//                {/*                <tr>*/}
//                {/*                    <td>{forecast.date}</td>*/}
//                {/*                    <td>{forecast.temperatureC}</td>*/}
//                {/*                    <td>{forecast.temperatureF}</td>*/}
//                {/*                    <td>{forecast.summary}</td>*/}
//                {/*                </tr>*/}
//                {/*            ))}*/}
//                {/*        </tbody>*/}
//                {/*    </table>*/}
//                {/*    </>*/}
//                {/*)}*/}

//            </>
//        );
//    }

//    async findAccounts() {
//        const response = await fetch('account?' +
//            (!this.state.searchAward ? '' : 'award=' + encodeURIComponent(this.state.searchAward)) +
//            (!this.state.searchAccountStatus ? '' : '&accountStatus=' + encodeURIComponent(this.state.searchAccountStatus)) +
//            (!this.state.searchAccount ? '' : '&account=' + encodeURIComponent(this.state.searchAccount)) +
//            (!this.state.searchAccountType ? '' : '&accountType=' + encodeURIComponent(this.state.searchAccountType)) +
//            (!this.state.searchLeadUnitFundCode ? '' : '&leadUnitFundCode=' + encodeURIComponent(this.state.searchLeadUnitFundCode)) +
//            (!this.state.searchCostCenterHierarchy ? '' : '&costCenterHierarchy=' + encodeURIComponent(this.state.searchCostCenterHierarchy)) +
//            (!this.state.searchPILastName ? '' : '&piLastName=' + encodeURIComponent(this.state.searchPILastName))
//        );
//        const data = await response.json();
//        this.setState({ accounts: data, loading: false, searching: false})
//    }

//    async populateAccountData() {
//    //    const response = await fetch('account');
//    //    const data = await response.json();
//    //    //console.log(data);
//    //    this.setState({ accounts: data, loading: false });
//    }
//    async populateLookUps() {
//        let response = await fetch('lookup/leadunits');
//        let data = await response.json();
//        let codes = [...new Set(data.map((code) => code.Deptid))].sort((a, b) => a > b ? 1 : -1);
//        this.setState({ optionsLeadUnitFundCode: codes });

//        response = await fetch('lookup/costcenters');
//        data = await response.json();
//        let costCenters = [...new Set(data.map((code) => code.Id))].sort((a, b) => a > b ? 1 : -1);
//        this.setState({ optionsCostCenterHierarchy: costCenters });

//    }
//    async populateMRUs() {
//        let response = await fetch('../account/mru?user=' + encodeURIComponent('David Alonzo'));
//        let data = await response.json();
//        this.setState({ accountMRUs: data, loading: false });
//        console.log(this.state.accountMRUs);
//    }
//}