
export const getAuthConfig = () => {
    let config = sessionStorage.getItem('authConfig');
    if (config) {
        return JSON.parse(config);
    }
    else {
        let retData = null;
        const request = new XMLHttpRequest();
        request.open("GET", '/api/lookup/authconfig', false); // `false` makes the request synchronous
        request.send(null);

        if (request.status === 200) {
            if (request.responseText) {
                sessionStorage.setItem('authConfig', request.responseText)
                retData = JSON.parse(request.responseText)
            }
        }
        return retData;
    }
};
