import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

const WelcomeName = () => {
    const { instance, accounts } = useMsal();
    const [name, setName] = useState(null);

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount) {
            setName(activeAccount.name.split(' ')[0]);
        }
    }, [activeAccount]);

    if (name) {
        return <>Welcome, {name}{activeAccount ? ' (' + activeAccount.username + ')' : null}</>;
    } else {
        return <>Welcome!</>;
    }
};

export default WelcomeName;