import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function TimeFromDate(props) {
    const { date, format } = props;

    const [showDate, setShowDate] = useState(false);

    useEffect(() => {

    }, []);

    return (
        <>
            <span role="button" onClick={() => setShowDate(!showDate)}>{showDate ? moment(date).format(format ?? "M/D/yyyy h:mm:ss A") : moment(date).fromNow()}</span>
        </>
    );
}
