import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faBook, faSackDollar, faMagnifyingGlass, faSpinner, faFileInvoice, faUserPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { useMsal } from "@azure/msal-react";
import { GetAuthUsername } from "../AuthUsername";

export default function ForecastBudgets(props) {
    const { instance, accounts } = useMsal();

    const [years, setYears] = useState([]);
    const [forecastBudgets, setForecastBudgets] = useState([]);
    const [ledgerAccounts, setLedgerAccounts] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [budgetExpenses, setBudgetExpenses] = useState([]);

    //const [ledgerAccountToAdd, setLedgerAccountToAdd] = useState(null);

    const [saveError, setSaveError] = useState(null);

    const activeAccount = instance.getActiveAccount();
    const authUsername = GetAuthUsername();

    const [modal, setModal] = useState(false);

    const [showLoading, setShowLoading] = useState(false);


    useEffect(() => {
        let currentYear = moment().year();
        let theseYears = [];
        for (var i = currentYear - 1; i <= currentYear + 3; i++) {
            theseYears.push(i);
        }
        setYears(theseYears);


    }, []);

    const modalToggle = () => setModal(!modal);

    const openModalExpensesByMonth = () => {
        getData();
        if (props.budgetExpenses) {
            setBudgetExpenses([...props.budgetExpenses]);
        }
        modalToggle();
    };
    const getData = async () => {
        setShowLoading(true);

        const lResponse = await fetch('/api/lookup/ledgeraccounts');
        const lData = await lResponse.json();
        setLedgerAccounts(lData);

        const fbResponse = await fetch('/api/account/forecastbudgets?account=' + encodeURIComponent(props.account));
        const fbData = await fbResponse.json();
        setForecastBudgets(fbData);

        const eResponse = await fetch('/api/account/forecastbudgetsexpenses?account=' + encodeURIComponent(props.account));
        const eData = await eResponse.json();
        setExpenses(eData);


        setShowLoading(false);

    }
    const getLedgerAccounts = () => {
        if (budgetExpenses && budgetExpenses.length > 0) {
            const data = [].concat(budgetExpenses)
                .sort((a, b) => a.LedgerAccount > b.LedgerAccount ? 1 : -1);

            return data;

        }
        else
            return [];
    };
    const getAvailableLedgerAccounts = () => {
        if (ledgerAccounts && budgetExpenses) {
            return ledgerAccounts.filter(l => !budgetExpenses.map(be => be.LedgerAccount).includes(l.Id + " " + l.Name));
        }
        else
            return [];
    };

    const getLedgerAccountIncrementalTotal = (expense) => {
        let returnValue = 0;
        forecastBudgets.filter(fb => fb.LedgerAccount === expense.LedgerAccount).forEach(b => returnValue += b.Budget);
        return returnValue;
    }
    const getIncrementalTotal = () => {
        let returnValue = 0;
        forecastBudgets.forEach(b => returnValue += b.Budget);
        return returnValue;
    }
    const getExpensesTotal = () => {
        let total = 0;
        if (expenses) {
            expenses.forEach(e => total += e.Budget);
        }
        return total;
    }
    const getExpensesBudget = (ledgerAccount) => {
        let budget = 0;
        if (expenses) {
            let exp = expenses.find(e => e.LedgerAccount === ledgerAccount)
            if (exp) {
                budget = exp.Budget;
            }
        }
        return budget;
    }
    const handleLedgerNumericChange = (context, values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
            return;
        }
        //find the record to see if it exists, if it doesn't then create it
        let fbi = forecastBudgets.findIndex(x => x.LedgerAccount === context.LedgerAccount && x.FiscalYear === context.Year);
        let clone = [...forecastBudgets];
        if (fbi >= 0) {
            let fb = clone[fbi];
            fb.Budget = values.floatValue === undefined ? null : values.floatValue;
            fb.IsUpdated = true;
            fb.UpdatedBy = activeAccount ? activeAccount.name : authUsername;
            clone[fbi] = fb;
            setForecastBudgets([...clone]);
        }
        else {
            let newFB = {
                Account: props.account,
                LedgerAccount: context.LedgerAccount,
                FiscalYear: context.Year,
                Budget: values.floatValue === undefined ? null : values.floatValue,
                Comment: null,
                IsUpdated: true,
                UpdatedBy: activeAccount ? activeAccount.name : authUsername,
            };
            clone.push(newFB);
            setForecastBudgets([...clone]);
        }
    }
    const handleChangeLedgerAccount = (e) => {
        if (e.target.value === undefined || e.target.value === null || e.target.value === "") {
            //setLedgerAccountToAdd("");
            return;
        }
        let clone = [...budgetExpenses];
        if (clone.some(x => x.LedgerAccount === e.target.value)) {
            //setLedgerAccountToAdd("");
            return;
        }
        clone.push({ LedgerAccount: e.target.value });
        setBudgetExpenses([...clone]);
        //setLedgerAccountToAdd("");
    };

    const handleSave = async () => {
        setShowLoading(true);
        setSaveError(null);

        let forecastBudgetsRecords = forecastBudgets.filter(fb => fb.IsUpdated);
        if (forecastBudgetsRecords && forecastBudgetsRecords.length > 0) {
            let newData = await fetch('/api/account/forecastbudgets?account=' + encodeURIComponent(props.account), {
                method: 'PUT',
                body: JSON.stringify(forecastBudgetsRecords),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                if (response.ok) {
                    setSaveError(false);
                    setTimeout(() => setSaveError(null), 5000);
                    let fbData = response.json();
                    return fbData;
                }
                throw new Error(`Error occurred saving forecast budgets: ${response.statusText}`);
            }
            ).catch(ex => {
                console.error(ex);
                //errorMessage = ex.message;
                setSaveError(true);
                setTimeout(() => setSaveError(null), 5000);
                throw ex;
            })
            .finally(() => {
                setShowLoading(false);
            });

            if (newData)
                setForecastBudgets(newData);

        }

        if (props.forecastBudgetsSaved)
            props.forecastBudgetsSaved();

    }



    return (
        <>
            <button type="submit" className="btn btn-secondary btn-sm" style={{ "--bs-btn-padding-y": ".10rem", "--bs-btn-padding-x": ".25rem", "--bs-btn-font-size": ".70rem"}} onClick={openModalExpensesByMonth} disabled={showLoading} >
                {showLoading ?
                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                    :
                    <FontAwesomeIcon icon={faSackDollar} className="pe-2" />
                }
                Budget
            </button>
            <Modal isOpen={modal} toggle={modalToggle} size="xl">
                <ModalHeader toggle={modalToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faSackDollar} className="pe-2" />Maintain Forecasted Budgets</h5>
                        {saveError !== null ?
                            saveError ?
                                <div className="col-auto pe-3">
                                    <span className="badge bg-danger rounded-square me-1">&nbsp;</span>Error saving
                                </div>
                                :
                                <div className="col-auto pe-3">
                                    <span className="badge bg-success rounded-square me-1">&nbsp;</span>Saved
                                </div>
                            :
                            null
                        }
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar",
                        "maxHeight": "calc(100vh - 225px)"
                    }}>

                        <table className="table table-sm table-xs table-xxs table-hover table-bordered table-sticky mb-0" style={{ display: "table" }}>
                            <thead className="" >
                                <tr>
                                    <th rowSpan="2">&nbsp;</th>
                                    <th className="table-light text-center nowrap" colSpan="5">Incremental Change to Budgets by Fiscal Year</th>
                                    <th className="table-light nowrap" rowSpan="2">&nbsp;</th>
                                    <th className="table-light text-center nowrap" colSpan="5">Total Budget after change</th>
                                    <th className="table-light nowrap" rowSpan="2">&nbsp;</th>
                                </tr>
                                <tr>
                                    <th className="table-light nowrap text-end" colSpan="1">Current</th>
                                    <th className="table-light nowrap" colSpan="4">&nbsp;</th>
                                    <th className="table-light nowrap text-end" colSpan="1">Current</th>
                                    <th className="table-light nowrap" colSpan="4">&nbsp;</th>
                                </tr>
                                <tr>
                                    <th className="table-light nowrap">Ledger Account</th>
                                    {years ? years.map(year =>
                                        <th key={year + '-Inc'} className="table-light nowrap text-end">{year}</th>
                                    ) : null}
                                    <th className="table-light text-end nowrap">Total</th>
                                    {years ? years.map(year =>
                                        <th key={year + '-Inc'} className="table-light nowrap text-end">{year}</th>
                                    ) : null}
                                    <th className="table-light text-end nowrap">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getLedgerAccounts().map((expense, i) =>
                                    <tr key={expense.LedgerAccount} >
                                        <td className="nowrap">{expense.LedgerAccount}</td>
                                        {
                                            years.map(y => {
                                                let value = null;
                                                if (forecastBudgets) {
                                                    let thisBudget = forecastBudgets.find(b => b.FiscalYear === y && b.LedgerAccount === expense.LedgerAccount);
                                                    if (thisBudget)
                                                        value = thisBudget.Budget;
                                                }

                                                return <td className="nowrap" key={y + "-Inc-" + expense.LedgerAccount}>
                                                    <NumericFormat
                                                        value={value}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        thousandSeparator=","
                                                        className="form-control form-control-sm form-control-xs form-control-xxs text-end bg-info-subtle fw-bold text-primary"
                                                        onValueChange={(v, si) => handleLedgerNumericChange({ LedgerAccount: expense.LedgerAccount, Year: y}, v, si)}
                                                    />
                                                </td>
                                            }
                                            )
                                        }
                                        <td className="text-end fw-bold">
                                            <NumericFormat value={getLedgerAccountIncrementalTotal(expense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                        </td>
                                        {
                                            years.map((y,i) => {
                                                let value = null;
                                                if (forecastBudgets) {
                                                    let thisBudget = forecastBudgets.find(b => b.FiscalYear === y && b.LedgerAccount === expense.LedgerAccount);
                                                    if (thisBudget)
                                                        value = thisBudget.Budget;
                                                }
                                                if (i === 0) {
                                                    //Find the expense value for this account
                                                    if (expenses) {
                                                        let exp = expenses.find(e => e.LedgerAccount === expense.LedgerAccount)
                                                        if (exp) {
                                                            if (value === null)
                                                                value = 0;

                                                            value += exp.Budget;
                                                        }
                                                    }
                                                }

                                                return <td className="nowrap text-end" key={y + "-Total-" + expense.LedgerAccount}>
                                                    <NumericFormat value={value ?? ''} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," className="" />
                                                </td>
                                            }
                                            )
                                        }
                                        <td className="nowrap text-end fw-bold">
                                            <NumericFormat value={getLedgerAccountIncrementalTotal(expense) + getExpensesBudget(expense.LedgerAccount)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot className="table-group-divider">
                                <tr>
                                    <td className="text-end fw-bold">Total:</td>
                                    {
                                        years.map(y => {
                                            let thisBudget = 0;
                                            if (forecastBudgets && forecastBudgets.some(b => b.FiscalYear === y)) {
                                                forecastBudgets.filter(fb => fb.FiscalYear === y).forEach(b => {
                                                    thisBudget += b.Budget;
                                                });
                                            }

                                            return <td className="nowrap text-end fw-bold" key={y + "-Inc-Total"}>
                                                <NumericFormat
                                                    value={thisBudget}
                                                    displayType="text"
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    thousandSeparator=","
                                                    className=""
                                                />
                                            </td>
                                        }
                                        )
                                    }
                                    <td className="text-end fw-bold">
                                        <NumericFormat value={getIncrementalTotal()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," className="" />
                                    </td>
                                    {
                                        years.map((y,i) => {
                                            let thisBudget = 0;
                                            if (forecastBudgets && forecastBudgets.some(b => b.FiscalYear === y)) {
                                                forecastBudgets.filter(fb => fb.FiscalYear === y).forEach(b => {
                                                    thisBudget += b.Budget;
                                                });
                                            }
                                            if (i === 0) {
                                                //sum up all expenses
                                                thisBudget += getExpensesTotal();
                                            }

                                            return <td className="nowrap text-end fw-bold" key={y + "-After-Total"}>
                                                <NumericFormat
                                                    value={thisBudget}
                                                    displayType="text"
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    thousandSeparator=","
                                                    className=""
                                                />
                                            </td>
                                        }
                                        )
                                    }
                                    <td className="text-end fw-bold">
                                        <NumericFormat value={getIncrementalTotal() + getExpensesTotal()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," className="" />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-between">
                    <div className="col-auto">
                        <div className="input-group">
                            <select className="d-block form-select form-select-sm form-select-xs" onChange={handleChangeLedgerAccount}>
                                <option value="">Select ledger account to add...</option>
                                {getAvailableLedgerAccounts().map((la, i) =>
                                    <option key={la.Id + " " + la.Name} value={la.Id + " " + la.Name}>{la.Id} {la.Name}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col d-flex justify-content-end">
                    <Button color="secondary" onClick={modalToggle} className="mx-2">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSave} disabled={!forecastBudgets.some(fb => fb.IsUpdated)}>
                        Save
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}
