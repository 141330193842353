import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faMagnifyingGlass, faSpinner, faBoxesStacked, faUser, faBan, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

function Encumbrances(props) {

    const [encumbrances, setEncumbrances] = useState([]);
    const [filterOffset, setFilterOffset] = useState(false);

    const [modal, setModal] = useState(false);

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {

    }, []);

    const modalToggle = () => setModal(!modal);
    const openModal = () => {
        getData();
    };
    const getData = async () => {

        setShowLoading(true);

        try {
            const encResponse = await fetch('/api/account/encumbrancedetail?account=' + encodeURIComponent(props.account));
            const encData = await encResponse.json();

            //Elimiate offsetting records
            encData.forEach((enc, i) => {
                if (i > 0) {
                    if (enc.Encumbrances + encData[i - 1].Encumbrances === 0 &&
                        enc.LedgerAccountId === encData[i - 1].LedgerAccountId &&
                        enc.SpendCategoryId === encData[i - 1].SpendCategoryId &&
                        enc.Supplier === encData[i - 1].Supplier &&
                        enc.BudgetDate === encData[i - 1].BudgetDate &&
                        !encData[i - 1].IsOffset) {
                        encData[i].IsOffset = true;
                        encData[i - 1].IsOffset = true;
                    }
                    else {
                        encData[i].IsOffset = false;
                    }
                }
            });
            setEncumbrances(encData);

            modalToggle();
            setShowLoading(false);
        }
        catch (e) {
            console.error(e);
            setShowLoading(false);
        }

    }

    const getEncumbrances = () => {
        if (!filterOffset) {
            return encumbrances.filter(enc => enc.IsOffset === null || !enc.IsOffset);// ? encumbrances.filter(enc => !enc.IsOffset) : encumbrances;
        }
        else
            return encumbrances;

    }

    const getEncumbrancesTotal = () => {
        let total = 0;
        if (encumbrances && encumbrances.length > 0) {
            total = encumbrances.map(e => e.Encumbrances).reduce((a, v) => a + v);
        }
        return total;
    }
    const toggleFilterOffset = () => setFilterOffset(!filterOffset);


    return (
        <>
            <button type="submit" className="btn btn-secondary btn-sm" onClick={openModal} disabled={showLoading} >
                {showLoading ?
                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                    :
                    <FontAwesomeIcon icon={faBoxesStacked} className="pe-2" />
                }
                Encumbrances
            </button>
            <Modal isOpen={modal} toggle={modalToggle} size="xxl">
                <ModalHeader toggle={modalToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faBoxesStacked} className="pe-2" />Encumbrance Detail</h5>
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody style={{
                    
                }}>
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar", "maxHeight": "calc(100vh - 225px)"
                    }}>
                        <div className="row pb-3 mx-0">
                            <div className="row justify-content-end">
                                <div className="col-auto">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={filterOffset} onChange={toggleFilterOffset} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Show Offset Items</label>
                                    </div>
                                </div>
                                <div className="col-auto fw-bold text-end">
                                    Total: 
                                </div>
                                <div className="col-auto">
                                    {props.encumbrancesTotal ? <NumericFormat value={props.encumbrancesTotal} displayType="text" prefix="$" decimalScale={2} fixedDecimalScale thousandSeparator="," /> : null }
                                </div>
                            </div>
                        </div>
                        <table className="table table-sm table-xs table-xxs table-hover" style={{ display: "table" }}>
                            <thead>
                                <tr>
                                    <th className="table-light nowrap">Ledger Account</th>
                                    <th className="table-light nowrap">Spend Category</th>
                                    <th className="table-light nowrap">Status</th>
                                    <th className="table-light nowrap">Encumbrances</th>
                                    <th className="table-light nowrap">Accounting Date</th>
                                    <th className="table-light nowrap">Budget Date</th>
                                    <th className="table-light nowrap">Supplier</th>
                                    <th className="table-light nowrap">Source</th>
                                    <th className="table-light nowrap">Business Document</th>
                                </tr>
                            </thead>
                            <tbody>
                                {encumbrances ? getEncumbrances().map((encumb, i) =>
                                    <Fragment key={i}>
                                        <tr className="table-group-divider">
                                            <td className="nowrap">{encumb.LedgerAccountId} {encumb.LedgerAccountName}</td>
                                            <td className="nowrap">{encumb.SpendCategoryId} {encumb.SpendCategoryName}</td>
                                            <td className="nowrap">{encumb.Status}</td>
                                            <td className="nowrap text-end"><NumericFormat value={encumb.Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end">{moment(encumb.AccountingDate).format('M/D/yyyy')}</td>
                                            <td className="nowrap text-end">{moment(encumb.BudgetDate).format('M/D/yyyy')}</td>
                                            <td className="nowrap">{encumb.Supplier}</td>
                                            <td className="nowrap">{encumb.Source}</td>
                                            <td>{encumb.BusinessDocument}</td>
                                        </tr>
                                    </Fragment>
                                ) : null
                                }
                            </tbody>
                            <tfoot>
                                <tr className="table-group-divider">
                                    <td colSpan="2">&nbsp;</td>
                                    <td className="nowrap fw-bold">Total</td>
                                    <td className="nowrap text-end"><NumericFormat value={getEncumbrancesTotal()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                    <td colSpan="5"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Encumbrances;