import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';

import { NavLink, Link, Route, Routes, useParams } from 'react-router-dom';
import { GetAuthUsername } from "../AuthUsername";

import Header from "./Header";
import ReportSubMenu from "./ReportSubMenu";
//import Find from "./Find";

import { NumericFormat } from 'react-number-format';

import { Alert, Button } from 'reactstrap';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faCopy, faRectangleXmark, faCircleXmark, faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faFilter, faFilterCircleXmark, faListUl, faTable, faChartLine, faSpinner, faFileInvoice, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Report.css';
import AccountDetailReport from './AccountDetailReport';
import { networkDays, isNumeric, isNumericDefault, isNullOrEmpty } from '../../utilities';
import useAuthRoles from "../useAuthRoles";


export default function Expenses(props) {
    const { guid } = useParams();
    const authUsername = GetAuthUsername();
    const { hasRole } = useAuthRoles();
    const { version } = props;
    const [reportAccounts, setReportAccounts] = useState([]);
    const [filterAccount, setFilterAccount] = useState(null);
    const [expenseDetail, setExpenseDetail] = useState([]);
    const [budgetVsActual, setBudgetVsActual] = useState([]);
    const [budgetVsActualRaw, setBudgetVsActualRaw] = useState([]);

    const [state, setState] = useState(null);

    const [loadingExpenseDetail, setLoadingExpenseDetail] = useState(true);
    const [loadingBudgetVsActual, setLoadingBudgetVsActual] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getReportData()

    }, []);

    const getReportData = async () => {
        if (version) {
            if (!hasRole('RA')) {
                const acResponse = await fetch('/api/report/version/' + encodeURIComponent(guid) + '/accesscheck?user=' + encodeURIComponent(authUsername));
                const acData = await acResponse.json();
                if (!acData) {
                    navigate('/noaccess');
                    return;
                }
            }
            getExpenseDetailVersionData();
        }
        else {
            if (!hasRole('RA')) {
                const acResponse = await fetch('/api/report/' + encodeURIComponent(guid) + '/accesscheck?user=' + encodeURIComponent(authUsername));
                const acData = await acResponse.json();
                if (!acData) {
                    navigate('/noaccess');
                    return;
                }
            }
            getExpenseDetailData();
        }
    };


    const applyFilter = async (e, account) => {
        e.preventDefault();
        setFilterAccount(account);

        if (version) {
            let sessionObj = await getReportVersionDataFromSession();
            if (account) {
                if (sessionObj) {
                    let dataB = sessionObj.BudgetVsActual.filter(x => x.Account === account);
                    setBudgetVsActualRaw(dataB);
                    dataB = await aggregateBudgetVsActual(dataB);
                    setBudgetVsActual(dataB);
                }
            }
            else {
                if (sessionObj) {
                    let dataB = sessionObj.BudgetVsActual;
                    setBudgetVsActualRaw(dataB);
                    dataB = await aggregateBudgetVsActual(dataB);
                    setBudgetVsActual(dataB);
                }
            }
        }
        else {
            if (account) {
                const responseB = await fetch('/api/report/budgetvsactual?account=' + encodeURIComponent(account));
                let dataB = await responseB.json();
                setBudgetVsActualRaw(dataB);
                dataB = await aggregateBudgetVsActual(dataB);
                setBudgetVsActual(dataB);
                console.log('filter', account, dataB);
            }
            else {
                setLoadingBudgetVsActual(true);
                const responseB = await fetch('/api/report/' + encodeURIComponent(guid) + '/budgetvsactual');
                let dataB = await responseB.json();
                setBudgetVsActualRaw(dataB);
                dataB = await aggregateBudgetVsActual(dataB);
                setBudgetVsActual(dataB);
                console.log('filter null', account, dataB);
            }
        }
    };
    const getExpenseDetailVersionData = async () => {
        let sessionObj = await getReportVersionDataFromSession();
        if (!sessionObj) {
            //Download the report data and set it in session
            const response = await fetch('/api/report/version/' + encodeURIComponent(guid) + '/data');
            const data = await response.json();
            let dataObj = JSON.parse(data);
            dataObj.Guid = guid;

            sessionObj = dataObj;

            try {
                sessionStorage.setItem('ReportVersionData', JSON.stringify(dataObj, (key, value) => typeof value === 'undefined' ? null : value));
            }
            catch (ex) {
                console.log(ex);
                //sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: true, savedSearch: row, searchCriteria: { AccountStatus: 'Active' }, results: [] }, (key, value) => typeof value === 'undefined' ? null : value));
            }
        }


        if (sessionObj) {
            const data = sessionObj.AccountSummary;
            setReportAccounts(data);
        }

        setLoadingExpenseDetail(true);
        if (sessionObj) {
            const dataE = sessionObj.ExpenseDetail;
            setExpenseDetail(dataE);
        }
        setLoadingExpenseDetail(false);

        setLoadingBudgetVsActual(true);
        if (sessionObj) {
            let dataB = sessionObj.BudgetVsActual;
            setBudgetVsActualRaw(dataB);
            dataB = await aggregateBudgetVsActual(dataB);
            setBudgetVsActual(dataB);
        }
        setLoadingBudgetVsActual(false);
    };
    const getExpenseDetailData = async () => {

        const response = await fetch('/api/report/' + encodeURIComponent(guid) + '/accountsummary');
        const data = await response.json();
        setReportAccounts(data);


        setLoadingExpenseDetail(true);
        const responseE = await fetch('/api/report/' + encodeURIComponent(guid) + '/expensedetail');
        const dataE = await responseE.json();
        setExpenseDetail(dataE);
        setLoadingExpenseDetail(false);

        setLoadingBudgetVsActual(true);
        const responseB = await fetch('/api/report/' + encodeURIComponent(guid) + '/budgetvsactual');
        let dataB = await responseB.json();
        setBudgetVsActualRaw(dataB);
        dataB = await aggregateBudgetVsActual(dataB);
        setBudgetVsActual(dataB);
        setLoadingBudgetVsActual(false);
    };
    const getReportVersionDataFromSession = () => {
        if (sessionStorage.getItem('ReportVersionData')) {
            const sessionObj = JSON.parse(sessionStorage.getItem('ReportVersionData'));
            //check if the session object's GUID matches the current GUID

            if (sessionObj.Guid === guid) {
                //We're good to keep using this object
                return sessionObj;
            }
            else {
                sessionStorage.removeItem('ReportVersionData');
                return null;
            }
        }
        return null;
    };
    const aggregateBudgetVsActual = async (data) => {

        let budgetTotal = 0;
        let budgetVsActualData = [];
        let expenses = 0;
        let clone = [...data];
        data.forEach((d, i) => {
            budgetTotal += d.Budget;
            expenses += d.Expenses;
            let thisDataIX = budgetVsActualData.findIndex(x => x.StartDate === d.StartDate);
            if (thisDataIX > -1) {
                budgetVsActualData[thisDataIX].TotalBudget += d.Budget;
                budgetVsActualData[thisDataIX].SpendPlan += d.PlannedSpending;
                budgetVsActualData[thisDataIX].CumulativeSpend += d.Expenses;
            }
            else {
                budgetVsActualData.push({
                    StartDate: d.StartDate,
                    TotalBudget: budgetTotal,
                    SpendPlan: d.PlannedSpending,
                    CumulativeSpend: expenses,
                });
            }
        //    clone[i].TotalBudget = budgetTotal;
        //    clone[i].SpendPlan = budgetTotal;
        })

        return budgetVsActualData;
    //    if (data) {
    //        data.forEach(ef => {
    //            if (!distinctEmployees.find(x => x.EmplId === ef.EmplId && x.Account === ef.Account)) {
    //                distinctEmployees.push({
    //                    ...ef
    //                })
    //            }
    //        })
    //    }
    //    return distinctEmployees;
    };

    return (
        <>
            <Header />
            <ReportSubMenu guid={guid} {...props} />
            <div className="container pb-3 fst-italic text-secondary">
                <p>
                    To view the expense chart for a particular grant, click the Filter Account button, and select the grant number from the list.
                </p>
                <p>
                    To view the expenses for a particular grant, scroll down to the Expense Pivot section, click the Account dropdown, and select the grant number from the list.
                </p>
            </div>
            <div>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <span><FontAwesomeIcon icon={faChartLine} className="pe-1" />Expenses Chart</span>
                        <div className="col-auto">
                            <div className="row">
                            <div className="col-auto px-1">
                            {loadingExpenseDetail ?
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin className="me-1" />
                                    Loading...
                                </>
                                :
                                null}
                            </div>
                                <div className="col-auto px-1">

                                    <div className="btn-group">
                                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faFilter} className="me-1" />
                                            Filter Account
                                        </button>
                                        <ul className="dropdown-menu">
                                            {reportAccounts && reportAccounts.length > 0 ?
                                                reportAccounts.map((a, i) =>
                                                    <li key={a.Account} >
                                                        {filterAccount && filterAccount === a.Account ?
                                                            <button className="dropdown-item active" onClick={(e) => { applyFilter(e, null) }}>
                                                                {a.Account}
                                                                <FontAwesomeIcon icon={faFilterCircleXmark} spin className="ms-1 mt-1 float-end" />
                                                            </button>
                                                            :
                                                            <button className="dropdown-item" onClick={(e) => { applyFilter(e, a.Account) }}>
                                                                {a.Account}
                                                            </button>
                                                        }
                                                    </li>
                                                )
                                                : null}
                                        </ul>
                                    </div>
                                {/*<button type="button" className="btn btn-sm btn-secondary" disabled={false} onClick={() => window.location.reload(false)} >*/}
                                {/*    <FontAwesomeIcon icon={faSpinner} className="me-2" />*/}
                                {/*    Reset*/}
                                {/*</button>*/}
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive" style={{height:"400px"}}>
                        {loadingExpenseDetail ?
                            <div className="text-center py-5 display-1">
                                <FontAwesomeIcon icon={faSpinner} spin className="" />
                            </div>
                            :
                            <Line
                                type="line"
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            stacked: false,
                                            grid: {
                                                display: true,
                                            }
                                        },
                                        x: {
                                            grid: {
                                                display: false
                                            }
                                        }
                                    },
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: "Budget vs Actuals By Month",
                                            //    fontSize: 20
                                        },
                                        legend: {
                                            display: true, //Is the legend shown?
                                            position: 'bottom' //Position of the legend.
                                        },
                                        colors: {
                                            forceOverride: true
                                        },
                                        tooltip: {
                                            position: 'nearest'
                                        }
                                    },
                                    interaction: {
                                        intersect: false,
                                        mode: 'index',
                                    },
                                }}
                                data={{
                                    labels: (filterAccount ? budgetVsActual : budgetVsActual).map(ba => moment(ba.StartDate).format("MMM yyyy")),
                                    datasets: [
                                        {
                                            data: budgetVsActual.map(ba => ba.TotalBudget),
                                            label: "Budget Total",
                                            lineTension: 0.25,
                                        },
                                        {
                                            data: budgetVsActual.map(ba => ba.CumulativeSpend),
                                            label: "Cumulative Spend",
                                            lineTension: 0.25,
                                        },
                                        {
                                            data: budgetVsActual.map(ba => ba.SpendPlan),
                                            label: "Spend Plan",
                                            lineTension: 0.25,
                                        },
                                    ]
                                }}
                            />
                        }
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <span><FontAwesomeIcon icon={faTable} className="pe-1" />Expenses Pivot</span>
                        <div className="col-auto">
                            {loadingBudgetVsActual ?
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin className="me-1" />
                                    Loading... 
                                </>
                                :
                                null}
                        </div>
                    </div>
                    <div className="table-responsive">
                        {loadingBudgetVsActual ?
                            <div className="text-center py-5 display-1">
                                <FontAwesomeIcon icon={faSpinner} spin className="" />
                            </div>
                            :
                            <PivotTableUI
                                data={expenseDetail}
                                onChange={s => setState(s)}
                                rows={["ObjectClass", "LedgerAccount"]}
                                cols={["Year", "Month"]}
                                aggregatorName="Sum"
                                vals={["Expenses"]}
                                {...state}
                                className="table-xs"
                            />
                        }
                    </div>
                </div>
                {/*<div className="card mb-4">*/}
                {/*    <div className="card-header d-flex justify-content-between align-items-center">*/}
                {/*        <span><FontAwesomeIcon icon={faChartLine} className="pe-1" />Expenses Pivot</span>*/}
                {/*        <div className="col-auto">*/}
                {/*            {loading ?*/}
                {/*                <>*/}
                {/*                    <FontAwesomeIcon icon={faSpinner} spin className="me-1" />*/}
                {/*                    Loading... */}
                {/*                </>*/}
                {/*                :*/}
                {/*                null}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="table-responsive">*/}

                        {/*<table className="table table-sm table-xs table-hover">*/}
                        {/*    <thead>*/}
                        {/*        <tr>*/}
                        {/*            <th className="table-light nowrap text-center">Account</th>*/}
                        {/*            <th className="table-light nowrap text-center">Start Date</th>*/}
                        {/*            <th className="table-light nowrap text-center">Year</th>*/}
                        {/*            <th className="table-light nowrap text-center">Month</th>*/}
                        {/*            <th className="table-light nowrap text-center">Object Class</th>*/}
                        {/*            <th className="table-light nowrap text-center">Ledger Account Id</th>*/}
                        {/*            <th className="table-light nowrap text-center">Ledger Account</th>*/}
                        {/*            <th className="table-light nowrap text-center">Expenses</th>*/}
                        {/*            <th className="table-light nowrap text-center">Budget</th>*/}
                        {/*        </tr>*/}
                        {/*    </thead>*/}
                        {/*    <tbody>*/}
                        {/*        {expenseDetail && expenseDetail.length > 0 ?*/}
                        {/*            expenseDetail.map((ed) =>*/}
                        {/*                <tr>*/}
                        {/*                    <td>{ed.Account}</td>*/}
                        {/*                    <td>{ed.StartDate}</td>*/}
                        {/*                    <td>{ed.Year}</td>*/}
                        {/*                    <td>{ed.Month}</td>*/}
                        {/*                    <td>{ed.ObjectClass}</td>*/}
                        {/*                    <td>{ed.LedgerAccountId}</td>*/}
                        {/*                    <td>{ed.LedgerAccount}</td>*/}
                        {/*                    <td>{ed.Expenses}</td>*/}
                        {/*                    <td>{ed.Budget}</td>*/}
                        {/*                </tr>*/}
                        {/*            )*/}
                        {/*            :*/}
                        {/*            null*/}
                        {/*        }*/}
                        {/*    </tbody>*/}
                        {/*</table>*/}


                    {/*    {loading ?*/}
                    {/*        <div className="text-center py-5 display-1">*/}
                    {/*            <FontAwesomeIcon icon={faSpinner} spin className="" />*/}
                    {/*        </div>*/}
                    {/*        :*/}
                    {/*        <>*/}
                    {/*            <table className="table table-sm table-xs table-hover">*/}
                    {/*                <thead>*/}
                    {/*                    <tr>*/}
                    {/*                        <th className="table-light nowrap text-center">Index</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Account</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Start Date</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Year</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Month</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Expenses</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Running Expenses</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Budget</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Total Budget</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Running Budget</th>*/}
                    {/*                        <th className="table-light nowrap text-center">Planned Spending</th>*/}
                    {/*                    </tr>*/}
                    {/*                </thead>*/}
                    {/*                <tbody>*/}
                    {/*                    {budgetVsActualRaw && budgetVsActualRaw.length > 0 ?*/}
                    {/*                        budgetVsActualRaw.map((ba, i) =>*/}
                    {/*                            <tr>*/}
                    {/*                                <td>{i + 1}</td>*/}
                    {/*                                <td>{ba.Account}</td>*/}
                    {/*                                <td>{ba.StartDate}</td>*/}
                    {/*                                <td>{ba.Year}</td>*/}
                    {/*                                <td>{ba.Month}</td>*/}
                    {/*                                <td>{ba.Expenses}</td>*/}
                    {/*                                <td>{ba.RunningExpenses}</td>*/}
                    {/*                                <td>{ba.Budget}</td>*/}
                    {/*                                <td>{ba.TotalBudget}</td>*/}
                    {/*                                <td>{ba.RunningBudget}</td>*/}
                    {/*                                <td>{ba.PlannedSpending}</td>*/}
                    {/*                            </tr>*/}
                    {/*                        )*/}
                    {/*                        :*/}
                    {/*                        null*/}
                    {/*                    }*/}
                    {/*                </tbody>*/}
                    {/*            </table>*/}
                    {/*        </>*/}
                    {/*    }*/}
        {/*            </div>*/}
        {/*        </div>*/}
            </div>
        </>
    );

}
