import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useMsal } from "@azure/msal-react";
import { NavLink, Link, Route, Routes, useParams } from 'react-router-dom';
import { GetAuthUsername } from "../AuthUsername";

import Header from "./Header";
import ReportSubMenu from "./ReportSubMenu";
//import Find from "./Find";

import { NumericFormat } from 'react-number-format';

import { Alert, Button } from 'reactstrap';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faCopy, faRectangleXmark, faCircleXmark, faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faLink, faRotateRight, faArrowRightToBracket, faListUl, faXmark, faMagnifyingGlass, faSpinner, faFileInvoice, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Report.css';
import AccountDetailReport from './AccountDetailReport';
import UserLookup from '../shared/UserLookup';
import useAuthRoles from "../useAuthRoles";


export default function ReportInformation(props) {
    const { guid, version } = props;

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const authUsername = GetAuthUsername();
    const authUserFullName = activeAccount ? activeAccount.name : authUsername;
    const { hasRole } = useAuthRoles();


    const [report, setReport] = useState(null);
    const [reportVersion, setReportVersion] = useState(null);
    const [reportVersions, setReportVersions] = useState(null);
    const [reportVersionAccess, setReportVersionAccess] = useState([]);

    const [creatingSnapshot, setCreatingSnapshot] = useState(false);

    const navigate = useNavigate();

    const dateNow = moment();

    useEffect(() => {
        getReportData();



        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    }, [guid]);

    const getReportData = async () => {

        if (version) {
            if (!hasRole('RA')) {
                const acResponse = await fetch('/api/report/version/' + encodeURIComponent(guid) + '/accesscheck?user=' + encodeURIComponent(authUsername));
                const acData = await acResponse.json();
                if (!acData) {
                    navigate('/noaccess');
                    return;
                }
            }
            else {
                const rvaResponse = await fetch('/api/report/version/' + encodeURIComponent(guid) + '/access');
                const rvaData = await rvaResponse.json();
                setReportVersionAccess(rvaData);
            }
            const rvResponse = await fetch('/api/report/version/' + encodeURIComponent(guid));
            const rvData = await rvResponse.json();
            setReportVersion(rvData);
            const rResponse = await fetch('/api/report/' + encodeURIComponent(rvData.report.guid));
            const rData = await rResponse.json();
            setReport(rData);
            if (!hasRole('RA')) {
                const rvsResponse = await fetch('/api/report/' + encodeURIComponent(rvData.report.guid) + '/versions?user=' + encodeURIComponent(authUsername));
                const rvsData = await rvsResponse.json();
                setReportVersions(rvsData);
            }
            else {
                setReportVersions(rData.reportVersions);
            }
        }
        else {
            if (!hasRole('RA')) {
                const acResponse = await fetch('/api/report/' + encodeURIComponent(guid) + '/accesscheck?user=' + encodeURIComponent(authUsername));
                const acData = await acResponse.json();
                if (!acData) {
                    navigate('/noaccess');
                    return;
                }
            }
            const rResponse = await fetch('/api/report/' + encodeURIComponent(guid));
            const rData = await rResponse.json();
            setReport(rData);
            if (!hasRole('RA')) {
                const rvsResponse = await fetch('/api/report/' + encodeURIComponent(guid) + '/versions?user=' + encodeURIComponent(authUsername));
                const rvsData = await rvsResponse.json();
                setReportVersions(rvsData);
            }
            else {
                setReportVersions(rData.reportVersions);
            }
        }

    }

    const getReportVersionDates = () => {
        let dates = [];
        if (reportVersions) {
            reportVersions.sort((a, b) => moment(a.dateCreated) < moment(b.dateCreated) ? 1 : -1).forEach(rv => {
                if (!dates.includes(new Date(rv.dateCreated).toDateString())) {
                    dates.push(new Date(rv.dateCreated).toDateString());
                }
            });
        }
        return dates.sort((a, b) => moment(a) < moment(b) ? 1 : -1);
    }
    const getReportVersions = () => {
        let thisReportVersions = [];
        if (reportVersions && reportVersions.length > 0) {
            getReportVersionDates().forEach(d => {
                reportVersions.filter(rv => new Date(rv.dateCreated).toDateString() === d).sort((a, b) => moment(a.dateCreated) < moment(b.dateCreated) ? 1 : -1).forEach((rv, i) => {
                    thisReportVersions.push({
                        guid: rv.guid,
                        label: moment(rv.dateCreated).format('M/D/YYYY') + ' - #' + (reportVersions.filter(rv => new Date(rv.dateCreated).toDateString() === d).length - i).toString().padStart(2, '0'),
                        dateCreated: rv.dateCreated,
                        status: rv.status,
                    })
                }
                )
            });
        }
        return thisReportVersions;
    };
    const getReportVersionLabel = (guid) => {
        let label = null;
        if (reportVersions && reportVersions.length > 0) {
            let versions = getReportVersions();
            let version = versions.find(v => v.guid === guid);
            if (version)
                label = version.label;
        }
        return label;
    };

    const createReportVersion = async () => {
        let reportVersion = null;
        setCreatingSnapshot(true);

        let saveObj = {
            ReportId: report.id,
            CreatedBy: authUsername,
            CreatedByName: authUserFullName,
            Status: 'Pending',
        };

        //Post to API
        await fetch('/api/report/version', {
            method: 'POST',
            body: JSON.stringify(saveObj, (key, value) => typeof value === 'undefined' ? null : value),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.json()).then(data => {
            let clone = report;
            clone.reportVersions.push(data);
            setReport(clone);

            //Now we need to kick off the data generation

        //    let item = clone.findIndex(i => i.id === savedSearchForReport.id);
        //    if (item >= -1) {
        //        clone[item].reportId = data.id;
        //        clone[item].repotGuid = data.guid;
        //    }
            //    setPIPortfolio([...clone]);
            getReportData();
            setCreatingSnapshot(false);
        }).catch(ex => {
            console.log('Caught Error')
        });


    }
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    };
    const copyLink = (e, rv) => {
        copyTextToClipboard(window.location.origin + '/reports/version/' + rv.guid)
            .then(() => {
                e.target.Title = 'Copied'
            })
            .catch((err) => {
                console.log(err)
            });
    };
    const addReportVersionAccess = async (user) => {
        let obj = {
            accessTo: user.accessTo,
            accessToName: user.accessToName,
            createdBy: authUsername,
            createdByName: authUserFullName,
            granted: true,
        }
        //Post to API
        await fetch('/api/report/version/' + reportVersion.guid + '/access', {
            method: 'POST',
            body: JSON.stringify(obj, (key, value) => typeof value === 'undefined' ? null : value),
            headers: {
                'Content-Type': 'application/json'
            },
        }).catch(ex => {
            console.log('Caught Error')
        });
    };
    const removeReportVersionAccess = async (user) => {
        let obj = {
            accessTo: user.accessTo,
            accessToName: user.accessToName,
            createdBy: authUsername,
            createdByName: authUserFullName,
            granted: false,
        }
        //Post to API
        await fetch('/api/report/version/' + reportVersion.guid + '/access', {
            method: 'POST',
            body: JSON.stringify(obj, (key, value) => typeof value === 'undefined' ? null : value),
            headers: {
                'Content-Type': 'application/json'
            },
        }).catch(ex => {
            console.log('Caught Error')
        });
    };

    return (
        <>
            <div className="container">
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="card h-100">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <span>Report Information</span>
                                {/*<span class="badge text-bg-success"><FontAwesomeIcon icon={faTowerBroadcast} /> LIVE</span>*/}
                                {/*<span class="badge text-bg-primary">2024.03.07.01</span>*/}
                                <div className="col-auto">
                                    <div className="row">
                                        {version && reportVersion ? null :
                                            <div className="col-auto px-1">
                                                <button className="btn btn-secondary btn-sm" disabled={creatingSnapshot} type="button" onClick={createReportVersion}>
                                                    <FontAwesomeIcon icon={creatingSnapshot ? faSpinner : faCopy} spin={creatingSnapshot} className="me-1" /> Create Snapshot
                                                </button>
                                            </div>
                                        }
                                        <div className="col-auto px-1">
                                            <div className="btn-group">
                                                <button className={`btn btn-secondary btn-sm dropdown-toggle ${version ? null : 'btn-success'}`} type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" >
                                                    <FontAwesomeIcon icon={faListUl} className="me-2" />{/* Report Versions*/} <span>&nbsp;</span>
                                                    {version && reportVersion && report ?
                                                        <>
                                                            {getReportVersionLabel(reportVersion.guid)}
                                                        </>
                                                        :
                                                        <>
                                                            <FontAwesomeIcon className="me-2" icon={faTowerBroadcast} /> LIVE
                                                        </>

                                                    }
                                                    <span className="ms-2">&nbsp;</span>
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><Link className={`dropdown-item ${!version ? 'text-bg-success' : null}`} to={`/reports/${version && report ? report.guid : guid}`}><FontAwesomeIcon className="me-2" icon={faTowerBroadcast} /> LIVE</Link></li>
                                                    {/*<li><a className="dropdown-item" href="#"><FontAwesomeIcon className="me-2" icon={faTowerBroadcast} /> LIVE</a></li>*/}
                                                    {reportVersions && reportVersions.length > 0 ?
                                                        <li><hr className="dropdown-divider" /></li>
                                                        : null}
                                                    {getReportVersions().map(rv => (
                                                        <li key={rv.guid}>
                                                            {rv.status === "Active" ?
                                                                <Link className={`dropdown-item ${guid === rv.guid ? 'active' : null}`} to={`/reports/version/${rv.guid}`}>
                                                                    <FontAwesomeIcon icon={faArrowRightToBracket} className="me-2" />
                                                                    {rv.label}
                                                                </Link>
                                                                :
                                                                <Link className="dropdown-item" onClick={(e) => e.preventDefault()}>
                                                                    <FontAwesomeIcon icon={faSpinner} className="me-2" spin />
                                                                    {rv.label}
                                                                    <FontAwesomeIcon className="ms-2" icon={faRotateRight} onClick={(e) => { e.preventDefault(); getReportData(); }} />
                                                                </Link>
                                                            }
                                                        </li>
                                                    ))}
                                                    {/*    <li><hr className="dropdown-divider" /></li>*/}
                                                    {/*    {getReportVersionDates().map(d =>*/}
                                                    {/*    (*/}
                                                    {/*        report.reportVersions.filter(rv => new Date(rv.dateCreated).toDateString() === d).sort((a, b) => moment(a.dateCreated) < moment(b.dateCreated) ? 1 : -1).map((rv, i) =>*/}
                                                    {/*            <li key={rv.guid}>*/}
                                                    {/*                {rv.status === "Active" ?*/}
                                                    {/*                    <Link className={`dropdown-item ${guid === rv.guid ? 'active' : null}`} to={`/reports/version/${rv.guid}`}>*/}
                                                    {/*                        <FontAwesomeIcon icon={faArrowRightToBracket} className="me-2" />*/}
                                                    {/*                        {moment(rv.dateCreated).format('M/D/YYYY') + ' - #' + (report.reportVersions.filter(rv => new Date(rv.dateCreated).toDateString() === d).length - i).toString().padStart(2, '0')}*/}
                                                    {/*                    </Link>*/}
                                                    {/*                    :*/}
                                                    {/*                    <Link className="dropdown-item disabled" to={`/reports/version/${rv.guid}`}>*/}
                                                    {/*                        <FontAwesomeIcon icon={faSpinner} className="me-2" spin />*/}
                                                    {/*                        {moment(rv.dateCreated).format('M/D/YYYY') + ' - #' + (report.reportVersions.filter(rv => new Date(rv.dateCreated).toDateString() === d).length - i).toString().padStart(2, '0')}*/}
                                                    {/*                    </Link>*/}
                                                    {/*                }*/}
                                                    {/*            </li>*/}
                                                    {/*        )*/}
                                                    {/*    )*/}
                                                    {/*    )}*/}
                                                </ul>
                                            </div>
                                        </div>
                                        {/*<div className="dropdown">*/}
                                        {/*    <button className="btn btn-success btn-sm dropdown-toggle badge" type="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                                        {/*        <FontAwesomeIcon icon={faTowerBroadcast} /> LIVE*/}
                                        {/*    </button>*/}
                                        {/*    {report && report.reportVersions ?*/}
                                        {/*        <ul className="version dropdown-menu">*/}
                                        {/*            {getReportVersionDates().map(d =>*/}
                                        {/*            (*/}
                                        {/*                report.reportVersions.filter(rv => new Date(rv.dateCreated).toDateString() === d).sort((a, b) => moment(a.dateCreated) < moment(b.dateCreated) ? 1 : -1).map((rv, i) =>*/}
                                        {/*                    <li key={rv.guid}>*/}
                                        {/*                        <Link className="dropdown-item" to={`/reports/version/${rv.guid}`}>{moment(rv.dateCreated).format('M/D/YYYY') + ' - #' + (report.reportVersions.filter(rv => new Date(rv.dateCreated).toDateString() === d).length - i).toString().padStart(2, '0')}</Link>*/}
                                        {/*                    </li>*/}
                                        {/*                )*/}
                                        {/*            )*/}
                                        {/*            )}*/}

                                        {/*        </ul>*/}
                                        {/*        : null}*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                {/*<div class="dropdown">*/}
                                {/*    <button class="btn btn-primary btn-sm dropdown-toggle badge" type="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
                                {/*        2024.03.07.01*/}
                                {/*    </button>*/}
                                {/*    <ul class="dropdown-menu">*/}
                                {/*        <li><a class="dropdown-item" href="#">Action</a></li>*/}
                                {/*        <li><a class="dropdown-item" href="#">Another action</a></li>*/}
                                {/*        <li><a class="dropdown-item" href="#">Something else here</a></li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <label className="col-sm-1 col-form-label col-form-label-sm">Title:</label>
                                    <div className="col-sm-5">
                                        <label className="form-control-plaintext form-control-sm fw-bold">{report ? report.title : null}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Created By:</label>
                                    <div className="col-sm-4">
                                        {report ?
                                            <label className="form-control-plaintext form-control-sm fw-bold">{report ? report.createdByName : null} ({report ? report.createdBy : null})</label>
                                            : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="offset-sm-6 col-sm-2 col-form-label col-form-label-sm">Created Date:</label>
                                    <div className="col-sm-4">
                                        {report ?
                                            <label className="form-control-plaintext form-control-sm fw-bold">{report.dateCreated ? moment(report.dateCreated).format("M/D/yyyy h:mm:ss A") : null}</label>
                                            : null}
                                    </div>
                                </div>
                                {version && reportVersion ?
                                    <>
                                        <hr />
                                        <div className="row">
                                            <label className="col-sm-1 col-form-label col-form-label-sm">Version:</label>
                                            <div className="col-sm-5">
                                                <label className="form-control-plaintext form-control-sm fw-bold">{guid}</label>
                                            </div>
                                            <label className="col-sm-2 col-form-label col-form-label-sm">Created By:</label>
                                            <div className="col-sm-4">
                                                <label className="form-control-plaintext form-control-sm fw-bold">{reportVersion.createdByName} ({reportVersion.createdBy})</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col-sm-1 col-form-label col-form-label-sm">Version Link:</label>
                                            <div className="col-sm-5">
                                                <label className="form-control-plaintext form-control-sm fw-bold">{window.location.origin + '/reports/version/' + reportVersion.guid}</label>
                                                <button type="button" className="btn btn-default px-1 py-0" title={`Copy Link:\n${window.location.origin + '/reports/version/' + reportVersion.guid}`} onClick={(e) => copyLink(e, reportVersion)}>
                                                    <FontAwesomeIcon icon={faLink} className="" />
                                                </button>

                                            </div>
                                            <label className="col-sm-2 col-form-label col-form-label-sm">Version Date:</label>
                                            <div className="col-sm-4">
                                                <label className="form-control-plaintext form-control-sm fw-bold">{moment(reportVersion.dateCreated).format("M/D/yyyy h:mm:ss A")}</label>
                                            </div>
                                        </div>
                                        {hasRole('RA') ?
                                            <>
                                                <hr />
                                                <div className="row">
                                                    <label className="col-sm-1 col-form-label col-form-label-sm">Shared With:</label>
                                                    <div className="d-flex flex-wrap col-sm-11 align-items-center pt-0">
                                                        <UserLookup initialItems={reportVersionAccess} onAdd={addReportVersionAccess} onRemove={removeReportVersionAccess} />
                                                        {/*<span className="badge text-bg-primary me-1">Neal Woodbury <FontAwesomeIcon icon={faCircleXmark} /></span>*/}
                                                        {/*<div className="col-auto"><small className="px-2 py-0 me-2  fw-semibold text-primary-emphasis border border-primary rounded-2">Neal Woodbury <FontAwesomeIcon icon={faCircleXmark} /></small></div>*/}
                                                        {/*<div className="col-auto"><small className="px-2 py-0 me-2  fw-semibold text-primary-emphasis border border-primary rounded-2">Neal Woodbury <FontAwesomeIcon icon={faRectangleXmark} /></small></div>*/}
                                                        {/*<div className="col-auto"><small className="px-2 py-0 me-2  fw-semibold text-primary-emphasis border border-primary rounded-2">Neal Woodbury <FontAwesomeIcon icon={faXmark} /></small></div>*/}
                                                        {/*<small className="col-auto px-2 py-0 me-2  fw-semibold text-primary-emphasis border border-primary rounded-2">Neal Woodbury asdf <FontAwesomeIcon icon={faCircleXmark} /></small>*/}
                                                        {/*<h6 className="mb-0"><span className="badge text-bg-primary me-1">Neal Woodbury <FontAwesomeIcon icon={faCircleXmark} /></span></h6>*/}
                                                        {/*<h6 className="mb-0"><span className="badge text-bg-primary me-1">Neal Woodbury <FontAwesomeIcon icon={faRectangleXmark} /></span></h6>*/}
                                                        {/*<h6 className="mt-0 mb-0"><span className="badge text-bg-primary me-1">Neal Woodbury <FontAwesomeIcon className="ms-1" icon={faXmark} /></span></h6>*/}
                                                        {/*<h6 className="mt-0 mb-0"><span className="badge text-bg-primary me-1">John Doe <FontAwesomeIcon className="ms-1" icon={faXmark} /></span></h6>*/}
                                                        {/*<h6 className="mt-0 mb-0"><span className="badge text-bg-primary me-1">Neal Woodbury <FontAwesomeIcon className="ms-1" icon={faXmark} /></span></h6>*/}
                                                    </div>
                                                </div>
                                            </>
                                        : null}
                                    </>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
