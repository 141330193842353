import React, { useState, Component } from 'react';
import { Button, Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, Badge } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

import SignInSignOutButton from "./SignInSignOutButton";
import WelcomeName from "./WelcomeName";
import { GetAuthUsername } from "./AuthUsername";
import useAuthRoles from "./useAuthRoles";
import './NavMenu.css';
import HomeLoadTimeWarning from './HomeLoadTimeWarning';

export const NavMenu = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [collapsed, setCollapsed] = useState(true);
    const { hasRole } = useAuthRoles();

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }
    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }
    const handleLogout = () => {
        instance.logoutRedirect();
    }



    return (
        <>
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white shadow border-bottom box-shadow" container light>

                    <NavbarBrand tag={Link} to="/">
                        {/*<img src="/images/ASULogo.png" alt="Arizona State University" height="40" />*/}
                        <img src="/images/ASUKELogo.png" alt="Arizona State University" height="50" className="me-2" />
                        Forecasting Tool
                    </NavbarBrand>
                    <NavbarToggler onClick={() => toggleNavbar()} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="nav-link text-dark" to="/">Home</NavLink>
                            </NavItem>
                            {isAuthenticated
                                ?
                                <>
                                    {hasRole('RA') ?
                                        <NavItem>
                                            <NavLink tag={Link} className="nav-link text-dark" to="/accounts">Accounts</NavLink>
                                        </NavItem>
                                        : null}
                                    <NavItem>
                                        <NavLink tag={Link} className="nav-link text-dark" to="/reports">Reports</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="nav-link text-dark" to="/logout" onClick={handleLogout}>
                                            <WelcomeName /> (Logout)
                                        </NavLink>
                                    </NavItem>
                                </>
                                :
                                // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
                                (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) ?
                                    <NavItem>
                                        <NavLink tag={Link} className="nav-link text-dark" to="/login" onClick={handleLogin}>Login</NavLink>
                                    </NavItem>
                                    : null
                            }
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
            <HomeLoadTimeWarning />
        </>
    );

}
//export class NavMenu extends Component {
//    static displayName = NavMenu.name;
//    static isAuthenticated = useIsAuthenticated();

//    constructor(props) {
//        super(props);

//        this.toggleNavbar = this.toggleNavbar.bind(this);
//        this.state = {
//            collapsed: true
//        };
//    }

//    toggleNavbar() {
//        this.setState({
//            collapsed: !this.state.collapsed
//        });
//    }

//    render() {
//        return (
//            <header>
//                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white shadow border-bottom box-shadow" container light>

//                    <NavbarBrand tag={Link} to="/">
//                        {/*<img src="/images/ASULogo.png" alt="Arizona State University" height="40" />*/}
//                        <img src="/images/OKED-Logo.png" alt="Arizona State University" height="50" />
//                        Forecasting Tool
//                    </NavbarBrand>
//                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//                        <ul className="navbar-nav flex-grow">
//                            <NavItem>
//                                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
//                            </NavItem>
//                            <NavItem>
//                                <NavLink tag={Link} className="text-dark" to="/forecasts">Forecasts</NavLink>
//                            </NavItem>
//                            <NavItem>
//                                <NavLink tag={Link} className="text-dark" to="/accounts">Accounts</NavLink>
//                            </NavItem>
//                        {/*    <NavItem>*/}
//                        {/*        <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
//                        {/*    </NavItem>*/}
//                        {/*    <NavItem>*/}
//                        {/*        <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>*/}
//                        {/*    </NavItem>*/}
//                        {/*    <NavItem>*/}
//                        {/*        <NavLink tag={Link} className="text-dark" to="/rank-movies">Rank movies</NavLink>*/}
//                        {/*    </NavItem>*/}
//                        {/*    <NavItem>*/}
//                        {/*        <NavLink tag={Link} className="text-dark" to="/rank-albums">Rank albums</NavLink>*/}
//                        {/*    </NavItem>*/}
//                        </ul>
//                        {this.isAuthenticated ? 'authenticated' : 'not authenticated' }
//                        <SignInSignOutButton />
//                    </Collapse>
//                </Navbar>
//            </header>
//        );
//    }
//}