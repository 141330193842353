import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { GetAuthUsername } from "../AuthUsername";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'bootstrap';
import TimeFromDate from '../shared/TimeFromDate';

function AccountNotes(props) {

    const { account, version, versionData } = props;

    const { instance } = useMsal();

    const [accountNotes, setAccountNotes] = useState([]);
    const [noteType, setNoteType] = useState('PI');

    useEffect(() => {
        getNotes();

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };


    }, []);

    const getNotes = async () => {
        if (version && versionData) {
            const dataNote = versionData.Notes;
            setAccountNotes(dataNote);
        }
        else {
            const responseNote = await fetch('/api/account/note?account=' + encodeURIComponent(account));
            const dataNote = await responseNote.json();
            setAccountNotes(dataNote);
        }
    };

    return (
        <>
            <div className="card mb-4 h-100">
                <div className="card-header">
                    <FontAwesomeIcon icon={faClipboard} className="pe-1" />Notes
                </div>
                <div className="card-body p-1">
                    <div className="notes" style={{ 'overflowY': 'auto', 'maxHeight': '280px' }}>
                        {version ?
                            accountNotes.filter(row => row.NoteType === noteType).map((note, index) =>
                                <div className="card mb-1" key={index}>
                                    <div className="card-header px-2 py-1 fw-bold">
                                        {note.UpdatedBy ?? note.NoteBy}
                                        <small data-bs-toggle="tooltip" data-bs-title={moment(note.DateUpdated ?? note.DateCreated).format("M/D/yyyy h:mm:ss A")} className="text-end float-end"><TimeFromDate date={note.DateUpdated ?? note.DateCreated} /></small>
                                    </div>
                                    <div className="card-body px-2 py-1">
                                        <p className="card-text">{note.Note}</p>
                                    </div>
                                </div>
                            )
                            :
                            accountNotes.filter(row => row.noteType === noteType).map((note, index) =>
                                <div className="card mb-1" key={index}>
                                    <div className="card-header px-2 py-1 fw-bold">
                                        {note.updatedBy ?? note.noteBy}
                                        <small data-bs-toggle="tooltip" data-bs-title={moment(note.dateUpdated ?? note.dateCreated).format("M/D/yyyy h:mm:ss A")} className="text-end float-end"><TimeFromDate date={note.dateUpdated ?? note.dateCreated} /></small>
                                    </div>
                                    <div className="card-body px-2 py-1">
                                        <p className="card-text">{note.note}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountNotes;