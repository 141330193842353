import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

const PrivateRoute = (Component) => {
    const isAuthenticated = true; //useIsAuthenticated();

    return isAuthenticated ? Component : <Navigate to="/" />;
//    return (
//        <Route
//            {...rest}
//            render={(props) =>
//                //    isAuthenticated ? <Component {...props} /> : <Navigate to="/" />
//                isAuthenticated ? <Outlet {...props} /> : <Navigate to="/" />
//            }
//        />
//    );
};

export default PrivateRoute;