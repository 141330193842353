import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link, Route, Routes } from 'react-router-dom';
import { GetAuthUsername } from "../AuthUsername";
import { useMsal } from "@azure/msal-react";

import Header from "./Header";
import SubMenu from "./SubMenu";
//import Find from "./Find";
import useAccount from '../useAccount';

import $ from 'jquery';

import { Alert, Button } from 'reactstrap';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faClone, faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faRightFromBracket, faArrowRightFromBracket, faArrowRightToBracket, faEllipsisVertical, faMagnifyingGlass, faSpinner, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
//import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { NumericFormat } from 'react-number-format';

import { networkDays, isNumeric, isNumericDefault, isNullOrEmpty } from '../../utilities';
import { getParameters } from '../Parameters';

//import DataTable from 'datatables.net';

/*import { useFetch } from '../../utilities';*/
import AddEmployee from '../accounts/AddEmployee';
import ExpensesByMonth from '../accounts/ExpensesByMonth';
import Earnings from '../accounts/Earnings';
import Effort from '../accounts/Effort';
import Encumbrances from '../accounts/Encumbrances';
import UserLookup from './UserLookup';
import ForecastBudgets from "../accounts/ForecastBudgets";
import AccountDetailReport from '../reports/AccountDetailReport';

//import {
//    ColumnDef,
//    flexRender,
//    getCoreRowModel,
//    useReactTable } from '@tanstack/react-table';



const columns = [
    {
        accessorKey: 'firstName',
        id: 'firstName',
        header: 'First Name',
        cell: info => info.getValue(),
        footer: props => props.column.id,
    },
    {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id,
    },
    {
        accessorKey: 'age',
        id: 'age',
        header: 'Age',
        footer: props => props.column.id,
    },
    {
        accessorKey: 'visits',
        id: 'visits',
        header: 'Visits',
        footer: props => props.column.id,
    },
    {
        accessorKey: 'status',
        id: 'status',
        header: 'Status',
        footer: props => props.column.id,
    },
    {
        accessorKey: 'progress',
        id: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id,
    },
//    {
//        header: 'Name',
//        footer: props => props.column.id,
//        columns: [
//            {
//                accessorKey: 'firstName',
//                cell: info => info.getValue(),
//                footer: props => props.column.id,
//            },
//            {
//                accessorFn: row => row.lastName,
//                id: 'lastName',
//                cell: info => info.getValue(),
//                header: () => <span>Last Name</span>,
//                footer: props => props.column.id,
//            },
//        ],
//    },
//    {
//        header: 'Info',
//        footer: props => props.column.id,
//        columns: [
//            {
//                accessorKey: 'age',
//                header: () => 'Age',
//                footer: props => props.column.id,
//            },
//            {
//                header: 'More Info',
//                columns: [
//                    {
//                        accessorKey: 'visits',
//                        header: () => <button className="btn btn-primary btn-sm">Visits</button>,
//                        footer: props => props.column.id,
//                    },
//                    {
//                        accessorKey: 'status',
//                        header: 'Status',
//                        footer: props => props.column.id,
//                    },
//                    {
//                        accessorKey: 'progress',
//                        header: 'Profile Progress',
//                        footer: props => props.column.id,
//                    },
//                ],
//            },
//        ],
//    },
]

const ledgerColumns = [
    {
        accessorKey: 'LedgerAccount',
        id: 'LedgerAccount',
        header: 'Ledger Account',
        cell: info => info.getValue(),
        footer: props => props.column.id,
    },
    {
        accessorKey: 'ApplyFA',
        id: 'ApplyFA',
        header: 'Apply F&A',
        cell: info => info.getValue(),
        footer: props => props.column.id,
    },
    {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id,
    },
    {
        accessorKey: 'age',
        id: 'age',
        header: 'Age',
        footer: props => props.column.id,
    },
    {
        accessorKey: 'visits',
        id: 'visits',
        header: 'Visits',
        footer: props => props.column.id,
    },
    {
        accessorKey: 'status',
        id: 'status',
        header: 'Status',
        footer: props => props.column.id,
    },
    {
        accessorKey: 'progress',
        id: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id,
    },
    //    {
    //        header: 'Name',
    //        footer: props => props.column.id,
    //        columns: [
    //            {
    //                accessorKey: 'firstName',
    //                cell: info => info.getValue(),
    //                footer: props => props.column.id,
    //            },
    //            {
    //                accessorFn: row => row.lastName,
    //                id: 'lastName',
    //                cell: info => info.getValue(),
    //                header: () => <span>Last Name</span>,
    //                footer: props => props.column.id,
    //            },
    //        ],
    //    },
    //    {
    //        header: 'Info',
    //        footer: props => props.column.id,
    //        columns: [
    //            {
    //                accessorKey: 'age',
    //                header: () => 'Age',
    //                footer: props => props.column.id,
    //            },
    //            {
    //                header: 'More Info',
    //                columns: [
    //                    {
    //                        accessorKey: 'visits',
    //                        header: () => <button className="btn btn-primary btn-sm">Visits</button>,
    //                        footer: props => props.column.id,
    //                    },
    //                    {
    //                        accessorKey: 'status',
    //                        header: 'Status',
    //                        footer: props => props.column.id,
    //                    },
    //                    {
    //                        accessorKey: 'progress',
    //                        header: 'Profile Progress',
    //                        footer: props => props.column.id,
    //                    },
    //                ],
    //            },
    //        ],
    //    },
]


function Debug() {
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();

    //$.DataTable = require('datatables.net');
    const tableRef = useRef();
    console.log(tableRef);

    //const {
    //    isLoading: accountIsLoading,
    //    loadData,
    //    accountDetail,
    //    accountEmployeeSemesters,
    //    accountEmployeeEarnings,
    //    accountBudgetExpenses,
    //    accountFiscalMonths,
    //    accountCurrentRate,
    //    getAccountCurrentRate,
    //    getAccountFABase,
    //    getDirectCostLedgerAccounts,
    //    getIndirectCostLedgerAccounts,
    //    getAccountLedgerForecast,
    //    getAccountLedgerAvailable,
    //    getDirectCosts,
    //    getIndirectAccountLedgerEncumbrances,
    //    getIndirectAccountLedgerAvailable,
    //    getForecastTotals,
    //    getActualFAPercent,
    //    getAvailableToSpend,
    //    getLedgerForecastByMonth,
    //    getCurrentRate,
    //    getEmployeeForecasts,
    //    getEmployeeFundingPercent
    //} = useAccount("GR39687", null, null, null, true);
    const account = "GR39687";

    const [ledgerAccountDirectory, setLedgerAccountDirectory] = useState([]);
    const [accountDetail, setAccountDetail] = useState(null);
    const [accountEmployeeFunding, setAccountEmployeeFunding] = useState(null);
    const [accountEmployeeEarnings, setAccountEmployeeEarnings] = useState(null);
    const [accountBudgetExpenses, setAccountBudgetExpenses] = useState(null);
    const [accountEmployeeSemesters, setAccountEmployeeSemesters] = useState(null);
    const [accountFiscalMonths, setAccountFiscalMonths] = useState(null);
    const [accountLedgerByMonth, setAccountLedgerByMonth] = useState(null);

    const [hasAccountChanged, setHasAccountChanged] = useState(false);

    const [loading, setLoading] = useState(true);
    const testdata = [{
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    {
        firstName: 'test',
        lastName: 'test',
        age: 10,
        visits: 100,
        status: 'A',
        progress: 100
    },
    ];

    const [data, setData] = useState(testdata);
    const authUsername = GetAuthUsername();

    const navigate = useNavigate();
    const parameters = getParameters();

    const clientid = process.env.ClientID;

    //const test = useFetch('https://as-api-forecast-wus2.azurewebsites.net/account/mru?user=dvdalonzo@ashconllc.com');


    useEffect(() => {


        // Update the document title using the browser API

        //const dataFetch = async () => {
        //    //useFetch('https://as-api-forecast-wus2.azurewebsites.net/account/mru?user=dvdalonzo@ashconllc.com');
        //};

        //dataFetch();


    }, []);



    const changeSessionVariable = () => {
        sessionStorage.setItem('theme', 'new value');
    };

    //const table = useReactTable({
    //    data: accountBudgetExpenses ? getDirectCostLedgerAccounts() : [],
    //    ledgerColumns,
    //    getCoreRowModel: getCoreRowModel(),
    //    columnResizeMode: 'onChange',
    //    initialState: {
    //        columnPinning: {
    //            left: ['LedgerAccount', 'ApplyFA'],
    //            right: [],
    //        },
    //        // ... Other initial state properties ...
    //    },
    //})

    return (
        <>
            <Header />
            <SubMenu />

            <div className="container-xl px-2 pb-4 mb-4">
                <div className="row g-3">
                    <h5>Debug</h5>
                    <p>Test stuff here</p>
                    <p>process.env.ClientID:<br /> "{JSON.stringify(clientid)}"</p>
                    <p>sessionStorage: {sessionStorage.getItem('theme') || 'light'} <button onClick={changeSessionVariable}>Test Change Variable</button></p>
                    <p>REACT_APP_VERSION = {process.env.REACT_APP_VERSION}</p>
                    <p>NODE_ENV = {process.env.NODE_ENV}</p>
                    <p>REACT_APP_ENVIRONMENT = {process.env.REACT_APP_ENVIRONMENT}</p>
                    <p>REACT_APP_ENVIRONMENT_BADGEBG = {process.env.REACT_APP_ENVIRONMENT_BADGEBG}</p>
                    <p>REACT_APP_API_URL = {process.env.REACT_APP_API_URL}</p>
                    <p>REACT_APP_API_TOKEN = {process.env.REACT_APP_API_TOKEN}</p>
                    <p>REACT_APP_AUTH_CLIENT_ID = {process.env.REACT_APP_AUTH_CLIENT_ID}</p>
                    <p>REACT_APP_AUTH_AUTHORITY = {process.env.REACT_APP_AUTH_AUTHORITY}</p>
                    <p>REACT_APP_AUTH_REDIRECTURI = {process.env.REACT_APP_AUTH_REDIRECTURI}</p>
                </div>
                <div className="col">
                    <AccountDetailReport account={"PG14112"} showBasicLink />
                </div>
                {/*<div className="col">*/}
                {/*    <UserLookup />*/}
                {/*</div>*/}

            </div>
            <hr />
        </>
    );

}
export default Debug;




//<table className="table"
//    style={{
//    }}
//>
//    <thead>
//        <tr>
//            <th colsPan="3"></th>
//            <th>Test</th>
//            <th>Test</th>
//            <th>Test</th>
//        </tr>
//        {table.getHeaderGroups().map(headerGroup => (
//            <tr key={headerGroup.id}>
//                {headerGroup.headers.map(header => {
//                    const { column } = header

//                    return (
//                        <th
//                            key={header.id}
//                            colSpan={header.colSpan}
//                            //IMPORTANT: This is where the magic happens!
//                            style={{ ...getCommonPinningStyles(column) }}
//                        >
//                            <div className="whitespace-nowrap">
//                                {header.isPlaceholder
//                                    ? null
//                                    : flexRender(
//                                        header.column.columnDef.header,
//                                        header.getContext()
//                                    )}{' '}
//                                {/* Demo getIndex behavior */}
//                                {column.getIndex(column.getIsPinned() || 'center')}
//                            </div>
//                            {/*{!header.isPlaceholder && header.column.getCanPin() && (*/}
//                            {/*    <div className="flex gap-1 justify-center">*/}
//                            {/*        {header.column.getIsPinned() !== 'left' ? (*/}
//                            {/*            <button*/}
//                            {/*                className="border rounded px-2"*/}
//                            {/*                onClick={() => {*/}
//                            {/*                    header.column.pin('left')*/}
//                            {/*                }}*/}
//                            {/*            >*/}
//                            {/*                {'<='}*/}
//                            {/*            </button>*/}
//                            {/*        ) : null}*/}
//                            {/*        {header.column.getIsPinned() ? (*/}
//                            {/*            <button*/}
//                            {/*                className="border rounded px-2"*/}
//                            {/*                onClick={() => {*/}
//                            {/*                    header.column.pin(false)*/}
//                            {/*                }}*/}
//                            {/*            >*/}
//                            {/*                X*/}
//                            {/*            </button>*/}
//                            {/*        ) : null}*/}
//                            {/*        {header.column.getIsPinned() !== 'right' ? (*/}
//                            {/*            <button*/}
//                            {/*                className="border rounded px-2"*/}
//                            {/*                onClick={() => {*/}
//                            {/*                    header.column.pin('right')*/}
//                            {/*                }}*/}
//                            {/*            >*/}
//                            {/*                {'=>'}*/}
//                            {/*            </button>*/}
//                            {/*        ) : null}*/}
//                            {/*    </div>*/}
//                            {/*)}*/}
//                            <div
//                                {...{
//                                    onDoubleClick: () => header.column.resetSize(),
//                                    onMouseDown: header.getResizeHandler(),
//                                    onTouchStart: header.getResizeHandler(),
//                                    className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''
//                                        }`,
//                                }}
//                            />
//                        </th>
//                    )
//                })}
//            </tr>
//        ))}
//    </thead>
//    <tbody>
//        {table.getRowModel().rows.map(row => (
//            <tr key={row.id}>
//                {row.getVisibleCells().map(cell => {
//                    const { column } = cell
//                    return (
//                        <td
//                            key={cell.id}
//                            //IMPORTANT: This is where the magic happens!
//                            style={{ ...getCommonPinningStyles(column) }}
//                        >
//                            {flexRender(
//                                cell.column.columnDef.cell,
//                                cell.getContext()
//                            )}
//                        </td>
//                    )
//                })}
//            </tr>
//        ))}
//    </tbody>
//</table>
