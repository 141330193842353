import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar, faTrashCan, faFloppyDisk, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faMagnifyingGlass, faSpinner, faFileInvoice, faUserPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

function AddEmployee(props) {

    const newEmployeeObj = { Employee: '', EmplIdPositionRcd: '', EmplId: '', PositionNumber: '', EmplRcd: '', Title: '', AYPayRate: '', SummerPayRate: '', StaffType: '', FTE: 1, EmplStatus: 'A', PayGroup: 'SAL' }
    const [modalAddEmployee, setModalAddEmployee] = useState(false);
    const [addEmployees, setAddEmployees] = useState([]);
    const [addEmployeeErrors, setAddEmployeeErrors] = useState({});
    const [editAddEmployee, setEditAddEmployee] = useState({ Employee: '', EmplIdPositionRcd: '', EmplId: '', PositionNumber: '', EmplRcd: null, Title: '', AYPayRate: null, SummerPayRate: null, StaffType: '', FTE: null, EmplStatus: '', PayGroup: '' });
    const [editAddEmployeeErrors, setEditAddEmployeeErrors] = useState({});
    const [editAddEmployeeIndex, setEditAddEmployeeIndex] = useState(null);
    const [newAddEmployee, setNewAddEmployee] = useState(newEmployeeObj);
    const [searchEmployee, setSearchEmployee] = useState('');
    const [searchingEmployee, setSearchingEmployee] = useState(false);
    const [searchEmployeeResults, setSearchEmployeeResults] = useState([]);
    const [showValidateEmployeeAddToast, setShowValidateEmployeeAddToast] = useState(false);
    const [validateEmployeeAddToastData, setValidateEmployeeAddToastData] = useState({});
    const [validateEmployeeAddToastMessage, setValidateEmployeeAddToastMessage] = useState('');
    const [errorRowsIndex, setErrorRowsIndex] = useState([]);

    useEffect(() => {

    }, []);

    const modalAddEmployeeToggle = () => setModalAddEmployee(!modalAddEmployee);
    const toastAddEmployeeErrorToggle = () => {
        setShowValidateEmployeeAddToast(!showValidateEmployeeAddToast);
        setErrorRowsIndex([]);

    };
    const openModalAddEmployee = () => {
        modalAddEmployeeToggle();
    };
    const handleEditAddEmployee = (index) => {
        setEditAddEmployeeIndex(index);
        const employeeToEdit = addEmployees.at(index);
        setEditAddEmployee({
            Employee: employeeToEdit.Employee,
            EmplIdPositionRcd: employeeToEdit.EmplIdPositionRcd,
            EmplId: employeeToEdit.EmplId,
            PositionNumber: employeeToEdit.PositionNumber,
            EmplRcd: employeeToEdit.EmplRcd,
            Title: employeeToEdit.Title,
            AYPayRate: employeeToEdit.AYPayRate,
            SummerPayRate: employeeToEdit.SummerPayRate,
            StaffType: employeeToEdit.StaffType,
            FTE: employeeToEdit.FTE,
            EmplStatus: employeeToEdit.EmplStatus,
            PayGroup: employeeToEdit.PayGroup
        });
    };
    const handleAddEmployeeInputChange = (e) => {
        const { name, value } = e.target;
        //validateEmployeeObject(editAddEmployee);
        setEditAddEmployee((prevEmp) => ({ ...prevEmp, [name]: value }));
    };
    const handleAddEmployeeNumericChange = (values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
            return;
        }
        //validateEmployeeObject(editAddEmployee);
        setEditAddEmployee((prevEmp) => ({ ...prevEmp, [sourceInfo.event.target.name]: values.floatValue }));
    };
    const handleNewAddEmployeeInputChange = (e) => {
        const { name, value } = e.target;
        //validateEmployeeObject(newAddEmployee);
        setNewAddEmployee((prevEmp) => ({ ...prevEmp, [name]: value }));
    };
    const handleNewAddEmployeeNumericChange = (values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
            return;
        }
        //validateEmployeeObject(newAddEmployee);
        setNewAddEmployee((prevEmp) => ({ ...prevEmp, [sourceInfo.event.target.name]: values.floatValue }));
    };

    const handleAddNewEmployee = () => {
        if (validateEmployeeObject(newAddEmployee, setAddEmployeeErrors)) {
            setAddEmployees((prevEmp) => [
                ...prevEmp,
                {
                    Employee: newAddEmployee.Employee,
                    EmplIdPositionRcd: newAddEmployee.EmplIdPositionRcd,
                    EmplId: newAddEmployee.EmplId,
                    PositionNumber: newAddEmployee.PositionNumber,
                    EmplRcd: newAddEmployee.EmplRcd,
                    Title: newAddEmployee.Title,
                    AYPayRate: newAddEmployee.AYPayRate,
                    SummerPayRate: newAddEmployee.SummerPayRate,
                    StaffType: newAddEmployee.StaffType,
                    FTE: newAddEmployee.FTE,
                    EmplStatus: newAddEmployee.EmplStatus,
                    PayGroup: newAddEmployee.PayGroup
                },
            ]);
            setNewAddEmployee(newEmployeeObj);
            setAddEmployeeErrors({});
        }
    };

    const handleSaveEditAddEmployee = (index) => {
        let clone = [...addEmployees];
        let item = clone[index];
        if (validateEmployeeObject(editAddEmployee, setEditAddEmployeeErrors, index)) {
            item.Employee = editAddEmployee.Employee;
            item.EmplIdPositionRcd = editAddEmployee.EmplIdPositionRcd;
            item.EmplId = editAddEmployee.EmplId;
            item.PositionNumber = editAddEmployee.PositionNumber;
            item.EmplRcd = editAddEmployee.EmplRcd;
            item.Title = editAddEmployee.Title;
            item.AYPayRate = editAddEmployee.AYPayRate;
            item.SummerPayRate = editAddEmployee.SummerPayRate;
            item.StaffType = editAddEmployee.StaffType;
            item.FTE = editAddEmployee.FTE;
            item.EmplStatus = editAddEmployee.EmplStatus;
            item.PayGroup = editAddEmployee.PayGroup;
            clone[index] = item;
            setAddEmployees([...clone]);
            setEditAddEmployeeIndex(null);

            setEditAddEmployee({ Employee: '', EmplIdPositionRcd: '', EmplId: '', PositionNumber: '', EmplRcd: null, Title: '', AYPayRate: null, SummerPayRate: null, StaffType: '', FTE: null, EmplStatus: '', PayGroup: '' });
            setEditAddEmployeeErrors({});
        }
    };
    const validateEmployeeObject = (emp, setErrors, index) => {
        let isValid = true;

        setErrors((prevErr) => ({
            ...prevErr,
            Employee: isNullOrEmpty(emp.Employee),
            EmplId: isNullOrEmpty(emp.EmplId),
            PositionNumber: isNullOrEmpty(emp.PositionNumber),
            EmplRcd: isNumericNullOrUndefined(emp.EmplRcd),
            Title: isNullOrEmpty(emp.Title),
            AYPayRate: isNumericNullOrUndefined(emp.AYPayRate),
            SummerPayRate: isNumericNullOrUndefined(emp.SummerPayRate),
            StaffType: isNullOrEmpty(emp.StaffType),
            FTE: isNumericNullOrUndefined(emp.FTE),
            EmplStatus: isNullOrEmpty(emp.EmplStatus),
            PayGroup: isNullOrEmpty(emp.PayGroup)
        }));
        if (!validateEmployeeKey(emp, index)) {
            setErrors((prevErr) => ({
                ...prevErr,
                EmplId: true,
                PositionNumber: true,
                EmplRcd: true,
            }));
            isValid = false;
        }
        console.log('keys', Object.keys(addEmployeeErrors), Object.keys(editAddEmployeeErrors));
        if (isNullOrEmpty(emp.Employee)) {
            isValid = false;
        }
        if (isNullOrEmpty(emp.EmplId)) {
            isValid = false;
        }
        if (isNullOrEmpty(emp.PositionNumber)) {
            isValid = false;
        }
        if (isNumericNullOrUndefined(emp.EmplRcd)) {
            isValid = false;
        }
        if (isNullOrEmpty(emp.Title)) {
            isValid = false;
        }
        if (isNumericNullOrUndefined(emp.AYPayRate)) {
            isValid = false;
        }
        if (isNumericNullOrUndefined(emp.SummerPayRate)) {
            isValid = false;
        }
        if (isNullOrEmpty(emp.StaffType)) {
            isValid = false;
        }
        if (isNumericNullOrUndefined(emp.FTE === null)) {
            isValid = false;
        }
        if (isNullOrEmpty(emp.EmplStatus)) {
            isValid = false;
        }
        if (isNullOrEmpty(emp.PayGroup)) {
            isValid = false;
        }
        console.log('addEmployeeErrors', addEmployeeErrors, 'editAddEmployeeErrors', editAddEmployeeErrors, 'emp', emp);
        return isValid;
    };
    const isNullOrEmpty = (val) => {
        return (!val || val === undefined || val === "" || val.length === 0);
    };
    const isNumericNullOrUndefined = (val) => {
        return (val === null || val === undefined) || val === "";
    }
    const validateEmployeeKey = (emp, ix) => {
        if (addEmployees && addEmployees.length > 0) {
            if (addEmployees.some((e, i) => e.EmplId.trim() === emp.EmplId.trim() && e.PositionNumber.trim() === emp.PositionNumber.trim() && e.EmplRcd === emp.EmplRcd && (i !== ix || ix === null))) {
                // there is an error...
                setValidateEmployeeAddToastData((prevErr) => ({
                    ...prevErr,
                    EmplId: emp.EmplId,
                    PositionNumber: emp.PositionNumber,
                    EmplRcd: emp.EmplRcd,
                }));
                setValidateEmployeeAddToastMessage(<>
                    <p>The employee could not be added.</p>
                    <p>The Employee Id, Position & Record Number already exists.</p>
                    <table className="table table-xxs">
                        < tr >
                            <td>Employee Id:</td>
                            <th>{emp.EmplId}</th>
                        </tr >
                        <tr>
                            <td>Position:</td>
                            <th>{emp.PositionNumber}</th>
                        </tr>
                        <tr>
                            <td>Record:</td>
                            <th>{emp.EmplRcd}</th>
                        </tr>
                    </table >
                </>);
                setShowValidateEmployeeAddToast(true);
                setTimeout(() => setShowValidateEmployeeAddToast(false), 5000);
                return false;
            }
        }
        return true;
    }

    const handleCancelEditAddEmployee = (index) => {
        setEditAddEmployeeIndex(null);
        setEditAddEmployee({ Employee: '', EmplIdPositionRcd: '', EmplId: '', PositionNumber: '', EmplRcd: null, Title: '', AYPayRate: null, SummerPayRate: null, StaffType: '', FTE: null, EmplStatus: '', PayGroup: '' });
        setEditAddEmployeeErrors({});
    };

    const handleDeleteAddEmployee = (index) => {
        let clone = [...addEmployees];
        clone.splice(index, 1);
        setAddEmployees([...clone]);
    };
    const handleFindEmployees = async (e) => {
        e.preventDefault();
        if (!(searchEmployee && searchEmployee !== '')) {
            return;
        }
        setSearchingEmployee(true);
        const response = await fetch('/api/account/findemployees?' +
            (!searchEmployee ? '' : 'search=' + encodeURIComponent(searchEmployee))
        );
        const data = await response.json();
        setSearchEmployeeResults(data);
        setSearchingEmployee(false);
    };
    const handleSearchEmployeeChange = (e) => {
        const { value } = e.target;
        setSearchEmployee(value);
    };
    const handleAddExistingEmployee = (index) => {
        let thisEmployee = searchEmployeeResults.at(index);
        if (thisEmployee) {
            if (validateEmployeeKey(thisEmployee)) {
                setAddEmployees((prevEmp) => [
                    ...prevEmp,
                    {
                        Employee: thisEmployee.PersonName,
                        EmplIdPositionRcd: thisEmployee.EmplId + '-' + thisEmployee.PositionNumber + '-' + thisEmployee.EmplRcd,
                        EmplId: thisEmployee.EmplId,
                        PositionNumber: thisEmployee.PositionNumber,
                        EmplRcd: thisEmployee.EmplRcd,
                        Title: thisEmployee.Title,
                        AYPayRate: thisEmployee.AYPayRate,
                        SummerPayRate: thisEmployee.SummerPayRate,
                        StaffType: thisEmployee.StaffType,
                        FTE: thisEmployee.FTE,
                        EmplStatus: thisEmployee.EmplStatus,
                        PayGroup: thisEmployee.PayGroup
                    },
                ]);

                let clone = [...searchEmployeeResults];
                clone.splice(index, 1);
                setSearchEmployeeResults([...clone]);
            }

        }
    };
    const handleAddEmployeesToAccount = () => {

        if (addEmployees && addEmployees.length > 0) {
            //Check to make sure that the employee doesn't already exist in the original list...
            let errorIndex = [];
            let errorData = [];
            if (props.currentEmployees && props.currentEmployees.length > 0) {
                addEmployees.forEach((ae, ix) => {
                    if (props.currentEmployees.some(ce => ce.EmplId.trim() === ae.EmplId.trim() && ce.Position.trim() === ae.PositionNumber.trim() && ce.EmplRcd === ae.EmplRcd)) {
                        errorIndex.push(ix);
                        errorData.push({ EmplId: ae.EmplId, PositionNumber: ae.PositionNumber, EmplRcd: ae.EmplRcd });
                    }
                })
                if (errorIndex.length > 0) {
                    setValidateEmployeeAddToastMessage(<>
                        <p>Some employees could not be added to the forecast because the Employee Id, Position and Record Number already exist.</p>
                        <table className="table table-xxs">
                            <tr>
                                <th>Employee Id</th>
                                <th>Position</th>
                                <th>Record</th>
                            </tr>
                            {errorData.map(ed => {
                                return <tr className="bg-danger-subtle">
                                    <td>{ed.EmplId}</td>
                                    <td>{ed.PositionNumber}</td>
                                    <td>{ed.EmplRcd}</td>
                                </tr>
                            })}
                        </table >
                    </>);
                    setShowValidateEmployeeAddToast(true);
                    setErrorRowsIndex(errorIndex);

                    //setTimeout(() => setShowValidateEmployeeAddToast(false), 5000);
                    return;

                }
            }

            props.handleCallback(addEmployees);
        }
        setAddEmployees([]);
        setSearchEmployeeResults([]);
        setSearchEmployee('');
        setAddEmployeeErrors({});
        setEditAddEmployeeErrors({});
        modalAddEmployeeToggle();
    };


    return (
        <>
            <button type="submit" className="btn btn-secondary btn-sm" onClick={openModalAddEmployee} disabled={false} >
                <FontAwesomeIcon icon={faUserPlus} className="pe-2" />
                Add Employees
            </button>
            <Modal isOpen={modalAddEmployee} toggle={modalAddEmployeeToggle} size="xxl">
                <ModalHeader toggle={modalAddEmployeeToggle}><FontAwesomeIcon icon={faUserPlus} className="pe-2" />Add Employees</ModalHeader>
                <ModalBody>
                    <div className="p-3 toast-container position-absolute top-0 end-0 p-3">
                        {/*isOpen={showSavingToast}*/}
                        {/*<Toast fade={true}  className="mt-3">*/}
                        {/*    */}{/*toggle={toggleSavingToast} icon={savingToastIcon} */}
                        {/*    <ToastHeader fade="true" aria-live="assertive" aria-atomic="true">*/}
                        {/*        Saving Account*/}
                        {/*    </ToastHeader>*/}
                        {/*    <ToastBody>*/}
                        {/*    Message*/}
                        {/*        */}{/*{savingToastMessage}*/}
                        {/*    </ToastBody>*/}
                        {/*</Toast>*/}
                        <Toast className="mt-3 opacity-100" isOpen={showValidateEmployeeAddToast} autoHide={true}>
                            <ToastHeader className="opacity-100" fade={true} toggle={toastAddEmployeeErrorToggle} icon="danger" aria-live="assertive" aria-atomic="true">
                                Error
                            </ToastHeader>
                            <ToastBody className="opacity-100 bg-white">
                                {validateEmployeeAddToastMessage}
                                {/*<p>The employee could not be added.</p>*/}
                                {/*<p>The Employee Id, Position & Record Number already exist.</p>*/}
                                {/*{validateEmployeeAddToastData ? */}
                                {/*    <table className="table table-xxs">*/}
                                {/*        <tr>*/}
                                {/*            <td>Employee Id:</td>*/}
                                {/*            <th>{validateEmployeeAddToastData.EmplId}</th>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>Position:</td>*/}
                                {/*            <th>{validateEmployeeAddToastData.PositionNumber}</th>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>Record:</td>*/}
                                {/*            <th>{validateEmployeeAddToastData.EmplRcd}</th>*/}
                                {/*        </tr>*/}
                                {/*    </table>*/}
                                {/*    :*/}
                                {/*    null*/}
                                {/*}*/}
                            </ToastBody>
                        </Toast>
                    </div>


                    <div className="row align-items-center pb-3">
                        <div className="col-md-4">Add employees to the account below:</div>
                        <div className="col-md-4 ms-auto text-end">
                            <Button color="primary" onClick={handleAddEmployeesToAccount} disabled={!(addEmployees && addEmployees.length > 0)}>
                                Add Employees To Account
                            </Button>{' '}
                        </div>
                    </div>
                    <table className="table table-xs">
                        <thead>
                            <tr>
                                <th>Employee</th>
                                {/*<th>Emplid-Position-Rcd</th>*/}
                                <th>EmplId</th>
                                <th>Position</th>
                                <th>Empl Rcd</th>
                                <th>Title</th>
                                <th>AY Bi-Weekly Pay Rate</th>
                                <th>Sum Bi-Weekly Pay Rate</th>
                                <th>Staff Type</th>
                                <th>FTE</th>
                                <th>Emp Status</th>
                                <th>Pay Group</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addEmployees ? addEmployees.map((addEmployee, i) =>
                                <>
                                    {editAddEmployeeIndex === i
                                        ?
                                        <tr className={`align-middle ${errorRowsIndex && errorRowsIndex.length > 0 && errorRowsIndex.some(e => e === i) ? 'table-danger' : ''}`} key={i}>
                                            <td>
                                                <input type="text" className={`form-control form-control-sm ${editAddEmployeeErrors && editAddEmployeeErrors.Employee ? 'is-invalid' : ''}`} value={editAddEmployee.Employee} onChange={handleAddEmployeeInputChange} name="Employee" />
                                            </td>
                                            {/*<td>*/}
                                            {/*    <input type="text" className="form-control form-control-sm" value={editAddEmployee.EmplIdPositionRcd} onChange={handleAddEmployeeInputChange} name="EmplIdPositionRcd" />*/}
                                            {/*</td>*/}
                                            <td>
                                                <input type="text" className={`form-control form-control-sm ${editAddEmployeeErrors && editAddEmployeeErrors.EmplId ? 'is-invalid' : ''}`} value={editAddEmployee.EmplId} onChange={handleAddEmployeeInputChange} name="EmplId" />
                                            </td>
                                            <td>
                                                <input type="text" className={`form-control form-control-sm ${editAddEmployeeErrors && editAddEmployeeErrors.PositionNumber ? 'is-invalid' : ''}`} value={editAddEmployee.PositionNumber} onChange={handleAddEmployeeInputChange} name="PositionNumber" />
                                            </td>
                                            <td>
                                                <NumericFormat value={editAddEmployee.EmplRcd} decimalScale={0} fixedDecimalScale className={`form-control form-control-sm text-end ${editAddEmployeeErrors && editAddEmployeeErrors.EmplRcd ? 'is-invalid' : ''}`} onValueChange={handleAddEmployeeNumericChange} name="EmplRcd" />
                                            </td>
                                            <td>
                                                <input type="text" className={`form-control form-control-sm ${editAddEmployeeErrors && editAddEmployeeErrors.Title ? 'is-invalid' : ''}`} value={editAddEmployee.Title} onChange={handleAddEmployeeInputChange} name="Title" />
                                            </td>
                                            <td>
                                                <NumericFormat value={editAddEmployee.AYPayRate} decimalScale={2} fixedDecimalScale thousandSeparator="," className={`form-control form-control-sm text-end ${editAddEmployeeErrors && editAddEmployeeErrors.AYPayRate ? 'is-invalid' : ''}`} onValueChange={handleAddEmployeeNumericChange} name="AYPayRate" />
                                            </td>
                                            <td>
                                                <NumericFormat value={editAddEmployee.SummerPayRate} decimalScale={2} fixedDecimalScale thousandSeparator="," className={`form-control form-control-sm text-end ${editAddEmployeeErrors && editAddEmployeeErrors.SummerPayRate ? 'is-invalid' : ''}`} onValueChange={handleAddEmployeeNumericChange} name="SummerPayRate" />
                                            </td>
                                            <td>
                                                <select className={`form-select form-select-sm ${editAddEmployeeErrors && editAddEmployeeErrors.StaffType ? 'is-invalid' : ''}`} name="StaffType" value={editAddEmployee.StaffType} onChange={handleAddEmployeeInputChange} >
                                                    <option></option>
                                                    <option>Faculty</option>
                                                    <option>NoBen</option>
                                                    <option>Part Time</option>
                                                    <option>PostDoc</option>
                                                    <option>RA/TA</option>
                                                    <option>Staff</option>
                                                    <option>Student</option>
                                                </select>
                                            </td>
                                            <td>
                                                <NumericFormat value={editAddEmployee.FTE} decimalScale={2} fixedDecimalScale thousandSeparator="," className={`form-control form-control-sm text-end ${editAddEmployeeErrors && editAddEmployeeErrors.FTE ? 'is-invalid' : ''}`} onValueChange={handleAddEmployeeNumericChange} name="FTE" isAllowed={(v) => {
                                                    const { floatValue } = v;
                                                    return floatValue <= 1 || floatValue === null || floatValue === undefined;
                                                }}
                                                />
                                                {/*    isAllowed={(v) => {*/}
                                                {/*        const { floatValue } = v;*/}
                                                {/*        console.log(v, 'v');*/}
                                                {/*        return floatValue < 2;*/}
                                                {/*    }}*/}
                                            </td>
                                            <td>
                                                <select className={`form-select form-select-sm ${editAddEmployeeErrors && editAddEmployeeErrors.EmplStatus ? 'is-invalid' : ''}`} name="EmplStatus" value={editAddEmployee.EmplStatus} onChange={handleAddEmployeeInputChange} >
                                                    <option></option>
                                                    <option>A</option>
                                                    <option>I</option>
                                                    <option>W</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select className={`form-select form-select-sm ${editAddEmployeeErrors && editAddEmployeeErrors.PayGroup ? 'is-invalid' : ''}`} name="PayGroup" value={editAddEmployee.PayGroup} onChange={handleAddEmployeeInputChange} >
                                                    <option></option>
                                                    <option>A12</option>
                                                    <option>ACD</option>
                                                    <option>FSW</option>
                                                    <option>GRD</option>
                                                    <option>HRY</option>
                                                    <option>SAL</option>
                                                    <option>STU</option>
                                                </select>
                                            </td>
                                            <td className="text-center">
                                                <div className="d-flex">
                                                    <button type="button" className="btn btn-default px-1 py-0" onClick={() => handleCancelEditAddEmployee(i)}>
                                                        <FontAwesomeIcon icon={faBan} className="" />
                                                    </button>
                                                    <button type="button" className="btn btn-default px-1 py-0" onClick={() => handleSaveEditAddEmployee(i)}>
                                                        <FontAwesomeIcon icon={faFloppyDisk} className="" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr className={`align-middle ${errorRowsIndex && errorRowsIndex.length > 0 && errorRowsIndex.some(e => e === i) ? 'table-danger' : ''}`} key={i}>
                                            <td className="">{addEmployee.Employee}</td>
                                            {/*<td className="">{addEmployee.EmplIdPositionRcd}</td>*/}
                                            <td className="">{addEmployee.EmplId}</td>
                                            <td className="">{addEmployee.PositionNumber}</td>
                                            <td className="">{addEmployee.EmplRcd}</td>
                                            <td className="">{addEmployee.Title}</td>
                                            <td className="text-end"><NumericFormat value={addEmployee.AYPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="text-end"><NumericFormat value={addEmployee.SummerPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="">{addEmployee.StaffType}</td>
                                            <td className="text-end"><NumericFormat value={addEmployee.FTE} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="">{addEmployee.EmplStatus}</td>
                                            <td className="">{addEmployee.PayGroup}</td>
                                            <td className="text-center">
                                                <div className="d-flex">
                                                    <button type="button" className="btn btn-default px-1 py-0" onClick={() => handleEditAddEmployee(i)}>
                                                        <FontAwesomeIcon icon={faPenToSquare} className="" />
                                                    </button>
                                                    <button type="button" className="btn btn-default px-1 py-0" onClick={() => handleDeleteAddEmployee(i)}>
                                                        <FontAwesomeIcon icon={faTrashCan} className="" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </>
                            )
                                :
                                null
                            }
                            <tr className="align-middle">
                                <td>
                                    <input type="text" className={`form-control form-control-sm ${addEmployeeErrors && addEmployeeErrors.Employee ? 'is-invalid' : ''}`} value={newAddEmployee.Employee} onChange={handleNewAddEmployeeInputChange} name="Employee" />
                                </td>
                                {/*<td>*/}
                                {/*    <input type="text" className="form-control form-control-sm" value={newAddEmployee.EmplIdPositionRcd} onChange={handleNewAddEmployeeInputChange} name="EmplIdPositionRcd" />*/}
                                {/*</td>*/}
                                <td>
                                    <input type="text" className={`form-control form-control-sm ${addEmployeeErrors && addEmployeeErrors.EmplId ? 'is-invalid' : ''}`} value={newAddEmployee.EmplId} onChange={handleNewAddEmployeeInputChange} name="EmplId" />
                                </td>
                                <td>
                                    <input type="text" className={`form-control form-control-sm ${addEmployeeErrors && addEmployeeErrors.PositionNumber ? 'is-invalid' : ''}`} value={newAddEmployee.PositionNumber} onChange={handleNewAddEmployeeInputChange} name="PositionNumber" />
                                </td>
                                <td>
                                    <NumericFormat value={newAddEmployee.EmplRcd} decimalScale={0} fixedDecimalScale className={`form-control form-control-sm text-end ${addEmployeeErrors && addEmployeeErrors.EmplRcd ? 'is-invalid' : ''}`} onValueChange={handleNewAddEmployeeNumericChange} name="EmplRcd" />
                                </td>
                                <td>
                                    <input type="text" className={`form-control form-control-sm ${addEmployeeErrors && addEmployeeErrors.Title ? 'is-invalid' : ''}`} value={newAddEmployee.Title} onChange={handleNewAddEmployeeInputChange} name="Title" />
                                </td>
                                <td>
                                    <NumericFormat value={newAddEmployee.AYPayRate} decimalScale={2} fixedDecimalScale thousandSeparator="," className={`form-control form-control-sm text-end ${addEmployeeErrors && addEmployeeErrors.AYPayRate ? 'is-invalid' : ''}`} onValueChange={handleNewAddEmployeeNumericChange} name="AYPayRate" />
                                </td>
                                <td>
                                    <NumericFormat value={newAddEmployee.SummerPayRate} decimalScale={2} fixedDecimalScale thousandSeparator="," className={`form-control form-control-sm text-end ${addEmployeeErrors && addEmployeeErrors.SummerPayRate ? 'is-invalid' : ''}`} onValueChange={handleNewAddEmployeeNumericChange} name="SummerPayRate" />
                                </td>
                                <td>
                                    <select className={`form-select form-select-sm ${addEmployeeErrors && addEmployeeErrors.StaffType ? 'is-invalid' : ''}`} name="StaffType" value={newAddEmployee.StaffType} onChange={handleNewAddEmployeeInputChange} >
                                        <option></option>
                                        <option>Faculty</option>
                                        <option>NoBen</option>
                                        <option>Part Time</option>
                                        <option>PostDoc</option>
                                        <option>RA/TA</option>
                                        <option>Staff</option>
                                        <option>Student</option>
                                    </select>
                                </td>
                                <td>
                                    <NumericFormat value={newAddEmployee.FTE} decimalScale={2} fixedDecimalScale thousandSeparator="," className={`form-control form-control-sm text-end ${addEmployeeErrors && addEmployeeErrors.FTE ? 'is-invalid' : ''}`} onValueChange={handleNewAddEmployeeNumericChange} name="FTE" isAllowed={(v) => {
                                        const { floatValue } = v;
                                        return floatValue <= 1 || floatValue === null || floatValue === undefined;
                                    }}
                                    />
                                    {/*    isAllowed={(v) => {*/}
                                    {/*        const { floatValue } = v;*/}
                                    {/*        console.log(v, 'v');*/}
                                    {/*        return floatValue < 2;*/}
                                    {/*    }}*/}
                                </td>
                                <td>
                                    <select className={`form-select form-select-sm ${addEmployeeErrors && addEmployeeErrors.EmplStatus ? 'is-invalid' : ''}`} name="EmplStatus" value={newAddEmployee.EmplStatus} onChange={handleNewAddEmployeeInputChange} >
                                        <option></option>
                                        <option>A</option>
                                        <option>I</option>
                                        <option>W</option>
                                    </select>
                                </td>
                                <td>
                                    <select className={`form-select form-select-sm ${addEmployeeErrors && addEmployeeErrors.PayGroup ? 'is-invalid' : ''}`} name="PayGroup" value={newAddEmployee.PayGroup} onChange={handleNewAddEmployeeInputChange} >
                                        <option></option>
                                        <option>A12</option>
                                        <option>ACD</option>
                                        <option>FSW</option>
                                        <option>GRD</option>
                                        <option>HRY</option>
                                        <option>SAL</option>
                                        <option>STU</option>
                                    </select>
                                </td>
                                <td className="text-center">
                                    <button type="button" className="btn btn-default px-1 py-0" onClick={handleAddNewEmployee}>
                                        <FontAwesomeIcon icon={faFloppyDisk} className="" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className="my-5" />
                    <div className="">
                        {/*<div className="toast-container position-absolute top-0 end-0 p-3">*/}
                        {/*    */}{/*isOpen={showSavingToast}*/}
                        {/*    <Toast fade={true} className="mt-3">*/}
                        {/*        */}{/*toggle={toggleSavingToast} icon={savingToastIcon} */}
                        {/*        <ToastHeader fade="true" aria-live="assertive" aria-atomic="true" toggle={modalAddEmployeeToggle} icon="danger">*/}
                        {/*            Saving Account*/}
                        {/*        </ToastHeader>*/}
                        {/*        <ToastBody className="opacity-100">*/}
                        {/*            Could not add employee.  Employee Id, Position, and Record Number already exists.*/}
                        {/*        </ToastBody>*/}
                        {/*    </Toast>*/}
                        {/*    */}{/*<Toast className="mt-3">*/}
                        {/*    */}{/*    <ToastHeader fade="true" icon="danger" aria-live="assertive" aria-atomic="true">*/}
                        {/*    */}{/*        Error*/}
                        {/*    */}{/*    </ToastHeader>*/}
                        {/*    */}{/*    <ToastBody>This is the error message</ToastBody>*/}
                        {/*    */}{/*</Toast>*/}
                        {/*</div>*/}

                        <form className="row g-3 justify-content-center align-items-center" onSubmit={handleFindEmployees}>
                            <div className="col-auto">
                                <label className="my-1 me-2">Find Existing Employee:</label>
                            </div>
                            <div className="col-auto">
                                <input type="text" className="form-control" value={searchEmployee} onChange={handleSearchEmployeeChange} />
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary mb-3 my-3" disabled={searchingEmployee || searchEmployee === ''} >
                                    {searchingEmployee
                                        ?
                                        <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                                        :
                                        <FontAwesomeIcon icon={faMagnifyingGlass} className="pe-2" />
                                    }
                                    Find Employee
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="" style={{ maxHeight: '300px', overflow: 'auto' }} >
                        <table className="table table-xs">
                            <thead className="sticky-top">
                                <tr>
                                    <th>Employee</th>
                                    <th>Emplid-Position-Rcd</th>
                                    <th>Title</th>
                                    <th>AY Bi-Weekly Pay Rate</th>
                                    <th>Sum Bi-Weekly Pay Rate</th>
                                    <th>Staff Type</th>
                                    <th>FTE</th>
                                    <th>Emp Status</th>
                                    <th>Pay Group</th>
                                    <th>Department</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchEmployeeResults ? searchEmployeeResults.map((result, i) =>
                                    <tr key={result.EmplId + '-' + result.PositionNumber + '-' + result.EmplRcd} className="align-middle">
                                        <td>{result.PersonName}</td>
                                        <td>{result.EmplId + '-' + result.PositionNumber + '-' + result.EmplRcd}</td>
                                        <td>{result.Title}</td>
                                        <td className="text-end"><NumericFormat value={result.AYPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="text-end"><NumericFormat value={result.SummerPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td>{result.StaffType}</td>
                                        <td className="text-end"><NumericFormat value={result.FTE} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td>{result.EmplStatus}</td>
                                        <td>{result.PayGroup}</td>
                                        <td>{result.DeptId}</td>
                                        <td className="text-center">
                                            <button type="button" className="btn btn-default px-1 py-0" onClick={() => handleAddExistingEmployee(i)}>
                                                <FontAwesomeIcon icon={faUserPlus} className="" />
                                            </button>
                                        </td>
                                    </tr>
                                )
                                    :
                                    <tr>
                                        <td colSpan="4" className="text-center">No data available</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={openModalAddEmployee}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default AddEmployee;