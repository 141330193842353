import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faMagnifyingGlass, faSpinner, faUsersBetweenLines, faUser, faBan, faCircleXmark, faCircleCheck, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import RPPR from "./RPPR";

function Effort(props) {

    const [earnings, setEarnings] = useState([]);
    const [includeForecast, setIncludeForecast] = useState(true);
    const [effortDates, setEffortDates] = useState(null);
    const [effortITD, setEffortITD] = useState(null);
    const [effortEarnings, setEffortEarnings] = useState([]);
    const [rpprPeriodStartDate, setRpprPeriodStartDate] = useState(null);
    const [rpprPeriodEndDate, setRpprPeriodEndDate] = useState(null);
    const [earningsHere, setEarningsHere] = useState(null);
    const [payPeriods, setPayPeriods] = useState([]);

    const [modalEarnings, setModalEarnings] = useState(false);

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {

    }, []);

    const modalEarningsToggle = () => setModalEarnings(!modalEarnings);
    const openModalEarnings = () => {
        getData();
    };
    const toggleIncludeForecast = () => setIncludeForecast(!includeForecast);
    const getData = async () => {

        setShowLoading(true);

        try {
            const ppResponse = await fetch('/api/lookup/payperiods?endDate=' + encodeURIComponent(props.accountEndDate));
            const ppData = await ppResponse.json();
            setPayPeriods(ppData);

            const eDatesResponse = await fetch('/api/account/effortdates?account=' + encodeURIComponent(props.account));
            const eDatesData = await eDatesResponse.json();
            setEffortDates(eDatesData);

            const eITDResponse = await fetch('/api/account/effortitd?account=' + encodeURIComponent(props.account));
            const eITDData = await eITDResponse.json();
            setEffortITD(eITDData);

            const eeResponse = await fetch('/api/account/effortearnings?account=' + encodeURIComponent(props.account));
            const eeData = await eeResponse.json();
            setEffortEarnings(eeData);
            //console.log(eeData);
            
            let earningsHere = eeData && eeData.length > 0 ? eeData.map(x => x.GrantEffort ?? 0).reduce((a, v) => a + v) : null;
            setEarningsHere(earningsHere);

            //setRpprPeriodStartDate(eeData && eeData.length > 0 ? eeData[0].PayStartDate : (props.accountStartDate ? props.accountStartDate : eDatesData.StartDate));
            setRpprPeriodStartDate(props.accountStartDate ? props.accountStartDate : eDatesData.StartDate);
            setRpprPeriodEndDate(eDatesData ? eDatesData.ObligatedEndDate : props.accountEndDate);
            const earnResponse = await fetch('/api/account/earnings?account=' + encodeURIComponent(props.account));
            const earnData = await earnResponse.json();
            //console.log(earnData);
            setEarnings(earnData);


            modalEarningsToggle();
            setShowLoading(false);
        }
        catch (e) {
            console.error(e);
            setShowLoading(false);
        }

    }

    const getEffortEarningsPayPeriods = () => {
        let result = [];
        if (effortEarnings) {
            result = Array.from(new Set(effortEarnings.filter(ee =>
                moment(ee.PayEndDate) < moment(rpprPeriodEndDate) &&
                moment(ee.PayStartDate) >= moment(rpprPeriodStartDate)
            ).map(e => e.PayStartDate)))
                .map(id => {
                    return {
                        PayStartDate: id,
                        Semester: effortEarnings.find(e => e.PayStartDate === id).Semester
                    }
                })
        }
        return result;
    }
    const getPayPeriods = () => {
        let filteredPayPeriods = null;
        if (payPeriods && payPeriods.length > 0) {
            filteredPayPeriods = payPeriods.filter(pp =>
                moment(pp.StartDate) < moment(rpprPeriodEndDate) &&
                moment(pp.StartDate) >= moment(rpprPeriodStartDate)
            );
        }
        return filteredPayPeriods;
    }
    const getEmployeeEarnings = () => {
        let employeeEarnings = [];
        if (props.employeeEarnings) {
            employeeEarnings = Array.from(new Set(props.employeeEarnings.map(e => e.EmplId.trim())))
                .map(id => {
                    let employeeEarning = props.employeeEarnings.find(e => e.EmplId.trim() === id);
                    return {
                        EmplId: id,
                        ...employeeEarning
                    }
                });
        }
        return employeeEarnings;
    }
    const getEmployeeActualHours = (earn) => {
        let calHours = 0;
        let acaHours = 0;
        let sumHours = 0;

        let theseEarnings = effortEarnings.filter(ee =>
            ee.EmplId.trim() === earn.EmplId.trim() &&
            moment(ee.PayEndDate) < moment(rpprPeriodEndDate) &&
            moment(ee.PayStartDate) >= moment(rpprPeriodStartDate)
        );
        if (theseEarnings.length > 0) {
            theseEarnings.forEach(ee => {
                calHours += ee.StdHours * 2 * (ee.GrantEffort + ee.OtherAccounts === 0 ? 0 : (ee.GrantEffort / (ee.GrantEffort + ee.OtherAccounts)));
                if (ee.Semester.startsWith('Sum')) {
                    sumHours += ee.StdHours * 2 * (ee.GrantEffort + ee.OtherAccounts === 0 ? 0 : (ee.GrantEffort / (ee.GrantEffort + ee.OtherAccounts)));
                }
                else {
                    acaHours += ee.StdHours * 2 * (ee.GrantEffort + ee.OtherAccounts === 0 ? 0 : (ee.GrantEffort / (ee.GrantEffort + ee.OtherAccounts)));
                }
            });
        }
        //calHours += thisStdHours * 2 * (thisGrantEffort + thisOtherAccounts === 0 ? 0 : (thisGrantEffort / (thisGrantEffort + thisOtherAccounts)));
        //return <td className="text-end"><NumericFormat value={(thisGrantEffort != null && thisOtherAccounts != null ? ((thisGrantEffort + thisOtherAccounts === 0 ? 0 : thisGrantEffort / (thisGrantEffort + thisOtherAccounts)) * 100) : null)} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," /></td>

        if (getPayPeriods() && includeForecast) {
            getPayPeriods().forEach((pp, i) => {
                let thisEF = getEmployeeFundingPercent(earn, pp);
                if (thisEF && thisEF.Value != null && thisEF.DisplayInput) {
                    calHours += thisEF.Value / 100 * (earn.Title && earn.Title.includes('grad') ? 40 : 80);
                    if (pp.AcadPeriod.startsWith('Sum')) {
                        sumHours += thisEF.Value / 100 * (earn.Title && earn.Title.includes('grad') ? 40 : 80);
                    }
                    else {
                        acaHours += thisEF.Value / 100 * (earn.Title && earn.Title.includes('grad') ? 40 : 80);
                    }
                }
            });
        }
        return { CalHours: calHours, AcaHours : acaHours, SumHours: sumHours };
    }
    const getEmployeeFundingPercent = (employeeEarning, employeeSemester) => {

        let value = null;
        let displayInput = true;
        let isUpdated = null;
        let thisEF = null;
        let thisPayGroup = employeeEarning.PayGroup;
        if (!thisPayGroup && employeeEarning.EmplStatusPayGroup)
            thisPayGroup = employeeEarning.EmplStatusPayGroup.split(' ')[1]

        if (props.employeeFunding) {
            thisEF = props.employeeFunding.find((ef) =>
                ef.StartDate === employeeSemester.StartDate &&
                ef.EmplId.trim() === employeeEarning.EmplId.trim()
            );
            if (thisPayGroup === 'FSW' && !employeeSemester.AcadPeriod.startsWith('Sum')) {
                value = null;
                displayInput = false;
            }
            else {
                if (thisPayGroup === 'ACD' && employeeSemester.AcadPeriod.startsWith('Sum')) {
                    value = null;
                    displayInput = false;
                }
                else {
                    if (thisEF) {
                        if (thisEF.EmplStatus === 'W') {
                            //if (thisPayGroup === 'FSW') {
                            //    value = Number(thisEF.FundingPercent) * 100;
                            //    displayInput = true;
                            //}
                            //if (thisPayGroup === 'GRD') {
                            //    value = Number(thisEF.FundingPercent) * 100;
                            //    displayInput = true;
                            //}
                            //    If rst!paygroup = "FSW" And Worksheets("Parameters").Cells(5, 8) = "Yes" Then
                            //        xls.Cells(irow, icol) = rst!fundingPercent
                            //    End If
                            //    If rst!paygroup = "GRD" And Worksheets("Parameters").Cells(7, 8) = "Yes" Then
                            //        xls.Cells(irow, icol) = rst!fundingPercent
                            //    End If
                            //assume that the options from the parameters (ForecastYourOptions) is the default which is 'No'
                            //value = Number(thisEF.FundingPercent) * 100;
                            displayInput = true;
                        }
                        else {
                            value = Number(thisEF.FundingPercent) * 100;
                            displayInput = true;
                        }
                    }
                }
            }
            if (thisEF && thisEF.IsForecast) {
                value = Number(thisEF.FundingPercent) * 100;
                displayInput = true;
                isUpdated = true;
            }

        }

        return { Value: value, DisplayInput: displayInput, IsUpdated: isUpdated }

    };

    const handleStartDateChange = (event) => {
        var d = moment(event.target.value);
        if (d.isValid()) {
            setRpprPeriodStartDate(event.target.value);
        }
    }
    const handleStartDateBlur = (event) => {
        var d = moment(event.target.value);
        if (d.isValid()) {
            //if (moment(d) < moment(effortEarnings && effortEarnings.length > 0 ? effortEarnings[0].PayStartDate : props.accountStartDate)) {
            if (moment(d) < moment(props.accountStartDate)) {
                //set it to the max date, or the current date set
                //setRpprPeriodStartDate(effortEarnings && effortEarnings.length > 0 ? effortEarnings[0].PayStartDate : props.accountStartDate);
                setRpprPeriodStartDate(props.accountStartDate);
            }
            else if (moment(d) >= moment(rpprPeriodEndDate)) {
                setRpprPeriodStartDate(effortEarnings && effortEarnings.length > 0 ? effortEarnings[0].PayStartDate : props.accountStartDate);
            }
            else {
                //setRpprPeriodEndDate(props.accountEndDate)
            }
            //getAccountPeriods(event.target.value);
        }
    }
    const handleEndDateChange = (event) => {
        var d = moment(event.target.value);
        if (d.isValid()) {
            setRpprPeriodEndDate(event.target.value);
        }
    }
    const handleEndDateBlur = (event) => {
        var d = moment(event.target.value);
        if (d.isValid()) {
            if (moment(d) > moment(props.accountEndDate)) {
                //set it to the max date, or the current date set
                setRpprPeriodEndDate(props.accountEndDate);
            }
            else if (moment(d) <= moment(rpprPeriodStartDate)) {
                setRpprPeriodEndDate(props.accountEndDate);
            }
            else {
                //setRpprPeriodEndDate(props.accountEndDate)
            }
            //getAccountPeriods(event.target.value);
        }
    }

    return (
        <>
            <button type="submit" className="btn btn-secondary btn-sm" onClick={openModalEarnings} disabled={showLoading} >
                <FontAwesomeIcon icon={showLoading ? faSpinner : faUsersBetweenLines} spin={showLoading} className="me-2" />
                Effort
            </button>
            <Modal isOpen={modalEarnings} toggle={modalEarningsToggle} size="xxl">
                <ModalHeader toggle={modalEarningsToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faUsersBetweenLines} className="pe-2" />Effort by Pay Period and Semester</h5>
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody style={{
                    
                }}>
                    <div className="row">
                        <div className="row">
                            <div className="col-sm-1 offset-sm-5 fw-bold">
                                Obligated Period
                            </div>
                            <div className="col-sm-1 fw-bold">
                                RPPR Period
                            </div>
                            <div className="col-sm-1 fw-bold offset-sm-3 text-end align-self-end" >
                                {earningsHere != null && effortITD != null && earningsHere && earningsHere.toFixed(2) === effortITD.toFixed(2) ?
                                    <FontAwesomeIcon icon={faCircleCheck} className="text-success" />
                                    :
                                    <FontAwesomeIcon icon={faCircleXmark} className="text-danger" />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-sm-1 col-form-label col-form-label-sm fw-bold">
                                Award:
                            </label>
                            <label className="col-sm-2 col-form-label col-form-label-sm">
                                {effortDates ? effortDates.AwardId : ''}
                            </label>
                            <label className="col-sm-2 col-form-label col-form-label-sm fw-bold text-end">Start Date:</label>
                            <label className="col-sm-1 col-form-label col-form-label-sm fw-bold text-end">{moment(props.accountStartDate ? props.accountStartDate : effortDates.StartDate).format('M/D/yyyy')}</label>
                            <div className="col-sm-2">
                                <input type="date" required className="form-control form-control-sm fw-bold bg-info-subtle" id="txtEndDate" value={moment(rpprPeriodStartDate).format('yyyy-MM-DD')} onChange={handleStartDateChange} onBlur={handleStartDateBlur} name="EndDate" />
                            </div>
                            {/*<label className="col-sm-1 col-form-label col-form-label-sm fw-bold text-end">{moment(rpprPeriodStartDate ?? (props.accountStartDate ? props.accountStartDate : effortDates.StartDate)).format('M/D/yyyy')}</label>*/}
                            <label className="col-sm-2 col-form-label col-form-label-sm fw-bold text-end">Account earnings ITD:</label>
                            <div className="col-sm-1 col-form-label col-form-label-sm text-end">
                                <NumericFormat value={effortITD ? effortITD : 0} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-1 col-form-label col-form-label-sm fw-bold offset-sm-0">
                                <RPPR {...props} startDate={rpprPeriodStartDate} endDate={rpprPeriodEndDate} employeeEarnings={props.employeeEarnings} getEmployeeActualHours={getEmployeeActualHours} />
                            </div>
                            <div className="col-sm-2 d-flex align-items-center">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={includeForecast} onChange={toggleIncludeForecast} />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Include Forecast</label>
                                </div>
                            </div>
                            <label className="col-sm-2 col-form-label col-form-label-sm fw-bold text-end">End Date:</label>
                            <label className="col-sm-1 col-form-label col-form-label-sm fw-bold text-end">{moment(props.accountEndDate ? props.accountEndDate : effortDates.ObligatedEndDate).format('M/D/yyyy')}</label>
                            <div className="col-sm-2">
                                <input type="date" required className="form-control form-control-sm fw-bold bg-info-subtle" id="txtEndDate" value={moment(rpprPeriodEndDate).format('yyyy-MM-DD')} onChange={handleEndDateChange} onBlur={handleEndDateBlur} name="EndDate" />
                            </div>
                            {/*<label className="col-sm-1 col-form-label col-form-label-sm fw-bold text-end">{moment(props.accountEndDate ? props.accountEndDate : effortDates.ObligatedEndDate).format('M/D/yyyy')}</label>*/}
                            <label htmlFor="txtTimePeriod" className="col-sm-2 col-form-label col-form-label-sm fw-bold text-end">Earnings listed here:</label>
                            <div className="col-sm-1 col-form-label col-form-label-sm text-end">
                                <NumericFormat value={earningsHere ? earningsHere : 0} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar", "maxHeight": "calc(100vh - 350px)"
                    }}>
                        <table className="table table-sm table-xs table-xxs table-hover" style={{ display: "table" }}>
                            <thead className="sticky-top" >
                                <tr>
                                    <th colSpan={8 + getEffortEarningsPayPeriods().length}>&nbsp;</th>
                                    {getPayPeriods() && getPayPeriods().length > 0 && includeForecast ?
                                        <th className={`table-light nowrap border-start`} colSpan={getPayPeriods().length}>Forecast</th>
                                    : null}
                                </tr>
                                <tr>
                                    <th colSpan="8">&nbsp;</th>
                                    {getEffortEarningsPayPeriods().map((pp, i) =>
                                        <th key={i} className="table-light nowrap">{pp.Semester}</th>
                                    )}
                                    {getPayPeriods() && includeForecast ? getPayPeriods().map((pp, i) =>
                                        <th key={i} className={`table-light nowrap ${i === 0 ? 'border-start' : ''}`}>{pp.AcadPeriod}</th>
                                    ) : null}
                                </tr>
                                <tr>
                                    <th className="table-light nowrap">EmplId</th>
                                    <th className="table-light nowrap">Employee</th>
                                    <th className="table-light nowrap">Title</th>
                                    <th className="table-light nowrap">Grant Role</th>
                                    <th className="table-light nowrap">Type</th>
                                    <th className="table-light nowrap">Actual Hours</th>
                                    <th className="table-light nowrap">Budgeted Months</th>
                                    <th className="table-light nowrap">&nbsp;</th>
                                    {getEffortEarningsPayPeriods().map((pp, i) =>
                                        <th key={i} className="table-light nowrap text-end" key={pp.PayStartDate}>{moment(pp.PayStartDate).format('M/D/yyyy')}</th>
                                    )}
                                    {getPayPeriods() && includeForecast ? getPayPeriods().map((pp,i) =>
                                        <th key={i} className={`table-light nowrap ${i === 0 ? 'border-start' : ''}`}>{moment(pp.StartDate).format('M/D/yyyy')}</th>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {props.employeeEarnings ? getEmployeeEarnings().map((earn, i) => {
                                    let thisEffEarn = effortEarnings ? effortEarnings.find(ee => ee.EmplId === earn.EmplId) : null;
                                    let thisActualHours = getEmployeeActualHours(earn);
                                    let thesePayPeriods = getPayPeriods();
                                    return <Fragment key={i}>
                                        <tr className="table-group-divider">
                                            <td className="nowrap">{earn.EmplId}</td>
                                            <td className="nowrap">{earn.Employee}</td>
                                            <td className="nowrap">{earn.Title}</td>
                                            <td className="nowrap">{earn.Role ?? earn.StaffType}</td>
                                            <td className="nowrap">{thisEffEarn ? thisEffEarn.ApptType : earn.ApptType}</td>
                                            <td className="nowrap text-end">{earn.FiscalYear}</td>
                                            <td className="nowrap"></td>
                                            <td className="nowrap">Account Earnings</td>
                                            {getEffortEarningsPayPeriods().map((pp, i) => {
                                                let theseEarnings = effortEarnings.filter(ee => ee.EmplId.trim() === earn.EmplId.trim() && ee.PayStartDate === pp.PayStartDate);
                                                let thisValue = null;
                                                if (theseEarnings.length > 0) {
                                                    thisValue = 0;
                                                    theseEarnings.forEach(ee => thisValue += ee.GrantEffort);
                                                }
                                                return <td key={pp.PayStartdate + i} className="text-end"><NumericFormat value={thisValue} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                            })}
                                            {/*    {effortEarnings ? effortEarnings.filter(ee => ee.EmplId.trim() === earn.EmplId.trim()).length > 0 ? effortEarnings.filter(ee => ee.EmplId.trim() === earn.EmplId.trim()).map(ee =>*/}
                                            {/*        <td className="text-end"><NumericFormat value={ee.GrantEffort} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>*/}
                                            {/*    ) : <td colSpan={getEffortEarningsPayPeriods().length}></td> : null}*/}
                                            {thesePayPeriods && includeForecast ? thesePayPeriods.map((pp, i) => {
                                                //let thisEF = props.employeeFunding.find(ef => ef.EmplId.trim() === earn.EmplId.trim() && moment(ef.StartDate).isSame(pp.StartDate));
                                                let thisEF = getEmployeeFundingPercent(earn, pp);
                                                return <td key={'fc-' + pp.PayStartdate + i} className={`text-end ${i === 0 ? 'border-start' : ''}`}>{thisEF && thisEF.Value != null ? <NumericFormat value={thisEF.Value / 100 * (pp.AcadPeriod.startsWith('Sum') ? earn.SummerPayRate : earn.AYPayRate)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /> : null}</td>
                                            }
                                            ) : null}
                                        </tr>
                                        <tr>
                                            <td colSpan="4"></td>
                                            <td>Cal:</td>
                                            <td className="text-end">
                                                <NumericFormat value={thisActualHours.CalHours} displayType="text" decimalScale={1} fixedDecimalScale thousandSeparator="," />
                                            </td>
                                            <td className="text-end">{earn.CalendarMonths}</td>
                                            <td>Other Earnings:</td>
                                            {getEffortEarningsPayPeriods().map((pp, i) => {
                                                let theseEarnings = effortEarnings.filter(ee => ee.EmplId.trim() === earn.EmplId.trim() && ee.PayStartDate === pp.PayStartDate);
                                                let thisValue = null;
                                                if (theseEarnings.length > 0) {
                                                    thisValue = 0;
                                                    theseEarnings.forEach(ee => thisValue += ee.OtherAccounts);
                                                }
                                                return <td key={i} className="text-end"><NumericFormat value={thisValue} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                            })}
                                            {/*{effortEarnings ? effortEarnings.filter(ee => ee.EmplId.trim() === earn.EmplId.trim()).length > 0 ? effortEarnings.filter(ee => ee.EmplId.trim() === earn.EmplId.trim()).map(ee =>*/}
                                            {/*    <td className="text-end"><NumericFormat value={ee.OtherAccounts} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>*/}
                                            {/*) : <td colSpan={getEffortEarningsPayPeriods().length}></td> : null}*/}
                                            {thesePayPeriods && includeForecast ? thesePayPeriods.map((pp, i) => {
                                                let thisEF = getEmployeeFundingPercent(earn, pp);
                                                return <td key={i} className={`text-end ${i === 0 ? 'border-start' : ''}`}>{thisEF && thisEF.Value != null ? <NumericFormat value={(pp.AcadPeriod.startsWith('Sum') ? earn.SummerPayRate : earn.AYPayRate) * (100 - thisEF.Value) / 100} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /> : null}</td>
                                            }) : null}
                                        </tr>
                                        <tr>
                                            <td colSpan="4"></td>
                                            <td>Aca:</td>
                                            <td className="text-end">
                                                <NumericFormat value={thisActualHours.AcaHours} displayType="text" decimalScale={1} fixedDecimalScale thousandSeparator="," />
                                            </td>
                                            <td className="text-end">{earn.AcademicMonths}</td>
                                            <td>Percent:</td>
                                            {getEffortEarningsPayPeriods().map((pp, i) => {
                                                let theseEarnings = effortEarnings.filter(ee => ee.EmplId.trim() === earn.EmplId.trim() && ee.PayStartDate === pp.PayStartDate);
                                                let thisGrantEffort = null;
                                                let thisOtherAccounts = null;
                                                if (theseEarnings.length > 0) {
                                                    thisGrantEffort = 0;
                                                    thisOtherAccounts = 0;
                                                    theseEarnings.forEach(ee => {
                                                        thisGrantEffort += ee.GrantEffort;
                                                        thisOtherAccounts += ee.OtherAccounts;
                                                    });
                                                }
                                                return <td key={i} className="text-end"><NumericFormat value={(thisGrantEffort != null && thisOtherAccounts != null ? ((thisGrantEffort + thisOtherAccounts === 0 ? 0 : thisGrantEffort / (thisGrantEffort + thisOtherAccounts)) * 100) : null)} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," /></td>
                                            })}
                                            {thesePayPeriods && includeForecast ? thesePayPeriods.map((pp, i) => {
                                                //let thisEF = props.employeeFunding.find(ef => ef.EmplId.trim() === earn.EmplId.trim() && moment(ef.StartDate).isSame(pp.StartDate));
                                                let thisEF = getEmployeeFundingPercent(earn, pp);
                                                return <td key={i} className={`text-end ${i === 0 ? 'border-start' : ''}`}><NumericFormat value={thisEF && thisEF.Value != null && thisEF.DisplayInput ? thisEF.Value : null} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," /></td>
                                            }) : null}
                                        </tr>
                                        <tr>
                                            <td colSpan="4"></td>
                                            <td>Sum:</td>
                                            <td className="text-end">
                                                <NumericFormat value={thisActualHours.SumHours} displayType="text" decimalScale={1} fixedDecimalScale thousandSeparator="," />
                                            </td>
                                            <td className="text-end">{earn.SummerMonths}</td>
                                            <td>Std hours:</td>
                                            {getEffortEarningsPayPeriods().map((pp, i) => {
                                                let theseEarnings = effortEarnings.filter(ee => ee.EmplId.trim() === earn.EmplId.trim() && ee.PayStartDate === pp.PayStartDate);
                                                let thisValue = null;
                                                if (theseEarnings.length > 0) {
                                                    thisValue = 0;
                                                    theseEarnings.forEach(ee => thisValue += ee.StdHours);
                                                }
                                                return <td key={i} className="text-end">{thisValue === null ? null : thisValue * 2}</td>
                                            })}
                                            {thesePayPeriods && includeForecast ? thesePayPeriods.map((pp, i) =>
                                                <td key={i} className={`text-end ${i === 0 ? 'border-start' : ''}`}>{earn.Title && earn.Title.includes('grad') ? 40 : 80}</td>
                                            ) : null}
                                        </tr>
                                    </Fragment>
                                }
                                ) : null
                                }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalEarningsToggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Effort;