import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

function SubMenu() {
    return (
        <div className="container-xl px-2 pb-3 mb-4">
            <nav className="nav nav-borders" >
                <nav className="nav nav-borders" >
                    {/*<NavLink to="/reports" className="nav-link" end>Reports</NavLink>*/}
                    {/*<NavLink to="/reports" className="nav-link">Account Summary</NavLink>*/}
                    {/*<NavLink to="/reports/employees" className="nav-link">Employees</NavLink>*/}
                    {/*<NavLink to="/reports/expenses" className="nav-link">Expenses</NavLink>*/}
                    {/*<Link to="/accounts/find" className="nav-link">My Portfolio</Link>*/}
                </nav>
            </nav>
        </div>
    );
}

export default SubMenu;