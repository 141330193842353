import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, Link, Route, Routes, useParams } from 'react-router-dom';
import { GetAuthUsername } from "../AuthUsername";

import Header from "./Header";
import ReportSubMenu from "./ReportSubMenu";
//import Find from "./Find";

import { NumericFormat } from 'react-number-format';

import { Alert, Button } from 'reactstrap';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faCopy, faRectangleXmark, faCircleXmark, faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faAnglesLeft, faAnglesRight, faUsers, faXmark, faMagnifyingGlass, faSpinner, faFileInvoice, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Report.css';
import AccountDetailReport from './AccountDetailReport';
import { networkDays, isNumeric, isNumericDefault, isNullOrEmpty } from '../../utilities';
import useAuthRoles from "../useAuthRoles";


export default function Employees(props) {
    const { guid } = useParams();
    const authUsername = GetAuthUsername();
    const { hasRole } = useAuthRoles();
    const { version } = props;
    const [reportEmployeeFundings, setReportEmployeeFundings] = useState([]);
    const [distinctEmployees, setDistinctEmployees] = useState([]);
    const [employeeSemesters, setEmployeeSemesters] = useState([]);
    const [semesterSummary, setSemesterSummary] = useState([]);

    const [collapseFutureFunding, setCollapseFutureFunding] = useState(true);

    const [ledgerAccountDirectory, setLedgerAccountDirectory] = useState(null);

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getReportData();



        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    }, []);

    const getReportData = async () => {
        if (version) {
            if (!hasRole('RA')) {
                const acResponse = await fetch('/api/report/version/' + encodeURIComponent(guid) + '/accesscheck?user=' + encodeURIComponent(authUsername));
                const acData = await acResponse.json();
                if (!acData) {
                    navigate('/noaccess');
                    return;
                }
            }
            getEmployeeSummaryVersionData();
        }
        else {
            if (!hasRole('RA')) {
                const acResponse = await fetch('/api/report/' + encodeURIComponent(guid) + '/accesscheck?user=' + encodeURIComponent(authUsername));
                const acData = await acResponse.json();
                if (!acData) {
                    navigate('/noaccess');
                    return;
                }
            }
            getEmployeeSummaryData();
        }
    };

    const getReportVersionDataFromSession = () => {
        if (sessionStorage.getItem('ReportVersionData')) {
            const sessionObj = JSON.parse(sessionStorage.getItem('ReportVersionData'));
            //check if the session object's GUID matches the current GUID

            if (sessionObj.Guid === guid) {
                //We're good to keep using this object
                return sessionObj;
            }
            else {
                sessionStorage.removeItem('ReportVersionData');
                return null;
            }
        }
        return null;
    };


    const getEmployeeSummaryVersionData = async () => {

        const responseLA = await fetch('/api/lookup/ledgeraccounts');
        const dataLA = await responseLA.json();
        setLedgerAccountDirectory(dataLA);

        let sessionObj = await getReportVersionDataFromSession();
        if (!sessionObj) {
            //Download the report data and set it in session
            const response = await fetch('/api/report/version/' + encodeURIComponent(guid) + '/data');
            const data = await response.json();
            let dataObj = JSON.parse(data);
            dataObj.Guid = guid;

            sessionObj = dataObj;

            try {
                sessionStorage.setItem('ReportVersionData', JSON.stringify(dataObj, (key, value) => typeof value === 'undefined' ? null : value));
            }
            catch (ex) {
                console.log(ex);
                //sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: true, savedSearch: row, searchCriteria: { AccountStatus: 'Active' }, results: [] }, (key, value) => typeof value === 'undefined' ? null : value));
            }
        }

        if (sessionObj) {

            const dataEF = sessionObj.EmployeeFundings;
            setReportEmployeeFundings(dataEF);

            if (dataEF.length > 0) {
                let dates = dataEF.map(ef => { return new Date(ef.StartDate) });
                let maxDate = new Date(Math.max.apply(null, dates));

                //Now get the pay calendar up to this max date
                let empSem = await getEmployeeSemesters(maxDate);
                let sumSem = generateSemesterSummary(empSem);
                setSemesterSummary(sumSem);
            }

            const distinctEmployees = await getDistinctEmployees(dataEF);

            setDistinctEmployees(distinctEmployees);
        }

        setLoading(false);

    };
    const getEmployeeSummaryData = async () => {
        const responseLA = await fetch('/api/lookup/ledgeraccounts');
        const dataLA = await responseLA.json();
        setLedgerAccountDirectory(dataLA);

        const responseEF = await fetch('/api/report/' + encodeURIComponent(guid) + '/employeefundings');
        const dataEF = await responseEF.json();
        setReportEmployeeFundings(dataEF);

        if (dataEF.length > 0) {
            let dates = dataEF.map(ef => { return new Date(ef.StartDate) });
            let maxDate = new Date(Math.max.apply(null, dates));

            //Now get the pay calendar up to this max date
            let empSem = await getEmployeeSemesters(maxDate);
            let sumSem = generateSemesterSummary(empSem);
            setSemesterSummary(sumSem);
        }

        const distinctEmployees = await getDistinctEmployees(dataEF);


        setDistinctEmployees(distinctEmployees);
        setLoading(false);

    };
    const getEmployeeSemesters = async (endDate) => {
        const response = await fetch('/api/report/employeepayperiods?endDate=' + (moment(endDate).isValid() ? encodeURIComponent(moment(endDate).format("M/D/yyyy")) : ''));
        const data = await response.json();
        setEmployeeSemesters(data);
        return data;
    };

    const getDistinctEmployees = async (data) => {
        let distinctEmployees = [];
        if (data) {
            data.forEach(ef => {
                if (!distinctEmployees.find(x => x.EmplId.trim() === ef.EmplId.trim() && x.Account === ef.Account)) {
                    distinctEmployees.push({
                        ...ef
                    })
                }
            })
        }
        return distinctEmployees;
    };

    const getEmployeeFunding = (employee, payPeriod) => {
        let employeeFunding = null;
        if (reportEmployeeFundings && reportEmployeeFundings.length > 0) {
            employeeFunding = reportEmployeeFundings.find(ef =>
                ef.EmplId.trim() === employee.EmplId.trim() &&
                ef.Position === employee.Position &&
                ef.EmplRcd === employee.EmplRcd &&
                ef.Account === employee.Account &&
                ef.StartDate === payPeriod.StartDate);
        }
        return employeeFunding;
    };
    const getEmployeeSemesterSummary = (employee, semesterSummary) => {
        let sum = 0.0;
        let count = 0;
        if (employeeSemesters && employeeSemesters.length > 0) {
            let theseSemesters = employeeSemesters.filter(es =>
                (es.AcadPeriod.startsWith("Sum") ? "Summer" : es.AcadPeriod) === semesterSummary.Semester &&
                (moment(es.EndDate).year()) === semesterSummary.YearNumber
            );
            theseSemesters.forEach(s => {
                count += 1;
            })
        }
        if (reportEmployeeFundings && reportEmployeeFundings.length > 0) {
            reportEmployeeFundings.filter(ef =>
                ef.EmplId.trim() === employee.EmplId.trim() &&
                ef.Position === employee.Position &&
                ef.EmplRcd === employee.EmplRcd &&
                ef.Account === employee.Account &&
                (ef.Semester.startsWith("Sum") ? "Summer" : ef.Semester) === semesterSummary.Semester &&
                (moment(ef.EndDate).year()) === semesterSummary.YearNumber
            ).forEach(f => {
                sum += f.FundingPercent;
            });
        }
        return count === 0 ? 0 : (sum / count) * 100;
    };

    const generateSemesterSummary = (data) => {
        let semSum = [];
        if (data && data.length > 0) {
            data.forEach(s => {
                let thisSemIX = semSum.findIndex(ss => ss.Semester === (s.AcadPeriod.startsWith("Sum") ? "Summer" : s.AcadPeriod) && ss.YearNumber === s.YearNumber);
                if (thisSemIX < 0) {
                    semSum.push({ ...s, Semester: (s.AcadPeriod.startsWith("Sum") ? "Summer" : s.AcadPeriod), SemesterWorkDays: s.Workdays });
                }
                else {
                    semSum[thisSemIX].SemesterWorkDays += s.Workdays;
                }
            });
        }
        return semSum;
    };
    const getAccountData = (account) => {
        if (version) {
            let data = getReportVersionDataFromSession();
            if (data) {
                let accountData = data.AccountData.find(a => a.Account === account);
                return accountData;
            }
            return null;
        }
        else {
            return null;
        }
    };

    return (
        <>
            <Header />
            <ReportSubMenu guid={guid} {...props} />
            <div>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <span><FontAwesomeIcon icon={faUsers} className="pe-1" />Employees</span>
                        <div className="col-auto">
                            {loading ?
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin className="me-1" />
                                    Loading... 
                                </>
                                :
                                null}
                        </div>
                    </div>
                    <div className="table-responsive">
                        {loading ?
                            <div className="text-center py-5 display-1">
                                <FontAwesomeIcon icon={faSpinner} spin className="" />
                            </div>
                            :
                            <table className="table table-sm table-xs table-hover">
                                <thead>
                                    <tr>
                                        <th rowSpan="3" colSpan="14">&nbsp;</th>
                                        <td></td>
                                        {!collapseFutureFunding && employeeSemesters && employeeSemesters.length > 0
                                            ?
                                            <th colSpan={employeeSemesters.length}><a href="/" title="Collapse Future Funding" onClick={(e) => { e.preventDefault(); setCollapseFutureFunding(true); }}><FontAwesomeIcon icon={faAnglesLeft} className="pe-1" /></a>Future Funding</th>
                                            :
                                            <th className="border-start border-end"><a href="/" title="Expand Future Funding" onClick={(e) => { e.preventDefault(); setCollapseFutureFunding(false); }}><FontAwesomeIcon icon={faAnglesRight} className="pe-1" /></a></th>
                                        }
                                        <th>&nbsp;</th>
                                        {semesterSummary && semesterSummary.length > 0
                                            ?
                                            <th colSpan={semesterSummary.length}>Forecast Summary</th>
                                            :
                                            null
                                        }
                                    </tr>
                                    <tr>
                                        <th className="table-light nowrap text-end">Semester:</th>
                                        {!collapseFutureFunding && employeeSemesters && employeeSemesters.length > 0
                                            ?
                                            employeeSemesters.map(es =>
                                                <th className={`table-light nowrap text-end ${es.AcadPeriod === 'Fall' ? 'bg-warning' : es.AcadPeriod === 'Spring' ? 'bg-success-subtle' : 'bg-danger-subtle'}`} key={es.StartDate + 'PayPeriod'}>{es.AcadPeriod.startsWith("Sum") ? "Summer" : es.AcadPeriod}</th>
                                            )
                                            :
                                            <th className="table-light nowrap text-end border-start border-end">&nbsp;</th>
                                        }
                                        <th className="table-light nowrap text-end">&nbsp;</th>
                                        {semesterSummary && semesterSummary.length > 0
                                            ?
                                            semesterSummary.map((ss, i) =>
                                                <th className="table-light text-end" key={ss.Semester + i}>{ss.Semester}</th>
                                            )
                                            :
                                            null
                                        }
                                    </tr>
                                    <tr>
                                        <th className="table-light nowrap text-end">Workdays:</th>
                                        {!collapseFutureFunding && employeeSemesters && employeeSemesters.length > 0
                                            ?
                                            employeeSemesters.map((es, i) =>
                                                <th className="table-light nowrap text-end" key={i}>{es.Workdays}</th>
                                            )
                                            :
                                            <th className="table-light nowrap text-end border-start border-end">&nbsp;</th>
                                        }
                                        <th className="table-light nowrap text-end">&nbsp;</th>
                                        {semesterSummary && semesterSummary.length > 0
                                            ?
                                            semesterSummary.map((ss, i) =>
                                                <th className="table-light text-end" key={i}>{ss.SemesterWorkDays}</th>
                                            )
                                            :
                                            null
                                        }
                                    </tr>
                                    <tr>
                                        <th className="table-light nowrap">#</th>
                                        <th className="table-light nowrap text-center">Employee</th>
                                        <th className="table-light nowrap text-center">EmplId</th>
                                        <th className="table-light nowrap text-center">Position</th>
                                        <th className="table-light nowrap text-center">Rcd</th>
                                        <th className="table-light nowrap text-center">Title</th>
                                        <th className="table-light nowrap text-center">AY Pay Rate</th>
                                        <th className="table-light nowrap text-center">Sum Pay Rate</th>
                                        <th className="table-light nowrap text-center">Staff Type</th>
                                        <th className="table-light nowrap text-center">Account</th>
                                        <th className="table-light nowrap text-center">Account Nick Name</th>
                                        <th className="table-light nowrap text-center">Account Name</th>
                                        <th className="table-light nowrap text-end">Account End Date</th>
                                        <th className="table-light nowrap">PI</th>
                                        <th className="table-light nowrap text-end">Start Date:</th>
                                        {!collapseFutureFunding && employeeSemesters && employeeSemesters.length > 0
                                            ?
                                            employeeSemesters.map((es, i) =>
                                                <th className="table-light nowrap text-end" key={i}>{moment(es.StartDate).format("M/D/YY")}</th>
                                            )
                                            :
                                            <th className="table-light nowrap text-end border-start border-end">&nbsp;</th>
                                        }
                                        <th className="table-light nowrap text-end">&nbsp;</th>
                                        {semesterSummary && semesterSummary.length > 0
                                            ?
                                            semesterSummary.map((ss, i) =>
                                                <th className="table-light text-end" key={i}>{moment(ss.StartDate).format("M/D/YY")}</th>
                                            )
                                            :
                                            null
                                        }
                                    </tr>
                                </thead>
                                {/*border-bottom border-black border-2 border-top-0 border-start-0 border-end-0*/}
                                <tbody className="">
                                    {distinctEmployees && distinctEmployees.length > 0 ?
                                        distinctEmployees.map((ef, i) =>
                                            <tr key={ef.EmplId + i} className={`${i === 0 || (i > 0 && distinctEmployees.at(i - 1).EmplId.trim() !== ef.EmplId.trim()) ? 'table-group-divider' : ''}`}>
                                                {i === 0 || (i > 0 && distinctEmployees.at(i - 1).EmplId.trim() !== ef.EmplId.trim()) ?
                                                    <>
                                                        <td>{i + 1}</td>
                                                        <td className="nowrap">{ef.Employee}</td>
                                                        <td>{ef.EmplId}</td>
                                                        <td>{ef.Position}</td>
                                                        <td>{ef.EmplRcd}</td>
                                                        <td className="nowrap">{ef.Title}</td>
                                                        <td className="text-end">
                                                            <NumericFormat
                                                                value={ef.AYPayRate}
                                                                displayType="text"
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                thousandSeparator=","
                                                            />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat
                                                                value={ef.SummerPayRate}
                                                                displayType="text"
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                thousandSeparator=","
                                                            />
                                                        </td>
                                                        <td>{ef.StaffType}</td>
                                                    </>
                                                    :
                                                    <td colSpan="9"></td>
                                                }
                                                <td>
                                                    {ef.IsMissingAccount ?
                                                        <>{ef.Account}</>
                                                        :
                                                        <AccountDetailReport {...props} versionData={getAccountData(ef.Account)} account={ef.Account} ledgerAccountDirectory={ledgerAccountDirectory} showBasicLink />
                                                    }
                                                </td>
                                                <td className="nowrap">{ef.AccountNickName}</td>
                                                <td style={{ maxWidth: '300px' }}>
                                                    <div className="text-truncate" data-bs-toggle="tooltip" data-bs-title={ef.AccountName}>{ef.AccountName}</div>
                                                </td>
                                                <td className="text-end">{ef.AccountEndDate ? moment(ef.AccountEndDate).format('M/D/yyyy') : null}</td>
                                                <td>{ef.AccountPI}</td>
                                                <td>&nbsp;</td>
                                                {!collapseFutureFunding && employeeSemesters && employeeSemesters.length > 0
                                                    ?
                                                    employeeSemesters.map((es, i) => {
                                                        let employeeFunding = getEmployeeFunding(ef, es);
                                                        return <td key={i} className={`text-end ${employeeFunding && employeeFunding.FundingPercent > 0 && employeeFunding.WorkDays !== 10 ? 'bg-warning-subtle' : ''}`}>
                                                            {employeeFunding ?
                                                                <NumericFormat
                                                                    displayType={'text'}
                                                                    value={isNumericDefault(employeeFunding.FundingPercent * 100, '')}
                                                                    decimalScale={1}
                                                                    suffix={'%'}
                                                                    fixedDecimalScale
                                                                    thousandSeparator=","
                                                                    className="text-end"
                                                                    {...(employeeFunding.FundingPercent > 0 && employeeFunding.WorkDays !== 10 ? {
                                                                        "data-bs-toggle": "tooltip",
                                                                        "data-bs-html": "true",
                                                                        "data-bs-title": `${Number(isNumericDefault((10 / employeeFunding.WorkDays * employeeFunding.FundingPercent) * 100, 0)).toFixed(1)}% over ${employeeFunding.WorkDays} workdays.`
                                                                    } : {})}
                                                                /> : null}</td>
                                                    }
                                                    )
                                                    :
                                                    <td className="nowrap text-end border-start border-end">&nbsp;</td>
                                                }
                                                <td>&nbsp;</td>
                                                {semesterSummary && semesterSummary.length > 0
                                                    ?
                                                    semesterSummary.map((ss, i) => {
                                                        let semSummary = getEmployeeSemesterSummary(ef, ss);
                                                        return <td key={i} className="text-end">
                                                            <NumericFormat
                                                                displayType={'text'}
                                                                value={semSummary}
                                                                decimalScale={1}
                                                                suffix={'%'}
                                                                fixedDecimalScale
                                                                thousandSeparator=","
                                                                className="text-end"
                                                            /></td>
                                                    }
                                                    )
                                                    :
                                                    null
                                                }
                                            </tr>
                                        )
                                        : null}
                                    {/*{reportEmployeeFundings && reportEmployeeFundings.length > 0 ?*/}
                                    {/*    reportEmployeeFundings.map((ef, i) =>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>{i}</td>*/}
                                    {/*            <td>{ef.Employee}</td>*/}
                                    {/*            <td>{ef.EmplId}</td>*/}
                                    {/*            <td>{ef.Position}</td>*/}
                                    {/*            <td>{ef.EmplRcd}</td>*/}
                                    {/*            <td>{ef.Title}</td>*/}
                                    {/*            <td className="text-end">*/}
                                    {/*                <NumericFormat*/}
                                    {/*                    value={ef.AYPayRate}*/}
                                    {/*                    displayType="text"*/}
                                    {/*                    decimalScale={2}*/}
                                    {/*                    fixedDecimalScale*/}
                                    {/*                    thousandSeparator=","*/}
                                    {/*                />*/}
                                    {/*            </td>*/}
                                    {/*            <td className="text-end">*/}
                                    {/*                <NumericFormat*/}
                                    {/*                    value={ef.SummerPayRate}*/}
                                    {/*                    displayType="text"*/}
                                    {/*                    decimalScale={2}*/}
                                    {/*                    fixedDecimalScale*/}
                                    {/*                    thousandSeparator=","*/}
                                    {/*                />*/}
                                    {/*            </td>*/}
                                    {/*            <td>{ef.StaffType}</td>*/}
                                    {/*            <td>{ef.Account}</td>*/}
                                    {/*            <td>{ef.AccountNickName}</td>*/}
                                    {/*            <td>{ef.AccountName}</td>*/}
                                    {/*            <td>{ef.AccountEndDate}</td>*/}
                                    {/*            <td>{ef.AccountPI}</td>*/}
                                    {/*        </tr>*/}
                                    {/*    )*/}
                                    {/*    : null}*/}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </>
    );

}
