import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faBoltLightning, faSackDollar, faMagnifyingGlass, faSpinner, faFileInvoice, faUserPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { isNumeric, isNumericDefault, isNullOrEmpty } from '../../utilities';
import { networkDays } from '../../utilities';

export default function StartupSummary(props) {
    const { version, reportVersionData } = props;

    const [actualPayPeriods, setActualPayPeriods] = useState(null);
    const [startUpPayPeriods, setStartUpPayPeriods] = useState({ PI: null, GRA: null, PIAdj: null, GRAAdj: null });
    const [forecastedPayPeriods, setForecastedPayPeriods] = useState({ PI: 0, GRA: 0 });
    const [accountEmployeeFunding, setAccountEmployeeFunding] = useState(null);
    const [accountEmployeeEarnings, setAccountEmployeeEarnings] = useState(null);
    const [accountEmployeeSemesters, setAccountEmployeeSemesters] = useState(null);

    useEffect(() => {

        if (version) {
            getReportVersionData()
        }
        else {
            getInitialData();
        }

    }, [props.accountDetail, props.forecastedPayPeriods]);

    useEffect(() => {

        if (props.forecastedPayPeriods)
            setForecastedPayPeriods(props.forecastedPayPeriods);
        else {
            setForecastedPayPeriods(getForecastPayPeriods());
        }

    }, [accountEmployeeFunding, accountEmployeeEarnings, accountEmployeeSemesters]);

    const getReportVersionData = async () => {

        if (reportVersionData && reportVersionData.AccountData) {
            var thisAccount = reportVersionData.AccountData.find(x => x.Account === props.account);

            if (thisAccount) {
                setActualPayPeriods(thisAccount.Startup ?? null);
                setAccountEmployeeFunding(thisAccount.EmployeeFunding);
                setAccountEmployeeEarnings(thisAccount.EmployeeEarnings);
                setAccountEmployeeSemesters(thisAccount.EmployeeSemesters);

                let startUpObj = { PI: null, GRA: null, PIAdj: null, GRAAdj: null };
                //populate the edit object
                if (props.accountDetail) {
                    startUpObj.PI = props.accountDetail && isNumeric(props.accountDetail.ippPI) ? props.accountDetail.ippPI : null;
                    startUpObj.GRA = props.accountDetail && isNumeric(props.accountDetail.ippGRA) ? props.accountDetail.ippGRA : null;
                    startUpObj.PIAdj = props.accountDetail && isNumeric(props.accountDetail.ippPIActAdj) ? props.accountDetail.ippPIActAdj : null;
                    startUpObj.GRAAdj = props.accountDetail && isNumeric(props.accountDetail.ippGRAActAdj) ? props.accountDetail.ippGRAActAdj : null;
                }
                setStartUpPayPeriods(startUpObj);

            }
        }

    };
    const getInitialData = async () => {
        const appResponse = await fetch('/api/account/startupactualpayperiods?account=' + encodeURIComponent(props.account));
        const appData = await appResponse.json();

        getAccountPeriods(props.accountDetail ? props.accountDetail.EndDate : null);

        const responseEE = await fetch('/api/account/employeeearnings?account=' + encodeURIComponent(props.account));
        const dataEE = await responseEE.json();
        setAccountEmployeeEarnings(dataEE);

        const responseEF = await fetch('/api/account/employeefunding?account=' + encodeURIComponent(props.account));
        const efData = await responseEF.json();
        setAccountEmployeeFunding(efData);


        let startUpObj = { PI: null, GRA: null, PIAdj: null, GRAAdj: null };
        //populate the edit object
        if (props.accountDetail) {
            startUpObj.PI = props.accountDetail && isNumeric(props.accountDetail.ippPI) ? props.accountDetail.ippPI : null;
            startUpObj.GRA = props.accountDetail && isNumeric(props.accountDetail.ippGRA) ? props.accountDetail.ippGRA : null;
            startUpObj.PIAdj = props.accountDetail && isNumeric(props.accountDetail.ippPIActAdj) ? props.accountDetail.ippPIActAdj : null;
            startUpObj.GRAAdj = props.accountDetail && isNumeric(props.accountDetail.ippGRAActAdj) ? props.accountDetail.ippGRAActAdj : null;
        }
        //if (appData) {
        //    startUpObj.PIAdj = props.accountDetail && props.accountDetail.ippPIActAdj !== null ? props.accountDetail.ippPIActAdj : 0;
        //    startUpObj.GRAAdj = props.accountDetail && props.accountDetail.ippGRAActAdj !== null ? props.accountDetail.ippGRAActAdj : 0;
        //}
        setStartUpPayPeriods(startUpObj);
        setActualPayPeriods(appData);
    };
    const getAccountPeriods = async (endDate) => {
        const esData = await getEmployeeSemesters(endDate);
    };
    const getEmployeeSemesters = async (endDate) => {
        const response = await fetch('/api/account/employeesemesters?account=' + encodeURIComponent(props.account) + '&endDate=' + (moment(endDate).isValid() ? encodeURIComponent(endDate) : ''));
        const data = await response.json();
        //find the last payperiod and adjust the workdays accordingly...
        if (data && data.length > 0) {
            let payPeriod = data[data.length - 1];
            payPeriod.Workdays = networkDays(payPeriod.MinStartDate, endDate);
            data[data.length - 1] = payPeriod;
        }
        setAccountEmployeeSemesters(data);
        return data;
    };
    const getAvailablePIPayPeriods = () => {
        let value = 0;
        value = props.accountDetail ? props.accountDetail.ippPI : 0;
        value -= (actualPayPeriods ? actualPayPeriods.PIPayPeriods : 0) + (props.accountDetail ? props.accountDetail.ippPIActAdj : 0);
        value -= forecastedPayPeriods && forecastedPayPeriods.PI !== null ? forecastedPayPeriods.PI : 0;
        return value;
    };
    const getAvailableGRAPayPeriods = () => {
        let value = 0;
        value = props.accountDetail ? props.accountDetail.ippGRA : 0;
        value -= (actualPayPeriods ? actualPayPeriods.GRAPayPeriods : 0) + (props.accountDetail ? props.accountDetail.ippGRAActAdj : 0);
        value -= forecastedPayPeriods && forecastedPayPeriods.GRA !== null ? forecastedPayPeriods.GRA : 0;
        return value;
    };

    const getEmployeeFundingPercent = (employeeEarning, employeeSemester) => {

        let value = null;
        let displayInput = true;
        let isUpdated = null;
        let thisEF = null;
        let thisPayGroup = employeeEarning.PayGroup;
        if (!thisPayGroup && employeeEarning.EmplStatusPayGroup)
            thisPayGroup = employeeEarning.EmplStatusPayGroup.split(' ')[1]

        if (accountEmployeeFunding) {
            thisEF = accountEmployeeFunding.find((ef) =>
                ef.StartDate === employeeSemester.MinStartDate &&
                ef.EmplId.trim() === employeeEarning.EmplId.trim() &&
                ef.Position === employeeEarning.Position &&
                ef.EmplRcd === employeeEarning.EmplRcd
            );
            if (thisPayGroup === 'FSW' && !employeeSemester.AcadPeriod.startsWith('Sum')) {
                value = null;
                displayInput = false;
            }
            else {
                if (thisPayGroup === 'ACD' && employeeSemester.AcadPeriod.startsWith('Sum')) {
                    value = null;
                    displayInput = false;
                }
                else {
                    if (thisEF) {
                        if (thisEF.EmplStatus === 'W') {
                            displayInput = true;
                        }
                        else {
                            if (thisEF.FundingPercent !== null) {
                                value = Number(thisEF.FundingPercent) * 100;
                            }
                            else {
                                value = null;
                            }
                            displayInput = true;
                        }
                    }
                }
            }
            if (thisEF && thisEF.IsForecast) {
                if (isNumeric(thisEF.FundingPercent)) {
                    value = Number(thisEF.FundingPercent) * 100;
                }
                displayInput = true;
                isUpdated = true;
            }

        }

        return { Value: value, DisplayInput: displayInput, IsUpdated: isUpdated }

    };
    const getForecastPayPeriods = () => {
        let forecasts = { PI: 0, GRA: 0 };
        if (accountEmployeeFunding && accountEmployeeEarnings && accountEmployeeSemesters) {

            accountEmployeeEarnings.forEach(ee => {
                let thisFTE = ee.FTE != null ? ee.FTE : ee.EmplStatusPayGroup.toString().substr(6, 1);
                if (isNumeric(thisFTE)) {
                    thisFTE = Number(thisFTE);
                }
                else {
                    thisFTE = 1;
                }
                accountEmployeeSemesters.forEach(es => {
                    let empFundingPercent = getEmployeeFundingPercent(ee, es);
                    if (empFundingPercent && empFundingPercent.DisplayInput && empFundingPercent.Value) {
                        if (ee.StaffType === 'Faculty') {
                            forecasts.PI += empFundingPercent.Value / 100;
                        }
                        else if (ee.PayGroup === 'GRD') {
                            if (thisFTE === 1) {
                                forecasts.GRA += empFundingPercent.Value / 100;
                            }
                            else if (thisFTE >= 0.5) {
                                forecasts.GRA += empFundingPercent.Value / 100 * 0.5 * 2;
                            }
                            else if (thisFTE < 0.5) {
                                forecasts.GRA += empFundingPercent.Value / 100 * 0.25 * 2;
                            }
                        }
                    }

                });
            });

        }
        return forecasts;
    };


    return (
        <>
            {props.accountDetail && (isNumeric(props.accountDetail.ippPI) || isNumeric(props.accountDetail.ippGRA) || isNumeric(props.accountDetail.ippPIActAdj) || isNumeric(props.accountDetail.ippGRAActAdj)) ?
                <>
                    <tr>
                        <td colSpan="2">{props.account} Startup</td>
                        <td colSpan="4">
                            {/*<div className="col-3 p-3">*/}
                            <table className="table table-sm mb-0" style={{ fontSize: ".65rem" }}>
                                <thead>
                                    <tr>
                                        <th scope="col" className="col-6">
                                        </th>

                                        <th className="text-end nowrap">Startup PP:</th>
                                        <th className="text-end nowrap">Used PP:</th>
                                        <th className="text-end nowrap">Forecasted PP:</th>
                                        <th className="text-end nowrap">Available PP:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col" className="col-3">PI</th>
                                        <td className="text-end"><NumericFormat value={props.accountDetail ? isNumericDefault(props.accountDetail.ippPI, '') : ''} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="text-end"><NumericFormat value={(actualPayPeriods ? isNumericDefault(actualPayPeriods.PIPayPeriods, 0) : 0) + (props.accountDetail ? isNumericDefault(props.accountDetail.ippPIActAdj, 0) : 0)} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="text-end"><NumericFormat value={forecastedPayPeriods ? isNumericDefault(forecastedPayPeriods.PI, 0) : 0} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="text-end"><NumericFormat value={getAvailablePIPayPeriods()} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>

                                    </tr>
                                    <tr>
                                        <th scope="col" className="col-3 nowrap">GRA & GSA</th>
                                        <td className="text-end"><NumericFormat value={props.accountDetail ? isNumericDefault(props.accountDetail.ippGRA, '') : ''} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="text-end"><NumericFormat value={(actualPayPeriods ? isNumericDefault(actualPayPeriods.GRAPayPeriods, 0) : 0) + (props.accountDetail ? isNumericDefault(props.accountDetail.ippGRAActAdj, 0) : 0)} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="text-end"><NumericFormat value={forecastedPayPeriods ? isNumericDefault(forecastedPayPeriods.GRA, 0) : 0} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                        <td className="text-end"><NumericFormat value={getAvailableGRAPayPeriods()} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                    </tr>
                                </tbody>
                            </table>
                            {/*</div>*/}
                        </td>
                        <td colSpan="14">&nbsp;</td>
                    </tr>
                </>
                :
                null
            }
        </>
    );
}
