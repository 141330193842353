import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "./Header";
import SubMenu from "./SubMenu";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faSpinner, faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat, PatternFormat } from 'react-number-format';
/*import AccountOpener from "/accounts/Find"*/
import SaveSearch from "./SaveSearch";


function Find() {
    const [searchCriteria, setSearchCriteria] = useState({ Award: '', AccountStatus: '', Account: '', AccountType: '', LeadUnitFundCode: '', CostCenterHierarchy: '', PILastName: '' })
    const [optionsAccountStatus, setOptionsAccountStatus] = useState(['Active', 'Active and Expired', 'All', 'Activation in Progress']);
    const [optionsAccountType, setOptionsAccountType] = useState(['All', 'C/S and P/I', 'Not C/S and P/I', 'Just Workday', 'Workday Programs', 'Workday Gifts', 'Workday Projects']);
    const [optionsLeadUnitFundCode, setOptionsLeadUnitFundCode] = useState([]);
    const [optionsCostCenterHierarchy, setOptionsCostCenterHierarchy] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);
    const [isPortfolioSearch, setIsPortfolioSearch] = useState(false);
    const [savedSearch, setSavedSearch] = useState(null);
    const dateNow = moment();
    const navigate = useNavigate();

    const columns = [
        { name: 'Award', selector: row => row.AwardID, sortable: true },
        { name: 'Award Status', selector: row => row.AwardStatus, sortable: true, omit: true },
        {
            name: 'Account', selector: row => row.Account, sortable: true,
            cell: row => <>
                <span title={row.Account} data-tag="allowRowEvents">{row.Account}</span>
                {/*<FontAwesomeIcon icon={faDeleteLeft} onClick={() => alert('test')} />*/}
            </>,
            width: '135px',
            wrap: false
        },
        { name: 'Account Type', selector: row => row.ERAAccountType, sortable: true, omit: true },
        { name: 'Dept ID', selector: row => row.Deptid, sortable: true, omit: true },
        { name: 'Cost Center', selector: row => row.WorkdayCostCenterRefID, sortable: true },
        { name: 'Account PI', selector: row => row.internalPI ? row.internalPI.valueOf() : null, sortable: true },
        { name: 'Sponsor', selector: row => row.Sponsor, sortable: true, wrap: true },
        { name: 'Title', selector: row => row.title, sortable: true, omit: false, wrap: true},
        {
            name: 'Account Start Date', selector: row => row.Start_Date ? moment(row.Start_Date).format("M/D/yyyy") : null, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                const a = rowA.Start_Date ? moment(rowA.Start_Date) : 0;
                const b = rowB.Start_Date ? moment(rowB.Start_Date) : 0;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        {
            name: 'Account End Date', selector: row => row.End_date ? moment(row.End_date).format("M/D/yyyy") : null, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                const a = rowA.End_date ? moment(rowA.End_date) : 0;
                const b = rowB.End_date ? moment(rowB.End_date) : 0;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        {
            name: 'Account Final End Date', selector: row => row.FinalEndDate ? moment(row.FinalEndDate).format("M/D/yyyy") : null, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                const a = rowA.FinalEndDate ? moment(rowA.FinalEndDate) : 0;
                const b = rowB.FinalEndDate ? moment(rowB.FinalEndDate) : 0;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        { name: 'Account Status', selector: row => row.AccountStatus, sortable: true, right: true, omit: true },
        {
            name: 'Time Burn Rate', selector: row => (
                <>
                <NumericFormat value={
                Number.parseFloat(
                    moment(row.Start_Date) >= moment(row.End_date) ? 0 : (
                        moment(row.End_date) <= dateNow ? 1 : (
                            moment(row.Start_Date) > dateNow ? 0 : (
                                dateNow.diff(moment(row.Start_Date), 'days') / moment(row.End_date).diff(moment(row.Start_Date), 'days')
                            )
                        )
                    ) * 100
                ).toFixed(1)
                } displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                </>
            ), sortable: true, right: true, omit: true 
        },
        {
            name: 'Budget Burn Rate', selector: row => (
                <NumericFormat value={
                    Number.parseFloat(row.Budget) === 0 ? 0 : (Number.parseFloat(row.ITD) / Number.parseFloat(row.Budget) * 100)
                } displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />
            ), sortable: true, right: true, omit: true 
        },
        { name: 'Fiscal Year', selector: row => row.FiscalYear, sortable: true, omit: true },
        {
            name: 'Budget', selector: row =>
                <NumericFormat value={row.Budget} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                    const a = rowA.Budget ?? 0;
                    const b = rowB.Budget ?? 0;

                    if (a > b) {
                        return 1;
                    }

                    if (b > a) {
                        return -1;
                    }

                    return 0;
                }
        },
        {
            name: 'Expenses', selector: row =>
                <NumericFormat value={row.ITD} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, right: true, sortFunction: (rowA, rowB) => {
                    const a = rowA.ITD ?? 0;
                    const b = rowB.ITD ?? 0;

                    if (a > b) {
                        return 1;
                    }

                    if (b > a) {
                        return -1;
                    }

                    return 0;
                }
        },
        {
            name: 'Encumbrances', selector: row =>
                <NumericFormat value={row.Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, omit: true, right: true, sortFunction: (rowA, rowB) => {
                    const a = rowA.Encumbrances ?? 0;
                    const b = rowB.Encumbrances ?? 0;

                    if (a > b) {
                        return 1;
                    }

                    if (b > a) {
                        return -1;
                    }

                    return 0;
                }
        },
        {
            name: 'Forecast', selector: row =>
                <NumericFormat value={0} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, omit: true, right: true
        },
        {
            name: 'Available', selector: row =>
                <NumericFormat value={row.Budget - row.ITD - row.Encumbrances - 0} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, omit: true, right: true, sortFunction: (rowA, rowB) => {
                    const a = rowA.Budget - rowA.ITD - rowA.Encumbrances - 0;
                    const b = rowB.Budget - rowB.ITD - rowB.Encumbrances - 0;

                    if (a > b) {
                        return 1;
                    }

                    if (b > a) {
                        return -1;
                    }

                    return 0;
                }
        },
        {
            name: 'Beg Balance Revenue', selector: row =>
                <NumericFormat value={row.RevenueBegBalance} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, omit: true, right: true, sortFunction: (rowA, rowB) => {
                    const a = rowA.RevenueBegBalance ?? 0;
                    const b = rowB.RevenueBegBalance ?? 0;

                    if (a > b) {
                        return 1;
                    }

                    if (b > a) {
                        return -1;
                    }

                    return 0;
                }
        },
        {
            name: 'Revenue To Date', selector: row =>
                <NumericFormat value={row.Revenue} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, right: true, omit: true 
        },
        {
            name: 'Direct Available to Spend', selector: row =>
                <NumericFormat value={null} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, right: true, omit: true
        },
        {
            name: 'Award Anticipated Remaining', selector: row =>
                <NumericFormat value={row.AnticipatedRemaining} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, right: true, omit: true 
        },
        { name: 'Email Address', selector: row => row.Email, sortable: true, omit: true },
        {
            name: 'Cash Available', selector: row =>
                <NumericFormat value={null} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, right: true, omit: true
        },
        {
            name: 'Direct Cash Available', selector: row =>
                <NumericFormat value={null} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," />, sortable: true, right: true, omit: true
        }
    ];
    //=IF(Budget=0,0,Expenses/Budget)
    //(moment()-moment(row.Start_Date))/(moment(row.End_date)-moment(row.Start_Date))
    //=IF(Start>=End,0,IF(End<=TODAY(),1,IF(Start>TODAY(),0,(TODAY()-Start)/(End-Start))))
    useEffect(() => {
        // Update the document title using the browser API
        populateLookUps();
        setIsPortfolioSearch(false);
        if (sessionStorage.getItem('FindAccountsObj')) {
            const sessionObj = JSON.parse(sessionStorage.getItem('FindAccountsObj'));
            if (sessionObj.isPortfolioSearch) {
                setIsPortfolioSearch(true);
                setSavedSearch(sessionObj.savedSearch);
            }
            else {
                setIsPortfolioSearch(false);
            }
            setSearchCriteria({ ...sessionObj.searchCriteria })
            setAccounts(sessionObj.results);
        }
        else {
            setSearchCriteria({ ...searchCriteria, AccountType: optionsAccountType[0], AccountStatus: optionsAccountStatus[0], LeadUnitFundCode: optionsLeadUnitFundCode[0] })
        }
        //setSearchAccountStatus(optionsAccountStatus[0]);
        //setSearchLeadUnitFundCode(optionsLeadUnitFundCode[0]);
        //setSearchCostCenterHierarchy('');

    }, []);

    const populateLookUps = async () => {
        if (sessionStorage.getItem('optionsLeadUnitFundCode')) {
            setOptionsLeadUnitFundCode(JSON.parse(sessionStorage.getItem('optionsLeadUnitFundCode')));
        }
        else {
            let response = await fetch('/api/lookup/leadunits');
            let data = await response.json();
            if (data && !data.find(c => c.Deptid === "")) {
                data.push({ Deptid: "" });
            }
            let codes = [...new Set(data.map((code) => code.Deptid))].sort((a, b) => a > b ? 1 : -1);
            setOptionsLeadUnitFundCode(codes);
            sessionStorage.setItem('optionsLeadUnitFundCode', JSON.stringify(codes));
        }
        if (sessionStorage.getItem('optionsCostCenterHierarchy')) {
            setOptionsCostCenterHierarchy(JSON.parse(sessionStorage.getItem('optionsCostCenterHierarchy')));
        }
        else {
            let response = await fetch('/api/lookup/costcenters');
            let data = await response.json();
            let costCenters = [...new Set(data.map((code) => code.Id))].sort((a, b) => a > b ? 1 : -1);
            setOptionsCostCenterHierarchy(costCenters);
            sessionStorage.setItem('optionsCostCenterHierarchy', JSON.stringify(costCenters));
        }

    }


    const findAccounts = async (e) => {
        e.preventDefault();

        setLoading(true);
        setSearching(true);

        if (isPortfolioSearch) {
            if (searchCriteria && searchCriteria.AccountStatus === undefined) {
                searchCriteria.AccountStatus = 'Active';
            }
            const response = await fetch('/api/savedsearch/portfoliosearch?' +
                ('savedSearchId=' + encodeURIComponent(savedSearch.id)) +
                (!searchCriteria.Award ? '' : 'award=' + encodeURIComponent(searchCriteria.Award)) +
                (!searchCriteria.AccountStatus ? '' : '&accountStatus=' + encodeURIComponent(searchCriteria.AccountStatus)) +
                (!searchCriteria.Account ? '' : '&account=' + encodeURIComponent(searchCriteria.Account)) +
                (!searchCriteria.AccountType ? '' : '&accountType=' + encodeURIComponent(searchCriteria.AccountType)) +
                (!searchCriteria.LeadUnitFundCode ? '' : '&leadUnitFundCode=' + encodeURIComponent(searchCriteria.LeadUnitFundCode)) +
                (!searchCriteria.CostCenterHierarchy ? '' : '&costCenterHierarchy=' + encodeURIComponent(searchCriteria.CostCenterHierarchy)) +
                (!searchCriteria.PILastName ? '' : '&piLastName=' + encodeURIComponent(searchCriteria.PILastName))
            );
            const data = await response.json();

            try {
                sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: true, savedSearch: savedSearch, searchCriteria: searchCriteria, results: data }, (key, value) => typeof value === 'undefined' ? null : value));
            }
            catch (ex) {
                sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: true, savedSearch: savedSearch, searchCriteria: searchCriteria, results: [] }, (key, value) => typeof value === 'undefined' ? null : value));
            }
            setAccounts(data);
        }
        else {
            const response = await fetch('/api/account/find?' +
                (!searchCriteria.Award ? '' : 'award=' + encodeURIComponent(searchCriteria.Award)) +
                (!searchCriteria.AccountStatus ? '' : '&accountStatus=' + encodeURIComponent(searchCriteria.AccountStatus)) +
                (!searchCriteria.Account ? '' : '&account=' + encodeURIComponent(searchCriteria.Account)) +
                (!searchCriteria.AccountType ? '' : '&accountType=' + encodeURIComponent(searchCriteria.AccountType)) +
                (!searchCriteria.LeadUnitFundCode ? '' : '&leadUnitFundCode=' + encodeURIComponent(searchCriteria.LeadUnitFundCode)) +
                (!searchCriteria.CostCenterHierarchy ? '' : '&costCenterHierarchy=' + encodeURIComponent(searchCriteria.CostCenterHierarchy)) +
                (!searchCriteria.PILastName ? '' : '&piLastName=' + encodeURIComponent(searchCriteria.PILastName))
            );
            const data = await response.json();

            try {
                sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: false, searchCriteria: searchCriteria, results: data }, (key, value) => typeof value === 'undefined' ? null : value));
            }
            catch (ex) {
                sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: false, searchCriteria: searchCriteria, results: [] }, (key, value) => typeof value === 'undefined' ? null : value));
            }

            setAccounts(data);
        }

        setLoading(false);
        setSearching(false);
    }

    const handleChangeSearchCriteria = (event) => {
        var targetVar = event.target.name;
        var targetVal = event.target.value;

        setSearchCriteria({ ...searchCriteria, [targetVar]: targetVal });
    }
    const clearSearch = (event) => {
        event.preventDefault();
        setSavedSearch(null);
        setIsPortfolioSearch(false);
        setSearchCriteria({ Award: '', AccountStatus: 'Active', Account: '', AccountType: 'All', LeadUnitFundCode: '', CostCenterHierarchy: '', PILastName: '' });
        setAccounts([]);
    }
    const accountHandler = row => {
        //const navigate = useNavigate();
        //let navigate = useNavigate();
        navigate(`/accounts/${row.Account}`);
        //console.log(this.props);
        //console.log(this.props.history);
        //let opener = AccountOpener();
        //opener.handleClick();
        //this.props.history.push('/accounts/' + row.Account);
    };

    return (
        <>
            <Header />
            <SubMenu />

            <div className="container-xl px-2 pb-4">
                <form onSubmit={findAccounts}>
                    <div className="row g-3">
                        <div className="col-auto">
                            <label htmlFor="txtSearchAward" className="col-form-label col-form-label-sm">Award</label>
                            <input id="txtSearchAward" type="text" className="form-control form-control-sm" name="Award" value={searchCriteria ? searchCriteria.Award : ''} onChange={handleChangeSearchCriteria} />
                        </div>
                        <div className="col-auto">
                            <label htmlFor="ddlAccountStatus" className="col-form-label col-form-label-sm">Account Status</label>
                            <select id="ddlAccountStatus" className="form-select form-select-sm" name="AccountStatus" onChange={handleChangeSearchCriteria} value={searchCriteria ? searchCriteria.AccountStatus : ''}>
                                {optionsAccountStatus.map(element =>
                                    <option key={element} value={element}>
                                        {element}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-auto">
                            <label htmlFor="txtSearchAccount" className="col-form-label col-form-label-sm">Account</label>
                            <input id="txtSearchAccount" type="text" className="form-control form-control-sm" name="Account" value={searchCriteria ? searchCriteria.Account : ''} onChange={handleChangeSearchCriteria} />
                        </div>
                        <div className="col-auto">
                            <label htmlFor="ddlAccountType" className="col-form-label col-form-label-sm">Account Type</label>
                            <select id="ddlAccountType" className="form-select form-select-sm" name="AccountType" onChange={handleChangeSearchCriteria} value={searchCriteria ? searchCriteria.AccountType : ''}>
                                {optionsAccountType.map(element =>
                                    <option key={element} value={element}>
                                        {element}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-auto">
                            <label htmlFor="ddlLeadUnitFundCode" className="col-form-label col-form-label-sm">Lead Unit/Fund Code</label>
                            <select id="ddlLeadUnitFundCode" className="form-select form-select-sm" name="LeadUnitFundCode" onChange={handleChangeSearchCriteria} value={searchCriteria ? searchCriteria.LeadUnitFundCode : ''}>
                                {optionsLeadUnitFundCode.map(element =>
                                    <option key={element} value={element}>
                                        {element}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-auto">
                            <label htmlFor="ddlCostCenterHierarchy" className="col-form-label col-form-label-sm">Cost Center/Hierarchy</label>
                            <select id="ddlCostCenterHierarchy" className="form-select form-select-sm" name="CostCenterHierarchy" onChange={handleChangeSearchCriteria} value={searchCriteria ? searchCriteria.CostCenterHierarchy : ''}>
                                <option key="" value=""></option>
                                {optionsCostCenterHierarchy.map(element =>
                                    <option key={element} value={element}>
                                        {element}
                                    </option>
                                )
                                }
                            </select>
                        </div>
                        <div className="col-auto">
                            <label htmlFor="txtPILastName" className="col-form-label col-form-label-sm">PI Last Name</label>
                            <input type="text" className="form-control form-control-sm" id="txtPILastName" name="PILastName" value={searchCriteria ? searchCriteria.PILastName : ''} onChange={handleChangeSearchCriteria} />
                        </div>
                    </div>
                    <div className="row g-3 pt-3 justify-content-between">
                        <div className="col-auto">
                            {isPortfolioSearch ?
                                <>
                                    Portfolio Search: <b>{savedSearch && savedSearch.searchName}</b>
                                </>
                                : null}
                        </div>
                        <div className="col-auto row pt-3">
                            <div className="col-auto">
                                <button type="button" className="btn btn-secondary btn-sm" disabled={searching} title="Clear Search" onClick={clearSearch} >
                                    <FontAwesomeIcon icon={faDeleteLeft} className="" />
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary btn-sm" disabled={searching} >
                                    <FontAwesomeIcon icon={searching ? faSpinner : faMagnifyingGlass} className="me-2" />
                                    Find Accounts
                                </button>
                            </div>
                            <div className="col-auto">
                                <SaveSearch searchCriteria={searchCriteria} disabled={searching || (isPortfolioSearch)} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <DataTable
                pagination
                dense
                highlightOnHover
                pointerOnHover
                striped
                progressPending={loading && searching}
                columns={columns}
                data={accounts}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationPerPage={50}
                onRowClicked={accountHandler}
                rowsPerPage={50}
                    /*selectableRows*/ />

        </>
    );

}

//export class Find extends Component {
//    static displayName = Find.name;


//    accountHandler = row => {
//        //const navigate = useNavigate();
//        //let navigate = useNavigate();
//        console.log(row);
//        console.log(row.Account);
//        console.log(`/accounts/${row.Account}`);
//        console.log(this.props);
//        console.log(this.props.history);
//        let opener = AccountOpener();
//        opener.handleClick();
//        //this.props.history.push('/accounts/' + row.Account);
//    };

//    render() {

//        //const dataSet = this.state.accounts;

//        return (
//        );
//    };



//}
export default Find;
//function AccountOpener(account) {
//    let navigate = useNavigate();
//    const handleClick = () => {
//        navigate('/')
//    };

//    return (
//        <button onClick={handleClick}>Test Button</button>
//    )

//}

