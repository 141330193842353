import React, { Component } from 'react';
import { MsalContext, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, withMsal } from '@azure/msal-react';
import { getParameters } from './Parameters';
import HomeDash from './HomeDash';

export class Home extends Component {
    static displayName = Home.name;
    static contextType = MsalContext;

    componentDidMount() {
        this.getParametersData();
    }

    async getParametersData() {
        if (this.context.accounts.length > 0) {
            getParameters();
        }
    }

    render() {
        return (
            <>
                <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-1">
                    <div className="container-xl px-2">
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between pt-2">
                                <div className="col-auto mb-3">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                        </div>
                                        &nbsp;
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-2 pb-4">
                    <div className="row g-3">
                        <h1>Welcome!</h1>
                        <AuthenticatedTemplate>
                            <HomeDash />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <h5>
                                <center>
                                    Please login to use the tool.
                                </center>
                            </h5>
                        </UnauthenticatedTemplate>
                    </div>
                </div>
            </>
        );
    }
}