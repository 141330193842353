import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import acquireTokenRequest from '../../MsalHelper.ts';
import { getGraphClient } from '../../graph/graph';

import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function UserLookup(props) {
    const { initialItems, onRemove, onAdd } = props;
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedItems, setSelectedItems] = useState(initialItems ?? []);

    const [token, setToken] = useState(null);

    useEffect(() => {
        //getData();
        if (initialItems)
            setSelectedItems(initialItems);
    }, [initialItems]);

    const onRemoveItem = async (item) => {
        if (onRemove)
            onRemove(item);
    };
    const onAddItem = async (item) => {
        if (onAdd)
            onAdd(item);
    };

    const getAccessToken = async () => {
        let accessToken = null;
        if (!token) {
            await instance.acquireTokenSilent({
                scopes: ['User.ReadBasic.All'],
                account: accounts[0],
            }).then(tokenResponse => {
                // Do something with the tokenResponse
                accessToken = tokenResponse.accessToken;
                setToken(accessToken);
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            accessToken = token;
        }
        return accessToken;
    };

    const runSearch = async (search) => {


        //var search = "displayName:'" + searchTerm + "'";

        const accessToken = await getAccessToken();
        await getGraphClient(accessToken)
            .api("users")
            //.select("displayName,userType,mail")
            .count(true)
            .filter("(endsWith(userPrincipalName, 'asurite.asu.edu') and not endsWith(userPrincipalName, 'onmicrosoft.com') and not endsWith(mail, 'onmicrosoft.com') and mail ne null)")
            .header('ConsistencyLevel', 'eventual')
            .search('"mail:' + search + '" OR "displayName:' + search + '" OR "userPrincipalName:' + search + '"')
            .get()
            .then(res => {
                setUsers(res.value.map(x => { return { accessTo: x.userPrincipalName, accessToName: x.displayName } }));
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });

    };

    const getData = async () => {
        let token = null;
        instance.acquireTokenSilent({
            scopes: ['User.ReadBasic.All'],
            account: accounts[0],
        }).then(tokenResponse => {
            // Do something with the tokenResponse
            token = tokenResponse.accessToken;
            setToken(token);
            console.log('tokenResponse', tokenResponse);

            //let testUsers = https://graph.microsoft.com/v1.0/users
            const headers = new Headers();
            const bearer = `Bearer ${token}`;

            headers.append("Authorization", bearer);

            const options = {
                method: "GET",
                headers: headers
            };

            //let testUsers = fetch("https://graph.microsoft.com/v1.0/users", options)
            //    .then(response => response.json())
            //    .catch(error => console.log(error));

            const newToken = activeAccount.idToken;
            console.log('newToken', newToken);

            const me = getGraphClient(token)
                .api("me")
                .get();

            console.log('me', me);

            let users = getGraphClient(token)
                .api("users?$filter=startswith(displayName,'a')&$count=true&$top=1")
                .get();

            console.log(tokenResponse, users);
        }).catch(error => {
            //if (error instanceof InteractionRequiredAuthError) {
            //    // fallback to interaction when silent call fails
            //    return instance.acquireTokenRedirect(request)
            //}
            console.log(error);
            // handle other errors
        });

        //https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'sarah') or startswith(givenName,'sarah') or startswith(surname,'sarah') or startswith(mail,'sarah') or startswith(userPrincipalName,'sarah')

    };


    return (
        <>
            <AsyncTypeahead
                id="userLookup"
                isLoading={loading}
                filterBy={() => true}
                labelKey={option => `${option.accessToName} (${option.accessTo})`}
                onChange={(s) => {
                    if (selectedItems.length > s.length) {
                        //We're removing
                        const removed = selectedItems.filter(si => !s.some(x => x.accessTo === si.accessTo));
                        removed.forEach(r => onRemoveItem(r));
                    }
                    else {
                        //We're removing
                        const added = s.filter(si => !selectedItems.some(x => x.accessTo === si.accessTo));
                        added.forEach(a => onAddItem(a));
                    }
                    setSelectedItems(s);
                }}
                onSearch={(query) => {
                    setLoading(true);
                    runSearch(query);
                }}
                selected={selectedItems}
                options={users}
                multiple
                useCache={false}
                minLength={3}
                style={{width: "100%"} }
            />

        </>
    )
}

/*
            <div class="input-group">
                <input type="text" className="form-control" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} />
                <button type="button" className="btn btn-primary" onClick={runSearch}>
                    Search
                </button>
            </div>
            User Lookup
            <hr />
            {users ?
                <>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Display Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(u =>
                                <tr>
                                    <td>{u.displayName}</td>
                                    <td>{u.mail}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {JSON.stringify(users)}
                </>
                : null
            }

*/