import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCheck, faSquareCheck, faSackDollar, faGauge, faSpinner, faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

export default function CashAvailable(props) {
    const {
        account,
        accountTotals,
        availableToSpend,
        revBeginBalance,
        revenueITD,
        currentRate,
        showButton,
        showActualTotal,
        showActualAvailableToSpend
    } = props;

    const [modal, setModal] = useState(false);

    useEffect(() => {

    }, []);


    const modalToggle = () => setModal(!modal);
    const openModalForecastAccuracy = () => {
        modalToggle();
    };
    const getGetActualTotal = () => {
        return revBeginBalance + revenueITD + (accountTotals ? 0 - accountTotals.Expenses - accountTotals.Encumbrances - accountTotals.Forecast : 0);
    };
    const getAvailableDifference = () => {
        return (Math.round((accountTotals ? accountTotals.Available : 0) * 100) / 100) - (Math.round(getGetActualTotal() * 100) / 100);
    };
    const getAvailableToSpendDifference = () => {
        return (Math.round(availableToSpend * 100) / 100) - (Math.round(getGetActualTotal() / (1 + (currentRate / 100)) * 100) / 100);
    };


    return (
        <>
            {showButton ? 
                <button type="button" className="btn btn-secondary btn-sm" style={{ "--bs-btn-padding-y": ".10rem", "--bs-btn-padding-x": ".25rem", "--bs-btn-font-size": ".70rem" }} onClick={openModalForecastAccuracy} >
                    {getAvailableDifference() > 0 || getAvailableToSpendDifference() > 0 ?
                        <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger pe-1" beat />
                        :
                        getAvailableDifference() < 0 || getAvailableToSpendDifference() < 0 ?
                            <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning pe-1" />
                            :
                            getAvailableDifference() === 0 || getAvailableToSpendDifference() === 0 ?
                                <FontAwesomeIcon icon={faSquareCheck} className="text-success pe-1" />
                                :
                                <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning pe-1" />
                    }
                    Cash Avail
                </button>
                : null}
            {showActualTotal ? <NumericFormat value={getGetActualTotal()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /> : null }
            {showActualAvailableToSpend ? <NumericFormat value={getGetActualTotal() / (1 + (currentRate / 100))} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /> : null}
            <Modal isOpen={modal} toggle={modalToggle}>
                <ModalHeader toggle={modalToggle}>
                    <FontAwesomeIcon icon={faSackDollar} className="pe-2" />Cash Available
                </ModalHeader>
                <ModalBody>
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Forecast</th>
                                <th>Actual</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-end">Total Available:</td>
                                <td className="text-end fw-bold"><NumericFormat value={accountTotals ? accountTotals.Available : 0} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                <td className="text-end fw-bold"><NumericFormat value={getGetActualTotal()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                <td>
                                    {getAvailableDifference() < 0
                                        ?
                                        <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning" />
                                        :
                                        getAvailableDifference() > 0
                                            ?
                                            <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger" beat />
                                            :
                                            <FontAwesomeIcon icon={faSquareCheck} className="text-success" />
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="text-end">Available to Spend:</td>
                                <td className="text-end fw-bold"><NumericFormat value={availableToSpend} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                <td className="text-end fw-bold"><NumericFormat value={getGetActualTotal() / (1 + (currentRate / 100))} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                <td>
                                    {getAvailableToSpendDifference() < 0
                                        ?
                                        <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning" />
                                        :
                                        getAvailableToSpendDifference() > 0
                                            ?
                                            <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger" beat />
                                            :
                                            <FontAwesomeIcon icon={faSquareCheck} className="text-success" />
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ModalBody>
            </Modal>
        </>
    );
}

