import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faMagnifyingGlass, faSpinner, faUsersBetweenLines, faUser, faBan, faCircleXmark, faCircleCheck, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

function RPPR(props) {

    const [hoursPerMonth, setHoursPerMonth] = useState(173);
    const [roundMonths, setRoundMonths] = useState(false);

    const [modal, setModal] = useState(false);

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {

    }, []);

    const modalToggle = () => setModal(!modal);
    const openModal = () => {
        getData();
    };
    const toggleRoundMonths = () => setRoundMonths(!roundMonths);
    const getData = async () => {

        setShowLoading(true);

        try {
            modalToggle();
            setShowLoading(false);
        }
        catch (e) {
            console.error(e);
            setShowLoading(false);
        }

    }

    const getEmployeeEarnings = () => {
        let employeeEarnings = [];
        if (props.employeeEarnings) {
            employeeEarnings = Array.from(new Set(props.employeeEarnings.map(e => e.EmplId.trim())))
                .map(id => {
                    let employeeEarning = props.employeeEarnings.find(e => e.EmplId.trim() === id);
                    return {
                        EmplId: id,
                        ...employeeEarning
                    }
                });
        }
        return employeeEarnings;
    }

    const handleHoursPerMonth = (values, sourceInfo) => {
        if (sourceInfo.event) {
            setHoursPerMonth(values.floatValue);
        }
    }

    return (
        <>
            <button type="submit" className="btn btn-secondary btn-sm" onClick={openModal} disabled={showLoading} >
                <FontAwesomeIcon icon={showLoading ? faSpinner : faFileInvoice} spin={showLoading} className="me-2" />
                RPPR
            </button>

            <Modal isOpen={modal} toggle={modalToggle} size="xxl">
                <ModalHeader toggle={modalToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faFileInvoice} className="pe-2" />RPPR</h5>
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody style={{
                    
                }}>
                    <div className="row">
                        <div className="row">
                            <label className="col-sm-2 offset-sm-5 col-form-label col-form-label-sm fw-bold text-end">Period Start:</label>
                            <label className="col-sm-1 col-form-label col-form-label-sm text-end">{moment(props.startDate).format('M/D/yyyy')}</label>
                            <label className="col-sm-1 col-form-label col-form-label-sm fw-bold text-end">Period End:</label>
                            <label className="col-sm-1 col-form-label col-form-label-sm text-end">{moment(props.endDate).format('M/D/yyyy')}</label>
                        </div>
                        <div className="row">
                            <label className="col-sm-2 col-form-label col-form-label-sm fw-bold text-end">Hours Per Month:</label>
                            <div className="col-sm-1">
                                <NumericFormat className="form-control form-control-sm form-control-xs fw-bold bg-info-subtle text-end" value={hoursPerMonth} onValueChange={handleHoursPerMonth} decimalScale={0} fixedDecimalScale thousandSeparator="," />
                            </div>
                            <div className="col-sm-2 d-flex align-items-center">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={roundMonths} onChange={toggleRoundMonths} />
                                    <label className="form-check-label col-form-label-sm lh-1 fw-bold" htmlFor="flexSwitchCheckDefault">Round Months</label>
                                </div>
                            </div>
                            <label className="col-sm-2 col-form-label col-form-label-sm fw-bold text-end">Hours in Period:</label>
                            <label className="col-sm-1 col-form-label col-form-label-sm text-end"><NumericFormat value={moment.duration(moment(props.endDate).diff(props.startDate)).asHours()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></label>
                            <label className="col-sm-1 col-form-label col-form-label-sm fw-bold text-end">Months:</label>
                            <label className="col-sm-1 col-form-label col-form-label-sm text-end"><NumericFormat value={moment.duration(moment(props.endDate).diff(props.startDate)).asHours() / 24 / 30} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></label>
                        </div>
                    </div>
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar", "maxHeight": "calc(100vh - 350px)"
                    }}>
                        {/*<div className="col-12">*/}
                        {/*</div>*/}
                        <table className="table table-sm table-xs table-xxs table-hover" style={{ display: "table" }}>
                            <thead className="sticky-top">
                                <tr>
                                    <th colSpan="5">&nbsp;</th>
                                    <th className="table-light nowrap text-center border" colSpan="6">Actual + Forecast</th>
                                    <th className="table-light nowrap text-center border" colSpan="3">Budgeted on ERA Award</th>
                                </tr>
                                <tr>
                                    <th className="table-light nowrap">EmplId</th>
                                    <th className="table-light nowrap">Name</th>
                                    <th className="table-light nowrap">Title</th>
                                    <th className="table-light nowrap">Role</th>
                                    <th className="table-light nowrap">Type</th>
                                    <th className="table-light border-start">Academic Hours</th>
                                    <th className="table-light">Summer Hours</th>
                                    <th className="table-light">Calendar Hours</th>
                                    <th className="table-light border-start">Academic Months</th>
                                    <th className="table-light">Summer Months</th>
                                    <th className="table-light">Calendar Months</th>
                                    <th className="table-light border-start">Academic Months</th>
                                    <th className="table-light">Summer Months</th>
                                    <th className="table-light border-end">Calendar Months</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {props.employeeEarnings ? getEmployeeEarnings().map((earn, i) => {
                                    let thisTotals = props.getEmployeeActualHours(earn);
                                    return <Fragment key={i}>
                                        <tr>
                                            <td className="nowrap">{earn.EmplId}</td>
                                            <td className="nowrap">{earn.Employee}</td>
                                            <td className="nowrap">{earn.Title}</td>
                                            <td className="nowrap">{earn.Role ?? earn.StaffType}</td>
                                            <td className="nowrap">{earn.ApptType}</td>
                                            <td className="nowrap text-end border-start"><NumericFormat value={thisTotals ? thisTotals.AcaHours : null} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end"><NumericFormat value={thisTotals ? thisTotals.SumHours : null} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end"><NumericFormat value={thisTotals ? thisTotals.CalHours : null} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end border-start"><NumericFormat value={thisTotals ? thisTotals.AcaHours / hoursPerMonth : null} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end"><NumericFormat value={thisTotals ? thisTotals.SumHours / hoursPerMonth : null} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end"><NumericFormat value={thisTotals ? thisTotals.CalHours / hoursPerMonth : null} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end border-start"><NumericFormat value={earn.AcademicMonths} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end"><NumericFormat value={earn.SummerMonths} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                            <td className="nowrap text-end border-end"><NumericFormat value={earn.CalendarMonths} displayType="text" decimalScale={roundMonths ? 0 : 1} fixedDecimalScale thousandSeparator="," /></td>
                                        </tr>
                                    </Fragment>
                                }) : null
                                }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default RPPR;