import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../ErrorBoundary';
import { useParams } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { GetAuthUsername } from "../AuthUsername";

import Header from "./Header";
import SubMenu from "./SubMenu";

import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faClone, faHeart, faStar, faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faXmark, faRightFromBracket, faArrowRightFromBracket, faArrowRightToBracket, faEllipsisVertical, faBoltLightning, faDeleteLeft, faMagnifyingGlass, faSpinner, faFileInvoice, faUser, faBan, faBook, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { networkDays, isNumeric, isNumericDefault, isNullOrEmpty, isNullOrEmptyDefault } from '../../utilities';
import { getParameters } from '../Parameters';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import AddEmployee from "./AddEmployee";
import AccountNotes from "./AccountNotes";
import ForecastAccuracy from "./ForecastAccuracy";
import ExpensesByMonth from "./ExpensesByMonth";
import Earnings from "./Earnings";
import Effort from "./Effort";
import Encumbrances from "./Encumbrances";
import ForecastBudgets from "./ForecastBudgets";
import Startup from "./Startup";
import CashAvailable from './CashAvailable';

function Account() {
    const { instance, accounts } = useMsal();
    const { account } = useParams();
    const [accountId, setAccountId] = useState(account);
    //const [parameters, setParameters] = useState(null);
    const [ledgerAccountDirectory, setLedgerAccountDirectory] = useState([]);
    const [accountDetail, setAccountDetail] = useState(null);
    const [accountBudgetExpenses, setAccountBudgetExpenses] = useState(null);
    const [accountFiscalMonths, setAccountFiscalMonths] = useState(null);
    const [accountEmployeeSemesters, setAccountEmployeeSemesters] = useState(null);
    const [accountLedgerByMonth, setAccountLedgerByMonth] = useState(null);
    const [accountEmployeeEarnings, setAccountEmployeeEarnings] = useState(null);
    const [accountEmployeeFunding, setAccountEmployeeFunding] = useState(null);
    const [accountEmployeeFundingByPayPeriod, setAccountEmployeeFundingByPayPeriod] = useState(null);
    const [accountFound, setAccountFound] = useState(true);
    const [searching, setSearching] = useState(false);

    const [hasChanges, setHasChanges] = useState(false);
    const [hasAccountChanged, setHasAccountChanged] = useState(false);
    const [showSavingToast, setShowSavingToast] = useState(false);
    const [savingToastIcon, setSavingToastIcon] = useState(<FontAwesomeIcon icon={faSpinner} spin className="" />);
    const [savingToastMessage, setSavingToastMessage] = useState('The account is being saved...');
    const [saveCompleted, setSaveCompleted] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [includeManualForecast, setIncludeManualForecast] = useState(true);

    const activeAccount = instance.getActiveAccount();
    const authUsername = GetAuthUsername();

    const parameters = getParameters();
    //const account = null; //accounts.find(acc => acc.id === Number(id));

    useEffect(() => {
        // Get the parameters
        //getParametersData();
        //setLedgerAccountDirectory(getLedgerAccountsData());
        //getLedgerAccountsData();
        getAccount(accountId);

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    }, [includeManualForecast]);

    const getLedgerAccountsData = async () => {
        const response = await fetch('/api/lookup/ledgeraccounts');
        const data = await response.json();
        setLedgerAccountDirectory(data);
    };
    const getAccount = async (account) => {
        setAccountFound(true);
        setSearching(true);
        try {
            const responseLA = await fetch('/api/lookup/ledgeraccounts');
            const dataLA = await responseLA.json();
            setLedgerAccountDirectory(dataLA);


            const responseAccount = await fetch('/api/account/detail/' +
                (!account ? '' : encodeURIComponent(account))
            );
            let dataAccount = await responseAccount.json();
            setAccountDetail(dataAccount);
            //apply logic to account data
            dataAccount = processAccountData(dataAccount);
            setAccountDetail(dataAccount);

            getAccountPeriods(getAccountEndDate(dataAccount));

            const responseEE = await fetch('/api/account/employeeearnings?account=' + encodeURIComponent(account) + '&includeManualForecast=' + includeManualForecast ?? 'true');
            let dataEE = await responseEE.json();
            setAccountEmployeeEarnings(dataEE);

            const responseEF = await fetch('/api/account/employeefunding?account=' + encodeURIComponent(account) + '&includeManualForecast=' + includeManualForecast ?? 'true');
            let efData = await responseEF.json();
            setAccountEmployeeFunding(efData);

            //const responseEFPP = await fetch('/api/account/employeefundingbypayperiod?account=' + encodeURIComponent(account));
            //const dataEFPP = await responseEFPP.json();
            //setAccountEmployeeFundingByPayPeriod(dataEFPP);

            await getBudgetExpenseData(dataAccount, efData, dataLA, dataEE);

            //const fmData = await getFiscalMonths((accountDetail ? encodeURIComponent(accountDetail.EndDate) : ''));
            

            const responseLBM = await fetch('/api/account/ledgerbymonth?account=' + encodeURIComponent(account));
            let lbmData = await responseLBM.json();
            //Now add the employee forecast totals to the last month for the right ledger accounts
            if (!includeManualForecast) {
                lbmData = [];
            }
            setAccountLedgerByMonth(lbmData);

            try {
                //Set MRU
                await fetch('/api/account/mru', {
                    method: 'POST',
                    body: JSON.stringify({
                        account: account,
                        accessedBy: authUsername
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).catch(ex => {
                    console.log('Caught Error')
                });

            }
            catch (ex) {
                console.log(ex);
                console.log('Error with MRU');
            }
            setAccountFound(true);
        }
        catch (error) {
            console.log(error);
            setAccountFound(false);
        }
        setSearching(false);

    };
    const getBudgetExpenseData = async (dataAccount, efData, dataLA, dataEE) => {
        const responseBE = await fetch('/api/account/budgetsexpenses?account=' + encodeURIComponent(account) + '&includeManualForecast=' + includeManualForecast ?? 'true');
        let beData = await responseBE.json();
        //if (!includeManualForecast) {
        //    beData = beData.filter(d => !d.IsManual);
        //}
        //add subagreement ledger accounts
        let newBEData = addSubAgreementLedger(beData, dataAccount);
        newBEData = addUnbudgetedRevenue(newBEData, dataAccount);
        newBEData = addMissingFALedger(newBEData, dataAccount);
        newBEData = processBudgetExpensesData(newBEData, dataAccount);
        //Check to see what Ledger accounts need to be added
        setAccountBudgetExpenses(addNeededLedgerRows(dataAccount, efData, newBEData, dataLA, dataEE));
    };
    //const extractRatePercent = /(\$(?=[0-9])((\d*\.\d{1,2})|\d+\.?)|((?=[0-9])(\d+\.?)|((?=([1-9]+|\d*\.0*[1-9]+))\d*\.\d{1,4}))\\%)/;
    const extractRatePercent = /(\$(?=[0-9])((\d*\.\d{1,2})|\d+\.?)|((?=[0-9])(\d+\.?)|((?=([1-9]+|\d*\.0*[1-9]+))\d*\.\d{1,4}))\%)/;

    const processAccountData = (data) => {
        if (data.Sponsor === "Arizona State University Foundation (ASUF)") {
            data.ASUFRate = 0.0;
            if (data.CurrentERAIndirectRate > data.CurrentWorkdayIndirectRate) {
                if (getCurrentRate(data) > 0) {
                    data.ASUFRate = 0.05;
                }
            }
        }
        return data;
    };
    const extractRate = (data) => {
        let extractedRate = null;
        if (data && data.Account.startsWith("GR")) {
            if (data && data.WorkdayRateException !== '') {
                //need to extract the rate from the exception rate
                extractedRate = data.ExceptionRate;
            }
            if (getFABase() === "TDC") {
                
            }
            else if (data.workdayRateAgreement.length > 30) {
                //extractRate(data);
                extractedRate = data.CurrentWorkdayIndirectRate;
                if (extractedRate !== null)
                    extractedRate = extractedRate * 100;
                //return extractedRate * 100;
            }

            if (extractedRate === null && data && data.workdayRateAgreement !== null) {
                let matches = data.workdayRateAgreement.match(extractRatePercent);
                if (matches && matches.length > 4) {
                    extractedRate = Number(matches[4]);
                }
            }
        }
        else {
            if (data.AdminServiceCharged === 0) {
                extractedRate = 0;
            }
            else {
                if (data.ASCExtraType === null) {
                    extractedRate = (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).asc * 100 : 0) + data.ASCExtra;
                }
                else {
                    if (data.ASCExtraType === '') {
                        extractedRate = (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).asc * 100 : 0);
                    }
                    else {
                        if (data.ASCExtraType === 'ABOR') {
                            extractedRate = data.ASCExtra;
                        }
                        if (["EOSS1", "EOSS3"].includes(data.ASCExtraType)) {
                            extractedRate = (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).asc * 100 : 0);
                        }

                    }
                }
            }
        }

        if (extractedRate === null && accountFiscalMonths && accountFiscalMonths.length > 0) {
            let indirectRate = parameters.IndirectRates.find(ir =>
                ir.rateAgreement === accountDetail.workdayRateAgreement &&
                ir.typeCampus === accountDetail.FARateType + '/' + accountDetail.WorkdayCampusType &&
                moment(accountFiscalMonths[0].StartDate) >= moment(ir.startDate) &&
                moment(accountFiscalMonths[0].StartDate) <= moment(ir.endDate)
            );
            if (indirectRate)
                extractedRate = indirectRate.rate * 100;
        }

        extractedRate = (data ? (data.ManualCurrentRate ? data.ManualCurrentRate * 100: extractedRate) : 0);

        return extractedRate;
    };
    const getCurrentRate = (data) => {
        let currentRate = null;
        if (!data)
            data = accountDetail;
        currentRate = extractRate(data);
        return currentRate;
    };
    const addSubAgreementLedger = (beData, dataAccount) => {
        if (dataAccount && beData && beData.length > 0) {
            //find a 7201 item
            let subItemIX = beData.findIndex(be => Number(be.LedgerAccountId) === 7201 && (be.Expenses > dataAccount.BasisLimit || be.Budget > dataAccount.BasisLimit));
            if (subItemIX >= 0) {
                let subItem = beData.at(subItemIX);
                let thisBudget = subItem.Budget;
                let thisExpenses = subItem.Expenses;
                let thisEncumbrances = subItem.Encumbrances;
                if (thisBudget > dataAccount.BasisLimit) {
                    subItem.Budget = dataAccount.BasisLimit;
                }
                if (thisExpenses > dataAccount.BasisLimit) {
                    subItem.Expenses = dataAccount.BasisLimit;
                    subItem.Encumbrances = 0;
                }
                else {
                    if (thisEncumbrances + thisExpenses > dataAccount.BasisLimit) {
                        subItem.Encumbrances = dataAccount.BasisLimit - thisExpenses;
                        thisEncumbrances = thisEncumbrances - (dataAccount.BasisLimit - thisExpenses);
                    }
                    else {
                        thisEncumbrances = 0;
                    }
                }
                //Add the new item...
                beData.splice(subItemIX + 1, 0, {
                    LedgerAccountId: "7201.1",
                    LedgerAccount: "7201 Subagreements > " + dataAccount.BasisLimit,
                    ApplyFA: (getFABase() === 'TDC' ? "Yes" : "No"),
                    OverrideFA: null,
                    Budget: thisBudget > dataAccount.BasisLimit ? thisBudget - dataAccount.BasisLimit : 0,
                    Expenses: thisExpenses > dataAccount.BasisLimit ? thisExpenses - dataAccount.BasisLimit : 0,
                    Encumbrances: thisEncumbrances,
                    Comment: null,
                    FiscalYear: null
                })
            }
        }
        return beData;
    };
    const addUnbudgetedRevenue = (beData, dataAccount) => {
        if (!account.startsWith("GR") && dataAccount) {
            if (!beData.find(be => be.LedgerAccount === 'Unbudgeted Revenue')) {
                //Add the new item...
                beData.splice(0, 0, {
                    LedgerAccountId: "0",
                    LedgerAccount: "Unbudgeted Revenue",
                    ApplyFA: "Yes",
                    OverrideFA: null,
                    Budget: (dataAccount.RevenueBegBalance + dataAccount.Revenue) > dataAccount.Budget ? dataAccount.RevenueBegBalance + dataAccount.Revenue - dataAccount.Budget : (dataAccount.RevenueBegBalance + dataAccount.Revenue < 0) ? dataAccount.RevenueBegBalance + dataAccount.Revenue : 0,
                    Expenses: null,
                    Encumbrances: null,
                    Comment: null,
                    FiscalYear: null
                })
            }
        }
        return beData;
    };
    const addMissingFALedger = (beData, dataAccount) => {
        let items = beData ? beData.filter((row) => {
            try {
                let ledgerNumber = Number(row.LedgerAccountId);
                if (row.LedgerAccount) {
                    let id = row.LedgerAccount.substring(0, 4);
                    if (id === "7201") {
                        row.LedgerAccountId = ledgerNumber;
                    }
                    else if (isNumeric(id)) {
                        if (ledgerNumber !== Number(id)) {
                            row.LedgerAccountId = Number(id);
                        }
                    }
                }

                return Number(row.LedgerAccountId) >= 7500;
            }
            catch { }
            return false;
        }) : null;
        if (items) {
            if (items.length === 0) {
                if (account.startsWith("GR")) {
                    beData.push({
                        LedgerAccountId: "7520",
                        LedgerAccount: "7520 Facilities & Administrative Costs",
                        ApplyFA: "",
                        OverrideFA: null,
                        Budget: 0,
                        Expenses: 0,
                        Encumbrances: 0,
                        Comment: null,
                        FiscalYear: null
                    });
                }
                else {
                    beData.push({
                        LedgerAccountId: "7500",
                        LedgerAccount: "7500 Internal Allocations Expense",
                        ApplyFA: "",
                        OverrideFA: null,
                        Budget: 0,
                        Expenses: 0,
                        Encumbrances: 0,
                        Comment: null,
                        FiscalYear: null
                    });
                }
            }
        }
        return beData;
    }
    const processBudgetExpensesData = (beData, dataAccount) => {
        let items = beData;
        if (items) {
            items.forEach((abe, i) => {
                let ledgerNumber = Number(abe.LedgerAccountId);
                if (abe.LedgerAccount) {
                    let id = abe.LedgerAccount.substring(0, 4);
                    if (id === "7201") {
                        items[i].LedgerAccountId = ledgerNumber;
                    }
                    else if (isNumeric(id)) {
                        if (ledgerNumber !== Number(id)) {
                            items[i].LedgerAccountId = Number(id);
                        }
                    }
                }
            });

        }
        let thisIX = beData.findIndex(b => b.LedgerAccountId === '7500');
        if (thisIX >= 0) {
            //We have a 7500, now check to see if it has a budget
            if (beData[thisIX].Budget && beData[thisIX].Budget > 0 && getPersonalServicesBudget(beData, dataAccount) > 0) {
                //7499 Netcomm
                thisIX = beData.findIndex(b => b.LedgerAccount === "7499 Netcom");
                if (thisIX >= 0) {
                    beData[thisIX].Budget = getPersonalServicesBudget(beData, dataAccount) * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm : 0);
                }
                //7499 Risk Mgmt
                thisIX = beData.findIndex(b => b.LedgerAccount === "7499 Risk Mgmt");
                if (thisIX >= 0) {
                    beData[thisIX].Budget = getPersonalServicesBudget(beData, dataAccount) * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt : 0);
                }
            }
        }

        return beData;
    };
    const addNeededLedgerRows = (dataAccount, efData, beData, laData, dataEE) => {
        //Get the Staff Types from the employeeFunding Data
        let staffTypes = [];
        efData.forEach(ef => {
            if (staffTypes.indexOf(ef.StaffType) === -1) {
                staffTypes.push(ef.StaffType);
            }
        });
        if (dataEE && dataEE.length > 0) {
            dataEE.forEach(ee => {
                if (staffTypes.indexOf(ee.StaffType) === -1) {
                    staffTypes.push(ee.StaffType);
                }
            });
        }
        //Now get the Ledger Account Ids for these Staff Types
        if (staffTypes.length > 0 && parameters && parameters.LedgerAccountsStaffType && laData) {
            let ledgerAccountsNeeded = parameters.LedgerAccountsStaffType.filter(l => l.staffTypes.some(st => staffTypes.includes(st)));
            if (ledgerAccountsNeeded.length > 0) {
                //Now that we have the ledgerAccounts, look at the Budget Expenses Data and see which ones are missing...
                let beLedgerAccounts = beData.map(be => be.LedgerAccountId);
                let accountsToAdd = ledgerAccountsNeeded.map(x => x.ledgerAccountId).filter(n => !beLedgerAccounts.includes(n));
                accountsToAdd.forEach(a => {
                    let thisLA = laData.find(la => la.Id === a);
                    let thisLedgerAccountNeeded = ledgerAccountsNeeded.find(n => n.ledgerAccountId === a);
                    if (thisLA) {
                        beData.push({
                            "LedgerAccountId": thisLA.Id,
                            "BasisLimit": "",
                            "LedgerAccount": thisLA.Id + ' ' + thisLA.Name,
                            "ApplyFA": getFABase(dataAccount) === "MTDC" && thisLA.Id === "7111" ? "No" : "Yes",
                            "OverrideFA": null,
                            "Budget": 0,
                            "Expenses": 0,
                            "Encumbrances": "",
                            "Comment": "",
                            "FiscalYear": "",
                            "Missing": efData.some(ef => thisLedgerAccountNeeded.staffTypes.includes(ef.StaffType))
                        });
                    }
                });
            }
        }
        return beData ? beData.sort((x, y) => { return x.LedgerAccountId > y.LedgerAccountId ? 1 : x.LedgerAccountId < y.LedgerAccountId ? -1 : 0 }) : null;
    };
    const getPersonalServicesBudget = (beData) => {
        if (!beData)
            beData = accountBudgetExpenses;

        let budget = 0;
        if (beData.length > 0) {
            beData.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => budget += x.Budget);
        }
        return budget;
    };
    const getFiscalMonths = async (endDate) => {
        const response = await fetch('/api/account/fiscalmonths?account=' + encodeURIComponent(account) + '&endDate=' + (moment(endDate).isValid() ? encodeURIComponent(endDate) : ''));
        const data = await response.json();
        setAccountFiscalMonths(data);
        return data;
    };
    const getEmployeeSemesters = async (endDate) => {
        const response = await fetch('/api/account/employeesemesters?account=' + encodeURIComponent(account) + '&endDate=' + (moment(endDate).isValid() ? encodeURIComponent(endDate) : ''));
        const data = await response.json();
        //find the last payperiod and adjust the workdays accordingly...
        if (data && data.length > 0) {
            let payPeriod = data[data.length - 1];
            payPeriod.Workdays = networkDays(payPeriod.MinStartDate, endDate);
            data[data.length - 1] = payPeriod;
        }
        setAccountEmployeeSemesters(data);
        return data;
    };
    const getAccountPeriods = async (endDate) => {
        const esData = await getEmployeeSemesters(endDate);
        const fmData = await getFiscalMonths(endDate);

        if (esData.length > 0 && fmData.length > 0) {
            let lastFM = fmData.at(-1);
            let lastES = esData.at(-1);

            while (Number(lastES.FiscalYear) * 100 + lastES.FiscalMonth > lastFM.FiscalYearNumber * 100 + lastFM.FiscalPeriodNumber) {
                fmData.push(
                    {
                        Account: account,
                        FiscalPeriodNumber: lastFM.FiscalPeriodNumber === 12 ? 1 : lastFM.FiscalPeriodNumber + 1,
                        FiscalYearNumber: lastFM.FiscalPeriodNumber === 12 ? lastFM.FiscalYearNumber + 1 : lastFM.FiscalYearNumber,
                        StartDate: moment(lastFM.StartDate).add(1, 'M').format()
                    }
                )
                lastFM = fmData.at(-1);
                setAccountFiscalMonths(fmData);
            }
        }
    }
    const getDirectCostLedgerAccounts = () => {
        let items = accountBudgetExpenses ? accountBudgetExpenses.filter((row) => {
            try {
                let ledgerNumber = Number(row.LedgerAccountId);
                if (row.LedgerAccount) {
                    let id = row.LedgerAccount.substring(0, 4);
                    if (isNumeric(id)) {
                        if (ledgerNumber !== Number(id)) {
                            ledgerNumber = Number(id);
                        }
                    }
                }

                return ledgerNumber < 7500;
            }
            catch { }
            return false;
        }) : null;
        if (items) {
            items.forEach((abe, i) => {
                if (getFABase() === 'TDC' || getFABase().startsWith('EOSS')) {
                    if (accountDetail && accountDetail.WorkdayType === 'Program' && (["7300", "7700", "7111"].includes(abe.LedgerAccountId))) {
                        items[i].ApplyFA = 'No';
                    }
                    else {
                        let ledgerNumber = Number(abe.LedgerAccountId);
                        if (abe.LedgerAccount) {
                            let id = abe.LedgerAccount.substring(0, 4);
                            if (isNumeric(id)) {
                                if (ledgerNumber !== Number(id)) {
                                    ledgerNumber = Number(id);
                                }
                            }
                        }
                        if (ledgerNumber > 0 && (ledgerNumber < 7100 || ledgerNumber >= 7500)) {
                            items[i].ApplyFA = 'No';
                        }
                        else {
                            if (!isNullOrEmpty(abe.OverrideFA)) {
                                items[i].ApplyFA = abe.OverrideFA;
                            }
                            else {
                                items[i].ApplyFA = 'Yes';
                            }
                        }
                    }
                }
            });
        }
        return items;
    }
    const getIndirectCostLedgerAccounts = () => {
        let items = accountBudgetExpenses ? accountBudgetExpenses.filter((row) => {
            try {
                let ledgerNumber = Number(row.LedgerAccountId);
                if (row.LedgerAccount) {
                    let id = row.LedgerAccount.substring(0, 4);
                    if (isNumeric(id)) {
                        if (ledgerNumber !== Number(id)) {
                            ledgerNumber = Number(id);
                        }
                    }
                }

                return ledgerNumber >= 7500;
            }
            catch { }
            return false;
        }) : null;

        if (items) {
            items.forEach((abe, i) => {
                if (accountDetail.ASUFRate !== null && accountDetail.ASUFRate !== undefined) {
                    if ((Number(abe.LedgerAccountId) === 7520 || Number(abe.LedgerAccountId) === 7521) && !abe.LedgerAccount.includes("ASUF") && accountBudgetExpenses.some(exp => exp.LedgerAccount.includes("ASUF"))) {
                        if (!abe.BudgetAdjusted) {
                            let directCosts = ((getDirectCosts('Yes').Budget + getDirectCosts('No').Budget) * accountDetail.ASUFRate);
                            items[i].Budget = abe.Budget - directCosts;
                            items[i].BudgetAdjusted = true;
                            items[i].Encumbrances = getDirectCosts('Yes').Encumbrances * getCurrentRate() / 100;
                        }
                    }
                    else if (abe.LedgerAccount.includes("ASUF")) {
                        items[i].Budget = (getDirectCosts('Yes').Budget + getDirectCosts('No').Budget) * accountDetail.ASUFRate;
                        items[i].Encumbrances = items[i].Budget - items[i].Expenses - getAccountLedgerForecast(abe);
                        if (items[i].Encumbrances < 0)
                            items[i].Encumbrances = 0;
                    }
                    else if ([7500, 7520].includes(Number(items[i].LedgerAccountId))) {
                        items[i].Encumbrances = getDirectCosts('Yes').Encumbrances * getCurrentRate() / 100;
                    }
                }
                else {
                    if ([7500, 7520].includes(Number(items[i].LedgerAccountId))) {
                        items[i].Encumbrances = getDirectCosts('Yes').Encumbrances * getCurrentRate() / 100;
                    }
                    if (abe.LedgerAccountId === "7500" & !abe.BudgetAdjusted && abe.Budget > 0 && getPersonalServicesBudget(accountBudgetExpenses, accountDetail) > 0) {
                        if (accountBudgetExpenses && accountBudgetExpenses.find(x => x.LedgerAccount === '7499 Netcom')) {
                            abe.Budget = abe.Budget - getPersonalServicesBudget(accountBudgetExpenses, accountDetail) * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm : 0);
                        }
                        if (accountBudgetExpenses && accountBudgetExpenses.find(x => x.LedgerAccount === '7499 Risk Mgmt')) {
                            abe.Budget = abe.Budget - getPersonalServicesBudget(accountBudgetExpenses, accountDetail) * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt : 0);
                        }
                        abe.BudgetAdjusted = true;
                    }
                }
            });
        }

        return items;
    }

    const handleEndDateBlur = (event) => {
        var d = moment(event.target.value);
        if (d.isValid()) {
            getAccountPeriods(event.target.value);
        }
    };
    const handleNumericChange = (values, sourceInfo) => {
        if (sourceInfo.event) {
            var targetVar = sourceInfo.event.target.name;
            var targetVal = values.floatValue;

            switch (targetVar) {
                case "ManualCurrentRate":
                    targetVal = targetVal / 100;
                    break;
                default:
                    break;
            }

            //setAccountDetail({ ...accountDetail, [targetVar]: targetVal, [targetVar + 'IsUpdated']: true });
            setAccountDetail({ ...accountDetail, [targetVar]: targetVal, ['UpdatedBy']: activeAccount ? activeAccount.name : authUsername });
            setHasAccountChanged(true);
        }
    };
    const getAccountEndDate = (accountData) => {
        if (!accountData)
            accountData = accountDetail;
        if (accountData) {
            return includeManualForecast ? accountData.EndDate : accountData.OriginalEndDate;
        }
        return null;
    };

    const handleLedgerNumericChange = (fiscalMonth, budgetExpense, values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
            return;
        }
        let newValue = values.floatValue ?? null;
        if (!isNumeric(newValue)) {
            sourceInfo.event.target.value = '';
        }
        let clone = [...accountLedgerByMonth];
        clone = storeLedgerValue(newValue, fiscalMonth, budgetExpense, clone);
        if (clone) {
            setAccountLedgerByMonth([...clone]);
            setHasAccountChanged(true);
        }
    };
    const storeLedgerValue = (amount, fiscalMonth, budgetExpense, ledgerByMonth) => {
        if (ledgerByMonth) {
            let thisLedgerIndex = ledgerByMonth.findIndex((l) => l.FiscalYear === fiscalMonth.FiscalYearNumber && l.FiscalMonth === fiscalMonth.FiscalPeriodNumber && Number(l.LedgerAccount) === Number(budgetExpense.LedgerAccountId));
            if (thisLedgerIndex >= 0) {
                let item = ledgerByMonth[thisLedgerIndex];
                item.LedgerAccount = '' + budgetExpense.LedgerAccountId + '';
                item.Amount = amount;
                item.IsUpdated = true;
                item.UpdatedBy = activeAccount ? activeAccount.name : authUsername;
                ledgerByMonth[thisLedgerIndex] = item;
            }
            else {
                let item = {
                    Account: account,
                    Amount: amount,
                    ApplyFA: budgetExpense.ApplyFA,
                    FiscalMonth: fiscalMonth.FiscalPeriodNumber,
                    FiscalYear: fiscalMonth.FiscalYearNumber,
                    LedgerAccount: '' + budgetExpense.LedgerAccountId + '',
                    LedgerAccountandDesc: budgetExpense.LedgerAccount,
                    comment: '',
                    IsUpdated: true,
                    UpdatedBy: activeAccount ? activeAccount.name : authUsername
                };
                ledgerByMonth.push(item);
            }
            return ledgerByMonth;
        }
    };

    const handleEmployeeFundingNumericChange = (context, values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
            return;
        }
        let newValue = values.floatValue ?? null;
        if (!isNumeric(newValue)) {
            sourceInfo.event.target.value = '';
        }
        else {
            newValue = newValue / 100;
        }
        let clone = [...accountEmployeeFunding];
        clone = storeEmployeeFunding(newValue, context.EmployeeSemester, context.EmployeeEarning, clone);
        if (clone) {
            setAccountEmployeeFunding([...clone]);
            setHasAccountChanged(true);
        }

    };
    const storeEmployeeFunding = (fundingPercent, employeeSemester, employeeEarning, employeeFunding, employeeFundingPercent) => {
        if (employeeFunding) {

            let thisEFIndex = employeeFunding.findIndex((ef) =>
                ef.StartDate === employeeSemester.MinStartDate &&
                ef.EmplId.trim() === employeeEarning.EmplId.trim() &&
                ef.Position === employeeEarning.Position &&
                ef.EmplRcd === employeeEarning.EmplRcd
            );
            if (thisEFIndex >= 0) {
                let item = employeeFunding[thisEFIndex];
                if (employeeFundingPercent && employeeFundingPercent.Value === null && fundingPercent === null) {
                    //Ignore it
                }
                else {
                    if (item.FundingPercent !== fundingPercent) {
                        item.FundingPercent = fundingPercent;
                        item.IsUpdated = true;
                        item.UpdatedBy = activeAccount ? activeAccount.name : authUsername;
                        item.IsForecast = true;
                        employeeFunding[thisEFIndex] = item;
                    }
                    else {
                        //Ignore, it is the same value
                    }
                }
            }
            else {
                if (isNumeric(fundingPercent)) {
                    let item = {
                        Employee: employeeEarning.Employee,
                        EmplId: employeeEarning.EmplId,
                        EmplRcd: employeeEarning.EmplRcd,
                        Title: employeeEarning.Title,
                        EmplClass: null,
                        StaffType: employeeEarning.StaffType,
                        EmplStatus: employeeEarning.EmplStatus,
                        FiscalSalary: null,
                        AYPayRate: employeeEarning.AYPayRate,
                        SummerPayRate: employeeEarning.SummerPayRate,
                        FTE: employeeEarning.FTE,
                        FTETotal: null,
                        PayPeriodRate: employeeSemester.AcadPeriod.startsWith('Sum') ? employeeEarning.SummerPayRate : employeeEarning.AYPayRate,
                        PayGroup: employeeEarning.PayGroup,
                        Position: employeeEarning.Position,
                        WorkDays: employeeSemester.Workdays,
                        AvgDist: null,
                        FundingPercent: fundingPercent,
                        StartDate: employeeSemester.MinStartDate,
                        EndDate: employeeSemester.MaxEndDate,
                        SalaryForecast: null,
                        Account: accountId,
                        AccountEndDate: null,
                        FiscalYear: employeeSemester.FiscalYear,
                        FiscalMonth: employeeSemester.FiscalMonth,
                        Semester: employeeSemester.AcadPeriod,
                        IsUpdated: true,
                        UpdatedBy: activeAccount ? activeAccount.name : authUsername,
                        IsForecast: true,
                    };
                    employeeFunding.push(item);
                }
            }

            return employeeFunding;
        }
    };

    const handleEmployeeEarningNumericChange = (ix, values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
            return;
        }
        if (accountEmployeeEarnings) {

            let clone = [...accountEmployeeEarnings];
            if (ix >= 0) {
                let item = clone[ix];

                if (sourceInfo.event) {
                    var targetVar = sourceInfo.event.target.name;
                    var targetVal = values.floatValue;

                    //setAccountDetail({ ...accountDetail, [targetVar]: targetVal, [targetVar + 'IsUpdated']: true });
                    item = { ...item, [targetVar]: targetVal, ['UpdatedBy']: activeAccount ? activeAccount.name : authUsername };
                    clone[ix] = item;

                    switch (targetVar) {
                        case "FTE":
                            //updateEmployeeFundings(item);
                            break;
                        default:
                            break;
                    }

                    setAccountEmployeeEarnings([...clone]);
                    setHasAccountChanged(true);
                }
            }

        }

    }
    const handleEmployeeEarningChange = (ix, event) => {

        if (accountEmployeeEarnings) {

            let clone = [...accountEmployeeEarnings];
            if (ix >= 0) {
                let item = clone[ix];

                if (event.target.name) {
                    var targetVar = event.target.name;
                    var targetVal = event.target.value;

                    //setAccountDetail({ ...accountDetail, [targetVar]: targetVal, [targetVar + 'IsUpdated']: true });
                    item = { ...item, [targetVar]: targetVal, ['UpdatedBy']: activeAccount ? activeAccount.name : authUsername };
                    clone[ix] = item;

                    switch (targetVar) {
                        case "FTE":
                            //updateEmployeeFundings(item);
                            break;
                        default:
                            break;
                    }

                    setAccountEmployeeEarnings([...clone]);
                    setHasAccountChanged(true);
                }
            }

        }

        //switch (event.target.name) {
        //    case "EndDate":
        //        var d = moment(event.target.value);
        //        if (d.isValid()) {
        //            setAccountDetail({ ...accountDetail, [event.target.name]: event.target.value, ['UpdatedBy']: activeAccount ? activeAccount.name : authUsername });
        //        }
        //        break;
        //    default:
        //        setAccountDetail({ ...accountDetail, [event.target.name]: event.target.value, ['UpdatedBy']: activeAccount ? activeAccount.name : authUsername });
        //        break;
        //}
        setHasAccountChanged(true);
    }

    const updateEmployeeFundings = (forEmpEarning) => {
        if (accountEmployeeFunding) {
            let empFundings = accountEmployeeFunding.filter((ef) =>
                ef.EmplId.trim() === forEmpEarning.EmplId.trim() &&
                ef.Position === forEmpEarning.Position &&
                ef.EmplRcd === forEmpEarning.EmplRcd
            );
            if (empFundings.length > 0) {
                let clone = [...accountEmployeeFunding];
                empFundings.forEach(eff => {
                    let thisIX = accountEmployeeFunding.findIndex((ef) =>
                        ef.StartDate === eff.StartDate &&
                        ef.EmplId.trim() === eff.EmplId.trim() &&
                        ef.Position === eff.Position &&
                        ef.EmplRcd === eff.EmplRcd
                    );
                    if (thisIX >= 0) {
                        let thisItem = clone[thisIX];
                        thisItem.FTE = forEmpEarning.FTE;
                        thisItem.IsUpdated = true;
                        thisItem.UpdatedBy = activeAccount ? activeAccount.name : authUsername;
                        thisItem.IsForecast = true;
                        clone[thisIX] = thisItem;
                    }
                });
                setAccountEmployeeEarnings([...clone]);
            }
        }
    }
    const handleAccountChange = (event) => {
        switch (event.target.name) {
            case "EndDate":
                var d = moment(event.target.value);
                if (d.isValid()) {
                    setAccountDetail({ ...accountDetail, [event.target.name]: event.target.value, ManualEndDate: event.target.value, ['UpdatedBy']: activeAccount ? activeAccount.name : authUsername });
                }
                break;
            default:
                setAccountDetail({ ...accountDetail, [event.target.name]: event.target.value, ['UpdatedBy']: activeAccount ? activeAccount.name : authUsername });
                break;
        }
        setHasAccountChanged(true);
    }
    const getDirectCostsByMonth = (fiscalMonth, applyFA) => {
        let returnValue = 0;
        if (accountBudgetExpenses) {
            let accountLedgers = accountBudgetExpenses.filter(x => x.ApplyFA === applyFA);
            if (accountLedgers && accountLedgers.length > 0) {
                accountLedgers.forEach(al => {
                    returnValue += getLedgerForecastByMonth(fiscalMonth, al) ?? 0;
                });
            }

        }
        return returnValue;
    }
    const getIndirectCostsByMonth = (fiscalMonth) => {
        let returnValue = 0;
        if (accountBudgetExpenses) {
            let accountLedgers = getIndirectCostLedgerAccounts();
            if (accountLedgers && accountLedgers.length > 0) {
                accountLedgers.forEach(al => {
                    returnValue += getDirectCostsByMonth(fiscalMonth, 'Yes') * (getCurrentRate() / 100);
                });
            }

        }
        return returnValue;
    }
    const getLedgerForecastByMonth = (fiscalMonth, budgetExpense) => {
        let thisForecast = null;

        if (!includeManualForecast) {
            //return thisForecast;
        }

        if (Number(budgetExpense.LedgerAccountId) >= 7200 && Number(budgetExpense.LedgerAccountId) < 7520) {
            let thisLedger = null;
            if (accountLedgerByMonth) {
                if (budgetExpense.LedgerAccount === "7499 Netcom") {
                    //get this month's forecasts by month Personal Services lines times the netCom rate
                    let value = 0;
                    if (accountBudgetExpenses) {
                        accountBudgetExpenses.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => value += getLedgerForecastByMonth(fiscalMonth, x));
                    }
                    thisForecast += value * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm : 0);
                }
                else if (budgetExpense.LedgerAccount === "7499 Risk Mgmt") {
                    //get this month's forecasts by month Personal Services lines times the riskMgmt rate
                    let value = 0;
                    if (accountBudgetExpenses) {
                        accountBudgetExpenses.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => value += getLedgerForecastByMonth(fiscalMonth, x));
                    }
                    thisForecast += value * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt : 0);
                }
                else {
                    thisLedger = accountLedgerByMonth.find((l) => Number(l.FiscalYear) === Number(fiscalMonth.FiscalYearNumber) && Number(l.FiscalMonth) === Number(fiscalMonth.FiscalPeriodNumber) && (l.LedgerAccountandDesc === budgetExpense.LedgerAccount || Number(l.LedgerAccount) === Number(budgetExpense.LedgerAccountId)));
                    if (thisLedger) {
                        if (thisLedger.Amount != null) {
                            thisForecast += thisLedger.Amount ?? null;
                        }
                    }
                }
            }
        }
        else {
            if (Number(budgetExpense.LedgerAccountId) < 7200) {
                let thisLedgerAccountStaffType = parameters.LedgerAccountsStaffType.find(x => Number(x.ledgerAccountId) === Number(budgetExpense.LedgerAccountId));
                if (thisLedgerAccountStaffType) {
                    let theseEarnings = accountEmployeeEarnings ? accountEmployeeEarnings.filter(aee => thisLedgerAccountStaffType.staffTypes.includes(aee.StaffType)) : null;
                    if (theseEarnings) {
                        theseEarnings.forEach(e => {
                            let theseSemesters = accountEmployeeSemesters ? accountEmployeeSemesters.filter(aes => Number(aes.FiscalYear) === Number(fiscalMonth.FiscalYearNumber) && Number(aes.FiscalMonth) === Number(fiscalMonth.FiscalPeriodNumber)) : null;
                            if (theseSemesters) {
                                theseSemesters.forEach(sem => {
                                    let empForecastBySem = getEmployeeForecastsBySemester(e, sem);
                                    if (Number(budgetExpense.LedgerAccountId) >= 7100 && Number(budgetExpense.LedgerAccountId) <= 7105) {
                                        thisForecast += empForecastBySem.Earnings;
                                    }
                                    else if (Number(budgetExpense.LedgerAccountId) === 7111) {
                                        thisForecast += empForecastBySem.TuitionRemissionForecast;
                                    }
                                    else if (Number(budgetExpense.LedgerAccountId) >= 7112 && Number(budgetExpense.LedgerAccountId) <= 7117) {
                                        thisForecast += empForecastBySem.EREForecast;
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }

        return thisForecast;
    }
    const getForecastTotals = () => {

        let returnBudget = null;
        let returnExpenses = null;
        let returnEncumbrances = null;
        let returnForecast = null;
        let returnAvailable = null;


        let dirTotals = getDirectCosts('No');
        let indirTotals = getDirectCosts('Yes');

        if (dirTotals.Budget || indirTotals.Budget) {
            returnBudget = 0;
            returnBudget += isNumericDefault(dirTotals.Budget, 0) + isNumericDefault(indirTotals.Budget, 0);
        }
        if (dirTotals.Expenses || indirTotals.Expenses) {
            returnExpenses = 0;
            returnExpenses += isNumericDefault(dirTotals.Expenses, 0) + isNumericDefault(indirTotals.Expenses, 0);
        }
        if (dirTotals.Encumbrances || indirTotals.Encumbrances) {
            returnEncumbrances = 0;
            returnEncumbrances += isNumericDefault(dirTotals.Encumbrances, 0) + isNumericDefault(indirTotals.Encumbrances, 0);
        }
        if (dirTotals.Forecast || indirTotals.Forecast) {
            returnForecast = 0;
            returnForecast += isNumericDefault(dirTotals.Forecast, 0) + isNumericDefault(indirTotals.Forecast, 0);
        }
        if (dirTotals.Available || indirTotals.Available) {
            returnAvailable = 0;
            returnAvailable += isNumericDefault(dirTotals.Available, 0) + isNumericDefault(indirTotals.Available, 0);
        }

        if (accountBudgetExpenses) {
            getIndirectCostLedgerAccounts().forEach(budgetExpense => {
                let thisForecastTotal = getAccountLedgerForecast(budgetExpense);
                returnBudget += budgetExpense.Budget;
                returnExpenses += budgetExpense.Expenses;
                returnEncumbrances += getIndirectAccountLedgerEncumbrances(budgetExpense);
                //if (budgetExpense.LedgerAccount.includes("ASUF")) {
                //    returnEncumbrances += budgetExpense.Encumbrances;
                //}
                //else {
                //    returnEncumbrances += getDirectCosts('Yes').Encumbrances * getCurrentRate() / 100;
                //}
                returnForecast += thisForecastTotal;
                returnAvailable += getIndirectAccountLedgerAvailable(budgetExpense);
            });
        }

        return { Budget: returnBudget, Expenses: returnExpenses, Encumbrances: returnEncumbrances, Forecast: returnForecast, Available: returnAvailable };

    }
    const getIndirectForecastTotal = () => {
        let returnValue = 0;
        if (accountFiscalMonths) {
            accountFiscalMonths.forEach(fm => {
                returnValue += getDirectCostsByMonth(fm, 'Yes') * (getCurrentRate() / 100);
            });
        }
        return returnValue;
    }
    const getEmployeeForecastsBySemester = (employeeEarning, empSemester) => {
        let employeeEarningsValue = 0;
        let employeeEREForecast = 0;
        let tuitionRemForecast = 0;

        let thisPayGroup = employeeEarning.PayGroup;
        if (!thisPayGroup && employeeEarning.EmplStatusPayGroup)
            thisPayGroup = employeeEarning.EmplStatusPayGroup.split(' ')[1]

        //find the ERE Inflated Rate
        let ereRate = parameters.ERERates.find((rate) => rate.StaffType === employeeEarning.StaffType && rate.FiscalYear === Number(empSemester.FiscalYear));
        let thisERERate = 0;
        if (ereRate)
            thisERERate = ereRate.ERERate;

        let ereRateInflated = 0;
        let lastYear = Math.max(...parameters.ERERates.map(r => Number(r.FiscalYear)));
        if (Number(empSemester.FiscalYear) > lastYear) {
            ereRate = parameters.ERERates.find((rate) => rate.StaffType === employeeEarning.StaffType && rate.FiscalYear === lastYear);
            if (ereRate)
                thisERERate = ereRate.ERERate;
            ereRateInflated = thisERERate * Math.pow((1 + parameters.Inflation), (Number(empSemester.FiscalYear) - lastYear));
        }
        else {
            ereRateInflated = thisERERate;
        }

        let tuitionRemInflated = 0;
        //Find the tuition reimbursement rate
        if (employeeEarning.StaffType === 'RA/TA') {
            let thisFTE = employeeEarning.FTE != null ? employeeEarning.FTE : employeeEarning.EmplStatusPayGroup.toString().substr(6, 1);
            thisFTE = Number(thisFTE) >= .5 ? 1 : Number(thisFTE) >= .25 ? .5 : 0;
            let tuitionRemissionRate = parameters.TuitionRemissionRates.find(x => x.FiscalYear === Number(empSemester.FiscalYear));
            if (tuitionRemissionRate == null) {
                tuitionRemissionRate = parameters.TuitionRemissionRates.findLast(x => x.FiscalYear !== Number(empSemester.FiscalYear));
            }
            let tuitionRate = 0;
            if (tuitionRemissionRate) {
                tuitionRate = (empSemester.AcadPeriod.startsWith('Sum') ? tuitionRemissionRate.SummerPerWorkDay : tuitionRemissionRate.AcademicPerWorkDay) * thisFTE;
                if (Number(empSemester.FiscalYear) > tuitionRemissionRate.FiscalYear) {
                    tuitionRemInflated = tuitionRate * Math.pow(
                        (1 + parameters.Inflation),
                        (Number(empSemester.FiscalYear) - tuitionRemissionRate.FiscalYear)
                    );
                }
                else {
                    tuitionRemInflated = tuitionRate;
                }
            }
        }

        let empFundingPercent = getEmployeeFundingPercent(employeeEarning, empSemester);

        if (empFundingPercent && empFundingPercent.DisplayInput) {
            employeeEarningsValue += (empSemester.AcadPeriod.startsWith('Sum') ? employeeEarning.SummerPayRate : employeeEarning.AYPayRate) * empSemester.Workdays / 10 * (empFundingPercent.Value / 100);
            employeeEREForecast += Math.round(((empSemester.AcadPeriod.startsWith('Sum') ? employeeEarning.SummerPayRate : employeeEarning.AYPayRate) * empSemester.Workdays / 10 * (empFundingPercent.Value / 100) + Number.EPSILON) * 100) / 100 * ereRateInflated;
            tuitionRemForecast += (empSemester.Workdays * tuitionRemInflated * (empFundingPercent.Value / 100));
        }

        return { Earnings: Math.round((employeeEarningsValue + Number.EPSILON) * 100) / 100, EREForecast: employeeEREForecast, TuitionRemissionForecast: tuitionRemForecast };


    }
    const getEmployeeForecasts = (employeeEarning) => {


        let employeeEarningsValue = 0;
        let employeeEREForecast = 0;
        let tuitionRemForecast = 0;
        if (accountEmployeeSemesters) {
            accountEmployeeSemesters.forEach((aes, i) => {

                let empForecast = getEmployeeForecastsBySemester(employeeEarning, aes);
                employeeEarningsValue += empForecast.Earnings;
                employeeEREForecast += empForecast.EREForecast;
                tuitionRemForecast += empForecast.TuitionRemissionForecast;
            });
        }
        return { Earnings: Math.round((employeeEarningsValue + Number.EPSILON) * 100) / 100, EREForecast: employeeEREForecast, TuitionRemissionForecast: tuitionRemForecast };
    };
    const getEmployeeFundingPercent = (employeeEarning, employeeSemester) => {

        let value = null;
        let displayInput = true;
        let isUpdated = null;
        let thisEF = null;
        let thisPayGroup = employeeEarning.PayGroup;
        if (!thisPayGroup && employeeEarning.EmplStatusPayGroup)
            thisPayGroup = employeeEarning.EmplStatusPayGroup.split(' ')[1]

        if (accountEmployeeFunding) {
            thisEF = accountEmployeeFunding.find((ef) =>
                ef.StartDate === employeeSemester.MinStartDate &&
                ef.EmplId.trim() === employeeEarning.EmplId.trim() &&
                ef.Position === employeeEarning.Position &&
                ef.EmplRcd === employeeEarning.EmplRcd
            );
            if (thisPayGroup === 'FSW' && !employeeSemester.AcadPeriod.startsWith('Sum')) {
                value = null;
                displayInput = false;
            }
            else {
                if (thisPayGroup === 'ACD' && employeeSemester.AcadPeriod.startsWith('Sum')) {
                    value = null;
                    displayInput = false;
                }
                else {
                    if (thisEF) {
                        if (thisEF.EmplStatus === 'W') {
                            //if (thisPayGroup === 'FSW') {
                            //    value = Number(thisEF.FundingPercent) * 100;
                            //    displayInput = true;
                            //}
                            //if (thisPayGroup === 'GRD') {
                            //    value = Number(thisEF.FundingPercent) * 100;
                            //    displayInput = true;
                            //}
                            //    If rst!paygroup = "FSW" And Worksheets("Parameters").Cells(5, 8) = "Yes" Then
                            //        xls.Cells(irow, icol) = rst!fundingPercent
                            //    End If
                            //    If rst!paygroup = "GRD" And Worksheets("Parameters").Cells(7, 8) = "Yes" Then
                            //        xls.Cells(irow, icol) = rst!fundingPercent
                            //    End If
                            //assume that the options from the parameters (ForecastYourOptions) is the default which is 'No'
                            //value = Number(thisEF.FundingPercent) * 100;
                            displayInput = true;
                        }
                        else {
                            if (thisEF.FundingPercent !== null) {
                                value = Number(thisEF.FundingPercent) * 100;
                            }
                            else {
                                value = null;
                            }
                            displayInput = true;
                        }
                    }
                }
            }
            if (thisEF && thisEF.IsForecast) {
                if (isNumeric(thisEF.FundingPercent)) {
                    value = Number(thisEF.FundingPercent) * 100;
                }
                displayInput = true;
                isUpdated = true;
            }

        }

        return { Value: value, DisplayInput: displayInput, IsUpdated: isUpdated }

    };
    const getDirectCosts = (applyFA) => {
        let budgetValue = 0;
        let expensesValue = 0;
        let encumbrancesValue = 0;
        let forecastValue = 0;
        let availableValue = 0;

        let ledgerAccounts = getDirectCostLedgerAccounts()
        if (ledgerAccounts) {
            ledgerAccounts = [...ledgerAccounts.filter(l => l.ApplyFA === applyFA && (l.LedgerAccount.startsWith("7499") || Number(l.LedgerAccountId) < 7500))];
            if (ledgerAccounts.length > 0) {
                budgetValue = ledgerAccounts.map(x => x.Budget ? x.Budget : 0).reduce((a, v) => a + v);
                expensesValue = ledgerAccounts.map(x => x.Expenses ? x.Expenses : 0).reduce((a, v) => a + v);
                encumbrancesValue = ledgerAccounts.map(x => x.Encumbrances ? x.Encumbrances : 0).reduce((a, v) => a + v);
                ledgerAccounts.forEach(la => {
                    forecastValue += getAccountLedgerForecast(la);
                });
            }
        }
        availableValue = budgetValue - (expensesValue + encumbrancesValue + forecastValue);
        return { Budget: budgetValue, Expenses: expensesValue, Encumbrances: encumbrancesValue, Forecast: forecastValue, Available: availableValue };
    };
    const getFACostsTotal = (applyFA, fiscalYearNumber, fiscalPeriodNumber) => {
        let returnValue = 0;
        if (accountFiscalMonths) {
            accountFiscalMonths.forEach(x => {
                returnValue += getDirectCostsByMonth(applyFA, x.FiscalYearNumber, x.FiscalPeriodNumber) * (getCurrentRate());
            });
        }
        return returnValue;
    };
    const getAccountLedgerForecast = (budgetExpense) => {
        let thisForecast = 0;
        if (Number(budgetExpense.LedgerAccountId) >= 7100 && Number(budgetExpense.LedgerAccountId) <= 7105) {
            let thisLedgerAccountStaffType = parameters.LedgerAccountsStaffType.find(x => Number(x.ledgerAccountId) === Number(budgetExpense.LedgerAccountId));
            if (thisLedgerAccountStaffType) {
                let theseEarnings = accountEmployeeEarnings ? accountEmployeeEarnings.filter(aee => thisLedgerAccountStaffType.staffTypes.includes(aee.StaffType)) : null;
                if (theseEarnings) {
                    theseEarnings.forEach(e => {
                        let thisEmpForecast = getEmployeeForecasts(e);
                        if (thisEmpForecast)
                            thisForecast += thisEmpForecast.Earnings;
                    });
                }
            }
        }
        else if (Number(budgetExpense.LedgerAccountId) === 7111) {
            let thisLedgerAccountStaffType = parameters.LedgerAccountsStaffType.find(x => Number(x.ledgerAccountId) === Number(budgetExpense.LedgerAccountId));
            if (thisLedgerAccountStaffType) {
                let theseEarnings = accountEmployeeEarnings ? accountEmployeeEarnings.filter(aee => thisLedgerAccountStaffType.staffTypes.includes(aee.StaffType)) : null;
                if (theseEarnings) {
                    theseEarnings.forEach(e => {
                        let thisEmpForecast = getEmployeeForecasts(e);
                        if (thisEmpForecast)
                            thisForecast += thisEmpForecast.TuitionRemissionForecast;
                    });
                }
            }
        }
        else if (Number(budgetExpense.LedgerAccountId) >= 7112 && Number(budgetExpense.LedgerAccountId) <= 7117) {
            let thisLedgerAccountStaffType = parameters.LedgerAccountsStaffType.find(x => Number(x.ledgerAccountId) === Number(budgetExpense.LedgerAccountId));
            if (thisLedgerAccountStaffType) {
                let theseEarnings = accountEmployeeEarnings ? accountEmployeeEarnings.filter(aee => thisLedgerAccountStaffType.staffTypes.includes(aee.StaffType)) : null;
                if (theseEarnings) {
                    theseEarnings.forEach(e => {
                        let thisEmpForecast = getEmployeeForecasts(e);
                        if (thisEmpForecast)
                            thisForecast += thisEmpForecast.EREForecast;
                    });
                }
            }
        }
        else if (Number(budgetExpense.LedgerAccountId) >= 7500 && Number(budgetExpense.LedgerAccountId) <= 7521) {
            if (Number(budgetExpense.LedgerAccountId) === 7521 && budgetExpense.LedgerAccount.includes("ASUF")) {
                thisForecast = (getDirectCosts('Yes').Forecast ?? 0) * (accountDetail.ASUFRate ?? 0);
            }
            else {
                thisForecast = getIndirectForecastTotal(); 
            }
        }
        else {
            if (budgetExpense.LedgerAccount === "7499 Netcom") {
                //get this month's forecasts by month Personal Services lines times the netCom rate
                let value = 0;
                if (accountBudgetExpenses && accountFiscalMonths) {
                    accountBudgetExpenses.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => {
                        accountFiscalMonths.forEach(fm => {
                            value += getLedgerForecastByMonth(fm, x)
                        });
                    });
                }
                thisForecast += value * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm : 0);
            }
            else if (budgetExpense.LedgerAccount === "7499 Risk Mgmt") {
                //get this month's forecasts by month Personal Services lines times the riskMgmt rate
                let value = 0;
                if (accountBudgetExpenses && accountFiscalMonths) {
                    accountBudgetExpenses.filter(be => be.LedgerAccount.includes("Personal Services")).forEach(x => {
                        accountFiscalMonths.forEach(fm => {
                            value += getLedgerForecastByMonth(fm, x)
                        });
                    });
                }
                thisForecast += value * (parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt : 0);
            }
            else {
                thisForecast = accountLedgerByMonth && accountLedgerByMonth.filter((l) => (l.LedgerAccountandDesc === budgetExpense.LedgerAccount || l.LedgerAccount === budgetExpense.LedgerAccountId)).length > 0
                    ?
                    accountLedgerByMonth.filter((l) => (l.LedgerAccountandDesc === budgetExpense.LedgerAccount || l.LedgerAccount === budgetExpense.LedgerAccountId)).map(x => x.Amount ? x.Amount : 0).reduce((a, v) => a + v)
                    :
                    0;
            }
        }
        return thisForecast;
    };
    const getAccountLedgerAvailable = (budgetExpense) => {
        if (budgetExpense.Missing) {
            return null;
        }
        let thisBudget = budgetExpense.Budget ? budgetExpense.Budget : 0;
        let thisExpenses = budgetExpense.Expenses ? budgetExpense.Expenses : 0;
        let thisEncumbrances = budgetExpense.Encumbrances ? budgetExpense.Encumbrances : 0;
        let thisForecast = getAccountLedgerForecast(budgetExpense);
        if (Number(budgetExpense.LedgerAccountId) > 0)
            return thisBudget - (thisExpenses + thisEncumbrances + thisForecast);
        else
            return null;
    };
    const getIndirectAccountLedgerEncumbrances = (budgetExpense) => {
        if (budgetExpense.LedgerAccount.includes("ASUF")) {
            let returnValue = 0;
            let thisBudget = budgetExpense.Budget ? budgetExpense.Budget : 0;
            let thisExpenses = budgetExpense.Expenses ? budgetExpense.Expenses : 0;
            let thisForecast = getAccountLedgerForecast(budgetExpense);
            returnValue = thisBudget - thisExpenses - thisForecast;
            if (returnValue < 0)
                returnValue = 0;
            return returnValue;
        }
        else {
            return budgetExpense.Encumbrances;
        }

    };
    const getIndirectAccountLedgerAvailable = (budgetExpense) => {
        let thisBudget = budgetExpense.Budget ? budgetExpense.Budget : 0;
        let thisExpenses = budgetExpense.Expenses ? budgetExpense.Expenses : 0;
        let thisEncumbrances = getIndirectAccountLedgerEncumbrances(budgetExpense);
        //let thisForecast = getIndirectForecastTotal();
        let thisForecast = getAccountLedgerForecast(budgetExpense);
        return thisBudget - (thisExpenses + thisEncumbrances + thisForecast);
    };
    const getAccountFABase = () => {
        return getFABase() + (accountDetail.Account.startsWith('GR') ? ' (' + accountDetail.FARateType + '/' + accountDetail.WorkdayCampusType + ')' : '')
    };
    const getFABase = (dataAccount) => {
        if (!dataAccount) {
            dataAccount = accountDetail;
        }
        let returnValue = '';
        if (dataAccount) {
            if (dataAccount.workdayRateAgreement?.startsWith('TDC')) {
                returnValue = 'TDC';
            }
            if (dataAccount.workdayRateAgreement?.startsWith('MTD')) {
                returnValue = 'MTDC';
            }
            if (["ABOR", "EOSS1", "EOSS3"].includes(dataAccount.ASCExtraType)) {
                returnValue = dataAccount.ASCExtraType;
            }
        }
        return returnValue;
    };
    const getActualFAPercent = () => {
        let retPerc = 0;
        let directCostsExpenses = getDirectCosts('Yes').Expenses;
        if (directCostsExpenses !== 0) {
            let la = getIndirectCostLedgerAccounts().find(l => l.LedgerAccount.startsWith("7520") || l.LedgerAccount.startsWith("7500"))
            if (la) {
                retPerc = la.Expenses / directCostsExpenses * 100;
            }
        }
        return retPerc;
    };
    const getAvailableToSpend = () => {
        let available = 0;
        if (accountBudgetExpenses) {
            let la = getIndirectCostLedgerAccounts().find(l => l.LedgerAccount.startsWith("7521"));
            let asufAvailable = 0;
            if (la) {
                asufAvailable = getIndirectAccountLedgerAvailable(la);
            }
            available = (getCurrentRate() === 0)
                ?
                getForecastTotals().Available
                :
                (
                    getForecastTotals().Available - asufAvailable) / (1 + (getCurrentRate() / 100)
                );
        }
        return available;
    }

    const addEmployeeCallBack = (data) => {
        if (data && data.length > 0) {
            let eeData = [...accountEmployeeEarnings];
            data.forEach(d => {
                eeData.push({
                    Employee: d.Employee,
                    EmplIdPositionRcd: d.EmplId + '-' + d.PositionNumber + '-' + d.EmplRcd,
                    EmplId: d.EmplId,
                    Position: d.PositionNumber,
                    EmplRcd: d.EmplRcd,
                    Title: d.Title,
                    StaffType: d.StaffType,
                    AYPayRate: d.AYPayRate,
                    SummerPayRate: d.SummerPayRate,
                    EmplStatus: d.EmplStatus,
                    PayGroup: d.PayGroup,
                    FTE: d.FTE,
                    Forecasts: 0,
                    Earnings: 0,
                    EmplStatusPayGroup: d.EmplStatus + ' ' + (d.PayGroup ? d.PayGroup : (d.StaffType === 'RA/TA' ? 'GRD' : 'SAL')) + ' ' + d.FTE  //strFTE
                });
            });
            setAccountEmployeeEarnings([...eeData]);

            //Now we need to add the missing ledger accounts
            let beData = addNeededLedgerRows(accountDetail, accountEmployeeFunding, accountBudgetExpenses, ledgerAccountDirectory, eeData)
            setAccountBudgetExpenses([...beData]);
        }
    };
    const handleSave = async () => {
        setSavingToastIcon(<FontAwesomeIcon icon={faSpinner} spin className="" />);
        setSavingToastMessage('The account is being saved...');
        toggleSavingToast();
        setSaveCompleted(false);
        setSaveInProgress(true);

        let errorMessage = null;

        try {
            await fetch('/api/account/detail/' + account, {
                method: 'PUT',
                body: JSON.stringify(accountDetail),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                if (response.ok)
                    return response.json();
                throw new Error(`Error occurred saving account detail: ${response.statusText}`);
            }
            ).catch(ex => {
                console.error(ex);
                errorMessage = ex.message;
                throw ex;
            });



            let ledgerByMonthRecords = accountLedgerByMonth.filter((lbm) => lbm.IsUpdated)
            if (ledgerByMonthRecords && ledgerByMonthRecords.length > 0) {

                await fetch('/api/account/ledgerbymonth?account=' + encodeURIComponent(account), {
                    method: 'PUT',
                    body: JSON.stringify(ledgerByMonthRecords),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    if (response.ok)
                        return response.json();
                    throw new Error(`Error occurred saving ledger by month: ${response.statusText}`);
                }
                ).catch(ex => {
                    console.error(ex);
                    errorMessage = ex.message;
                    throw ex;
                });

            }

            let empFundingRecords = accountEmployeeFunding.filter((ef) => ef.IsUpdated)
            if (empFundingRecords && empFundingRecords.length > 0) {

                await fetch('/api/account/employeefunding?account=' + encodeURIComponent(account), {
                    method: 'PUT',
                    body: JSON.stringify(empFundingRecords),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    if (response.ok)
                        return response.json();
                    throw new Error(`Error occurred saving employee funding: ${response.statusText}`);
                }
                ).catch(ex => {
                    console.error(ex);
                    errorMessage = ex.message;
                    throw ex;
                });

            }

            setTimeout(() => {
                setShowSavingToast(false);
                setTimeout(() => {
                    setShowSavingToast(true);
                    setSaveCompleted(true);
                    setSavingToastIcon('success');
                    setSavingToastMessage('The account has been saved successfully.');
                    setTimeout(() => {
                        setShowSavingToast(false);
                    }, 3000);
                }, 500);
            }, 2000);
            setHasAccountChanged(false);
            setSaveInProgress(false);
        }
        catch (ex) {
            setTimeout(() => {
                setShowSavingToast(false);
                setTimeout(() => {
                    setShowSavingToast(true);
                    setSavingToastIcon('danger');
                    setSavingToastMessage(ex.message);
                    setTimeout(() => {
                        setShowSavingToast(false);
                    }, 3000);
                }, 500);
            }, 2000);
            setSaveInProgress(false);
            console.error(ex.message);
        }

        
    }
    const toggleSavingToast = () => {
        setShowSavingToast(!showSavingToast);
    }
    const deleteEmployeeEarnings = (i) => {

        let employeeEarning = accountEmployeeEarnings[i];
        //first remove the employeeFundings
        if (accountEmployeeFunding && employeeEarning) {
            let clone = [...accountEmployeeFunding];


            let efs = clone.filter((aef, index) => (aef.EmplId.trim() === employeeEarning.EmplId.trim() &&
                aef.Position === employeeEarning.Position &&
                aef.EmplRcd === employeeEarning.EmplRcd));



            efs.forEach(efd => {
                let thisEFIx = clone.findIndex((ef) =>
                    ef.StartDate === efd.StartDate &&
                    ef.EmplId.trim() === efd.EmplId.trim() &&
                    ef.Position === efd.Position &&
                    ef.EmplRcd === efd.EmplRcd
                );
                if (thisEFIx >= 0) {
                    let thisEF = clone[thisEFIx];
                    thisEF.FundingPercent = null;
                    thisEF.IsUpdated = true;
                    thisEF.UpdatedBy = activeAccount ? activeAccount.name : authUsername;
                    clone[thisEFIx] = thisEF;
                }
            });

            setAccountEmployeeFunding([...clone]);
        }



        let clone = [...accountEmployeeEarnings];
        clone.splice(i, 1);
        setAccountEmployeeEarnings([...clone]);
        setHasAccountChanged(true);


    };
    const savedStartup = (data) => {
        if (data) {
            setAccountDetail({
                ...accountDetail,
                ippPI: data.PI,
                ippGRA: data.GRA,
                ippPIActAdj: data.PIAdj,
                ippGRAActAdj: data.GRAAdj,
            });
        }

    };
    const getForecastPayPeriods = () => {
        let forecasts = { PI: 0, GRA: 0 };
        if (accountEmployeeFunding && accountEmployeeEarnings && accountEmployeeSemesters) {

            accountEmployeeEarnings.forEach(ee => {
                let thisFTE = ee.FTE != null ? ee.FTE : ee.EmplStatusPayGroup.toString().substr(6, 1);
                if (isNumeric(thisFTE)) {
                    thisFTE = Number(thisFTE);
                }
                else {
                    thisFTE = 1;
                }
                accountEmployeeSemesters.forEach(es => {
                    let empFundingPercent = getEmployeeFundingPercent(ee, es);
                    if (empFundingPercent && empFundingPercent.DisplayInput && empFundingPercent.Value) {
                        if (ee.StaffType === 'Faculty') {
                            forecasts.PI += empFundingPercent.Value / 100;
                        }
                        else if (ee.PayGroup === 'GRD') {
                            if (thisFTE === 1) {
                                forecasts.GRA += empFundingPercent.Value / 100;
                            }
                            else if (thisFTE >= 0.5) {
                                forecasts.GRA += empFundingPercent.Value / 100 * 0.5 * 2;
                            }
                            else if (thisFTE < 0.5) {
                                forecasts.GRA += empFundingPercent.Value / 100 * 0.25 * 2;
                            }
                        }
                    }

                });
            });

        }
        return forecasts;
    };

    const fillEmptyEmployeeValues = (e, currentValue, employeeSemester, employeeEarning) => {
        e.preventDefault();
        //find the semesters...
        let semesters = accountEmployeeSemesters.filter(s => moment(s.MinStartDate) > moment(employeeSemester.MinStartDate));
        if (semesters.length > 0) {
            let clone = [...accountEmployeeFunding];
            semesters.every(sem => {
                let employeeFundingPercent = getEmployeeFundingPercent(employeeEarning, sem);
                if (employeeFundingPercent.DisplayInput && isNumericDefault(employeeFundingPercent.Value, '') !== '') {
                    return false;
                }
                if (employeeFundingPercent.DisplayInput && isNumericDefault(employeeFundingPercent.Value, '') === '') {
                    //apply the value
                    clone = storeEmployeeFunding(isNumeric(currentValue) ? currentValue / 100 : currentValue, sem, employeeEarning, clone, employeeFundingPercent);
                    setHasAccountChanged(true);
                }
                return true;
            });
            setAccountEmployeeFunding([...clone]);

        }
    };
    const fillReplaceEmployeeValues = (e, currentValue, employeeSemester, employeeEarning) => {
        e.preventDefault();
        //find the semesters...
        let semesters = accountEmployeeSemesters.filter(s => moment(s.MinStartDate) > moment(employeeSemester.MinStartDate));
        if (semesters.length > 0) {
            let clone = [...accountEmployeeFunding];
            semesters.every(sem => {
                let employeeFundingPercent = getEmployeeFundingPercent(employeeEarning, sem);
                if (employeeFundingPercent.DisplayInput) {
                    //apply the value
                    clone = storeEmployeeFunding(isNumeric(currentValue) ? currentValue / 100 : null, sem, employeeEarning, clone, employeeFundingPercent);
                    setHasAccountChanged(true);
                }
                return true;
            });
            setAccountEmployeeFunding([...clone]);

        }
    };
    const fillEmptyLedgerValues = (e, currentValue, fiscalMonth, budgetExpense) => {
        e.preventDefault();
        //find the fiscal months...
        let fiscalMonths = accountFiscalMonths.filter(m => moment(m.StartDate) > moment(fiscalMonth.StartDate));
        if (fiscalMonths.length > 0) {
            let clone = [...accountLedgerByMonth];
            fiscalMonths.every(mon => {
                let forecastByMonth = getLedgerForecastByMonth(mon, budgetExpense);

                if (isNumeric(forecastByMonth)) {
                    return false;
                }
                if (!isNumeric(forecastByMonth)) {
                    //apply the value
                    clone = storeLedgerValue(currentValue, mon, budgetExpense, clone);
                    setHasAccountChanged(true);
                }
                return true;
            });
            setAccountLedgerByMonth([...clone]);
        }
    };
    const fillReplaceLedgerValues = (e, currentValue, fiscalMonth, budgetExpense) => {
        e.preventDefault();
        //find the fiscal months...
        let fiscalMonths = accountFiscalMonths.filter(m => moment(m.StartDate) > moment(fiscalMonth.StartDate));
        if (fiscalMonths.length > 0) {
            let clone = [...accountLedgerByMonth];
            fiscalMonths.every(mon => {
                clone = storeLedgerValue(currentValue, mon, budgetExpense, clone);
                return true;
            });
            setAccountLedgerByMonth([...clone]);
            //console.log('fillReplaceledgerValues', clone);
        }
    };
    const forecastBudgetsSaved = async () => {
        await getBudgetExpenseData(accountDetail, accountEmployeeFunding, ledgerAccountDirectory, accountEmployeeEarnings);
    };

    return (
        <>
            <Header />
            <SubMenu />

            <ErrorBoundary>
            {searching ? <>Loading...</> : <></>}
            {accountFound && accountDetail ?
                    <>
                        <div className="p-3 toast-container position-fixed top-0 end-0 p-3">
                            <Toast fade={true} isOpen={showSavingToast} className="mt-3">
                                <ToastHeader toggle={toggleSavingToast} icon={savingToastIcon} fade="true" aria-live="assertive" aria-atomic="true">
                                    Saving Account
                                </ToastHeader>
                                <ToastBody>
                                    {savingToastMessage}
                                </ToastBody>
                            </Toast>
                            {/*<Toast className="mt-3">*/}
                            {/*    <ToastHeader fade="true" icon="danger" aria-live="assertive" aria-atomic="true">*/}
                            {/*        Error*/}
                            {/*    </ToastHeader>*/}
                            {/*    <ToastBody>This is the error message</ToastBody>*/}
                            {/*</Toast>*/}
                        </div>

                        <div className="row mb-4">
                            <div className="col-10">
                                <div className="card mb-4 h-100">
                                    {/*<h5 class="card-header d-flex justify-content-between align-items-center">*/}
                                    {/*    Title*/}
                                    {/*    <button type="button" class="btn btn-sm btn-primary">Button</button>*/}


                                    {/*</h5>*/}
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <span><FontAwesomeIcon icon={faFileInvoice} className="pe-1" />Account Details</span>
                                        <div className="col-auto">
                                            <div className="row">
                                                <div className="col-auto px-1">
                                                    <input type="checkbox" className="btn-check" id="btn-check-outlined" defaultChecked={includeManualForecast} autoComplete="off" onClick={() => setIncludeManualForecast(!includeManualForecast)} />
                                                    {includeManualForecast ?
                                                        <label className="btn btn-outline-success btn-sm" htmlFor="btn-check-outlined">
                                                            <FontAwesomeIcon icon={faCheck} className="pe-2" />
                                                            Include Manual Forecast
                                                        </label>
                                                        :
                                                        <label className="btn btn-outline-danger btn-sm" htmlFor="btn-check-outlined">
                                                            <FontAwesomeIcon icon={faXmark} className="pe-2 text-danger" />
                                                            Include Manual Forecast
                                                        </label>
                                                    }
                                                </div>
                                                <div className="col-auto px-1">
                                                    <ForecastAccuracy account={account} payPeriodStartDate={parameters.PayPeriodStartDate} />
                                                </div>
                                                <div className="col-auto px-1">
                                                    <ExpensesByMonth account={account} />
                                                </div>
                                                <div className="col-auto px-1">
                                                    <Earnings account={account} />
                                                </div>
                                                <div className="col-auto px-1">
                                                    <Effort account={account} accountStartDate={accountDetail.StartDate} accountEndDate={getAccountEndDate(accountDetail)} employeeEarnings={accountEmployeeEarnings} employeeFunding={accountEmployeeFunding} />
                                                </div>
                                                <div className="col-auto px-1">
                                                    <Encumbrances account={account} encumbrancesTotal={getDirectCosts('Yes').Encumbrances} accountStartDate={accountDetail.StartDate} accountEndDate={getAccountEndDate(accountDetail)} employeeEarnings={accountEmployeeEarnings} employeeFunding={accountEmployeeFunding} />
                                                </div>
                                                <div className="col-auto px-1">
                                                    <button type="button" className="btn btn-sm btn-secondary" disabled={!(hasChanges || hasAccountChanged)} onClick={() => window.location.reload(false)} >
                                                        <FontAwesomeIcon icon={faDeleteLeft} className="me-2" />
                                                        Reset
                                                    </button>
                                                </div>
                                                <div className="col-auto px-1">
                                                    <button type="button" className="btn btn-sm btn-primary" disabled={!(hasChanges || hasAccountChanged)} onClick={handleSave} >
                                                        <FontAwesomeIcon icon={saveInProgress ? faSpinner : faFloppyDisk} spin={saveInProgress} className="me-2" />
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="row">
                                                <label htmlFor="txtType" className="col-sm-2 col-form-label col-form-label-sm fw-bold">
                                                    {accountDetail.WorkdayType === "Grant" ? "Award" : accountDetail.WorkdayType}
                                                </label>
                                                <div className="col-sm-2">
                                                    {/*<input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtType" value={accountDetail.Award.valueOf()} />*/}
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtType" value={accountDetail.Award ? accountDetail.Award.valueOf() : null} />
                                                </div>
                                                <label htmlFor="txtFiscalYear" className="col-sm-2 col-form-label col-form-label-sm">Fiscal Year:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtFiscalYear" value={"Current"} /> {/*value={"Current/Prior"}*/}
                                                </div>
                                                <label htmlFor="txtTimePeriod" className="col-sm-2 col-form-label col-form-label-sm">Time Period:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtTimePeriod" value={accountDetail.TimePeriod} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                {accountDetail.WorkdayType === 'Grant' ?
                                                    <>
                                                        <label htmlFor="txtPrincipalInvestigator" className="col-sm-2 col-form-label col-form-label-sm">Principal Investigator:</label>
                                                    </>
                                                    :
                                                    <>
                                                        <label htmlFor="txtAcademicEmployee" className="col-sm-2 col-form-label col-form-label-sm">Academic Employee:</label>
                                                    </>
                                                }
                                                <div className="col-sm-2">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtPrincipalInvestigator" value={accountDetail.PI ? accountDetail.PI.valueOf() : ''} />
                                                </div>
                                                <label htmlFor="txtAccountStatus" className="col-sm-2 col-form-label col-form-label-sm">Account Status:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtAccountStatus" value={accountDetail.AccountStatus} />
                                                </div>
                                                {/*<label htmlFor="txtOverride" className="col-sm-2 col-form-label col-form-label-sm">Override:</label>*/}
                                                {/*<div className="col-sm-2">*/}
                                                {/*    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtOverride" value={accountDetail.AccountStatusOverride} />*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className="row">
                                                <label htmlFor="txtAccount" className="col-sm-2 col-form-label col-form-label-sm">Account:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtAccount" value={accountDetail.Account} />
                                                </div>
                                                <label htmlFor="txtAccountNickname" className="col-sm-2 col-form-label col-form-label-sm">Account Nickname:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" className="form-control form-control-sm fw-bold bg-info-subtle text-primary" id="txtAccountNickname" value={accountDetail.NickName} onChange={handleAccountChange} name="NickName" />
                                                </div>
                                                <div className="col-sm-4">

                                                </div>
                                            </div>
                                            <div className="row">
                                                <label htmlFor="txtTitle" className="col-sm-2 col-form-label col-form-label-sm">Title:</label>
                                                <div className="col-sm-4">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtTitle" value={accountDetail.Title} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label htmlFor="txtCostCenter" className="col-sm-2 col-form-label col-form-label-sm">Cost Center:</label>
                                                <div className="col-sm-4">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtCostCenter" value={accountDetail.CostCenter} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label htmlFor="txtSponsor" className="col-sm-2 col-form-label col-form-label-sm">Sponsor:</label>
                                                <div className="col-sm-4">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtSponsor" value={accountDetail.Sponsor} />
                                                </div>
                                                <div className="col-sm">
                                                </div>
                                                {accountDetail.WorkdayType === 'Grant' ?
                                                    <>
                                                        <label htmlFor="txtSponsorAwardNumber" className="col-sm-2 col-form-label col-form-label-sm">Sponsor Award Number:</label>
                                                        <label className="col-sm-2 col-form-label fw-bold col-form-label-sm">{accountDetail.SponsorsAwardID}</label>
                                                    </>
                                                    :
                                                    <>
                                                        {/*<label htmlFor="txtAccount" className="col-sm-2 col-form-label col-form-label-sm">Account:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtAccount" value={accountDetail.Account} />
                                                </div>*/}
                                                    </>
                                                }
                                            </div>
                                            <div className="row">
                                                <label htmlFor="txtPrimeSponsor" className="col-sm-2 col-form-label col-form-label-sm">Prime Sponsor:</label>
                                                <div className="col-sm-4">
                                                    <input type="text" readOnly className="form-control-plaintext form-control-sm fw-bold" id="txtPrimeSponsor" value={accountDetail.PrimeSponsor !== accountDetail.Sponsor ? accountDetail.PrimeSponsor : ''} />
                                                </div>
                                                <div className="col-sm">
                                                </div>
                                                <label htmlFor="txtRateAgreement" className="col-sm-2 col-form-label col-form-label-sm">Rate Agreement:</label>
                                                <label className="col-sm-2 col-form-label fw-bold col-form-label-sm">{accountDetail.workdayRateAgreement}</label>
                                            </div>
                                            <div className="row">
                                                <label htmlFor="txtStartDate" className="col-sm-2 col-form-label col-form-label-sm">Account Start Date:</label>
                                                <label className="col-sm-2 col-form-label col-form-label-sm"><span className="fw-bold">{moment(accountDetail.StartDate).format('M/D/yyyy')}</span></label>
                                                <div className="col-sm-4"></div>
                                                <label htmlFor="txtFABase" className="col-sm-2 col-form-label col-form-label-sm">F&A Base:</label>
                                                <label className="col-sm-2 col-form-label fw-bold col-form-label-sm">
                                                    {getAccountFABase()}
                                                    {accountDetail && ["EOSS1", "EOSS3"].includes(accountDetail.ASCExtraType) ?
                                                        <> (EOSS Rate: <NumericFormat value={accountDetail.ASCExtra * 100} renderText={(value) => value} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," className="form-control form-control-sm fw-bold text-end bg-info-subtle" style={{ width: '60px' }} name="ManualCurrentRate" />
                                                            )
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </label>
                                            </div>
                                            <div className="row">
                                                <label htmlFor="txtEndDate" className="col-sm-2 col-form-label col-form-label-sm">Account End Date:</label>
                                                <div className="col-sm-2">
                                                    <input type="date" required className={`form-control form-control-sm fw-bold bg-info-subtle ${includeManualForecast && accountDetail && accountDetail.ManualEndDate && (moment(accountDetail.EndDate) !== moment(accountDetail.ManualEndDate)) ? 'text-primary' : null}`} id="txtEndDate" value={moment(getAccountEndDate(accountDetail)).format('yyyy-MM-DD')} onChange={handleAccountChange} onBlur={handleEndDateBlur} name="EndDate" />
                                                </div>
                                                <label className="col-sm-2 col-form-label col-form-label-sm">{/*<span className="fw-bold">{moment(accountDetail.EndDate).format('M/D/yyyy')}</span> */}{moment(getAccountEndDate(accountDetail)) < moment() ? 0 : moment(getAccountEndDate(accountDetail)).diff(moment(), 'months')} Months Remaining</label>
                                                <div className="col-sm col-form-label col-form-label-sm">
                                                </div>
                                                <label className="col-sm-2 col-form-label col-form-label-sm">Current Rate:</label>
                                                <div className="col-sm-2 d-flex">
                                                    <div className="col-auto">
                                                        <NumericFormat value={getCurrentRate()} renderText={(value) => value} decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," className={`form-control form-control-sm fw-bold text-end bg-info-subtle ${accountDetail && accountDetail.ManualCurrentRate ? 'text-primary' : ''}`} style={{ width: '60px' }} onValueChange={handleNumericChange} name="ManualCurrentRate" />
                                                    </div>
                                                    <div className="ps-2 col-auto col-form-label col-form-label-sm">
                                                        {accountDetail.WorkdayRateException}
                                                    </div>
                                                </div>
                                                {/*    <label className="col-sm-2 col-form-label fw-bold col-form-label-sm"></label>*/}
                                            </div>
                                            <div className="row">
                                                <label className="col-sm-2 col-form-label col-form-label-sm">Final End Date:</label>
                                                <label className="col-sm-2 col-form-label col-form-label-sm"><span className="fw-bold">{moment(accountDetail.FinalEndDate).format('M/D/yyyy')}</span></label>
                                                <label className="col-sm-2 col-form-label col-form-label-sm">{moment(accountDetail.FinalEndDate) < moment() ? 0 : moment(accountDetail.FinalEndDate).diff(moment(), 'months')} Months Remaining</label>
                                                <div className="col-sm col-form-label col-form-label-sm">
                                                </div>
                                                {accountDetail.ERAAccountType === "Sub Award"
                                                    ?
                                                    <>
                                                        <label className="col-sm-2 col-form-label col-form-label-sm">Basis Limit:</label>
                                                        <div className="col-sm-2 col-form-label col-form-label-sm fw-bold">
                                                            <NumericFormat value={accountDetail.BasisLimit} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                                {accountDetail.ASUFRate !== null && accountDetail.ASUFRate !== undefined
                                                    ?
                                                    <>
                                                        <label className="col-sm-2 col-form-label col-form-label-sm">ASUF Rate:</label>
                                                        <div className="col-sm-2 col-form-label col-form-label-sm fw-bold">
                                                            <NumericFormat value={(accountDetail.ASUFRate !== null ? accountDetail.ASUFRate : 0) * 100} displayType="text" renderText={(value) => value} decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," className="form-control form-control-sm fw-bold text-end bg-info-subtle" name="ASUFRate" />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                                {account && !account.startsWith("GR") ?
                                                    <>
                                                        <label className="col-sm-2 col-form-label col-form-label-sm">Rev. Begin Balance:</label>
                                                        <div className="col-sm-2 col-form-label col-form-label-sm fw-bold">
                                                            <NumericFormat value={accountDetail.RevenueBegBalance !== null ? accountDetail.RevenueBegBalance : 0} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div className="row">
                                                <label htmlFor="txtPayPeriodStartDate" className="col-sm-2 col-form-label col-form-label-sm">Pay Period Start Date:</label>
                                                <label className="col-sm-2 col-form-label col-form-label-sm"><span className="fw-bold">{parameters ? moment(parameters.PayPeriodStartDate).format('M/D/yyyy') : ''}</span></label>
                                                <label className="col-sm-2 col-form-label col-form-label-sm">{parameters ? networkDays(parameters.PayPeriodStartDate, getAccountEndDate(accountDetail)) : 0} Days Remaining</label>
                                                <label className="col-sm-2 col-form-label col-form-label-sm"></label>
                                                {account && !account.startsWith("GR") ?
                                                    <>
                                                        <label className="col-sm-2 col-form-label col-form-label-sm">Revenue ITD:</label>
                                                        <div className="col-sm-2 col-form-label col-form-label-sm fw-bold">
                                                            <NumericFormat value={accountDetail.Revenue !== null ? accountDetail.Revenue : 0} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                                <AccountNotes account={account} />
                            </div>
                        </div>
                        <div>
                            <div className="card mb-4">
                                <div className="card-header"><FontAwesomeIcon icon={faBook} className="pe-1" />Ledger Accounts</div>
                                {/*<div className="card-body">*/}
                                {/*<div className="row">*/}
                                {/*<div className="d-flex">*/}
                                {/*    <div className="">asdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdf</div>*/}
                                {/*    <div className="">zxvzxvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxvzxvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxvzxvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxvzxvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxvzxvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxvzxvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxvzxvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcvzxcv</div>*/}
                                {/*</div>*/}
                                <div className="d-flex overflow-x-auto">
                                    <div className="col-auto">
                                        <table className="table table-sm table-xs /*table-xxs*/">
                                            <thead>
                                                <tr>
                                                    <th colSpan="11" rowSpan="3"></th>
                                                    <th className="table-light nowrap">F&A Rate</th>
                                                </tr>
                                                <tr>
                                                    <th className="table-light nowrap">Cal Month</th>
                                                </tr>
                                                <tr>
                                                    <th className="table-light nowrap">Fiscal Year</th>
                                                </tr>
                                                <tr style={{ height: "32px" }}>
                                                    <th className="table-light nowrap" colSpan="4">Ledger Account</th>
                                                    <th className="table-light nowrap">Apply F&A</th>
                                                    <th className="table-light text-center nowrap"><ForecastBudgets account={account} budgetExpenses={accountBudgetExpenses} payPeriodStartDate={parameters.PayPeriodStartDate} forecastBudgetsSaved={forecastBudgetsSaved} /></th>
                                                    <th className="table-light text-center nowrap">Expenses</th>
                                                    <th className="table-light text-center nowrap">Encumbrances</th>
                                                    <th className="table-light text-center nowrap">Forecast</th>
                                                    <th className="table-light text-center nowrap">Available</th>
                                                    <th className="table-light"></th>
                                                    <th className="table-light nowrap">Fiscal Month</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accountBudgetExpenses ? getDirectCostLedgerAccounts().map(budgetExpense =>
                                                    <tr key={budgetExpense.LedgerAccount} className="align-middle" style={{ height: "40px" }}>
                                                        <td className="nowrap" colSpan="3">
                                                            {budgetExpense.LedgerAccount}
                                                        </td>
                                                        <td className="text-end">
                                                            {budgetExpense.LedgerAccount === "7499 Netcom" ?
                                                                <NumericFormat value={parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm * 100 : 0} displayType="text" decimalScale={2} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                                                : null}
                                                            {budgetExpense.LedgerAccount === "7499 Risk Mgmt" ?
                                                                <NumericFormat value={parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt * 100 : 0} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                                                : null}
                                                        </td>
                                                        <td>{budgetExpense.ApplyFA}</td>
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator=","
                                                                className={`${budgetExpense.IsManual ? 'fw-bold text-primary' : null}`}
                                                            />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={getAccountLedgerForecast(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={getAccountLedgerAvailable(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ) : <tr>
                                                    <td colSpan="7">No ledger records found.</td>
                                                </tr>}
                                            </tbody>
                                            <tbody className="table-group-divider">
                                                <tr>
                                                    <td colSpan="4">Direct costs without {accountDetail.Account.startsWith('GR') ? 'F&A' : 'ASC'} applied</td>
                                                    <td></td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Available} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4">Direct costs with {accountDetail.Account.startsWith('GR') ? 'F&A' : 'ASC'} applied</td>
                                                    <td></td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Available} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                            <tbody className="table-group-divider">
                                                {accountBudgetExpenses ? getIndirectCostLedgerAccounts().map(budgetExpense =>
                                                    <tr key={Number(budgetExpense.LedgerAccountId)} >
                                                        <td className="nowrap" colSpan="4">{budgetExpense.LedgerAccount}</td>
                                                        <td>{Number(budgetExpense.LedgerAccountId) < 7521 ? '' : budgetExpense.ApplyFA}</td>
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator=","
                                                                className={`${budgetExpense.IsManual ? 'fw-bold text-primary' : null}`}
                                                            />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={getIndirectAccountLedgerEncumbrances(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={getAccountLedgerForecast(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={getIndirectAccountLedgerAvailable(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ) : <tr>
                                                    <td colSpan="7">No ledger records found.</td>
                                                </tr>}
                                            </tbody>
                                            <tbody className="table-group-divider">
                                                <tr>
                                                    <td colSpan="5">Total</td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getForecastTotals().Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getForecastTotals().Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getForecastTotals().Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getForecastTotals().Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end fw-bold">
                                                        <NumericFormat value={getForecastTotals().Available} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td></td>
                                                    <td className="text-end fw-bold table-light nowrap">
                                                        {!account.startsWith("GR") ?
                                                            <CashAvailable
                                                                account={account}
                                                                accountTotals={getForecastTotals()}
                                                                availableToSpend={getAvailableToSpend()}
                                                                revBeginBalance={accountDetail ? accountDetail.RevenueBegBalance ?? 0 : 0}
                                                                revenueITD={accountDetail ? accountDetail.Revenue ?? 0 : 0}
                                                                currentRate={getCurrentRate()}
                                                                payPeriodStartDate={parameters.PayPeriodStartDate}
                                                                showActualTotal
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                                <tr style={{ height: "32px" }}>
                                                    <td colSpan="4"></td>
                                                    <td colSpan="2" className="text-end">Actual {accountDetail.Account.startsWith('GR') ? 'F&A' : 'ASC'} %</td>
                                                    <td className="text-end fw-bold">
                                                        <NumericFormat value={getActualFAPercent()} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                                    </td>
                                                    <td colSpan="4" className="">
                                                    </td>
                                                    <td colSpan="2" className="table-light text-end nowrap">
                                                        {!account.startsWith("GR") ?
                                                            <CashAvailable
                                                                account={account}
                                                                accountTotals={getForecastTotals()}
                                                                availableToSpend={getAvailableToSpend()}
                                                                revBeginBalance={accountDetail ? accountDetail.RevenueBegBalance ?? 0 : 0}
                                                                revenueITD={accountDetail ? accountDetail.Revenue ?? 0 : 0}
                                                                currentRate={getCurrentRate()}
                                                                payPeriodStartDate={parameters.PayPeriodStartDate}
                                                                showButton
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <td colSpan="5" className="text-end">Available to spend (if in {accountDetail.Account.startsWith('GR') ? 'F&A' : 'ASC'} applied categories):</td>
                                                    <td className="text-end fw-bold">
                                                        <NumericFormat value={getAvailableToSpend()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td></td>
                                                    <td className="text-end fw-bold table-light nowrap">
                                                        {!account.startsWith("GR") ?
                                                            <CashAvailable
                                                                account={account}
                                                                accountTotals={getForecastTotals()}
                                                                availableToSpend={getAvailableToSpend()}
                                                                revBeginBalance={accountDetail ? accountDetail.RevenueBegBalance ?? 0 : 0}
                                                                revenueITD={accountDetail ? accountDetail.Revenue ?? 0 : 0}
                                                                currentRate={getCurrentRate()}
                                                                payPeriodStartDate={parameters.PayPeriodStartDate}
                                                                showActualAvailableToSpend
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <td colSpan="5" className="text-end">Possible available due to indirect rate reconcilation:</td>
                                                    <td className="text-end">
                                                        <NumericFormat value={accountDetail.ReconcileIndirectAmt} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td colSpan="2"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {accountFiscalMonths && accountFiscalMonths.length > 0 ?
                                        <div className="overflow-x-auto" style={{ minWidth: "100px", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar" }}>
                                            <table className="table table-sm table-xs /*table-xxs*/">
                                                <thead>
                                                    <tr>
                                                        {accountFiscalMonths ? accountFiscalMonths.map(fiscalMonth =>
                                                            <td className="text-end nowrap" key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber}><NumericFormat value={getCurrentRate()} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," /></td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountFiscalMonths ? accountFiscalMonths.map(fiscalMonth =>
                                                            <td className="text-end nowrap" key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber}>{moment(fiscalMonth.StartDate).format('MMM yyyy')}</td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountFiscalMonths ? accountFiscalMonths.map(fiscalMonth =>
                                                            <td className="text-end nowrap" key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber}>{fiscalMonth.FiscalYearNumber}</td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr style={{ height: "32px" }}>
                                                        {accountFiscalMonths ? accountFiscalMonths.map(fiscalMonth =>
                                                            <td className="text-end" key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber}>{fiscalMonth.FiscalPeriodNumber}</td>
                                                        ) : ''}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {accountBudgetExpenses ? getDirectCostLedgerAccounts().map(budgetExpense =>
                                                        <tr key={budgetExpense.LedgerAccount} className="align-middle" style={{ height: "40px" }}>
                                                            {accountFiscalMonths ? (accountFiscalMonths.map(fiscalMonth => {
                                                                let forecastByMonth = getLedgerForecastByMonth(fiscalMonth, budgetExpense);
                                                                if (Number(budgetExpense.LedgerAccountId) >= 7200 && Number(budgetExpense.LedgerAccountId) < 7520 && Number(budgetExpense.LedgerAccountId) !== 7499) {
                                                                    let thisLedger = null;
                                                                    if (accountLedgerByMonth) {
                                                                        thisLedger = accountLedgerByMonth.find((l) => l.FiscalYear === fiscalMonth.FiscalYearNumber && l.FiscalMonth === fiscalMonth.FiscalPeriodNumber && l.LedgerAccount === budgetExpense.LedgerAccountId);
                                                                    }
                                                                    return <td key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber}>
                                                                        {/*ref={{ Year: fiscalMonth.FiscalYearNumber, Month: fiscalMonth.FiscalperiodNumber, LedgerAccount: budgetExpense.LedgerAccount.substring(0, 4) }}*/}
                                                                        <div className="position-relative">
                                                                            <NumericFormat
                                                                                value={isNumericDefault(forecastByMonth, '')}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale
                                                                                thousandSeparator=","
                                                                                className="form-control form-control-sm form-control-xs text-end bg-info-subtle fw-bold text-primary"
                                                                                onValueChange={(v, si) => handleLedgerNumericChange(fiscalMonth, budgetExpense, v, si)}
                                                                                onFocus={(e) => e.target.select()}
                                                                            />
                                                                            <span className="position-absolute translate-middle badge text-primary" style={{ "top": "8px", "right": "-10px", "cursor": "pointer" }} data-bs-toggle="dropdown" aria-expanded="false">
                                                                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                                                                {/*<span class="visually-hidden">unread messages</span>*/}
                                                                            </span>
                                                                            <ul className="dropdown-menu" style={{ "fontSize": ".75em" }}>
                                                                                <li>
                                                                                    <span className="dropdown-item-text">
                                                                                        <NumericFormat
                                                                                            displayType="text"
                                                                                            value={isNumericDefault(forecastByMonth, '')}
                                                                                            decimalScale={2}
                                                                                            fixedDecimalScale
                                                                                            thousandSeparator=","
                                                                                            className={'fw-bold text-primary'}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                                <li><hr className="dropdown-divider" /></li>
                                                                                <li>
                                                                                    <a className="dropdown-item" href="#" onClick={(e) => fillEmptyLedgerValues(e, isNumericDefault(forecastByMonth, null), fiscalMonth, budgetExpense)}>
                                                                                        <FontAwesomeIcon icon={faArrowRightFromBracket} className="mt-1 float-end" />
                                                                                        Fill Empty Values
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a className="dropdown-item" href="#" onClick={(e) => fillReplaceLedgerValues(e, isNumericDefault(forecastByMonth, null), fiscalMonth, budgetExpense)}>
                                                                                        <FontAwesomeIcon icon={faClone} className="mt-1 float-end" />
                                                                                        Fill & Override Values
                                                                                    </a>
                                                                                </li>
                                                                                {/*<li><a class="dropdown-item" href="#">Fill & Override Values</a></li>*/}
                                                                            </ul>
                                                                        </div>
                                                                        {/*className={`form-control form-control-sm form-control-xs text-end bg-info-subtle ${thisLedger && thisLedger.IsUpdated ? 'fw-bold text-primary' : ''}`}*/}
                                                                        {/*className="form-control form-control-sm form-control-xs text-end bg-info-subtle"*/}
                                                                        {/* onValueChange={(v, si) => handleLedgerNumericChange({ Ledger: thisLedger, NewLedger: { ApplyFA: budgetExpense.ApplyFA, Month: fiscalMonth.FiscalPeriodNumber, Year: fiscalMonth.FiscalYearNumber, LedgerAccount: budgetExpense.LedgerAccountId, LedgerAccountandDesc: budgetExpense.LedgerAccount, comment: '' } }, v, si)} */}
                                                                    </td>
                                                                }
                                                                else {
                                                                    return <td className="text-end" key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber}>
                                                                        <NumericFormat displayType="text" value={forecastByMonth ?? ''} decimalScale={2} fixedDecimalScale thousandSeparator="," className="form-control-plaintext form-control-sm form-control-xs text-end" />
                                                                    </td>
                                                                }
                                                            }))
                                                                : ''}
                                                        </tr>
                                                    ) : <tr>
                                                        <td colSpan="7">No ledger records found.</td>
                                                    </tr>}
                                                </tbody>
                                                <tbody className="table-group-divider">
                                                    <tr>
                                                        {accountFiscalMonths ? accountFiscalMonths.map(fiscalMonth =>
                                                            <td key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber} className="text-end">
                                                                <NumericFormat value={getDirectCostsByMonth(fiscalMonth, 'No')} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            </td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountFiscalMonths ? accountFiscalMonths.map(fiscalMonth =>
                                                            <td key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber} className="text-end">
                                                                <NumericFormat value={getDirectCostsByMonth(fiscalMonth, 'Yes')} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            </td>
                                                        ) : ''}
                                                    </tr>
                                                </tbody>
                                                <tbody className="table-group-divider">
                                                    {accountBudgetExpenses ? getIndirectCostLedgerAccounts().map(budgetExpense =>
                                                        <tr key={Number(budgetExpense.LedgerAccountId)} >
                                                            {accountFiscalMonths ? accountFiscalMonths.map(fiscalMonth =>
                                                                <td key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber} className="text-end">
                                                                    {budgetExpense.LedgerAccount.includes("ASUF") ?
                                                                        <NumericFormat value={getDirectCostsByMonth(fiscalMonth, 'Yes') * (accountDetail && accountDetail.ASUFRate !== null ? accountDetail.ASUFRate : 0)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                                        :
                                                                        <NumericFormat value={getDirectCostsByMonth(fiscalMonth, 'Yes') * (getCurrentRate() / 100)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                                    }
                                                                </td>
                                                            ) : ''}
                                                        </tr>
                                                    ) : <tr>
                                                        <td colSpan="7">No ledger records found.</td>
                                                    </tr>}
                                                </tbody>
                                                <tbody className="table-group-divider">
                                                    <tr>
                                                        {accountFiscalMonths ? accountFiscalMonths.map(fiscalMonth =>
                                                            <td key={fiscalMonth.FiscalYearNumber + '-' + fiscalMonth.FiscalPeriodNumber} className="text-end">
                                                                <NumericFormat value={getDirectCostsByMonth(fiscalMonth, 'No') + getDirectCostsByMonth(fiscalMonth, 'Yes') + getIndirectCostsByMonth(fiscalMonth)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            </td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr style={{ height: "32px" }}>
                                                        <td colSpan="11">&nbsp;</td>
                                                        <td colSpan={accountFiscalMonths ? accountFiscalMonths.length + 1 : 1}></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="11">&nbsp;</td>
                                                        <td colSpan={accountFiscalMonths ? accountFiscalMonths.length + 1 : 1}></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="11">&nbsp;</td>
                                                        <td colSpan={accountFiscalMonths ? accountFiscalMonths.length + 1 : 1}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <div className="card mb-4">

                                <div className="card-header"><FontAwesomeIcon icon={faUser} className="pe-1" />Employees</div>
                                {/*<div className="card-body">*/}
                                {/*<div className="row">*/}
                                <div className="d-flex overflow-x-auto">
                                    <div className="col-auto">
                                        <table className="table table-sm table-xs /*table-xxs*/" style={{ display: "table", width: "100px" }}>
                                            <thead>
                                                <tr className="no-hover">
                                                    <td colSpan="1" rowSpan="5" className="text-center align-middle">
                                                    </td>
                                                    <td colSpan="4" rowSpan="5" className="text-center align-middle">
                                                        {accountDetail.WorkdayType === "Program" ?
                                                            <>
                                                                <Startup account={account} accountDetail={accountDetail} forecastedPayPeriods={getForecastPayPeriods()} employeeFunding={accountEmployeeFunding} savedChanges={savedStartup} />
                                                            </>
                                                            : null}
                                                    </td>
                                                    <td colSpan="11" rowSpan="5" className="text-center align-middle"></td>
                                                    <th className="table-light nowrap">Start Date</th>
                                                </tr>
                                                <tr>
                                                    <th className="table-light nowrap">Cal Month</th>
                                                </tr>
                                                <tr>
                                                    <th className="table-light nowrap">Fiscal Year</th>
                                                </tr>
                                                <tr>
                                                    <th className="table-light nowrap">Fiscal Month</th>
                                                </tr>
                                                <tr>
                                                    <th className="table-light nowrap">Semester</th>
                                                </tr>
                                                <tr>
                                                    <th rowSpan="2" className="table-light nowrap">&nbsp;</th>
                                                    <th rowSpan="2" className="table-light nowrap">Employee</th>
                                                    {/*<th className="table-light nowrap">Emplid-Position-Rcd</th>*/}
                                                    <th rowSpan="2" className="table-light nowrap">EmplId</th>
                                                    <th rowSpan="2" className="table-light nowrap">Position</th>
                                                    <th rowSpan="2" className="table-light nowrap">Rcd</th>
                                                    <th rowSpan="2" className="table-light nowrap">Title</th>
                                                    <th rowSpan="2" className="table-light nowrap">AY<br />Pay Rate</th>
                                                    <th rowSpan="2" className="table-light nowrap">Sum<br />Pay Rate</th>
                                                    <th rowSpan="2" className="table-light nowrap">Earnings</th>
                                                    <th rowSpan="2" className="table-light nowrap">Staff<br />Type</th>
                                                    <th rowSpan="2" className="table-light nowrap">Earnings<br />Forecast</th>
                                                    <th rowSpan="2" className="table-light nowrap">ERE<br />Forecast</th>
                                                    <th rowSpan="2" className="table-light nowrap">Tuition Rem<br />Forecast</th>
                                                    {/*<th className="table-light nowrap">Empl Status</th>*/}
                                                    <th rowSpan="2" className="table-light nowrap">Status</th>
                                                    <th rowSpan="2" className="table-light nowrap">Pay<br />Group</th>
                                                    <th rowSpan="2" className="table-light nowrap">FTE</th>
                                                    <th className="table-light nowrap">Indirect Rate</th>
                                                </tr>
                                                <tr>
                                                    <th className="table-light nowrap">Workdays</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accountEmployeeEarnings ? accountEmployeeEarnings.map((employeeEarning, ix) => {
                                                    let employeeForecasts = getEmployeeForecasts(employeeEarning);
                                                    return <tr key={employeeEarning.EmplIdPositionRcd} className="align-middle" style={{ height: "37px" }}>
                                                        <td>{!employeeEarning.Earnings && !employeeEarning.IsEarnings ? <FontAwesomeIcon icon={faTrashCan} className="pe-1" style={{ cursor: "pointer" }} onClick={() => deleteEmployeeEarnings(ix)} /> : null}</td>
                                                        <td className="nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <input type="text" className="form-control form-control-sm form-control-xs" value={employeeEarning.Employee} onChange={(e) => handleEmployeeEarningChange(ix, e)} name="Employee" />
                                                                :
                                                                <>{employeeEarning.Employee}</>
                                                            }
                                                        </td>

                                                        {/*<td className="nowrap">{employeeEarning.EmplStatus ? employeeEarning.EmplId + '-' + employeeEarning.Position + '-' + employeeEarning.EmplRcd : employeeEarning.EmplIdPositionRcd}</td>*/}
                                                        <td className="nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <input type="text" className="form-control form-control-sm form-control-xs" value={employeeEarning.EmplId} onChange={(e) => handleEmployeeEarningChange(ix, e)} name="EmplId" />
                                                                :
                                                                <>{employeeEarning.EmplId}</>
                                                            }
                                                        </td>
                                                        <td className="nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <input type="text" className="form-control form-control-sm form-control-xs" value={employeeEarning.Position} onChange={(e) => handleEmployeeEarningChange(ix, e)} name="Position" />
                                                                :
                                                                <>{employeeEarning.Position}</>
                                                            }
                                                        </td>
                                                        <td className="nowrap text-end">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <NumericFormat style={{ width: "2em" }} value={employeeEarning.EmplRcd} decimalScale={0} fixedDecimalScale className={`form-control form-control-sm form-control-xs text-end`} onValueChange={(v, si) => handleEmployeeEarningNumericChange(ix, v, si)} name="EmplRcd" />
                                                                :
                                                                <NumericFormat value={employeeEarning.EmplRcd} displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator="," />
                                                            }
                                                        </td>
                                                        <td className="nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <input type="text" className="form-control form-control-sm form-control-xs" value={employeeEarning.Title} onChange={(e) => handleEmployeeEarningChange(ix, e)} name="Title" />
                                                                :
                                                                <>{employeeEarning.Title}</>
                                                            }
                                                        </td>
                                                        <td className="text-end nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <NumericFormat value={employeeEarning.AYPayRate} decimalScale={2} fixedDecimalScale thousandSeparator="," className={`form-control form-control-sm form-control-xs text-end`} onValueChange={(v, si) => handleEmployeeEarningNumericChange(ix, v, si)} name="AYPayRate" />
                                                                :
                                                                <NumericFormat value={employeeEarning.AYPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            }
                                                        </td>
                                                        <td className="text-end nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <NumericFormat value={employeeEarning.SummerPayRate} decimalScale={2} fixedDecimalScale thousandSeparator="," className={`form-control form-control-sm form-control-xs text-end`} onValueChange={(v, si) => handleEmployeeEarningNumericChange(ix, v, si)} name="SummerPayRate" />
                                                                :
                                                                <NumericFormat value={employeeEarning.SummerPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            }
                                                        </td>
                                                        <td className="text-end nowrap">
                                                            <NumericFormat value={employeeEarning.Earnings} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <select style={{ width: "7em" }} className={`form-select form-select-sm form-select-xs`} name="StaffType" value={employeeEarning.StaffType} onChange={(e) => handleEmployeeEarningChange(ix, e)} >
                                                                    <option></option>
                                                                    <option>Faculty</option>
                                                                    <option>NoBen</option>
                                                                    <option>Part Time</option>
                                                                    <option>PostDoc</option>
                                                                    <option>RA/TA</option>
                                                                    <option>Staff</option>
                                                                    <option>Student</option>
                                                                </select>
                                                                :
                                                                <>{employeeEarning.StaffType}</>
                                                            }
                                                        </td>
                                                        <td className="text-end nowrap">
                                                            <NumericFormat value={employeeForecasts.Earnings} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end nowrap">
                                                            <NumericFormat value={employeeForecasts.EREForecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end nowrap">
                                                            <NumericFormat value={employeeForecasts.TuitionRemissionForecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        {/*<td className="text-center nowrap">{employeeEarning.EmplStatus ? (employeeEarning.EmplStatus + ' ' + employeeEarning.PayGroup + ' ' + (employeeEarning.StaffType === "RA/TA" ? (employeeEarning.FTE >= 0.5 ? '1' : (employeeEarning.FTE >= 0.25 ? '.5' : '0')) : '')) : employeeEarning.EmplStatusPayGroup}</td>*/}
                                                        <td className="text-center nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <select style={{ width: "4.5em" }} className={`form-select form-select-sm form-select-xs`} name="EmplStatus" value={employeeEarning.EmplStatus ?? employeeEarning.EmplStatusPayGroup.split(' ')[0]} onChange={(e) => handleEmployeeEarningChange(ix, e)} >
                                                                    <option></option>
                                                                    <option>A</option>
                                                                    <option>D</option>
                                                                    <option>L</option>
                                                                    <option>P</option>
                                                                    <option>R</option>
                                                                    <option>T</option>
                                                                    <option>W</option>
                                                                </select>
                                                                :
                                                                <>{employeeEarning.EmplStatus ?? employeeEarning.EmplStatusPayGroup.split(' ')[0]}</>
                                                            }
                                                        </td>
                                                        <td className="text-center nowrap">
                                                            {!employeeEarning.Earnings && !employeeEarning.IsEarnings ?
                                                                <select style={{ width: "5.0em" }} className={`form-select form-select-sm form-select-xs`} name="PayGroup" value={employeeEarning.PayGroup ?? employeeEarning.EmplStatusPayGroup.split(' ')[1]} onChange={(e) => handleEmployeeEarningChange(ix, e)} >
                                                                    <option></option>
                                                                    <option>A12</option>
                                                                    <option>ACD</option>
                                                                    <option>FSW</option>
                                                                    <option>GRD</option>
                                                                    <option>HRY</option>
                                                                    <option>SAL</option>
                                                                    <option>STU</option>
                                                                </select>
                                                                :
                                                                <>{employeeEarning.PayGroup ?? employeeEarning.EmplStatusPayGroup.split(' ')[1]}</>
                                                            }
                                                        </td>
                                                        <td className="text-center nowrap">
                                                            {employeeEarning.StaffType === 'RA/TA' ?
                                                                <NumericFormat
                                                                    style={{ width: "3.25em" }}
                                                                    value={employeeEarning.FTE ?? employeeEarning.EmplStatusPayGroup.toString().substr(6, 1)}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    thousandSeparator=","
                                                                    className={`form-control form-control-sm form-control-xs text-end`}
                                                                    onValueChange={(v, si) => handleEmployeeEarningNumericChange(ix, v, si)}
                                                                    name="FTE"
                                                                    isAllowed={(v) => {
                                                                        const { floatValue } = v;
                                                                        return floatValue <= 1 || floatValue === null || floatValue === undefined;
                                                                    }}
                                                                />
                                                                :
                                                                <>{employeeEarning.FTE ?? employeeEarning.EmplStatusPayGroup.toString().substr(6, 1)}</>
                                                            }
                                                            {/*onValueChange={(v, si) => handleEmployeeFundingNumericChange({ EmployeeEarning: employeeEarning, EmployeeSemester: employeeSemester, EmployeeFunding: employeeFundingPercent }, v, si)}*/}
                                                            {/*className={`form-control form-control-sm text-end ${editAddEmployeeErrors && editAddEmployeeErrors.FTE ? 'is-invalid' : ''}`}*/}
                                                        </td>
                                                        <td className="nowrap"></td>
                                                    </tr>
                                                }) : <tr>
                                                    <td colSpan={accountEmployeeSemesters ? accountEmployeeSemesters.length + 16 : 16}>No records found.</td>
                                                </tr>}
                                                <tr style={{ height: "40px" }}>
                                                    <td colSpan="16" className="text-center">
                                                        <AddEmployee handleCallback={addEmployeeCallBack} currentEmployees={accountEmployeeEarnings} />
                                                    </td>
                                                    <td colSpan={accountEmployeeSemesters ? accountEmployeeSemesters.length + 1 : 0}>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {accountEmployeeSemesters && accountEmployeeSemesters.length > 0 ?
                                        <div className="overflow-x-auto" style={{ minWidth: "100px", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar" }}>
                                            <table className="table table-sm table-xs /*table-xxs*/" style={{ display: "table" }}>
                                                <thead>
                                                    <tr className="no-hover">
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className="text-end nowrap" key={employeeSemester.MinStartDate + 'StartDate'}>
                                                                {moment(employeeSemester.MinStartDate).format('M/D/yyyy')}
                                                                {/*<NumericFormat value={getCurrentRate() * 100} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />*/}
                                                            </td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className="text-end nowrap" key={employeeSemester.MinStartDate + 'Month'}>{employeeSemester.CalMonth}</td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className="text-end nowrap" key={employeeSemester.MinStartDate + 'FiscalYear'}>{employeeSemester.FiscalYear}</td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className="text-end nowrap" key={employeeSemester.MinStartDate + 'FiscalMonth'}>{employeeSemester.FiscalMonth}</td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className={`text-end nowrap ${employeeSemester.AcadPeriod === 'Fall' ? 'bg-warning' : employeeSemester.AcadPeriod === 'Spring' ? 'bg-success-subtle' : 'bg-danger-subtle'}`} key={employeeSemester.MinStartDate + 'Semester'}>{employeeSemester.AcadPeriod}</td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className="text-end" key={employeeSemester.MinStartDate + 'IndirectRate'}><NumericFormat value={getCurrentRate()} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," /></td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className="text-end" key={employeeSemester.MinStartDate + 'Workdays'}>{employeeSemester.Workdays}</td>
                                                        ) : ''}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {accountEmployeeEarnings ? accountEmployeeEarnings.map((employeeEarning, ix) => {
                                                        let employeeForecasts = getEmployeeForecasts(employeeEarning);
                                                        return <tr key={employeeEarning.EmplIdPositionRcd} className="align-middle" style={{ height: "37px" }}>
                                                            {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester => {
                                                                let employeeFundingPercent = getEmployeeFundingPercent(employeeEarning, employeeSemester);
                                                                return <td className="text-end" key={employeeSemester.MinStartDate + '-' + employeeEarning.EmplIdPositionRcd}>
                                                                    <div className="position-relative">
                                                                        <NumericFormat
                                                                            displayType={employeeFundingPercent.DisplayInput ? 'input' : 'text'}
                                                                            value={isNumericDefault(employeeFundingPercent.Value, '')}
                                                                            decimalScale={1}
                                                                            suffix={'%'}
                                                                            fixedDecimalScale
                                                                            thousandSeparator=","
                                                                            className={`${employeeFundingPercent.DisplayInput ? 'form-control form-control-sm form-control-xs text-end bg-info-subtle' : 'form-control-plaintext form-control-sm form-control-xs text-end'} ${employeeFundingPercent.IsUpdated ? 'fw-bold text-primary' : ''}`}
                                                                            onValueChange={(v, si) => handleEmployeeFundingNumericChange({ EmployeeEarning: employeeEarning, EmployeeSemester: employeeSemester, EmployeeFunding: employeeFundingPercent }, v, si)}
                                                                            onFocus={(e) => e.target.select()}
                                                                        />
                                                                        {employeeFundingPercent.DisplayInput ?
                                                                            <>
                                                                                <span className="position-absolute translate-middle badge text-primary" style={{ "top": "8px", "right": "-10px", "cursor": "pointer" }} data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <FontAwesomeIcon icon={faEllipsisVertical} />
                                                                                    {/*<span class="visually-hidden">unread messages</span>*/}
                                                                                </span>
                                                                                <ul className="dropdown-menu" style={{ "fontSize": ".75em" }}>
                                                                                    <li>
                                                                                        <span className="dropdown-item-text">
                                                                                            <NumericFormat
                                                                                                displayType={'text'}
                                                                                                value={isNumericDefault(employeeFundingPercent.Value, '')}
                                                                                                decimalScale={1}
                                                                                                suffix={'%'}
                                                                                                fixedDecimalScale
                                                                                                thousandSeparator=","
                                                                                                className={'fw-bold text-primary'}
                                                                                            />
                                                                                        </span>
                                                                                    </li>
                                                                                    <li><hr className="dropdown-divider" /></li>
                                                                                    <li>
                                                                                        <a className="dropdown-item" href="#" onClick={(e) => fillEmptyEmployeeValues(e, isNumericDefault(employeeFundingPercent.Value, null), employeeSemester, employeeEarning)}>
                                                                                            <FontAwesomeIcon icon={faArrowRightFromBracket} className="mt-1 float-end" />
                                                                                            Fill Empty Values
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a className="dropdown-item" href="#" onClick={(e) => fillReplaceEmployeeValues(e, isNumericDefault(employeeFundingPercent.Value, null), employeeSemester, employeeEarning)}>
                                                                                            <FontAwesomeIcon icon={faClone} className="mt-1 float-end" />
                                                                                            Fill & Override Values
                                                                                        </a>
                                                                                    </li>
                                                                                    {/*<li><a class="dropdown-item" href="#">Fill & Override Values</a></li>*/}
                                                                                </ul>
                                                                            </>
                                                                            : null}
                                                                    </div>
                                                                    {/*className={`form-control form-control-sm form-control-xs text-end bg-info-subtle ${thisLedger && thisLedger.IsUpdated ? 'fw-bold text-primary' : ''}`}*/}
                                                                    {/*className={employeeFundingPercent.DisplayInput ? 'form-control form-control-sm form-control-xs text-end bg-info-subtle' : 'form-control-plaintext form-control-sm form-control-xs text-end'}*/}
                                                                    {/*onValueChange={(v, si) => handleLedgerNumericChange({ Ledger: thisLedger, NewLedger: { ApplyFA: budgetExpense.ApplyFA, Month: fiscalMonth.FiscalPeriodNumber, Year: fiscalMonth.FiscalYearNumber, LedgerAccount: budgetExpense.LedgerAccount.substring(0, 4), LedgerAccountandDesc: budgetExpense.LedgerAccount, comment: '' } }, v, si)}*/}

                                                                </td>
                                                            }
                                                            ) : ''}
                                                        </tr>
                                                    }) : <tr>
                                                        <td colSpan={accountEmployeeSemesters ? accountEmployeeSemesters.length + 16 : 16}>No records found.</td>
                                                    </tr>}
                                                    <tr style={{ height: "40px" }}>
                                                        <td colSpan={accountEmployeeSemesters ? accountEmployeeSemesters.length + 1 : 0}>&nbsp;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                </>
                :
                <div>Account not found</div>
            }
        </ErrorBoundary >
        </>
    );
}

export default Account;