import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faBook, faMagnifyingGlass, faSpinner, faFileInvoice, faUser, faBan } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import useAccount from '../useAccount';
import AccountNotes from "./AccountNotes";
import { getParameters } from '../Parameters';
import { networkDays, isNumeric, isNumericDefault, isNullOrEmpty } from '../../utilities';
import { Tooltip } from 'bootstrap';
import Startup from "./Startup";

export default function AccountDetailReport(props) {
    const { guid, account, ledgerAccountDirectory, handleTotals, preLoadData, showBasicLink, version, versionData, innerRef } = props;
    const parameters = getParameters();
    const {
        isLoading: accountIsLoading,
        loadData,
        accountDetail,
        accountEmployeeSemesters,
        accountEmployeeEarnings,
        accountBudgetExpenses,
        accountFiscalMonths,
        accountCurrentRate,
        accountEmployeeFunding,
        getAccountCurrentRate,
        getAccountFABase,
        getDirectCostLedgerAccounts,
        getIndirectCostLedgerAccounts,
        getAccountLedgerForecast,
        getAccountLedgerAvailable,
        getDirectCosts,
        getIndirectAccountLedgerEncumbrances,
        getIndirectAccountLedgerAvailable,
        getForecastTotals,
        getActualFAPercent,
        getAvailableToSpend,
        getLedgerForecastByMonth,
        getCurrentRate,
        getEmployeeForecasts,
        getEmployeeFundingPercent,
        getForecastPayPeriods,
        loadComplete: accountLoadComplete,
        getGetActualTotal,
        getAvailableDifference,
        getAvailableToSpendDifference
    } = useAccount(account, ledgerAccountDirectory, version, versionData, preLoadData);


    const [modal, setModal] = useState(false);

    const dateNow = moment();

    useEffect(() => {
    //    if (!accountIsLoading) {
    //        if (handleTotals) {
    //            handleTotals(account, getForecastTotals());
    //        }
    //    }
    }, []);

    useEffect(() => {
        if (handleTotals && accountLoadComplete) {
            handleTotals(account, getForecastTotals(), getAvailableToSpend(), getGetActualTotal(), getGetActualTotal() / (1 + (accountCurrentRate / 100)));
        }
    }, [guid, accountLoadComplete, accountCurrentRate, accountBudgetExpenses, accountEmployeeEarnings, accountEmployeeFunding, accountFiscalMonths, accountEmployeeSemesters]); //]);

    const modalToggle = () => setModal(!modal);
    const openModal = () => {
        loadData();
        modalToggle();

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    //    getData();
    };

    return (
        <>
            {showBasicLink
                ?
                <a className="link-opacity-50-hover" href="" onClick={(e) => { e.preventDefault(); openModal(); }}>{account}</a>
                :
                <button ref={innerRef} type="button" className={`btn btn-sm btn-outline-primary ${accountIsLoading ? 'btn-outline-secondary disabled' : 'btn-outline-primary'}`} onClick={openModal} disabled={accountIsLoading} >
                    {accountIsLoading ?
                        <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                        :
                        null
                    }
                    {account}
                </button>
                }
            <Modal isOpen={modal} toggle={modalToggle} size="xxl">
                <ModalHeader toggle={modalToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faFileInvoice} className="pe-2" />Account Detail Report</h5>
                        {accountIsLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody className="mb-0">
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar",
                        "maxHeight": "calc(100vh - 225px)"
                    }}>
                        <div className="row mb-3 mx-0 g-2">
                            <div className="col-9">
                                {accountDetail ?
                                    <div className="card mb-4 h-100">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <span><FontAwesomeIcon icon={faFileInvoice} className="pe-1" />Account Details</span>
                                            <div className="col-auto">
                                                <div className="row">
                                                    <div className="col-auto px-1">
                                                        {/*<ForecastAccuracy account={account} payPeriodStartDate={parameters.PayPeriodStartDate} />*/}
                                                    </div>
                                                    <div className="col-auto px-1">
                                                        {/*<ExpensesByMonth account={account} />*/}
                                                    </div>
                                                    <div className="col-auto px-1">
                                                        {/*<Earnings account={account} />*/}
                                                    </div>
                                                    <div className="col-auto px-1">
                                                        {/*<Effort account={account} accountStartDate={accountDetail.StartDate} accountEndDate={accountDetail.EndDate} employeeEarnings={accountEmployeeEarnings} employeeFunding={accountEmployeeFunding} />*/}
                                                    </div>
                                                    <div className="col-auto px-1">
                                                        {/*<Encumbrances account={account} encumbrancesTotal={getDirectCosts('Yes').Encumbrances} accountStartDate={accountDetail.StartDate} accountEndDate={accountDetail.EndDate} employeeEarnings={accountEmployeeEarnings} employeeFunding={accountEmployeeFunding} />*/}
                                                    </div>
                                                    <div className="col-auto px-1">
                                                        {/*<button type="button" className="btn btn-sm btn-secondary" disabled={!(hasChanges || hasAccountChanged)} onClick={() => window.location.reload(false)} >*/}
                                                        {/*    <FontAwesomeIcon icon={faDeleteLeft} className="me-2" />*/}
                                                        {/*    Reset*/}
                                                        {/*</button>*/}
                                                    </div>
                                                    <div className="col-auto px-1">
                                                        {/*<button type="button" className="btn btn-sm btn-primary" disabled={!(hasChanges || hasAccountChanged)} onClick={handleSave} >*/}
                                                        {/*    <FontAwesomeIcon icon={saveInProgress ? faSpinner : faFloppyDisk} spin={saveInProgress} className="me-2" />*/}
                                                        {/*    Save*/}
                                                        {/*</button>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body mb-0 pb-0">
                                            <div className="row table-xs">
                                                <div className="row">
                                                    <label className="col-sm-12 col-form-label fw-bold">{accountDetail.Title}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">Account:</label>
                                                    <label className="col-sm-5 col-form-label">{accountDetail.Account}</label>
                                                    <label className="col-sm-2 col-form-label fw-bold text-end">Indirect Rate:</label>
                                                    <label className="col-sm-2 col-form-label text-end"><NumericFormat value={accountCurrentRate ?? ''} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," /></label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">&nbsp;{accountDetail.NickName ? 'Account Nickname:' : ''}</label>
                                                    <label className="col-sm-9 col-form-label">{accountDetail.NickName}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">PI:</label>
                                                    <label className="col-sm-6 col-form-label">{accountDetail.PI ? accountDetail.PI.valueOf() : ''}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">Cost Center:</label>
                                                    <label className="col-sm-9 col-form-label">{accountDetail.WorkdayCostCenterRefID}</label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">Sponsor:</label>
                                                    <label className="col-sm-9 col-form-label">{accountDetail.Sponsor}</label>
                                                    <div className="col-sm">
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">Prime Sponsor:</label>
                                                    <label className="col-sm-9 col-form-label">{accountDetail.PrimeSponsor !== accountDetail.Sponsor ? accountDetail.PrimeSponsor : ''}</label>
                                                    <div className="col-sm">
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">Start Date:</label>
                                                    <label className="col-sm-5 col-form-label">{moment(accountDetail.StartDate).format('M/D/yyyy')}</label>
                                                    <label className="col-sm-2 col-form-label fw-bold text-end">Time Burn Rate:</label>
                                                    <label className="col-sm-2 col-form-label text-end">
                                                        <NumericFormat value={
                                                            Number.parseFloat(
                                                                moment(accountDetail.StartDate) >= moment(accountDetail.EndDate) ? 0 : (
                                                                    moment(accountDetail.EndDate) <= dateNow ? 1 : (
                                                                        moment(accountDetail.StartDate) > dateNow ? 0 : (
                                                                            dateNow.diff(moment(accountDetail.StartDate), 'days') / moment(accountDetail.EndDate).diff(moment(accountDetail.StartDate), 'days')
                                                                        )
                                                                    )
                                                                ) * 100
                                                            ).toFixed(1)
                                                        } displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">End Date:</label>
                                                    <label className="col-sm-5 col-form-label">{moment(accountDetail.EndDate).format('M/D/yyyy')}</label>
                                                    <label className="col-sm-2 col-form-label fw-bold text-end">Budget Burn Rate:</label>
                                                    <label className="col-sm-2 col-form-label text-end">
                                                        <NumericFormat value={
                                                            (getForecastTotals().Budget ?? Number.parseFloat(accountDetail.Budget)) === 0 ? 0 : ((getForecastTotals().Expenses ?? Number.parseFloat(accountDetail.ITD)) / (getForecastTotals().Budget ?? Number.parseFloat(accountDetail.Budget)) * 100)
                                                        } displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                                    </label>
                                                </div>
                                                <div className="row">
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">Anticipated End Date:</label>
                                                    <label className="col-sm-4 col-form-label">{moment(accountDetail.FinalEndDate).format('M/D/yyyy')}</label>
                                                    <label className="col-sm-3 col-form-label fw-bold text-end">Budget Available to Spend:</label>
                                                    <label className="col-sm-2 col-form-label text-end">
                                                        <NumericFormat value={getAvailableToSpend()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            <div className="col-3">
                                <AccountNotes account={account} {...props} />
                            </div>
                        </div>
                        <div className="row mb-2 mx-0 g-2">
                            <div className="col-12">
                                <div className="card mb-2">
                                    <div className="card-header"><FontAwesomeIcon icon={faBook} className="pe-1" />Ledger Accounts</div>
                                    {/*<div className="card-body">*/}
                                    {/*<div className="row">*/}
                                    <div className="table-responsive table-responsive-sm" style={{
                                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar"
                                    }}>
                                        <table className="table table-sm table-xs table-xxs table-hover" style={{ display: "table" }}>
                                            <thead>
                                                <tr>
                                                    <th className="table-light nowrap" colSpan="4">Ledger Account</th>
                                                    <th className="table-light text-center nowrap">Budget</th>
                                                    <th className="table-light text-center nowrap">Expenses</th>
                                                    {/*<th className="table-light text-center nowrap">Encumbrances</th>*/}
                                                    {/*<th className="table-light text-center nowrap">Forecast</th>*/}
                                                    <th className="table-light text-center">Forecasted<br />Encumbrances</th>
                                                    <th className="table-light text-center nowrap">Available</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getDirectCostLedgerAccounts().map(budgetExpense =>
                                                    <tr key={budgetExpense.LedgerAccount} className="align-middle">
                                                        <td className="nowrap" colSpan="4">
                                                            {budgetExpense.LedgerAccount}
                                                        </td>
                                                        {/*<td className="text-end">*/}
                                                        {/*    {budgetExpense.LedgerAccount === "7499 Netcom" ?*/}
                                                        {/*        <NumericFormat value={parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).netComm * 100 : 0} displayType="text" decimalScale={2} fixedDecimalScale suffix={'%'} thousandSeparator="," />*/}
                                                        {/*        : null}*/}
                                                        {/*    {budgetExpense.LedgerAccount === "7499 Risk Mgmt" ?*/}
                                                        {/*        <NumericFormat value={parameters && parameters.ExtraRates && parameters.ExtraRates.length > 0 ? parameters.ExtraRates.at(0).riskMgmt * 100 : 0} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />*/}
                                                        {/*        : null}*/}
                                                        {/*</td>*/}
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        {/*<td className="text-end">*/}
                                                        {/*    <NumericFormat value={budgetExpense.Encumbrances ?? 0} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                        {/*</td>*/}
                                                        {/*<td className="text-end">*/}
                                                        {/*    <NumericFormat value={getAccountLedgerForecast(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                        {/*</td>*/}
                                                        <td className="text-end">
                                                            <NumericFormat value={isNumericDefault(budgetExpense.Encumbrances, 0) + getAccountLedgerForecast(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={getAccountLedgerAvailable(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tbody className="table-group-divider">
                                                <tr>
                                                    <td colSpan="4">Direct costs without {accountDetail && !accountDetail.Account.startsWith('GR') ? 'ASC' : 'F&A'} applied</td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    {/*<td className="text-end">*/}
                                                    {/*    <NumericFormat value={getDirectCosts('No').Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                    {/*</td>*/}
                                                    {/*<td className="text-end">*/}
                                                    {/*    <NumericFormat value={getDirectCosts('No').Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                    {/*</td>*/}
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Encumbrances + getDirectCosts('No').Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('No').Available} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4">Direct costs with {accountDetail && !accountDetail.Account.startsWith('GR') ? 'ASC' : 'F&A'} applied</td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    {/*<td className="text-end">*/}
                                                    {/*    <NumericFormat value={getDirectCosts('Yes').Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                    {/*</td>*/}
                                                    {/*<td className="text-end">*/}
                                                    {/*    <NumericFormat value={getDirectCosts('Yes').Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                    {/*</td>*/}
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Encumbrances + getDirectCosts('Yes').Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getDirectCosts('Yes').Available} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody className="table-group-divider">
                                                {getIndirectCostLedgerAccounts().map(budgetExpense =>
                                                    <tr key={Number(budgetExpense.LedgerAccountId)} >
                                                        <td className="nowrap" colSpan="4">{budgetExpense.LedgerAccount}</td>
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={budgetExpense.Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        {/*<td className="text-end">*/}
                                                        {/*    <NumericFormat value={getIndirectAccountLedgerEncumbrances(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                        {/*</td>*/}
                                                        {/*<td className="text-end">*/}
                                                        {/*    <NumericFormat value={getAccountLedgerForecast(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                        {/*</td>*/}
                                                        <td className="text-end">
                                                            <NumericFormat value={getIndirectAccountLedgerEncumbrances(budgetExpense) + getAccountLedgerForecast(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat value={getIndirectAccountLedgerAvailable(budgetExpense)} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tbody className="table-group-divider">
                                                <tr>
                                                    <td colSpan="4">Total</td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getForecastTotals().Budget} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end">
                                                        <NumericFormat value={getForecastTotals().Expenses} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    {/*<td className="text-end">*/}
                                                    {/*    <NumericFormat value={getForecastTotals().Encumbrances} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                    {/*</td>*/}
                                                    {/*<td className="text-end">*/}
                                                    {/*    <NumericFormat value={getForecastTotals().Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />*/}
                                                    {/*</td>*/}
                                                    <td className="text-end">
                                                        <NumericFormat value={getForecastTotals().Encumbrances + getForecastTotals().Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                    <td className="text-end fw-bold">
                                                        <NumericFormat value={getForecastTotals().Available} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {!account.startsWith("GR") ?
                                                <tbody className="table-group-divider">
                                                    <tr>
                                                        <td colSpan="2">&nbsp;</td>
                                                        <td className="text-end">Cash Available</td>
                                                        <td className="text-end fw-bold table-light"><NumericFormat value={getGetActualTotal()} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                                        <td colSpan="5">&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2">&nbsp;</td>
                                                        <td className="text-end">Cash Available to Spend</td>
                                                        <td className="text-end fw-bold table-light"><NumericFormat value={getGetActualTotal() / (1 + (accountCurrentRate / 100))} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                                        <td colSpan="5">&nbsp;</td>
                                                    </tr>
                                                </tbody>
                                            : null}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {accountEmployeeEarnings && accountEmployeeEarnings.length > 0 ?
                            <div className="row mb-2 mx-0 g-2">
                                <div className="col-12">
                                    <div className="card mb-2">
                                        <div className="card-header"><FontAwesomeIcon icon={faUser} className="pe-1" />Employees</div>
                                        {/*<div className="card-body">*/}
                                        {/*<div className="row">*/}
                                        {accountDetail && accountDetail.WorkdayType === "Program" ?
                                            <>
                                                {<Startup version={version} account={account} accountData={versionData} accountDetail={accountDetail} forecastedPayPeriods={getForecastPayPeriods()} employeeFunding={accountEmployeeFunding} />}
                                            </>
                                            : null}
                                        <div className="table-responsive table-responsive-sm" style={{
                                            "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar"
                                        }}>
                                            <table className="table table-sm table-xs table-xxs table-hover" style={{ display: "table" }}>
                                                <thead>
                                                    <tr>
                                                        <th rowSpan="2" className="table-light nowrap">Employee</th>
                                                        <th rowSpan="2" className="table-light nowrap">EmplId</th>
                                                        <th rowSpan="2" className="table-light nowrap">Position</th>
                                                        <th rowSpan="2" className="table-light nowrap">Rcd</th>
                                                        <th rowSpan="2" className="table-light nowrap">Title</th>
                                                        <th rowSpan="2" className="table-light nowrap">AY Pay<br />Rate</th>
                                                        <th rowSpan="2" className="table-light nowrap">Sum Pay<br />Rate</th>
                                                        <th rowSpan="2" className="table-light nowrap">Earnings</th>
                                                        <th rowSpan="2" className="table-light nowrap">Staff Type</th>
                                                        <th rowSpan="2" className="table-light nowrap">Earnings<br />Forecast</th>
                                                        <th rowSpan="2" className="table-light nowrap">ERE<br />Forecast</th>
                                                        <th rowSpan="2" className="table-light nowrap">Tuition Rem<br />Forecast</th>
                                                        <th className="table-light nowrap">Start Date</th>
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className="text-end nowrap" key={employeeSemester.MinStartDate + 'StartDate'}>
                                                                {moment(employeeSemester.MinStartDate).format('M/D/yyyy')}
                                                                {/*<NumericFormat value={getCurrentRate() * 100} displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />*/}
                                                            </td>
                                                        ) : ''}
                                                    </tr>
                                                    <tr>
                                                        <th className="table-light nowrap">Semester</th>
                                                        {/*<th className="table-light nowrap">Emplid-Position-Rcd</th>*/}
                                                        {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester =>
                                                            <td className={`text-end nowrap ${employeeSemester.AcadPeriod === 'Fall' ? 'bg-warning' : employeeSemester.AcadPeriod === 'Spring' ? 'bg-success-subtle' : 'bg-danger-subtle'}`} key={employeeSemester.MinStartDate + 'Semester'}>{employeeSemester.AcadPeriod}</td>
                                                        ) : ''}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {accountEmployeeEarnings ? accountEmployeeEarnings.map((employeeEarning, ix) => {
                                                        let employeeForecasts = getEmployeeForecasts(employeeEarning);
                                                        return <tr key={employeeEarning.EmplIdPositionRcd} className="align-middle">
                                                            <td className="nowrap">{employeeEarning.Employee}</td>
                                                            <td className="nowrap">{employeeEarning.EmplId}</td>
                                                            <td className="nowrap">{employeeEarning.Position}</td>
                                                            <td className="nowrap text-end"><NumericFormat value={employeeEarning.EmplRcd} displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator="," /></td>
                                                            <td className="nowrap">{employeeEarning.Title}</td>
                                                            <td className="text-end nowrap"><NumericFormat value={employeeEarning.AYPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                                            <td className="text-end nowrap"><NumericFormat value={employeeEarning.SummerPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                                            <td className="text-end nowrap">
                                                                <NumericFormat value={employeeEarning.Earnings} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            </td>
                                                            <td className="nowrap">{employeeEarning.StaffType}</td>
                                                            <td className="text-end nowrap">
                                                                <NumericFormat value={employeeForecasts.Earnings} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            </td>
                                                            <td className="text-end nowrap">
                                                                <NumericFormat value={employeeForecasts.EREForecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            </td>
                                                            <td className="text-end nowrap">
                                                                <NumericFormat value={employeeForecasts.TuitionRemissionForecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," />
                                                            </td>
                                                            <td></td>
                                                            {accountEmployeeSemesters ? accountEmployeeSemesters.map(employeeSemester => {
                                                                let employeeFundingPercent = getEmployeeFundingPercent(employeeEarning, employeeSemester);
                                                                return <td className="text-end" key={employeeSemester.MinStartDate + '-' + employeeEarning.EmplIdPositionRcd}>
                                                                    <NumericFormat
                                                                        displayType={'text'}
                                                                        value={isNumericDefault(employeeFundingPercent.Value, '')}
                                                                        decimalScale={1}
                                                                        suffix={'%'}
                                                                        fixedDecimalScale
                                                                        thousandSeparator=","
                                                                        className="text-end"
                                                                    />

                                                                    {/*className={`form-control form-control-sm form-control-xs text-end bg-info-subtle ${thisLedger && thisLedger.IsUpdated ? 'fw-bold text-primary' : ''}`}*/}
                                                                    {/*className={employeeFundingPercent.DisplayInput ? 'form-control form-control-sm form-control-xs text-end bg-info-subtle' : 'form-control-plaintext form-control-sm form-control-xs text-end'}*/}
                                                                    {/*onValueChange={(v, si) => handleLedgerNumericChange({ Ledger: thisLedger, NewLedger: { ApplyFA: budgetExpense.ApplyFA, Month: fiscalMonth.FiscalPeriodNumber, Year: fiscalMonth.FiscalYearNumber, LedgerAccount: budgetExpense.LedgerAccount.substring(0, 4), LedgerAccountandDesc: budgetExpense.LedgerAccount, comment: '' } }, v, si)}*/}

                                                                </td>
                                                            }
                                                            ) : ''}

                                                        </tr>
                                                    }) : <tr>
                                                        <td colSpan={accountEmployeeSemesters ? accountEmployeeSemesters.length + 16 : 16}>No records found.</td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
