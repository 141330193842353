import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGauge, faSpinner, faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import DataTable from 'react-data-table-component';

function ForecastVsActual(props) {

    const [modal, setModal] = useState(false);
    const [data, setData] = useState(null);

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {

    }, []);


    const modalToggle = () => setModal(!modal);
    const openModal = () => {
        setShowLoading(true);
        if (!data) {
            getData();
        }
        modalToggle();
        setShowLoading(false);
    };
    const getData= async () => {


        const response = await fetch('/api/account/forecastvsactual?account=' + encodeURIComponent(props.account));
        const rdata = await response.json();

        setData(rdata);
    }


    return (
        <>
            <button type="button" className="btn btn-secondary btn-sm" onClick={openModal} disabled={showLoading} >
                <FontAwesomeIcon icon={showLoading ? faSpinner : faCodeCompare} spin={showLoading} className="pe-2" />
                Forecast vs Actual
            </button>
            <Modal isOpen={modal} toggle={modalToggle} size="xxl">
                <ModalHeader toggle={modalToggle}>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faCodeCompare} className="pe-2" />Forecast vs. Actual</h5>
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <table className="table table-sm table-xs table-xxs">
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>EmplId-Position-Rcd</th>
                                <th>Title</th>
                                <th>Academic Year Pay Rate</th>
                                <th>Summer Pay Rate</th>
                                <th>Fiscal Year</th>
                                <th>Fiscal Month</th>
                                <th>Semester</th>
                                <th>Pay Period Start Date</th>
                                <th>Pay Period End Date</th>
                                <th>Workdays</th>
                                <th>Forecasted Funding %</th>
                                <th>Forecast</th>
                                <th>Earnings</th>
                                <th>Includes Redistribution</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/*&& r.Name === (i < data.length - 1 ? data[i + 1].Name : null) */}
                            {/*<tr key={i} className={(i === 3)? "table-group-divider" : ""}>*/}
                            {data ? data.map((r, i) =>
                                <tr key={i} className={i === 0 || r.Name !== (i > 0 && i < data.length ? data[i - 1].Name : null) ? "table-group-divider" : ""}>
                                    <td className="nowrap">{r.Name}</td>
                                    <td className="nowrap">{r.EmplidPositionRcd}</td>
                                    <td className="nowrap">{r.Title}</td>
                                    <td className="text-end"><NumericFormat value={r.AYPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                    <td className="text-end"><NumericFormat value={r.SumPayRate} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                    <td className="text-end">{r.FiscalYear}</td>
                                    <td className="text-end">{r.FiscalMonth}</td>
                                    <td>{r.Semester}</td>
                                    <td className="text-end">{moment(r.StartDate).format("M/D/YYYY")}</td>
                                    <td className="text-end">{moment(r.EndDate).format("M/D/YYYY")}</td>
                                    <td className="text-end">{r.Workdays}</td>
                                    <td className="text-end"><NumericFormat value={r.FundingPercent * 100} displayType="text" fixedDecimalScale decimalScale={2} suffix={'%'} thousandSeparator="," className="" /></td>
                                    <td className={`text-end ${(r.Forecast === 0 && r.Forecasts === 0 ? null : r.Forecast) !== r.Earnings ? 'bg-danger-subtle' : ''}`}><NumericFormat value={r.Forecast === 0 && r.Forecasts === 0 ? null : r.Forecast} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                    <td className={`text-end ${(r.Forecast === 0 && r.Forecasts === 0 ? null : r.Forecast) !== r.Earnings ? 'bg-danger-subtle' : ''}`}><NumericFormat value={r.Earnings} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                    <td>{r.RDs > 0 ? 'Yes' : 'No'}</td>
                                </tr> 
                            ) : null }
                        </tbody>
                    {/*    <tbody>*/}
                    {/*        {data ? data.map(fcAccuracy =>*/}
                    {/*            <tr key={fcAccuracy.StartDate}>*/}
                    {/*                <td className="text-end">{moment(fcAccuracy.StartDate).format('M/D/yyyy')}</td>*/}
                    {/*                <td className="text-end"><NumericFormat value={fcAccuracy.Forecast} displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator="," /></td>*/}
                    {/*                <td className="text-end"><NumericFormat value={fcAccuracy.Actual} displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator="," /></td>*/}
                    {/*                <td className="text-end">*/}
                    {/*                    <NumericFormat value={fcAccuracy.Difference} displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator="," className={"fw-bold " + (fcAccuracy.Difference < 0 ? "text-danger" : "text-success")} />*/}
                    {/*                    &nbsp;*/}
                    {/*                    <NumericFormat value={fcAccuracy.Forecast === 0 ? 0 : (fcAccuracy.Difference) / fcAccuracy.Forecast * 100} displayType="text" decimalScale={2} suffix={'%'} thousandSeparator="," className="fw-bold text-danger" renderText={(value) => <small className={"fw-bold " + (fcAccuracy.Difference < 0 ? "text-danger" : "text-success")} >({value})</small>} />*/}
                    {/*                </td>*/}
                    {/*            </tr>*/}
                    {/*        )*/}
                    {/*            :*/}
                    {/*            <tr>*/}
                    {/*                <td colSpan="4" className="text-center">No data available</td>*/}
                    {/*            </tr>*/}
                    {/*        }*/}
                    {/*    </tbody>*/}
                    </table>
                </ModalBody>
            </Modal>
        </>
    );
}

//{/*    columns={[*/ }
//{/*        { name: 'Employee', selector: row => row.Name, sortable: true, wrap: true },*/ }
//{/*        { name: 'EmplId-Position-Rcd', selector: row => row.EmplidPositionRcd, sortable: true },*/ }
//{/*        { name: 'Title', selector: row => row.Title, sortable: true },*/ }
//{/*        { name: 'AY Pay Rate', selector: row => row.AYPayRate, sortable: true },*/ }
//{/*        { name: 'Sum Pay Rate', selector: row => row.SumPayRate, sortable: true },*/ }
//{/*        { name: 'Fiscal Year', selector: row => row.FiscalYear, sortable: true },*/ }
//{/*        { name: 'Fiscal Month', selector: row => row.FiscalMonth, sortable: true },*/ }
//{/*        { name: 'Semester', selector: row => row.Semester, sortable: true },*/ }
//{/*        { name: 'Pay Period Start Date', selector: row => row.StartDate, sortable: true },*/ }
//{/*        { name: 'Pay Period End Date', selector: row => row.EndDate, sortable: true },*/ }
//{/*        { name: 'Workdays', selector: row => row.Workdays, sortable: true },*/ }
//{/*        { name: 'Forecasted Funding %', selector: row => row.FundingPercent, sortable: true },*/ }
//{/*        { name: 'Forecast', selector: row => row.Forecast, sortable: true },*/ }
//{/*        { name: 'Earnings', selector: row => row.Earnings, sortable: true },*/ }
//{/*    ]}*/ }



export default ForecastVsActual;