import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, Link, Route, Routes, useParams } from 'react-router-dom';
import { GetAuthUsername } from "../AuthUsername";

import Header from "./Header";
import ReportSubMenu from "./ReportSubMenu";
//import Find from "./Find";

import { NumericFormat } from 'react-number-format';

import { Alert, Button } from 'reactstrap';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faCopy, faRectangleXmark, faCircleXmark, faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faListUl, faXmark, faMagnifyingGlass, faSpinner, faFileInvoice, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Report.css';
import AccountDetailReport from './AccountDetailReport';
import ReportInformation from './ReportInformation';
import StartupSummary from "./StartupSummary";
import useLookup from "../shared/useLookup";


export default function AccountSummary(props) {
    const { guid } = useParams();
    const { getLedgerAccountsData: getLAData } = useLookup();
    const { version } = props;
    const reportRefs = useRef([]);
    const [reportAccounts, setReportAccounts] = useState(null);

    const [ledgerAccountDirectory, setLedgerAccountDirectory] = useState(null);

    const [reportVersionData, setReportVersionData] = useState(null);

    const navigate = useNavigate();

    const dateNow = moment();

    useEffect(() => {
        getLedgerAccountData();
        if (version) {
            getReportVersionData()
        }
        else {
            getReportData();
        }

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        };

    }, [guid]);

    const getLedgerAccountData = async () => {
        //const responseLA = await fetch('/api/lookup/ledgeraccounts');
        //const dataLA = await responseLA.json();
        //setLedgerAccountDirectory(dataLA);
        const dataLA = await getLAData();
        setLedgerAccountDirectory(dataLA);
    };

    const getReportVersionData = async () => {

        let sessionObj = await getReportVersionDataFromSession();
        if (sessionObj) {
            setReportAccounts(sessionObj.AccountSummary);
            setReportVersionData(sessionObj);
        }
        else {
            //Download the report data and set it in session
            const response = await fetch('/api/report/version/' + encodeURIComponent(guid) + '/data');
            const data = await response.json();
            let dataObj = JSON.parse(data);
            setReportAccounts(dataObj.AccountSummary);
            setReportVersionData(dataObj);

            dataObj.Guid = guid;


            try {
                sessionStorage.setItem('ReportVersionData', JSON.stringify(dataObj, (key, value) => typeof value === 'undefined' ? null : value));
            }
            catch (ex) {
                console.log(ex);
                //sessionStorage.setItem('FindAccountsObj', JSON.stringify({ isPortfolioSearch: true, savedSearch: row, searchCriteria: { AccountStatus: 'Active' }, results: [] }, (key, value) => typeof value === 'undefined' ? null : value));
            }
        }

    };
    const getReportVersionDataFromSession = () => {
        if (sessionStorage.getItem('ReportVersionData')) {
            const sessionObj = JSON.parse(sessionStorage.getItem('ReportVersionData'));
            //check if the session object's GUID matches the current GUID

            if (sessionObj.Guid === guid) {
                //We're good to keep using this object
                return sessionObj;
            }
            else {
                sessionStorage.removeItem('ReportVersionData');
                return null;
            }
        }
        return null;
    };
    const getReportData = async () => {

        const response = await fetch('/api/report/' + encodeURIComponent(guid) + '/accountsummary');
        const data = await response.json();
        setReportAccounts(data);
    }

    const handleAccountTotals = (account, accountTotals, availableToSpend, cashAvail, cashAvailableToSpend) => {
        let budget = null;
        if (reportAccounts && reportAccounts.length > 0) {
            let clone = [...reportAccounts];
            let thisAccountIX = reportAccounts.findIndex(a => a.Account === account);
            if (thisAccountIX >= 0) {
                let thisAccount = clone.at(thisAccountIX);
                thisAccount.CalcBudget = accountTotals.Budget;
                thisAccount.CalcExpenses = accountTotals.Expenses;
                thisAccount.CalcEncumbrances = accountTotals.Encumbrances;
                thisAccount.CalcForecast = accountTotals.Forecast;
                thisAccount.CalcAvailable = accountTotals.Available;
                thisAccount.CalcAvailableToSpend = availableToSpend;
                if (account.startsWith('GR')) {
                    thisAccount.CalcCashAvail = null;
                    thisAccount.CalcCashAvailableToSpend = null;
                }
                else {
                    thisAccount.CalcCashAvail = cashAvail;
                    thisAccount.CalcCashAvailableToSpend = cashAvailableToSpend;
                }
                clone[thisAccountIX] = thisAccount;

                setReportAccounts([...clone]);
            }
        }
    };
    const getTotalBudget = () => {
        if (reportAccounts && reportAccounts.length > 0) {
            const totalBudget = reportAccounts.map(a => a.CalcBudget ? a.CalcBudget : (a.Budget < (a.RevenueBegBalance + a.Revenue) ? a.RevenueBegBalance + a.Revenue : a.Budget === 0 ? a.RevenueBegBalance + a.Revenue : a.Budget)).reduce((a, b) => a + b);
            return totalBudget;
        }
        else
            return null;
    };
    const getTotalITD = () => {
        if (reportAccounts && reportAccounts.length > 0) {
            const totalBudget = reportAccounts.map(a => a.CalcExpenses ? a.CalcExpenses : a.ITD).reduce((a, b) => a + b);
            return totalBudget;
        }
        else
            return null;
    };
    const getAccountData = (account) => {
        if (version) {
            let data = getReportVersionDataFromSession();
            if (data) {
                let accountData = data.AccountData.find(a => a.Account === account);
                return accountData;
            }
            return null;
        }
        else {
            return null;
        }
    };

    return (
        <>
            <Header />
            <ReportSubMenu guid={guid} {...props} />
            <ReportInformation guid={guid} {...props} />
            <div>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <span><FontAwesomeIcon icon={faListUl} className="pe-1" />Account Summary</span>
                        <div className="btn-group">
                            {reportAccounts && reportAccounts.length > 0 ?
                                reportAccounts.map((a, i) =>
                                    <AccountDetailReport innerRef={(element) => {
                                        let curRef = reportRefs.current.length > 0 ? reportRefs.current.find(r => r.account === a.Account) : null;
                                        if (!curRef && element) {
                                            reportRefs.current.push({ element: element, account: a.Account });
                                        }
                                    }} {...props} versionData={getAccountData(a.Account)} account={a.Account} ledgerAccountDirectory={ledgerAccountDirectory} key={a.Account} handleTotals={handleAccountTotals} preLoadData />
                                )
                                : null}
                            {/*<a href="#" class="btn btn-primary active" aria-current="page">GR42484</a>*/}
                            {/*<a href="#" class="btn btn-outline-secondary disabled">*/}
                            {/*    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />*/}
                            {/*    GR42484*/}
                            {/*</a>*/}
                            {/*<a href="#" class="btn btn-outline-primary">GR42484</a>*/}
                            {/*<a href="#" class="btn btn-outline-primary">GR42484</a>*/}
                            {/*<a href="#" class="btn btn-outline-primary">GR42484</a>*/}
                            {/*<a href="#" class="btn btn-outline-primary">GR42484</a>*/}
                            {/*<a href="#" class="btn btn-outline-primary">GR42484</a>*/}
                            {/*<a href="#" class="btn btn-outline-primary">GR42484</a>*/}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-xs table-hover">
                            <thead>
                                <tr>
                                    {/*<th className="table-light nowrap">Index</th>*/}
                                    <th className="table-light nowrap text-center">Account</th>
                                    <th className="table-light nowrap text-center">Cost Centers</th>
                                    <th className="table-light nowrap text-center">Award Number</th>
                                    <th className="table-light nowrap text-center">Account Nick Name</th>
                                    <th className="table-light nowrap text-center">Account Title</th>
                                    <th className="table-light nowrap text-center">Sponsor</th>
                                    <th className="table-light nowrap text-end">Start Date</th>
                                    <th className="table-light nowrap text-end">End Date</th>
                                    <th className="table-light nowrap text-center">Final<br />End Date</th>
                                    <th className="table-light nowrap text-center">Days to<br />Expire</th>
                                    <th className="table-light nowrap text-center">Total Budget</th>
                                    <th className="table-light nowrap text-center">ITD<br />Expenditures</th>
                                    <th className="table-light nowrap text-center">Forecast &<br />Encumbrances</th>
                                    <th className="table-light nowrap text-center">Available<br />Funds</th>
                                    <th className="table-light nowrap text-center">Available <br />to Spend</th>
                                    <th className="table-light nowrap text-center">Award<br />Anticipated<br />Funding</th>
                                    <th className="table-light nowrap text-center">Time<br />Burn Rate</th>
                                    <th className="table-light nowrap text-center">Budget<br />Burn Rate</th>
                                    <th className="table-light nowrap text-center">Cash Avail</th>
                                    <th className="table-light nowrap text-center">Avail to Spend<br />(ASC Applied)</th>
                                    {/*<th className="table-light nowrap text-center">Notes</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {reportAccounts && reportAccounts.length > 0 ?
                                    reportAccounts.map((a, i) =>
                                        <Fragment key={a.Account + i}>
                                            <tr>
                                                {/*<td>{i}</td>*/}
                                                <td>
                                                    <a className="link-opacity-50-hover" href="/" onClick={(e) => {
                                                        e.preventDefault();
                                                        let ref = reportRefs.current.find(r => r.account === a.Account);
                                                        if (ref) {
                                                            ref.element.click();
                                                        }
                                                    }}>{a.Account}</a>
                                                    {/*<AccountDetailReport {...props} versionData={getAccountData(a.Account)} account={a.Account} ledgerAccountDirectory={ledgerAccountDirectory} showBasicLink />*/}
                                                </td>
                                                <td>{a.WorkdayCostCenterRefID}</td>
                                                <td>{a.AwardID}</td>
                                                <td>{a.NickName}</td>
                                                <td>{a.title}</td>
                                                <td>{a.Sponsor}</td>
                                                <td className="text-end">{a.Start_Date ? moment(a.Start_Date).format("M/D/yyyy") : ''}</td>
                                                <td className="text-end">{a.EndDate ? moment(a.EndDate).format("M/D/yyyy") : ''}</td>
                                                <td className="text-end">{a.FinalEndDate ? moment(a.FinalEndDate).format("M/D/yyyy") : ''}</td>
                                                <td className="text-end">{a.End_date ? moment(a.End_date).diff(moment(), 'days') : ''}</td>
                                                {/*rst!budget < (rst!revenuebegbalance + rst!revenue) ? rst!revenuebegbalance + rst!revenue : rst!budget = 0 ? rst!revenuebegbalance + rst!revenue : rst!budget*/}
                                                <td className="text-end"><NumericFormat value={a.CalcBudget ? a.CalcBudget : (a.Budget < (a.RevenueBegBalance + a.Revenue) ? a.RevenueBegBalance + a.Revenue : a.Budget === 0 ? a.RevenueBegBalance + a.Revenue : a.Budget)} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," /></td>
                                                <td className="text-end"><NumericFormat value={a.CalcExpenses ? a.CalcExpenses : a.ITD} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," /></td>
                                                <td className={`${a.CalcForecast !== undefined && a.CalcEncumbrances !== undefined ? 'text-end' : 'text-center'}`}>
                                                    {a.CalcForecast !== undefined && a.CalcEncumbrances !== undefined ? <NumericFormat value={a.CalcForecast + a.CalcEncumbrances} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," className="text-end" /> : <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                                </td>
                                                <td className={`${a.CalcAvailable !== undefined ? 'text-end' : 'text-center'}`}>
                                                    {a.CalcAvailable !== undefined ? <NumericFormat value={a.CalcAvailable} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," className="text-end" /> : <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                                </td>
                                                <td className={`${a.CalcAvailableToSpend !== undefined ? 'text-end' : 'text-center'}`}>
                                                    {a.CalcAvailableToSpend !== undefined ? <NumericFormat value={a.CalcAvailableToSpend} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," className="text-end" /> : <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                                </td>
                                                <td className="text-end"><NumericFormat value={a.AnticipatedRemaining} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," /></td>
                                                <td className="text-end">
                                                    <NumericFormat value={
                                                        Number.parseFloat(
                                                            moment(a.Start_Date) >= moment(a.EndDate) ? 0 : (
                                                                moment(a.EndDate) <= dateNow ? 1 : (
                                                                    moment(a.Start_Date) > dateNow ? 0 : (
                                                                        dateNow.diff(moment(a.Start_Date), 'days') / moment(a.EndDate).diff(moment(a.Start_Date), 'days')
                                                                    )
                                                                )
                                                            ) * 100
                                                        ).toFixed(1)
                                                    } displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                                </td>
                                                <td className="text-end">
                                                    <NumericFormat value={
                                                        Number.parseFloat(a.Budget) === 0 ? 0 : (Number.parseFloat(a.ITD) / Number.parseFloat(a.Budget) * 100)
                                                    } displayType="text" decimalScale={1} fixedDecimalScale suffix={'%'} thousandSeparator="," />
                                                </td>
                                                <td className={`${a.CalcCashAvail !== undefined ? 'text-end' : 'text-center'}`}>
                                                    {a.CalcCashAvail !== undefined ? <NumericFormat value={a.CalcCashAvail} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," className="text-end" /> : <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                                </td>
                                                <td className={`${a.CalcCashAvailableToSpend !== undefined ? 'text-end' : 'text-center'}`}>
                                                    {a.CalcCashAvailableToSpend !== undefined ? <NumericFormat value={a.CalcCashAvailableToSpend} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," className="text-end" /> : <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                                </td>
                                                {/*<td>Note</td>*/}
                                            </tr>
                                            {a.WorkdayType === "Program" ?
                                                <>
                                                    <StartupSummary version={version} account={a.Account} accountDetail={a} reportVersionData={reportVersionData} />
                                                    {/*<StartupSummary account={a.Account} accountDetail={a} forecastedPayPeriods={getForecastPayPeriods()} employeeFunding={accountEmployeeFunding} />*/}
                                                </>
                                                : null}
                                        </Fragment>
                                    )
                                    : null}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan="9" className="table-light nowrap">&nbsp;</th>
                                    <th className="table-light nowrap text-center">Total:</th>
                                    <th className="table-light nowrap text-end"><NumericFormat value={getTotalBudget()} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," /></th>
                                    <th className="table-light nowrap text-end"><NumericFormat value={getTotalITD()} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," /></th>
                                    <th className={`table-light nowrap ${reportAccounts && reportAccounts.every(a => a.CalcForecast !== undefined && a.CalcEncumbrances !== undefined) ? 'text-end' : 'text-center'}`}>
                                        {reportAccounts && reportAccounts.every(a => a.CalcForecast !== undefined && a.CalcEncumbrances !== undefined) ? <NumericFormat value={reportAccounts.map(a => a.CalcForecast + a.CalcEncumbrances).reduce((a, b) => a + b)} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," className="text-end" /> : <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                    </th>
                                    <th className={`table-light nowrap ${reportAccounts && reportAccounts.every(a => a.CalcAvailable !== undefined) ? 'text-end' : 'text-center'}`}>
                                        {reportAccounts && reportAccounts.every(a => a.CalcAvailable !== undefined) ? <NumericFormat value={reportAccounts.map(a => a.CalcAvailable).reduce((a, b) => a + b)} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," className="text-end" /> : <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                    </th>
                                    <th className={`table-light nowrap ${reportAccounts && reportAccounts.every(a => a.CalcAvailableToSpend !== undefined) ? 'text-end' : 'text-center'}`}>
                                        {reportAccounts && reportAccounts.every(a => a.CalcAvailableToSpend !== undefined) ? <NumericFormat value={reportAccounts.map(a => a.CalcAvailableToSpend).reduce((a, b) => a + b)} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," className="text-end" /> : <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                    </th>
                                    <th className="table-light nowrap text-end"><NumericFormat value={reportAccounts ? reportAccounts.map(a => a.AnticipatedRemaining).reduce((a, b) => a + b) : 0} displayType="text" decimalScale={2} fixedDecimalScale prefix={'$'} thousandSeparator="," /></th>
                                    <th colSpan="4" className="table-light nowrap">&nbsp;</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            {/*<div className="row g-3 mb-4 mx-2">*/}
            {/*    <ul className="nav nav-tabs">*/}
            {/*        <li className="nav-item">*/}
            {/*            <a className="nav-link" href="#">Active</a>*/}
            {/*        </li>*/}
            {/*        <li className="nav-item">*/}
            {/*            <a className="nav-link" href="#">Link</a>*/}
            {/*        </li>*/}
            {/*        <li className="nav-item">*/}
            {/*            <a className="nav-link" href="#">Link</a>*/}
            {/*        </li>*/}
            {/*        <li className="nav-item">*/}
            {/*            <a className="nav-link">Disabled</a>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </>
    );

}
