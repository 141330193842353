import React, { Component } from 'react';
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';

export class Forecasts extends Component {
    static displayName = Forecasts.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };
    }

    componentDidMount() {
        this.populateForecastData();
    }


    render() {

        //const dataSet = [
        //    ["Tiger Nixon", "System Architect", "Edinburgh", "5421", "2011/04/25", "$320,800"],
        //    ["Garrett Winters", "Accountant", "Tokyo", "8422", "2011/07/25", "$170,750"],
        //    ["Ashton Cox", "Junior Technical Author", "San Francisco", "1562", "2009/01/12", "$86,000"]
        //];

        const dataSet = this.state.forecasts;
        const columns = [
            { name: 'Id', selector: row => row.id, sortable: true },
            { name: 'Name', selector: row => row.name, sortable: true },
            { name: 'Created By', selector: row => row.createdBy, sortable: true },
            { name: 'Status', selector: row => row.status, sortable: true },
            { name: 'Date Created', selector: row => moment(row.dateCreated).format('lll'), sortable: true },
            { name: 'Date Updated', selector: row => row.dateUpdated ? moment(row.dateUpdated).format('lll') : null, sortable: true }
        ];



        return (
            <div>
                <h3>Forecasts</h3>
                <DataTable pagination pointerOnHover striped progressPending={this.state.loading} columns={columns} data={this.state.forecasts} /*selectableRows*/ />

                {/*{this.state.loading ? (*/}
                {/*    <h4 className="text-warning">Loading data...</h4>*/}
                {/*) : (*/}
                {/*    //<DataTableFunc columns={[*/}
                {/*    //                            { title: "date" },*/}
                {/*    //    { title: "temperatureC" },*/}
                {/*    //    { title: "temperatureF" },*/}
                {/*    //    { title: "summary" }*/}
                {/*    //                        ] } data={this.state.forecasts} />*/}
                {/*        <>*/}
                {/*    <table className="table table-hover">*/}
                {/*        <thead className="table-light">*/}
                {/*            <tr>*/}
                {/*                <th>Date</th>*/}
                {/*                <th>C</th>*/}
                {/*                <th>F</th>*/}
                {/*                <th>Summary</th>*/}
                {/*            </tr>*/}
                {/*        </thead>*/}
                {/*        <tbody class="table-group-divider">*/}
                {/*            {this.state.forecasts.map(forecast => (*/}
                {/*                <tr>*/}
                {/*                    <td>{forecast.date}</td>*/}
                {/*                    <td>{forecast.temperatureC}</td>*/}
                {/*                    <td>{forecast.temperatureF}</td>*/}
                {/*                    <td>{forecast.summary}</td>*/}
                {/*                </tr>*/}
                {/*            ))}*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*    </>*/}
                {/*)}*/}

            </div>
        );
    }


    async populateForecastData() {
        const response = await fetch('forecast');
        const data = await response.json();
        //console.log(data);
        this.setState({ forecasts: data, loading: false });
    }

}