import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGauge, faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import ForecastVsActual from './ForecastVsActual';

function ForecastAccuracy(props) {

    const [modalForecastAccuracy, setModalForecastAccuracy] = useState(false);
    const [forecastAccuracy, setForecastAccuracy] = useState(null);

    useEffect(() => {

    }, []);


    const modalForecastAccuracyToggle = () => setModalForecastAccuracy(!modalForecastAccuracy);
    const openModalForecastAccuracy = () => {
        if (!forecastAccuracy) {
            getForecastAccuracy();
        }
        modalForecastAccuracyToggle();
    };
    const getForecastAccuracy = async () => {

        const response = await fetch('/api/account/forecastaccuracy?account=' + encodeURIComponent(props.account) + '&startDate=' + encodeURIComponent(props.payPeriodStartDate));
        const data = await response.json();

        setForecastAccuracy(data);
    }


    return (
        <>
            <button type="button" className="btn btn-secondary btn-sm" onClick={openModalForecastAccuracy} >
                <FontAwesomeIcon icon={faGauge} className="pe-2" />
                Forecast Accuracy
            </button>
            <Modal isOpen={modalForecastAccuracy} toggle={modalForecastAccuracyToggle}>
                <ModalHeader toggle={modalForecastAccuracyToggle}>
                    <h5><FontAwesomeIcon icon={faGauge} className="pe-2" />Forecast Accuracy</h5>
                </ModalHeader>
                <ModalBody>
                    <h5>Prior 3 pay Periods Forecast Accuracy</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Pay Period Start Date</th>
                                <th>Forecast</th>
                                <th>Actual</th>
                                <th>Difference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forecastAccuracy ? forecastAccuracy.map(fcAccuracy =>
                                <tr key={fcAccuracy.StartDate}>
                                    <td className="text-end">{moment(fcAccuracy.StartDate).format('M/D/yyyy')}</td>
                                    <td className="text-end"><NumericFormat value={fcAccuracy.Forecast} displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator="," /></td>
                                    <td className="text-end"><NumericFormat value={fcAccuracy.Actual} displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator="," /></td>
                                    <td className="text-end">
                                        <NumericFormat value={fcAccuracy.Difference} displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator="," className={"fw-bold " + (fcAccuracy.Difference < 0 ? "text-danger" : "text-success")} />
                                        &nbsp;
                                        <NumericFormat value={fcAccuracy.Forecast === 0 ? 0 : (fcAccuracy.Difference) / fcAccuracy.Forecast * 100} displayType="text" decimalScale={2} suffix={'%'} thousandSeparator="," className="fw-bold text-danger" renderText={(value) => <small className={"fw-bold " + (fcAccuracy.Difference < 0 ? "text-danger" : "text-success")} >({value})</small>} />
                                    </td>
                                </tr>
                            )
                                :
                                <tr>
                                    <td colSpan="4" className="text-center">No data available</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div className="text-center">
                        <ForecastVsActual {...props} />
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default ForecastAccuracy;