import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, Link, Route, Routes, useParams } from 'react-router-dom';

import Header from "./Header";
import ReportSubMenu from "./ReportSubMenu";

import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Report.css';
import ReportInformation from './ReportInformation';


export default function Report(props) {
    const { version } = props;
    const { guid } = useParams();

    useEffect(() => {
        /*console.log("report version", version);*/
    }, []);

    return (
        <>
            <Header />
            <ReportSubMenu {...props} guid={guid} />
            <div className="container pb-3 fst-italic text-secondary">
                <p>
                    Click the above tabs to view the sections of the report.
                </p>
            </div>
            <ReportInformation {...props} guid={guid} />
        </>
    );

}
