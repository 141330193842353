import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { GetAuthUsername } from "../AuthUsername";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faStar, faTrashCan, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faMagnifyingGlassPlus, faFloppyDisk, faSpinner, faBoxesStacked, faUser, faBan, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

function SaveSearch(props) {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const [saveAs, setSaveAs] = useState(null);
    const [modal, setModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [searchSaved, setSearchSaved] = useState(false);

    const authUsername = GetAuthUsername();
    const authUserFullName = activeAccount ? activeAccount.name : authUsername;

    useEffect(() => {

    }, []);

    const modalToggle = () => setModal(!modal);
    const openModal = (e) => {
        e.preventDefault();
        setSearchSaved(false);
        getData();
    };
    const getData = async () => {

        setShowLoading(true);

        try {
            //const encResponse = await fetch('/api/account/encumbrancedetail?account=' + encodeURIComponent(props.account));
            //const encData = await encResponse.json();
            //setEncumbrances(encData);

            modalToggle();
            setShowLoading(false);
        }
        catch (e) {
            console.error(e);
            setShowLoading(false);
        }

    }

    const handleChangeSaveAs = (e) => {
        setSaveAs(e.target.value);
    }

    const saveSearch = async () => {
        let saveObj = {
            SearchName: saveAs,
            Criteria: JSON.stringify(props.searchCriteria, (key, value) => typeof value === 'undefined' ? null : value),
            Username: authUsername,
            UserFullName: authUserFullName,
        };

        try {
            //Post to API
            await fetch('/api/savedsearch', {
                method: 'POST',
                body: JSON.stringify(saveObj, (key, value) => typeof value === 'undefined' ? null : value),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).catch(ex => {
                console.log('Caught Error')
            });
            setSearchSaved(true);

        }
        catch (ex) {
            console.log(ex);
            console.log('Error with Saved Search');
        }

    }


    return (
        <>
            <button className="btn btn-secondary btn-sm" onClick={openModal} disabled={showLoading || props.disabled} >
                {showLoading ?
                    <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                    :
                    <FontAwesomeIcon icon={faFloppyDisk} className="pe-2" />
                }
                Save Search
            </button>
            <Modal isOpen={modal} toggle={modalToggle} size="md">
                <ModalHeader toggle={modalToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faMagnifyingGlassPlus} className="pe-2" />Save Search</h5>
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody>
                    {!searchSaved ?
                        <>
                            <div className="row pb-3">
                                <div className="col-auto">
                                    Search Criteria:
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Award:
                                </div>
                                <div className="col-auto fw-bold">
                                    {props.searchCriteria.Award}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Account Status:
                                </div>
                                <div className="col-auto fw-bold">
                                    {props.searchCriteria.AccountStatus}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Account:
                                </div>
                                <div className="col-auto fw-bold">
                                    {props.searchCriteria.Account}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Account Type:
                                </div>
                                <div className="col-auto fw-bold">
                                    {props.searchCriteria.AccountType}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Lead Unit/Fund Code:
                                </div>
                                <div className="col-auto fw-bold">
                                    {props.searchCriteria.LeadUnitFundCode}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Cost Center/Hierarchy:
                                </div>
                                <div className="col-auto fw-bold">
                                    {props.searchCriteria.CostCenterHierarchy}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    PI Last Name:
                                </div>
                                <div className="col-auto fw-bold">
                                    {props.searchCriteria.PILastName}
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-6">
                                    Save Search As:
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 fw-bold">
                                    <input type="text" className="form-control form-control-sm" maxLength="50" name="SaveAs" value={saveAs} onChange={handleChangeSaveAs} />
                                </div>
                            </div>
                        </>
                        :
                        <div className="row p-3">
                            <div className="col-12 text-center">
                                Search Saved
                            </div>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        Close
                    </Button>
                    {!searchSaved ?
                        <Button color="primary" onClick={saveSearch} disabled={saveAs && saveAs !== '' ? false : true}>
                            Save
                        </Button>
                        :
                        null
                    }
                </ModalFooter>
            </Modal>
        </>
    );
}

export default SaveSearch;