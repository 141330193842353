export const getParameters = () => {
    let params = sessionStorage.getItem('parameters');
    if (params) {
        return JSON.parse(params);
    }
    else {
        fetch('/api/lookup/parameters')
            .then(res => res.json())
            .then(data => {
                sessionStorage.setItem('parameters', JSON.stringify(data))
                return data;
            });
    }
};
