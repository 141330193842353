import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faBoltLightning, faSackDollar, faMagnifyingGlass, faSpinner, faFileInvoice, faUserPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { isNumeric, isNumericDefault, isNullOrEmpty } from '../../utilities';

export default function Startup(props) {
    const { version, accountDetail, accountData } = props;
    const [actualPayPeriods, setActualPayPeriods] = useState(null);
    const [startUpPayPeriods, setStartUpPayPeriods] = useState({ PI: null, GRA: null, PIAdj: null, GRAAdj: null });
    const [forecastedPayPeriods, setForecastedPayPeriods] = useState({ PI: 0, GRA: 0 });

    //const [ledgerAccountToAdd, setLedgerAccountToAdd] = useState(null);

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        getInitialData();
        if (props.forecastedPayPeriods)
            setForecastedPayPeriods(props.forecastedPayPeriods);

    }, [accountDetail, props.forecastedPayPeriods]);


    const getInitialData = async () => {
        if (version && accountDetail && accountData && accountData.Startup) {
            let startUpObj = { PI: null, GRA: null, PIAdj: null, GRAAdj: null };
            //populate the edit object
            startUpObj.PI = isNumeric(accountDetail.ippPI) ? accountDetail.ippPI : null;
            startUpObj.GRA = isNumeric(accountDetail.ippGRA) ? accountDetail.ippGRA : null;
            startUpObj.PIAdj = isNumeric(accountDetail.ippPIActAdj) ? accountDetail.ippPIActAdj : null;
            startUpObj.GRAAdj =isNumeric(accountDetail.ippGRAActAdj) ? accountDetail.ippGRAActAdj : null;
            //if (appData) {
            //    startUpObj.PIAdj = accountDetail && accountDetail.ippPIActAdj !== null ? accountDetail.ippPIActAdj : 0;
            //    startUpObj.GRAAdj = accountDetail && accountDetail.ippGRAActAdj !== null ? accountDetail.ippGRAActAdj : 0;
            //}
            setStartUpPayPeriods(startUpObj);
            setActualPayPeriods(accountData.Startup);
        }
        else {
            const appResponse = await fetch('/api/account/startupactualpayperiods?account=' + encodeURIComponent(props.account));
            const appData = await appResponse.json();

            let startUpObj = { PI: null, GRA: null, PIAdj: null, GRAAdj: null };
            //populate the edit object
            if (accountDetail) {
                startUpObj.PI = accountDetail && isNumeric(accountDetail.ippPI) ? accountDetail.ippPI : null;
                startUpObj.GRA = accountDetail && isNumeric(accountDetail.ippGRA) ? accountDetail.ippGRA : null;
                startUpObj.PIAdj = accountDetail && isNumeric(accountDetail.ippPIActAdj) ? accountDetail.ippPIActAdj : null;
                startUpObj.GRAAdj = accountDetail && isNumeric(accountDetail.ippGRAActAdj) ? accountDetail.ippGRAActAdj : null;
            }
            //if (appData) {
            //    startUpObj.PIAdj = accountDetail && accountDetail.ippPIActAdj !== null ? accountDetail.ippPIActAdj : 0;
            //    startUpObj.GRAAdj = accountDetail && accountDetail.ippGRAActAdj !== null ? accountDetail.ippGRAActAdj : 0;
            //}
            setStartUpPayPeriods(startUpObj);
            setActualPayPeriods(appData);
        }
    };
    const getAvailablePIPayPeriods = () => {
        let value = 0;
        value = accountDetail ? accountDetail.ippPI : 0;
        value -= (actualPayPeriods ? actualPayPeriods.PIPayPeriods : 0) + (accountDetail ? accountDetail.ippPIActAdj : 0);
        value -= forecastedPayPeriods && forecastedPayPeriods.PI !== null ? forecastedPayPeriods.PI : 0;
        return value;
    };
    const getAvailableGRAPayPeriods = () => {
        let value = 0;
        value = accountDetail ? accountDetail.ippGRA : 0;
        value -= (actualPayPeriods ? actualPayPeriods.GRAPayPeriods : 0) + (accountDetail ? accountDetail.ippGRAActAdj : 0);
        value -= forecastedPayPeriods && forecastedPayPeriods.GRA !== null ? forecastedPayPeriods.GRA : 0;
        return value;
    }

    return (
        <>
            {accountDetail && (isNumeric(accountDetail.ippPI) || isNumeric(accountDetail.ippGRA) || isNumeric(accountDetail.ippPIActAdj) || isNumeric(accountDetail.ippGRAActAdj)) ?
                <div className="col-3 p-3">
                    <table className="table table-sm table-bordered mb-0" style={{ fontSize: ".65rem" }}>
                        <thead>
                            <tr>
                                <th scope="col" className="col-6">
                                </th>
                                <th scope="col" className="col-3">PI</th>
                                <th scope="col" className="col-3 nowrap">GRA & GSA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="text-end">Startup PP:</th>
                                <td className="text-end"><NumericFormat value={accountDetail ? isNumericDefault(accountDetail.ippPI, '') : ''} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                <td className="text-end"><NumericFormat value={accountDetail ? isNumericDefault(accountDetail.ippGRA, '') : ''} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                            </tr>
                            <tr>
                                <th className="text-end">Used PP:</th>
                                <td className="text-end"><NumericFormat value={(actualPayPeriods ? isNumericDefault(actualPayPeriods.PIPayPeriods, 0) : 0) + (accountDetail ? isNumericDefault(accountDetail.ippPIActAdj, 0) : 0)} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                <td className="text-end"><NumericFormat value={(actualPayPeriods ? isNumericDefault(actualPayPeriods.GRAPayPeriods, 0) : 0) + (accountDetail ? isNumericDefault(accountDetail.ippGRAActAdj, 0) : 0)} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                            </tr>
                            <tr>
                                <th className="text-end">Forecasted PP:</th>
                                <td className="text-end"><NumericFormat value={forecastedPayPeriods ? isNumericDefault(forecastedPayPeriods.PI, 0) : 0} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                <td className="text-end"><NumericFormat value={forecastedPayPeriods ? isNumericDefault(forecastedPayPeriods.GRA, 0) : 0} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                            </tr>
                            <tr>
                                <th className="text-end">Available PP:</th>
                                <td className="text-end"><NumericFormat value={getAvailablePIPayPeriods()} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                                <td className="text-end"><NumericFormat value={getAvailableGRAPayPeriods()} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                :
                null
            }
        </>
    );
}
