import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar, faTrashCan, faFloppyDisk, faPenToSquare, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { faBoltLightning, faSackDollar, faMagnifyingGlass, faSpinner, faFileInvoice, faUserPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { useMsal } from "@azure/msal-react";
import { GetAuthUsername } from "../AuthUsername";
import { isNumeric, isNumericDefault, isNullOrEmpty } from '../../utilities';

export default function Startup(props) {
    const { instance, accounts } = useMsal();

    const [actualPayPeriods, setActualPayPeriods] = useState(null);
    const [startUpPayPeriods, setStartUpPayPeriods] = useState({ PI: null, GRA: null, PIAdj: null, GRAAdj: null });
    const [forecastedPayPeriods, setForecastedPayPeriods] = useState({ PI: 0, GRA: 0 });
    const [hasChanges, setHasChanges] = useState(false);

    //const [ledgerAccountToAdd, setLedgerAccountToAdd] = useState(null);

    const [saveError, setSaveError] = useState(null);

    const activeAccount = instance.getActiveAccount();
    const authUsername = GetAuthUsername();

    const [modal, setModal] = useState(false);

    const [showLoading, setShowLoading] = useState(false);
    const [saving, setSaving] = useState(false);


    useEffect(() => {
        getInitialData();
        if (props.forecastedPayPeriods)
            setForecastedPayPeriods(props.forecastedPayPeriods);

    }, [props.accountDetail, props.forecastedPayPeriods]);

    const modalToggle = () => setModal(!modal);

    const openModal = () => {
        getData();
        modalToggle();
    };
    const getInitialData = async () => {
        const appResponse = await fetch('/api/account/startupactualpayperiods?account=' + encodeURIComponent(props.account));
        const appData = await appResponse.json();

        let startUpObj = { PI: null, GRA: null, PIAdj: null, GRAAdj: null };
        //populate the edit object
        if (props.accountDetail) {
            startUpObj.PI = props.accountDetail && isNumeric(props.accountDetail.ippPI) ? props.accountDetail.ippPI : null;
            startUpObj.GRA = props.accountDetail && isNumeric(props.accountDetail.ippGRA) ? props.accountDetail.ippGRA : null;
            startUpObj.PIAdj = props.accountDetail && isNumeric(props.accountDetail.ippPIActAdj) ? props.accountDetail.ippPIActAdj : null;
            startUpObj.GRAAdj = props.accountDetail && isNumeric(props.accountDetail.ippGRAActAdj) ? props.accountDetail.ippGRAActAdj : null;
        }
        //if (appData) {
        //    startUpObj.PIAdj = props.accountDetail && props.accountDetail.ippPIActAdj !== null ? props.accountDetail.ippPIActAdj : 0;
        //    startUpObj.GRAAdj = props.accountDetail && props.accountDetail.ippGRAActAdj !== null ? props.accountDetail.ippGRAActAdj : 0;
        //}
        setStartUpPayPeriods(startUpObj);
        setActualPayPeriods(appData);
    };
    const getData = async () => {
        setShowLoading(true);
        setHasChanges(false);
        getInitialData(); 
        setShowLoading(false);

    }
    const handleLedgerNumericChange = (context, values, sourceInfo) => {
        if (sourceInfo.source !== 'event') {
            return;
        }

        var targetVar = sourceInfo.event.target.name;
        var targetVal = values.floatValue ?? null;
        if (!isNumeric(targetVal)) {
            sourceInfo.event.target.value = '';
        }

        setStartUpPayPeriods({ ...startUpPayPeriods, [targetVar]: targetVal });
        setHasChanges(true);

    }
    const getAvailablePIPayPeriods = () => {
        let value = 0;
        value = props.accountDetail ? props.accountDetail.ippPI : 0;
        value -= (actualPayPeriods ? actualPayPeriods.PIPayPeriods : 0) + (props.accountDetail ? props.accountDetail.ippPIActAdj : 0);
        value -= forecastedPayPeriods && forecastedPayPeriods.PI !== null ? forecastedPayPeriods.PI : 0;
        return value;
    };
    const getAvailableGRAPayPeriods = () => {
        let value = 0;
        value = props.accountDetail ? props.accountDetail.ippGRA : 0;
        value -= (actualPayPeriods ? actualPayPeriods.GRAPayPeriods : 0) + (props.accountDetail ? props.accountDetail.ippGRAActAdj : 0);
        value -= forecastedPayPeriods && forecastedPayPeriods.GRA !== null ? forecastedPayPeriods.GRA : 0;
        return value;
    }
    const handleSave = async () => {
        setSaving(true);
        setSaveError(null);

        let faObj = {
            account: props.account,
            startupPIPayPeriods: startUpPayPeriods.PI,
            startupGRAPayPeriods: startUpPayPeriods.GRA,
            startupPIPayPeriodsActualAdj: startUpPayPeriods.PIAdj,
            startupGRAPayPeriodsActualAdj: startUpPayPeriods.GRAAdj,
            updatedBy: activeAccount ? activeAccount.name : authUsername,
        }

        let newData = await fetch('/api/account/startuppayperiods', {
            method: 'PUT',
            body: JSON.stringify(faObj),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            if (response.ok) {
                setSaveError(false);
                setTimeout(() => setSaveError(null), 5000);
                setHasChanges(false);
                let fbData = response.json();
                return fbData;
            }
            throw new Error(`Error occurred saving startup pay periods: ${response.statusText}`);
        }
        ).catch(ex => {
            console.error(ex);
            //errorMessage = ex.message;
            setSaveError(true);
            setTimeout(() => setSaveError(null), 5000);
            throw ex;
        })
        .finally(() => {
            setSaving(false);
        });

        if (newData) {
            setStartUpPayPeriods({ PI: newData.startupPIPayPeriods, GRA: newData.startupGRAPayPeriods, PIAdj: newData.startupPIPayPeriodsActualAdj, GRAAdj: newData.startupGRAPayPeriodsActualAdj });
            if (props.savedChanges) {
                props.savedChanges({ PI: newData.startupPIPayPeriods, GRA: newData.startupGRAPayPeriods, PIAdj: newData.startupPIPayPeriodsActualAdj, GRAAdj: newData.startupGRAPayPeriodsActualAdj });
            }
        }





    }



    return (
        <>
            {props.accountDetail && (isNumeric(props.accountDetail.ippPI) || isNumeric(props.accountDetail.ippGRA) || isNumeric(props.accountDetail.ippPIActAdj) || isNumeric(props.accountDetail.ippGRAActAdj)) ?
                <table className="table table-sm table-bordered mb-0" style={{fontSize: ".65rem"} }>
                    <thead>
                        <tr>
                            <th scope="col" className="col-6">
                                <button type="submit" className="btn btn-secondary btn-sm" style={{ "--bs-btn-padding-y": ".15rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} onClick={openModal} disabled={showLoading} >
                                    <FontAwesomeIcon icon={faBoltLightning} className="pe-1" />
                                    Startup
                                </button>
                            </th>
                            <th scope="col" className="col-3">PI</th>
                            <th scope="col" className="col-3 nowrap">GRA & GSA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="text-end">Startup PP:</th>
                            <td className="text-end"><NumericFormat value={props.accountDetail ? isNumericDefault(props.accountDetail.ippPI, '') : ''} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                            <td className="text-end"><NumericFormat value={props.accountDetail ? isNumericDefault(props.accountDetail.ippGRA, '') : ''} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                        </tr>
                        <tr>
                            <th className="text-end">Used PP:</th>
                            <td className="text-end"><NumericFormat value={(actualPayPeriods ? isNumericDefault(actualPayPeriods.PIPayPeriods, 0) : 0) + (props.accountDetail ? isNumericDefault(props.accountDetail.ippPIActAdj, 0) : 0)} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                            <td className="text-end"><NumericFormat value={(actualPayPeriods ? isNumericDefault(actualPayPeriods.GRAPayPeriods, 0) : 0) + (props.accountDetail ? isNumericDefault(props.accountDetail.ippGRAActAdj, 0) : 0)} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                        </tr>
                        <tr>
                            <th className="text-end">Forecasted PP:</th>
                            <td className="text-end"><NumericFormat value={forecastedPayPeriods ? isNumericDefault(forecastedPayPeriods.PI, 0) : 0} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                            <td className="text-end"><NumericFormat value={forecastedPayPeriods ? isNumericDefault(forecastedPayPeriods.GRA, 0) : 0} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                        </tr>
                        <tr>
                            <th className="text-end">Available PP:</th>
                            <td className="text-end"><NumericFormat value={getAvailablePIPayPeriods()} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                            <td className="text-end"><NumericFormat value={getAvailableGRAPayPeriods()} renderText={(value) => value} displayType="text" decimalScale={2} fixedDecimalScale thousandSeparator="," /></td>
                        </tr>
                    </tbody>
                </table>
                :
                <button type="submit" className="btn btn-secondary btn-sm" style={{ "--bs-btn-padding-y": ".15rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }} onClick={openModal} disabled={showLoading} >
                    <FontAwesomeIcon icon={faBoltLightning} className="pe-1" />
                    Startup
                </button>
            }
            <Modal isOpen={modal} toggle={modalToggle} size="sm">
                <ModalHeader toggle={modalToggle} tag="div" className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5><FontAwesomeIcon icon={faBoltLightning} className="pe-2" />Startup</h5>
                        {saveError !== null ?
                            saveError ?
                                <div className="col-auto pe-3">
                                    <span className="badge bg-danger rounded-square me-1">&nbsp;</span>Error saving
                                </div>
                                :
                                <div className="col-auto pe-3">
                                    <span className="badge bg-success rounded-square me-1">&nbsp;</span>Saved
                                </div>
                            :
                            null
                        }
                        {showLoading ?
                            <div className="col-auto">
                                Loading... <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                            </div>
                            : null}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive table-responsive-sm" style={{
                        "display": "block", "width": "100%", "overflowX": "auto", "msOverflowStyle": "-ms-autohiding-scrollbar",
                        "maxHeight": "calc(100vh - 225px)"
                    }}>

                        <table className="table table-sm table-xs table-hover mb-0" style={{ display: "table" }}>
                            <thead className="" >
                                <tr>
                                    <th>&nbsp;</th>
                                    <th className="table-light text-center nowrap">PI<br />Pay Periods</th>
                                    <th className="table-light text-center nowrap">GRA & GSA<br />Pay Periods</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="table-light text-end nowrap align-middle">Startup PP:</th>
                                    <td className="nowrap">
                                        <NumericFormat
                                            value={startUpPayPeriods && isNumeric(startUpPayPeriods.PI) ? startUpPayPeriods.PI : ''}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator=","
                                            className="form-control form-control-sm text-end fw-bold"
                                            name="PI"
                                            onValueChange={(v, si) => handleLedgerNumericChange({}, v, si)}
                                        />
                                    </td>
                                    <td className="nowrap">
                                        <NumericFormat
                                            value={startUpPayPeriods && isNumeric(startUpPayPeriods.GRA) ? startUpPayPeriods.GRA : ''}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator=","
                                            className="form-control form-control-sm text-end fw-bold"
                                            name="GRA"
                                            onValueChange={(v, si) => handleLedgerNumericChange({}, v, si)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="table-light text-end nowrap align-middle">Adjustments:</th>
                                    <td className="nowrap">
                                        <NumericFormat
                                            value={startUpPayPeriods && isNumeric(startUpPayPeriods.PIAdj) ? startUpPayPeriods.PIAdj : ''}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator=","
                                            className="form-control form-control-sm text-end fw-bold"
                                            name="PIAdj"
                                            onValueChange={(v, si) => handleLedgerNumericChange({}, v, si)}
                                        />
                                    </td>
                                    <td className="nowrap">
                                        <NumericFormat
                                            value={startUpPayPeriods && isNumeric(startUpPayPeriods.GRAAdj) ? startUpPayPeriods.GRAAdj : ''}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator=","
                                            className="form-control form-control-sm text-end fw-bold"
                                            name="GRAAdj"
                                            onValueChange={(v, si) => handleLedgerNumericChange({}, v, si)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-end">
                    <Button color="secondary" onClick={modalToggle} className="mx-2">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSave} disabled={!hasChanges}>
                        <FontAwesomeIcon icon={saving ? faSpinner : faFloppyDisk} spin={saving} className="me-2" />
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
