import React, { useState, useEffect, useCallback } from 'react';

function useLookup() {

    const [ledgerAccountDirectory, setLedgerAccountDirectory] = useState(null);

    useEffect(() => {
        //if (!ledgerAccountDirectory) {
        //    getLedgerAccountsData();
        //}

    }, []);

    const getLedgerAccountsData = useCallback(async () => {
        if (!ledgerAccountDirectory) {
            let params = sessionStorage.getItem('ledgerAccountDirectory');
            if (params) {
                return JSON.parse(params);
            }
            else {
                return await fetch('/api/lookup/ledgeraccounts')
                    .then(res => res.json())
                    .then(data => {
                        setLedgerAccountDirectory(data);
                        sessionStorage.setItem('ledgerAccountDirectory', JSON.stringify(data))
                        return data;
                    });
            }
        }
        else
            return ledgerAccountDirectory;
    }, []);


    return {
        getLedgerAccountsData
    };
}
export default useLookup;